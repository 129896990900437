import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse }	from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import * as moment from 'moment' 

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  baseUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) { }
  
  saveBrowser(id, data){
    return this.http.post<any>(`${this.baseUrl}/api/login/${id}/save_browser/`, data);
  }

  getDataBrowser(){
    return this.http.get<any>(`${this.baseUrl}/api/login/save_browser/`);
  }

  getDataForCharts() : Observable<HttpResponse<any>>{
    //write in params filters in case necessary
    //let from = moment(filters.from_date).format("YYYY-MM-DD");
    //let to = moment(filters.to_date).format("YYYY-MM-DD");
    return this.http.get<any>(`${this.baseUrl}/api/login/data_for_charts_browser/`, {observe : 'response'});
  }

  getDownloadReport(filters){
  	let from = moment(filters.from_date).format("YYYY-MM-DD");
  	let to = moment(filters.to_date).format("YYYY-MM-DD");
  	return this.http.get(`${this.baseUrl}/api/login/download_report?start_date=${from}&end_date=${to}`, {responseType: 'blob'});
  }

}
