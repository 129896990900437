import { Component, OnInit, TemplateRef } 		from '@angular/core';
import { Router }								from '@angular/router';
import { BsModalService } 						from 'ngx-bootstrap/modal';
import { BsModalRef } 							from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CommunicationService }					from '../_services';
import { CommLogs }								from '../_models';
import * as globals from '../globals.json';

@Component({
  selector: 'app-email-detail',
  templateUrl: './email-detail.component.html',
  styleUrls: ['./email-detail.component.css']
})

export class EmailDetailComponent implements OnInit {
  log: CommLogs = new CommLogs;
  modal_carousel: BsModalRef;
  public globals: any = globals.default;
  lastAttachmentClicked;
  constructor(
  		private CS: CommunicationService,
  		private router: Router,
  		private modalService: BsModalService
  	) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  	const current_path = this.router.url;
  	const path = current_path.split('/');
  	const log_id = +path[path.length - 1];
  	this.getLogById(log_id);
  }

  getLogById(id: number) {
  	this.CS.getLogById(id)
  		.subscribe(response => {
  			this.log = response.body[0];
        document.getElementById('logMessage').innerHTML = this.log.message;
        document.getElementsByTagName('button')[0].hidden = true;
        console.log('message log ', this.log );
  		}, error => {
  			console.log('Error getting log by id: ', error);
  		});
  }

  async downloadImages(image_urls) {
    console.log('iamgeus : ', image_urls);
    if (image_urls.length) {
      for (const url of image_urls) {
        await this.downloadAnImage(url);
      }
    }
  }

  downloadAnImage(url: string) {
    if (url.length) {
        // let link = document.createElement("a");
        // link.href = url;
        // link.setAttribute('visibility','hidden');
        // link.download;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        window.open(url, '_blank');
    }
  }

  openCarouselModal(template, url) {
    this.lastAttachmentClicked = url;
    this.openModal(template);
  }

  openModal(template: TemplateRef<any>) {
    if (this.log.attachments.length) {
      this.modal_carousel = this.modalService.show(template);
    }
  }

  // This is use so the last image clicked is the one that open first in the carousel
  orderedAttachments() {
    if (!this.log) {
      return [];
    }

    const indexLastClicked = this.log.attachments.findIndex(l => l === this.lastAttachmentClicked);

    if (indexLastClicked === -1) {
      return this.log.attachments;
    }

    const beforeFound = this.log.attachments.slice(0, indexLastClicked);

    const afterFound = this.log.attachments.slice(indexLastClicked + 1);

    return [this.lastAttachmentClicked, ...afterFound, ...beforeFound];
  }
}
