import { Component, OnInit } from "@angular/core";
import { CryptoService, PaymentsService } from "src/app/_services";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { ViewChild } from "@angular/core";

@Component({
  selector: "app-sync-payments",
  templateUrl: "./sync-payments.component.html",
  styleUrls: ["./sync-payments.component.css"],
})
export class SyncPaymentsComponent implements OnInit {
  @ViewChild("syncFailedPayments")
  private syncFailedPaymentsSwal: SwalComponent;

  constructor(
    private paymentService: PaymentsService,
    private cryptoService: CryptoService
  ) {}
  public interval;
  public user: any;
  public syncing: boolean = false;
  public loading: boolean = false;
  public failedPaymentsCount: Number;
  public syncStatus: any;
  public syncingString: string = "Syncing Payments";
  public syncString: string = "Sync Payments";

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);

    if (window.location.href.includes("/es/")) {
      this.syncingString = "Sincronizando pagos";
      this.syncString = "Sincronizar pagos";
    }

    this.syncing = true;
    if (this.user.user_type_id != 3) {
      this.paymentService.getSyncPaymentStatus().subscribe((response) => {
        this.failedPaymentsCount = response.current_failed_payments;
        this.syncStatus = response;

        if (response.end_date != null) {
          this.syncing = false;
        }
      });
    }
  }

  ngAfterViewInit() {
    this.interval = setInterval(() => {
      if (this.user.user_type_id != 3 && this.failedPaymentsCount > 0) {
        this.paymentService.getSyncPaymentStatus().subscribe((response) => {
          this.failedPaymentsCount = response.current_failed_payments;
          this.syncStatus = response;

          if (response.end_date != null) {
            this.syncing = false;
          }
        });
      }
    }, 2000);
  }

  syncPayments() {
    this.paymentService.syncPayments().subscribe(
      (response) => {
        this.syncStatus.total_payments = this.failedPaymentsCount;
        this.syncing = true;
      },
      (error) => {
        console.log("error: ", error);
      }
    );
  }
}
