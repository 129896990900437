import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WaterConsumptionService {
    baseUrl: string = environment.serverUrl;

    constructor(private http: HttpClient) { }

    getMapData(beginMonth, beginYear, endMonth, endYear) {
        return this.http.get<any>(`${this.baseUrl}/api/water_consumption/map?beginMonth=${beginMonth}&beginYear=${beginYear}&endMonth=${endMonth}&endYear=${endYear}`);
    }
}