import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Router, ActivatedRoute } from "@angular/router";
import * as globals from "../globals.json";
import * as moment from "moment";

import {
  CustomerService,
  UsioService,
  PaymentsService,
  CryptoService,
} from "src/app/_services";
import { User, Account, UserSettings, UserInfo } from "src/app/_models";
import { TermsComponent } from "../_common/terms/terms.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { environment } from "../../environments/environment";

// import Swal from 'sweetalert2';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FeesComponent } from "../_common/fees/fees.component";
import { FeeCalculator } from "../_helpers";
import { MONTHS } from "../constants.util";

// import { setCheckout } from '../../../vendor/pds/checkout.js';

declare var $: any;

@Component({
  selector: "app-make-payment",
  templateUrl: "./make-payment.component.html",
  styleUrls: ["./make-payment.component.css"],
})
export class MakePaymentComponent implements OnInit {
  @ViewChild("successAlert") private successAlert: SwalComponent;
  @ViewChild("errorAlert") private errorAlert: SwalComponent;
  @ViewChild("paymentSettings") private paymentSettingsSwal: SwalComponent;
  @ViewChild("ccdetailsErrorAlert")
  private ccdetailsErrorAlertSwal: SwalComponent;
  @ViewChild("enterCCDetails") private enterCCDetailsSwal: SwalComponent;

  public form: FormGroup;
  public globals: any = globals.default;
  public user: UserInfo;
  public accountId: Number;
  public account: Account;
  public userSettings: UserSettings;
  public errorMessage: String = String();
  public ccForm: FormGroup;

  public paymentConfirmation: any;

  public pdsInfoId: string;
  public savePaymentMethod: boolean = false;
  public autopay: boolean = false;

  public showPayNowPanel: boolean = false;
  public showConfirmationPanel: boolean = false;
  public disabledPayNowButton: boolean = false;
  public isDepositBill: boolean = false;
  public allowQuickpay: boolean = false;

  public payType: string = "full";
  public payAmount: number = 0.0;
  public payFees: string = "0.0275 | 1.00";
  public payFeesFirstEntry = parseInt(this.payFees.split("|")[0]);
  public authToken: string;
  public cardType: String;

  public loading: boolean = false;
  public ccNumber: number;

  public bsModalRef: BsModalRef;
  public paymentSettingsTitle: string = "Update Payment Settings";
  public paymentSettingsHtml: string = `<div class='row p-sm'>
  <div class='col-md-12'>
      <div class='pull-right'>
          <div class='switch'>
              <div class='onoffswitch'>
                  <input type='checkbox' class='onoffswitch-checkbox' id='save_payment_method_checkbox'>
                  <label class='onoffswitch-label' for='save_payment_method_checkbox'>
                      <span class='onoffswitch-inner'></span>
                      <span class='onoffswitch-switch'></span>
                  </label>
              </div>
          </div>
      </div>
      <h4>Save Payment Method</h4>
  </div>
  <div class='col-md-12'>
      <div class='pull-right'>
          <div class='switch'>
              <div class='onoffswitch'>
                  <input type='checkbox' class='onoffswitch-checkbox' id='autopay_checkbox'>
                  <label class='onoffswitch-label' for='autopay_checkbox'>
                      <span class='onoffswitch-inner'></span>
                      <span class='onoffswitch-switch'></span>
                  </label>
              </div>
          </div>
      </div>
      <h4>Autopay</h4>
  </div>
</div>`;
  public quickpayAlertTitle = "Quickpay";
  public quickpayAlertText1 = "Continue with ";
  public quickpayAlertText2 = " payment using credit card on file?";
  public successAlertTitle = "Success";
  public errorAlertTitle = "Error";
  public errorAlertText = "Something went wrong!";

  public months: Array<object> = [];

  constructor(
    private customerService: CustomerService,
    private usioService: UsioService,
    private paymentService: PaymentsService,
    private router: Router,
    private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private fb: FormBuilder,
    private feeCalculator: FeeCalculator,
    private cryptoService: CryptoService
  ) {
    this.ccForm = this.fb.group({
      ccNum: ["", Validators.required],
      exp_month: [
        "",
        [Validators.minLength(2), Validators.maxLength(2), Validators.required],
      ],
      exp_year: [
        "",
        [Validators.minLength(4), Validators.maxLength(4), Validators.required],
      ],
      security_code: [
        "",
        [Validators.minLength(3), Validators.maxLength(4), Validators.required],
      ],
    });

    this.months = MONTHS;
  }

  openFeesModal() {
    this.bsModalRef = this.bsModalService.show(
      FeesComponent,
      Object.assign(
        {},
        {
          class: "gray modal-lg inmodal animated bounceInRight",
          backdrop: false,
          ignoreBackdropClick: true,
        }
      )
    );
    this.bsModalRef.content.subscribeToSettings();
    this.bsModalRef.content.closeBtnName = "Close";
  }

  ngOnInit() {
    console.log("route/params: ", this.route.snapshot.params);
    console.log("route/params: ", this.route.snapshot);
    console.log("route/params: ", this.route);

    if (this.route.snapshot.params.pdsInfoId) {
      // console.log("route/params: ", this.route.snapshot.params);
      this.showPayNowPanel = false;
      this.pdsInfoId = this.route.snapshot.params.pdsInfoId;
      setTimeout(() => {
        if (this.pdsInfoId == "error") {
          this.errorAlert.show();
        } else {
          this.showConfirmationPanel = true;
          if (this.user.user_type_id == 3) {
            this.customerService.getPDSDetails(this.pdsInfoId).subscribe(
              (data) => {
                console.log("data: ", data);
                this.paymentConfirmation = data;
                // let fee = +this.paymentConfirmation.fees;
                this.paymentConfirmation.total_amount =
                  +this.paymentConfirmation.amount +
                  +this.paymentConfirmation.fees;
                this.paymentConfirmation.service_fee =
                  +this.paymentConfirmation.fees;
                let type = "";
                switch (this.paymentConfirmation.payment_type) {
                  case "VISA" || "MSTR" || "DISC" || "AMEX":
                    type = "cc";
                    break;
                  default:
                    type = this.paymentConfirmation.payment_type;
                    break;
                }
                this.paymentConfirmation.ipay_fee =
                  +this.feeCalculator.getIPayFeeDelta(
                    this.paymentConfirmation.amount,
                    type
                  )["fee"];
                console.log("PDS INFO : ", data);

                let convenience_fee_amount = 0;
                if (this.paymentConfirmation.payment_type == "ach") {
                  let ach_fee = +this.paymentConfirmation.fees.split("|")[1];
                  if (ach_fee > 0.04) {
                    convenience_fee_amount = ach_fee;
                  } else {
                    convenience_fee_amount =
                      +this.paymentConfirmation.amount * ach_fee;
                  }
                } else {
                  let cc_fee = this.paymentConfirmation.fees.split("|")[0];
                  if (cc_fee > 0.04) {
                    convenience_fee_amount = cc_fee;
                  } else {
                    convenience_fee_amount =
                      +this.paymentConfirmation.amount * cc_fee;
                  }
                }
                // let fee = this.paymentConfirmation.fees.split('|');

                this.paymentConfirmation.total_amount =
                  +convenience_fee_amount + +this.paymentConfirmation.amount;
                this.paymentConfirmation.service_fee = convenience_fee_amount;
                console.log("paymentConfirmation: ", this.paymentConfirmation);
              },
              (err) => {
                this.errorAlert.show();
              }
            );
          } else {
            this.errorAlert.show();
          }
        }
      }, 200);
    }

    this.user = JSON.parse(window.localStorage.current_user);
    this.authToken = window.localStorage.access_token;
    this.accountId = this.route.snapshot.params.accountId;
    console.log("USER : ", this.user);
    console.log("ACCOUNTID : ", this.accountId);
    this.setPayNowButton();

    if (window.location.href.includes("/es/")) {
      this.paymentSettingsTitle = "Actualizar Configuraciones de Pago";
      this.paymentSettingsHtml = `<div class='row p-sm'>
  <div class='col-md-12'>
      <div class='pull-right'>
          <div class='switch'>
              <div class='onoffswitch'>
                  <input type='checkbox' class='onoffswitch-checkbox' id='save_payment_method_checkbox'>
                  <label class='onoffswitch-label' for='save_payment_method_checkbox'>
                      <span class='onoffswitch-inner'></span>
                      <span class='onoffswitch-switch'></span>
                  </label>
              </div>
          </div>
      </div>
      <h4>Guardar Metodo de Pago</h4>
  </div>
  <div class='col-md-12'>
      <div class='pull-right'>
          <div class='switch'>
              <div class='onoffswitch'>
                  <input type='checkbox' class='onoffswitch-checkbox' id='autopay_checkbox'>
                  <label class='onoffswitch-label' for='autopay_checkbox'>
                      <span class='onoffswitch-inner'></span>
                      <span class='onoffswitch-switch'></span>
                  </label>
              </div>
          </div>
      </div>
      <h4>Auto Pago</h4>
  </div>
</div>`;
      this.quickpayAlertTitle = "Pago Rapido";
      this.quickpayAlertText1 = "Continuar con el pago ";
      this.quickpayAlertText2 = " usando la tarjeta de credito del archivo?";
      this.successAlertTitle = "Exito";
      this.errorAlertTitle = "Error";
      this.errorAlertText = "Ocurrio un error!";
    }
  }

  ngAfterViewInit() {
    this.customerService.getAllAccounts(this.user.id).subscribe((response) => {
      this.account = response.filter((x) => x.id == this.accountId)[0];
      console.log("ACCOUNT : ", this.account);
      if (!this.account) {
        this.router.navigate(["/404"]);
      }

      console.log("PARAMS : ", this.route.snapshot.params);
      if (!this.route.snapshot.params.pdsInfoId) {
        this.viewPayPanel();
      }
      this.setPayAmount();

      //FOR QUICKPAY

      // this.customerService.checkIfUserCanUseQuickpay(this.user.id, this.account.id)
      //   .subscribe(
      //     response => {
      //       console.log("response: ", response);
      //       this.allowQuickpay = response.allow_quickpay;
      //     }
      //   )
    });

    this.customerService
      .getCustomerSettingsById(this.user.id)
      .subscribe((data) => {
        this.userSettings = data;
      });

    this.customerService
      .getAllCustomerBillsByUserId(this.user.id)
      .subscribe((data) => {
        if (data.body.total == 1 && data.body.bills[0].is_deposit_fee) {
          this.isDepositBill = true;
        }
      });
  }

  triggerTermsModal() {
    const tModalRef = (this.bsModalRef = this.bsModalService.show(
      TermsComponent,
      Object.assign(
        {},
        {
          class: "gray modal-lg inmodal",
          backdrop: true,
          ignoreBackdropClick: false,
        }
      )
    ));
  }

  goToDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  viewPayPanel() {
    this.showPayNowPanel = true;
    // this.payAmount = this.account.balance;

    // $('#checkoutscript').attr('pds-amount', this.payAmount);
    // $('#checkoutscript').attr('pds-email', this.user.email);
    // $('#checkoutscript').attr('pds-fees', this.payFees);
  }

  setPayAmount() {
    // console.log("balance: ", parseFloat(this.account.balance) + 500.00);
    this.payAmount = this.account.balance;

    if (this.payAmount <= +this.account.balance + 500.0 && this.payAmount > 1) {
      $("#checkoutscript").attr("pds-amount", this.payAmount);
      $(".themeBlue").attr("disabled", false);
      this.disabledPayNowButton = false;
    } else {
      $(".themeBlue").attr("disabled", true);
      this.disabledPayNowButton = true;
    }
  }

  acceptPayment() {
    this.loading = true;
    let payment = {
      account_id: this.accountId,
      pds_info_id: this.pdsInfoId,
    };

    this.customerService.makeCustomerPayment(payment).subscribe(
      (data) => {
        this.loading = false;
        this.router.navigate(["/payments", data.id, "receipt"]);
      },
      (err) => {
        console.log("err: ", err);

        if (err.message.includes(":")) {
          this.errorAlert.text = err.message.split(":")[1];
        }

        this.loading = false;
        this.errorAlert.show();
      }
    );
  }

  declinePayment() {
    this.router.navigate(["/payments/new", this.accountId]);
  }

  makeQuickPayment() {
    let payment = {
      account_id: this.accountId,
      amount: this.payAmount,
      fees: this.payFees,
    };

    this.customerService.makeQuickPayment(payment).subscribe(
      (data) => {
        this.router.navigate(["/payments", data.id, "receipt"]);
      },
      (err) => {
        // code-1321 - something wrong with service address
        if (err.message.includes(":")) {
          this.errorAlert.text = err.message.split(":")[1];
        }
        this.errorAlert.show();
      }
    );
  }

  setSettings() {
    $("#save_payment_method_checkbox").attr(
      "checked",
      this.userSettings.save_payment_method
    );
    $("#autopay_checkbox").attr("checked", this.userSettings.autopay);
  }

  saveSettings() {
    let user_settings = {
      save_payment_method: $("#save_payment_method_checkbox").is(":checked"),
      autopay: $("#autopay_checkbox").is(":checked"),
      email_notifications: this.userSettings.email_notifications || false,
    };

    this.customerService
      .updateCustomerSettings(this.user.id, user_settings)
      .subscribe(
        (data) => {
          this.userSettings = data[0];

          this.successAlert.title = "Success!";
          this.successAlert.text = "Settings updated";
          if (window.location.href.includes("/es/")) {
            this.successAlert.title = "Excelente!";
            this.successAlert.text = "Ajustes Actualizados";
          }
          this.successAlert.show();
        },
        (err) => {
          this.errorAlert.show();
        }
      );
  }

  openCCDetailsModal(template: TemplateRef<any>) {
    this.bsModalRef = this.bsModalService.show(template);
    console.log("PAY AMOUNT : ", this.payAmount);
  }

  get ccNum() {
    return this.ccForm.get("ccNum");
  }
  get exp_month() {
    return this.ccForm.get("exp_month");
  }
  get exp_year() {
    return this.ccForm.get("exp_year");
  }
  get security_code() {
    return this.ccForm.get("security_code");
  }

  setPayNowButton() {
    $("#paymentform").submit(function (event) {
      var $Scripts = $("#checkoutscript"); //Used to get values from script

      var $Params =
        "?pds-key=" +
        $Scripts.attr("pds-key") +
        "&pds-image=" +
        $Scripts.attr("pds-image") +
        "&pds-name=" +
        $Scripts.attr("pds-name") +
        "&pds-description=" +
        $Scripts.attr("pds-description") +
        "&pds-button=" +
        $Scripts.attr("pds-button") +
        "&pds-amount=" +
        $Scripts.attr("pds-amount") +
        "&pds-theme=" +
        $Scripts.attr("pds-theme") +
        "&pds-paymenttypes=" +
        $Scripts.attr("pds-paymenttypes") +
        "&pds-email=" +
        $Scripts.attr("pds-email") +
        "&pds-pinless=" +
        $Scripts.attr("pds-pinless") +
        "&pds-fees=" +
        $Scripts.attr("pds-fees") +
        "&pds-pinlesscredit=" +
        $Scripts.attr("pds-pinlesscredit");

      var $HTML =
        "<iframe " +
        "id='paymentapp' " +
        "src='https://checkout.usiopay.com/checkout/payment.aspx" +
        $Params +
        "' " +
        "frameborder='0' " +
        "onload='scrollTo(0,0)' " +
        "style='background: rgba(0, 0, 0, 0.5); " +
        "margin: 0px; " +
        "padding: 0px; " +
        "border: 0px transparent; " +
        "border-image: none; " +
        "left: 0px; " +
        "top: 0px; " +
        "width: 100%; " +
        "height: 100%; " +
        "display: block; " +
        "visibility: visible; " +
        "position: fixed; " +
        "z-index: 2147483647; " +
        "-ms-overflow-x: hidden; " +
        "-ms-overflow-y: auto; " +
        "-webkit-touch-callout: none; " +
        "-webkit-tap-highlight-color: transparent;' " +
        "allowtransparency='true' >" +
        "</iframe>";

      $("body").append($HTML);

      // Prevent the form from submitting with the default action
      return false;
    });

    //Add button to form
    var $Scripts = $("#checkoutscript");
    $("head").append(
      $(
        '<link href="https://checkout.usiopay.com/checkout/checkout.css" rel="stylesheet" />'
      )
    );
    var $Theme = "themeBlue";
    switch ($Scripts.attr("pds-theme").toLowerCase()) {
      case "blue":
        $Theme = "themeBlue";
        break;
      case "darkblue":
        $Theme = "themeDarkBlue";
        break;
      case "aqua":
        $Theme = "themeAqua";
        break;
      case "green":
        $Theme = "themeGreen";
        break;
      case "yellow":
        $Theme = "themeYellow";
        break;
      case "orange":
        $Theme = "themeOrange";
        break;
      case "red":
        $Theme = "themeRed";
        break;
      case "fuchsia":
        $Theme = "themeFuchsia";
        break;
      case "purple":
        $Theme = "themePurple";
        break;
      case "gray":
        $Theme = "themeGray";
        break;
      case "black":
        $Theme = "themeBlack";
        break;
      default:
        $Theme = "themeBlue";
        break;
    }

    var $form = $("#paymentform");
    $form.append(
      $(
        '<button class="button ' +
          $Theme +
          '" type="submit" >' +
          $Scripts.attr("pds-button") +
          "</button>"
      )
    );
  }

  setMonth(month: any) {
    this.ccForm.patchValue({ exp_month: month.value });
    console.log(this.exp_month.value);
  }
}
