export const MONTHS = [
  {
    value: '01',
    name: '01'
  },
  {
    value: '02',
    name: '02'
  },
  {
    value: '03',
    name: '03'
  },
  {
    value: '04',
    name: '04'
  },
  {
    value: '05',
    name: '05'
  },
  {
    value: '06',
    name: '06'
  },
  {
    value: '07',
    name: '07'
  },
  {
    value: '08',
    name: '08'
  },
  {
    value: '09',
    name: '09'
  },
  {
    value: '10',
    name: '10'
  },
  {
    value: '11',
    name: '11'
  },
  {
    value: '12',
    name: '12'
  }
];