import { Component, OnInit } from '@angular/core';
import { TermsComponent } from '../_common/terms/terms.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

  bsModalRef : BsModalRef;

  constructor(
    private bsModalService : BsModalService
  ) { }

  ngOnInit() {
    const initialState = {
      class: 'gray modal-md inmodal',
      backdrop: true,
      ignoreBackdropClick: true
    } 
    this.bsModalRef = this.bsModalService.show(TermsComponent,{initialState});
  }

}
