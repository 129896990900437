import { Component, OnInit, ViewChild } 		from '@angular/core';
import { ForgotPasswordService }	from '../_services'
import { Router } 					from '@angular/router';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

declare var $:any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot_password.component.html',
  styleUrls: ['./forgot_password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

	loading:boolean = false
	sentMessage:string
  email : string
	@ViewChild('success') private successSwal: SwalComponent;
	@ViewChild('failure') private failureSwal: SwalComponent;
	@ViewChild('400failure') private notFoundFailureSwal: SwalComponent;

	public successAlertTitle = "A link has been sent to you";
	public _400failureAlertTitle = "The e-mail you entered is invalid or was not found";
	public failureAlertTitle = "Something went wrong, we are very sorry";
	public emailAddressText = "Email address";

  constructor(
  	private FPS: ForgotPasswordService,
  	private router: Router
  	) { }

  ngOnInit() {

	if(window.location.href.includes("/es/")){
		this.successAlertTitle = "Un enlace ha sido enviado a su correo";
		this._400failureAlertTitle = "El correo que ha ingresado no fue encontrado o no es válido";
		this.failureAlertTitle = "Lo sentimos, hubo un error";
		this.emailAddressText = "Dirección de Correo";

	}
  }

  onOpen(event){
	$('body').removeClass("swal2-height-auto");
  }

  emailLink(){
	this.loading = true;
  	this.FPS.emailResetPasswordLink(this.email)
  		.subscribe(response => {
				this.loading = false;
  			console.log("response", response);
  			if(response.status === 200){
					this.sentMessage = ""
					this.successSwal.show()
  			}
  		}, error => {
				console.log("error sending email", error);
				this.loading = false;
				if(error.message){
				if(error.message.code === 400 )
				this.sentMessage = ""
				this.notFoundFailureSwal.show()
		  	} else{
					this.sentMessage = ""
					this.failureSwal.show()
				}
  		});
	}

  navigateToLogin(){
    this.router.navigate(['/login'])
  }

	void(){

	}
}
