export class CommLogs {
	id				: number;
    type			: number;
    _to 				: string[];
    from 			: string;
    message 		: string;
    subject 		: string;
    attachments 	: string[];
    date_created 	: string;
}
