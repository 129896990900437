import { Component, OnInit, Renderer2, ViewChild, TemplateRef, Inject, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
/* SERVICES */
import { CommunicationService, TypeService, UserService } from '../_services';
/* MODELOS */
import { CommLogs, AccountTypes, User, Notification } from '../_models';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { Location, DOCUMENT } from '@angular/common';
import * as globals from '../globals.json';
import { ToastrService } from 'ngx-toastr';
import { NgModel } from '@angular/forms';
/* LIBRERIA VALIDACIONES */
import { FormBuilder, Validators } from '@angular/forms';
/* RXJS */
import { debounceTime, switchMap, tap, share, map } from 'rxjs/operators';
import { observable, Observable, Subject } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { a } from '@angular/core/src/render3';
declare var $: any;

// export const EMAIL_FROM = "waterdept@cityoflajoya.com";
export const EMAIL_FROM = 'water@cityofdonna.org';

@Component({
  selector: "app-communication",
  templateUrl: "./communication.component.html",
  styleUrls: ["./communication.component.css"],
})
export class CommunicationComponent implements OnInit {
  public paginationMaxSize = 5;
  public paginationPageLimit = 10;
  public paginationTotalUsers = 0;
  public paginationCurrentPage = 1;
  public loading = false;
  public inputFiles: File[] = [];
  public filesToUpload: File[] = [];
  public viewFiles: any[] = [];
  public globals: any = globals.default;
  public activeTab: string;
  public activeTabContent: any;
  public accountTypes: AccountTypes[] = [];
  public miModal = "";
  private today;
  private row_date;
  private modal_carousel: BsModalRef;
  public row_notifi: Notification = new Notification();
  public LoggedUser;


  /* PAGINAR TABLA */
  ItemsTableNotifications = 5;
  /* INICIO DE PAGINA */
  PosicionArray = 0;
  /* NUMERO DE PAGINAS */
  TotalPages = 0;
  /* PAGINA ACTUAL */
  paginationCurrentPageNotifi = 1;
  /* BACK PAGE */
  paginationBackCurrentPageNotifi = 0;
  /* NEXT PAGE */
  paginationNextCurrentPageNotifi = 0;
  limitpagesNotifi = 0;


  @Output()
  public vewNotifications = new EventEmitter<boolean>();
  @Inject(DOCUMENT) private document: Document;
  @ViewChild("successSwal") private successSwal: SwalComponent;
  @ViewChild("errorSwal") private errorSwal: SwalComponent;

  @ViewChild("successSwalNotif") private successSwalNotif: SwalComponent;
  @ViewChild("errorSwalNotif") private errorSwalNotif: SwalComponent;
  @ViewChild("deleteSwalNotif") private deleteSwalNotif: SwalComponent;
  @ViewChild("datePicker") dateRangeDOM;

  @Input() notificatio: boolean = false;

  // public image_preview : any;
  // public images_urls : any = [];

  /* -----------ARRAY NOTIFICATIONS---------------- */
  allnotifications: Notification[] = [];
  PageNotifications: Notification[] = [];
  /* ---------------------------------------------- */
  searchText: String = "";
  comm_logs: CommLogs[] = [];
  account_types: AccountTypes[] = [];
  users: any = [];
  zones: any = [];

  submitNotif: any = {
    paginationCurrentPage: Number,
    paginationPageLimit: Number
  };

  log_filters: any = {
    from_date: String,
    to_date: String,
  };
  emailForm: any = {
    to: String,
    customerType: Number,
    subject: String,
    message: String,
    images: String,
  };

  notification: any = {
    reason_id: Number,
    title: String,
    message: String,
    type: String,
    schedule: String,
    account_type: Number,
    position: String,
  };

  IdDate: any = {
    id: Number,
    date: String
  };
  editorConfig: any = {};
  showErrorMessage: Boolean = false;

  showErrorNotif: Boolean = false;

  public searchBoxPlaceHolder = "Search";
  public subjectPlaceholder = "Subject";
  /* CONSTRUCTOR */
  constructor(
    private CS: CommunicationService,
    private TS: TypeService,
    private US: UserService,
    private location: Location,
    private router: Router,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    /* OBJETO FORMULARIO */
    private fb: FormBuilder
  ) {

  }
  public submitText = "Submit";


  /* ngOnInit */
  ngOnInit() {

    this.activeTab = "logs";
    // console.log("called ngOnInit");
    this.log_filters.from_date = moment().startOf("month").format("YYYY-MM-DD");
    this.log_filters.to_date = moment().endOf("month").format("YYYY-MM-DD");
    // console.log("initial from date", this.log_filters.from_date);
    // console.log("initial to date", this.log_filters.to_date);
    this.emailForm.customerType = null;
    this.emailForm.subject = null;
    this.emailForm.message = null;
    this.emailForm.to = [];
    this.emailForm.from = this.globals.cityContactEmail;

    $.trumbowyg.svgPath = "/assets/icons.svg";
    $("#trumbowyg").trumbowyg(this.editorInit());

    const domRange = this.dateRangeDOM.elementRef.nativeElement;

    $("#trumbowyg").attr("placeholder", "Write your message here...");
    if (window.location.href.includes("/es/")) {
      this.searchBoxPlaceHolder = "Buscar";
      this.subjectPlaceholder = "Asunto";

      this.submitText = "Buscar";
      $("#trumbowyg").attr("placeholder", "Escriba su mensaje aqui...");
      domRange.getElementsByClassName("yk-label")[0].innerText = "Inicio";
      domRange.getElementsByClassName("yk-label")[1].innerText = "Fin";
      domRange.getElementsByClassName("yk-btn")[0].innerText = "Este Mes";
      domRange.getElementsByClassName("yk-btn")[1].innerText = "Ultimo Mes";
      domRange.getElementsByClassName("yk-btn")[2].innerText = "Esta Semana";
      domRange.getElementsByClassName("yk-btn")[3].innerText = "Ultima Semana";
      domRange.getElementsByClassName("yk-btn")[4].innerText = "Este Año";
      domRange.getElementsByClassName("yk-btn")[5].innerText = "Ultimo Año";
    }

    this.TS.getAccountTypes_support().subscribe((data) => {
      this.accountTypes = data;
    });
    /* FILL THE ARRAY NOTIFICATIONS */
    /*     this.signupRequests$ = this.searchTerms.pipe(
          debounceTime(200),
          switchMap((term: string) => this.CS.getAllNotifications()
            .pipe(
              tap(data => {
                console.log("Notifications: ", data);
                this.loading = false;
      
              })
            )),
          share()
        ) */
    /* LLENAR TABLE */
    this.getAllNotifications();

    /* OBTENER FECHA ACTUAL */
    this.today = moment().format('YYYY-MM-DD');
    /* --------------- */
    this.nextback();

  }
  /* END ONINTI */

  /* VALIDACIONES NOTIFICACIONES */
  formControl = this.fb.group({
    reason: ['', [Validators.required]],
    date_c: ['', [Validators.required]],
    account_type: ['', [Validators.required]],
    title: ['', [Validators.required]],
    message: ['', [Validators.required]]
  });
  /* VALIDADOR DE CAMPO */
  isValidateField(field: string): boolean {

    return ((this.formControl.get(field).touched
      || this.formControl.get(field).dirty)
      && !this.formControl.get(field).valid);

  }
  /* MENSAJES DE VALIDACION */
  getErrorMessage(field: string) {
    let message;
    if (this.formControl.get(field).errors.required) {
      message = "Its field required";
      if (window.location.href.includes('/es/')) {
        message = "Este campo es requerido";
      }
    }
    return message;
  }

  newNotifi() {
    // this.row_notifi = new Notification();
    this.clearNotification();
    this.today = moment().format('YYYY-MM-DD');
    this.miModal = "new";
    // console.log(this.miModal);
  }
  editNotifi() {
    this.miModal = "edit";
    // console.log(this.miModal);
  }
  isValidateButton(): boolean {

    if (this.formControl.invalid) {
      // console.log("botones act");
      return true;
    } else {
      // console.log("botones act");
      return false;
    }

  }

  ngAfterViewInit() {
    // console.log("called ngAfterViewInit");
    const domRange = this.dateRangeDOM.elementRef.nativeElement;
    if (window.location.href.includes("/es/")) {
      domRange.getElementsByClassName("yk-weekday")[0].innerText = "Dom";
      domRange.getElementsByClassName("yk-weekday")[1].innerText = "Lun";
      domRange.getElementsByClassName("yk-weekday")[2].innerText = "Mar";
      domRange.getElementsByClassName("yk-weekday")[3].innerText = "Mie";
      domRange.getElementsByClassName("yk-weekday")[4].innerText = "Jue";
      domRange.getElementsByClassName("yk-weekday")[5].innerText = "Vie";
      domRange.getElementsByClassName("yk-weekday")[6].innerText = "Sab";
    }
  }

  ngAfterContentInit() {
    // console.log("called ngAfterContentInit");
    /*	$(document).ready(function() {
        $('.footable').footable();
    });*/

    this.loading = true;
    this.setDateRange({
      from: this.log_filters.from_date,
      to: this.log_filters.to_date,
    });
    this.submitFilters(
      this.log_filters.from_date,
      this.log_filters.to_date,
      this.paginationCurrentPage,
      this.paginationPageLimit,
      this.searchText
    );
    //this.getCommLogsByDateRange(this.log_filters.from_date, this.log_filters.to_date, this.paginationCurrentPage, this.paginationPageLimit);
    this.getAccountTypes();
  }

  closeSidebar() {
    window.scroll(0, 60);
    this.renderer.removeClass(document.body, "mini-navbar");
  }

  emailDetail(id: number) {
    window.open(`/communication/${id}`, "_blank");
  }

  submitFilters(from, to, page, limit, search) {
    this.loading = true;
    // console.log("on submitFilters from: ", from);
    // console.log("on submitFilters to: ", to);
    this.getCommLogsByDateRange(
      from,
      to,
      this.paginationCurrentPage,
      this.paginationPageLimit,
      search
    );
  }

  imageOnChange(fileInput: any) {
    // console.log("iamgeonhange: ", fileInput);
    if (fileInput.target.files && fileInput.target.files.length) {
      this.inputFiles = <Array<File>>fileInput.target.files;
      //this.viewFiles = [];

      Array.from(this.inputFiles).forEach((file) => {
        this.filesToUpload.push(file);
        // console.log("file: ", file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.viewFiles.push({ data: reader.result, name: file.name });
          // console.log("files? ", this.viewFiles);
        };
      });
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modal_carousel = this.modalService.show(template);
  }

  pageChanged(event: any) {
    this.paginationCurrentPage = event.page;
    this.search();
  }
  search() {
    // console.log("search");
    this.loading = true;
    this.submitFilters(
      this.log_filters.from_date,
      this.log_filters.to_date,
      this.paginationCurrentPage,
      this.paginationPageLimit,
      this.searchText
    );
  }

  clearSearchText() {
    this.searchText = "";
    this.search();
  }

  customerTypeSelected(id: number) {
    // console.log("CUSTOMER TYPE: ", id);
    if ((id || id === 0) && id != 100) {
      this.getUsersByAccountType(id);
    } else if (id == 100) {
      this.getAccountZones();
    } else {
      this.emailForm.to = [];
    }
  }

  setDateRange(range) {
    // console.log("setting date ranges: ", range);
    this.log_filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.log_filters.to_date = moment(range.to).format("YYYY-MM-DD");
    console.log(
      "initial dates",
      this.log_filters.from_date,
      this.log_filters.to_date
    );
  }

  sendEmail(data, fileData) {
    this.loading = true;
    this.showErrorMessage = false;
    data.message = $("#trumbowyg").trumbowyg("html");

    if (
      !(
        data &&
        data.subject &&
        (data.to.length > 0 || data.toZoneClient != null) &&
        data.customerType >= 0 &&
        data.message
      )
    ) {
      this.loading = false;
      this.showErrorMessage = true;
    } else {
      const formData: any = new FormData();
      const files: Array<File> = this.filesToUpload;
      formData.append("type", this.emailForm.customerType);
      delete data.customerType;

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i], files[i]["name"]);
        }
      }

      formData.append("data", JSON.stringify(data));
      // console.log(JSON.stringify(data));
      this.CS.sendEmail(formData).subscribe(
        (response) => {
          this.loading = false;
          if (response.status == 200) {
            this.successSwal.show();
            this.search();
          }
          console.log("email response: ", response);
        },
        (err) => {
          console.log("Error: ", err);
          this.loading = false;
          this.errorSwal.show();
        }
      );
    }
  }

  getCommLogs() {
    this.CS.getAll().subscribe(
      (response) => {
        // console.log("YO", response);
        this.loading = false;
        this.comm_logs = response.body.logs;
        // console.log("comm_logs: ", this.comm_logs);
      },
      (err) => {
        console.log("Erorr getting comm logs: ", err);
      },
      () => {
        console.log("finished gettings all logs");
      }
    );
  }

  getAllUsers() {
    this.US.getAll().subscribe(
      (users) => {
        this.users = users.map((u) => u[0]); ////FORMAT: [user email, maddr zipcode, saddr zipcode]
      },
      (error) => {
        console.log("Error getting users: ", error);
      },
      () => {
        console.log("finished getting users");
      }
    );
  }

  getCommLogsByDateRange(from, to, page, limit, search) {
    this.CS.getCommLogsByDateRange(from, to, page, limit, search).subscribe(
      (response) => {
        // console.log('logs by date: ', data);
        this.loading = false;
        this.paginationTotalUsers = response.body.total;
        this.comm_logs = response.body.logs;
        console.log("this.CommLogs: ", this.comm_logs);
      },
      (err) => {
        this.loading = false;
        console.log("err: ", err);
      },
      () => {
        this.loading = false;
        console.log("finished getting logs by date");
      }
    );
  }

  getAccountTypes() {
    this.TS.getAccountTypes().subscribe(
      (response) => {
        this.account_types = response.body;
        const all = {
          id: 0,
          name: "All",
          description: "Retrieve both residential and commercial accounts.",
          date_created: moment().format("YYYY-MM-DD"),
          date_updated: moment().format("YYYY-MM-DD"),
        };
        const zones = {
          id: 100,
          name: "Zone",
          description: "Retrieve all accounts from zone",
          date_created: moment().format("YYYY-MM-DD"),
          date_updated: moment().format("YYYY-MM-DD"),
        };
        this.account_types.push(all);
        this.account_types.push(zones);
        // console.log("Accoutn types: ", this.account_types);
      },
      (err) => {
        console.log("Error getting account types: ", err);
      },
      () => {
        console.log("Finished getting account types");
      }
    );
  }

  getUsersByAccountType(id: number) {
    this.US.getUsersByAccountType(id).subscribe(
      (users: any) => {
        this.users = users ? users : [];
        console.log("USERS : ", this.users);
      },
      (error) => {
        console.log("Error getting users by account type: ", error);
        console.log("FORMAT: [user email, maddr zipcode, saddr zipcode]");
      }
    );
  }

  getAccountZones() {
    this.US.getAccountZones().subscribe((zones: any) => {
      this.zones = zones ? zones : [];
    });
  }

  fullHeightScroll() {
    $(".full-height-scroll").slimscroll({
      height: "100%",
    });
  }

  editorInit() {
    return {
      autogrowOnEnter: true,
      btns: [
        ["undo", "redo"],
        ["formatting"],
        ["strong", "em", "del"],
        ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
        ["unorderedList", "orderedList"],
        ["horizontalRule"],
        ["removeformat"],
        ["fontsize", "fontfamily"],
        ["foreColor", "backColor"],
      ],
    };
  }

  reloadSendEmail() {
    this.showErrorMessage = false;
    this.emailForm.customerType = null;
    this.emailForm.subject = null;
    this.emailForm.message = null;
    this.emailForm.to = [];
    this.viewFiles = [];
    this.filesToUpload = [];
    this.inputFiles = [];
    $("#trumbowyg").trumbowyg("html", "");
  }
  switchTab(tab) {
    //const logsTab = $("#logs");
    //const emailTab = $("#email");
    // console.log("logstabe: ", logsTab)
    // console.log("emailTab: ", emailTab)

    // if(tab == 'logs'){
    //   	emailTab.removeClass('myactive');
    //   	logsTab.addClass('myactive');
    // }else{
    //   logsTab.removeClass('myactive');
    // 	emailTab.addClass('myactive');
    // }
    this.activeTab = tab;

    //Hide current active tab title
    const activeTabTitle = $("#tab-title").find("li.active");
    activeTabTitle.removeClass("active");

    //Show new tab title
    const newActiveTabTitle = $(`#tab-title-${tab}`);
    newActiveTabTitle.addClass("active");
  }

  functionSimple() {
    // console.log('en simple notificacion');
    this.toastr.success("Without any options", "Simple notification!");
  }

  createNotification() {
    var toastType = $("#toastTypeGroup input:radio:checked").val();
    var reason = $("#inputReason").val();
    var msg = $("#message").val();
    var title = $("#title").val() || "";
    var position = $("#positionGroup input:radio:checked").val();
    var dateToast = $("#dateToast").val();
    var accountType = $("#account-type").val();
    var icon = $("#icon-id").val();
    var linkOption = $("#link-optional").val();
    /* NUNCA ELIMNIAR ESTAS ETIQUETAS  <h1><strong>*/
    msg = '<h3><strong>' + msg + '</strong></h3>';
    if (icon !== null) {
      msg =
        '<i class="' +
        icon +
        '"  style:"color:black; font-weight: 200%;"> </i> ' +
        msg;
      // console.log("dentro de condicion de icono");
    }
    if (linkOption !== "") {
      msg =
        msg +
        '<br/><a href="http://' +
        linkOption +
        '" target="_blank ">Link</a> ';
    }

    // console.log("mensaje = " + msg);
    if (toastType === "success") {
      this.CS.notificationSuccess(msg, title, position);
      this.getAllNotifications();
    } else if (toastType === "info") {
      this.CS.notificationInfo(msg, title, position);
      this.getAllNotifications();
    } else if (toastType === "warning") {
      this.CS.notificationWarning(msg, title, position);
      this.getAllNotifications();
    } else {
      this.CS.notificationError(msg, title, position);
      this.getAllNotifications();
    }
  }

  addNotification() {
    var reason = $("#inputReason").val();
    var title = $("#title").val() || "";
    var msg = $("#message").val();
    var toastType = $("#toastTypeGroup input:radio:checked").val();
    var dateToast = $("#dateToast").val();
    var accountType = $("#account-type").val();
    var position = $("#positionGroup input:radio:checked").val();

    var icon = $("#icon-id").val();
    var linkOption = $("#link-optional").val();
    /* NUNCA ELIMNIAR ESTAS ETIQUETAS  <h1><strong>*/
    msg = '<h3><strong>' + msg + '</strong></h3>';
    //console.log("ícon" + icon);
    if (icon !== null) {
      msg =
        '<i class="' +
        icon +
        '"  style:"color:black; font-weight: 200%;"> </i> ' +
        msg;
    }
    if (linkOption !== "") {
      msg =
        msg +
        '<br/><a href="http://' +
        linkOption +
        '" target="_blank ">Link</a> ';
    }

    this.notification.reason_id = reason;
    this.notification.title = title;
    this.notification.message = msg;
    this.notification.type = toastType;
    this.notification.schedule = dateToast;
    this.notification.account_type = accountType;
    this.notification.position = position;

    if (this.notification.account_type == 'All') {
      for (let type of this.accountTypes) {
        this.notification.account_type = type.id;
        this.CS.addNotification(this.notification).subscribe(
          (data) => {
            this.showErrorNotif = true;
            this.getAllNotifications();
            // console.log(data);
            this.successSwalNotif.show();
          },
          (err) => {
            // console.log("error: ", err);
            this.loading = false;
            if (err.status === 422 || err.status === 500) {
              this.errorSwalNotif.show();
            }
            if (err.status === 400) {
              this.errorSwalNotif.show();
            }
          }
        );

      }
    } else {
      this.CS.addNotification(this.notification).subscribe(
        (data) => {
          this.showErrorNotif = true;
          this.getAllNotifications();
          // console.log(data);
          this.successSwalNotif.show();
        },
        (err) => {
          // console.log("error: ", err);
          this.loading = false;
          if (err.status === 422 || err.status === 500) {
            this.errorSwalNotif.show();
          }
          if (err.status === 400) {
            this.errorSwalNotif.show();
          }
        }
      );
    }

  }

  editNotification() {

    var reason = $("#inputReason").val();
    var title = $("#title").val() || "";
    var msg = $("#message").val();
    var toastType = $("#toastTypeGroup input:radio:checked").val();
    var dateToast = $("#dateToast").val();
    var accountType = $("#account-type").val();
    var position = $("#positionGroup input:radio:checked").val();

    var icon = $("#icon-id").val();
    var linkOption = $("#link-optional").val();
    /* NUNCA ELIMNIAR ESTAS ETIQUETAS  <h1><strong>*/
    msg = '<h3><strong>' + msg + '</strong></h3>';
    //console.log("ícon" + icon);
    if (icon !== null) {
      msg =
        '<i class="' +
        icon +
        '"  style:"color:black; font-weight: 200%;"> </i> ' +
        msg;
    }
    if (linkOption !== "") {
      msg =
        msg +
        '<br/><a href="http://' +
        linkOption +
        '" target="_blank ">Link</a> ';
    }

    this.notification.reason_id = reason;
    this.notification.title = title;
    this.notification.message = msg;
    this.notification.type = toastType;
    this.notification.schedule = dateToast;
    this.notification.account_type = accountType;
    this.notification.position = position;

    this.CS.updateNotification(this.row_notifi.notif_id, this.notification).subscribe(
      (data) => {
        this.getAllNotifications();
        this.showErrorNotif = true;
        // console.log(data);

        this.successSwalNotif.show();

      },
      (err) => {
        // console.log("error: ", err);
        this.loading = false;
        if (err.status === 422 || err.status === 500) {
          this.errorSwalNotif.show();
        }
        if (err.status === 400) {
          this.errorSwalNotif.show();
        }
      }
    );
  }


  deleteNotification() {

    this.CS.deleteNotification(this.row_notifi.notif_id).subscribe(
      (data) => {
        this.showErrorNotif = true;
        // console.log(data);
        this.getAllNotifications();
        this.deleteSwalNotif.show();

      },
      (err) => {
        // console.log("error: ", err);
        this.loading = false;
        if (err.status === 422 || err.status === 500) {
          this.errorSwalNotif.show();
        }
        if (err.status === 400) {
          this.errorSwalNotif.show();
        }
      }
    );

  }
  /* TABLE */

  /* LLENAR TABLE NOTIFICATIONS */
  getAllNotifications() {
    this.CS.getAllNotifications().subscribe((response) => {
      this.allnotifications = response.body;
      if (this.allnotifications) {

        for (var i = 0; i < this.allnotifications.length; i++) {
          /* OBTENER MENSAJE */
          this.allnotifications[i].message = this.allnotifications[i].message.replace('<strong>', '░');
          this.allnotifications[i].message = this.allnotifications[i].message.replace('</strong>', '░');
          this.allnotifications[i].message = this.allnotifications[i].message.split('░')[1];
          for (let type of this.accountTypes) {
            if (type.id == this.allnotifications[i].account_type) {
              this.allnotifications[i].name_type = type.name;
            }
          }
        }
        this.paginatorNotifi();
        /* console.log(" allnotifications Notificaciones Diego: ", this.allnotifications); */
      }
    }),
      (err) => {
        console.log("Erorr getting Notifications: ", err);
      },
      () => {
        console.log("finished gettings all Notifications");
      }
  }/* FIN getAllNotifications */
  /* DETALLES MESAGE */
  private viewingNotifi = null;
  viewDetails(notification) {
    if (notification.notif_id != this.viewingNotifi) {
      this.viewingNotifi = notification.notif_id;
    } else {
      this.viewingNotifi = null

    }
    // console.log('VER DETALLES-->', this.viewingNotifi)
  }

  initpage() {
    /* INICIO DE PAGINA */
    this.PosicionArray = 0;
    /* NUMERO DE PAGINAS */
    this.TotalPages = 0;
    /* PAGINA ACTUAL */
    this.paginationCurrentPageNotifi = 1;
    this.limitpagesNotifi = 0;
    this.nextback();

  }
  paginatorNotifi() {
    /* ITEMS A MOSTRAR */
    var TotalItem = this.ItemsTableNotifications - 1;
    /* NUERO DE PAGINAS */
    this.TotalPages = this.allnotifications.length / TotalItem;
    // console.log('total de paginas', Math.round(this.TotalPages));

    /* CREAR PAGINA */
    if (this.allnotifications) {
      /* NUEVA PAGINA */
      this.PageNotifications = [];
      // console.log('total de items--->', this.PageNotifications.length);
      /* LLENAR PAGINA */
      for (var i = this.PosicionArray; i < this.allnotifications.length; i++) {

        if (this.PageNotifications.length <= TotalItem) {
          /* OBTENER MENSAJE */
          // console.log('add item');
          this.PageNotifications.push(this.allnotifications[i]);
        }

      }
    }
  }

  NumberPage() {
    /* PAGINA ACTUAL */
    if ((this.TotalPages - 1) > 0) {
      /* current page */
      this.paginationCurrentPageNotifi = this.TotalPages - (this.TotalPages - 1);
      this.nextback();
      /* --------------- */
      /* console.log('PAGINA BACK -->', Math.round(this.paginationBackCurrentPageNotifi));
      console.log('PAGINA ACTUAL -->', Math.round(this.paginationCurrentPageNotifi));
      console.log('PAGINA NEXT -->', Math.round(this.paginationNextCurrentPageNotifi)); */
    }
  }

  nextPageNotifi() {
    this.limitpagesNotifi = this.PosicionArray + this.ItemsTableNotifications;
    if (this.allnotifications[this.limitpagesNotifi] != null) {
      this.paginationCurrentPageNotifi++;
      this.PosicionArray = this.PosicionArray + this.ItemsTableNotifications;
      this.paginatorNotifi();
      // console.log(this.PosicionArray);
      // console.log('PAGINA ACTUAL -->', Math.round(this.paginationCurrentPageNotifi));
      // console.log('total paginas -->', Math.round(this.TotalPages));
      this.nextback();
    }

  }
  backPageNotifi() {

    this.limitpagesNotifi = this.PosicionArray - this.ItemsTableNotifications;
    // console.log('limit pages= ' + this.limitpagesNotifi);
    if (this.PosicionArray > 0) {
      this.paginationCurrentPageNotifi--;
      this.PosicionArray = this.PosicionArray - this.ItemsTableNotifications;
      this.paginatorNotifi();

      // console.log(this.PosicionArray);
      // console.log('PAGINA ACTUAL -->', Math.round(this.paginationCurrentPageNotifi));
      this.nextback();
    }
  }
  nextback() {
    /* ------------------ */
    /* back page */
    this.paginationBackCurrentPageNotifi = this.paginationCurrentPageNotifi;
    this.paginationBackCurrentPageNotifi = this.paginationBackCurrentPageNotifi - 1;
    /* nextpage */
    this.paginationNextCurrentPageNotifi = this.paginationCurrentPageNotifi;
    this.paginationNextCurrentPageNotifi = this.paginationNextCurrentPageNotifi + 1;
    /* ------------------ */
  }
  /* FIN PAGINAR TABLA */
  /* OBTENER LOS DATOS DE UNA FILA, EN LA TABLA NOTIFICACIONES */
  RowSelected(row_data_notification) {

    this.row_notifi = row_data_notification;
    this.row_date = moment(this.row_notifi.schedule).format('YYYY-MM-DD');
    // document.getElementById('todday').innerHTML = '<br> <input type="date" name="Data" value="' + this.today + '" ><br>';;
    //console.log("FECHANA CTUAL 1-->", this.today);
    //this.row_notifi.schedule== 'null' ? new Date() : new Date(this.row_notifi.schedule);
    // console.log(this.row_notifi);
    // console.log('MENSAJE DE DIEGO', this.row_notifi.message)
    this.getAllNotifications();
  }

  clearNotification() {
    // console.log('clear');
    this.row_notifi.reason = null;
    this.row_notifi.title = null;
    this.row_notifi.message = null;
    this.row_notifi.type = null;
    this.row_notifi.schedule = moment().format('YYYY-MM-DD');
    this.row_notifi.account_type = null;
    this.row_notifi.position = null;
  };




}
