export class UserSettings {
  id: string;
  user_id: number;
  save_payment_method: boolean;
  autopay: boolean;
  email_notifications: boolean;
  text_notifications: boolean;
  date_created: string;
  date_updated: string;
  email_otp: boolean;
  phone_otp: boolean;
}
