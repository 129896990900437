import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { loadModules, } from 'esri-loader';
import esri = __esri; // Esri TypeScript Types
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { WaterConsumptionService, TicketService, UserService, WorkOrderService,AccountService } from 'src/app/_services';
import { WeatherService } from 'src/app/_services/weather.service';
import * as globals from '../../globals.json';

declare var $:any;

@Component({
  selector: 'app-esri-map',
  templateUrl: './esri-map.component.html',
  styleUrls: ['./esri-map.component.css']
})
export class EsriMapComponent implements OnInit {
  @Output() mapLoadedEvent = new EventEmitter<boolean>();

  // The <div> where we will place the map
  @ViewChild('mapViewNode') private mapViewEl: ElementRef;

  /**
   * _zoom sets map zoom
   * _center sets map center
   * _basemap sets type of map
   * _loaded provides map loaded status
   */
  private _zoom = 2;
  private _center: Array<number> = [0.1278, 51.5074];
  private _basemap = 'streets';
  _loaded = false;

  private consumptionLayer: esri.FeatureLayer;
  private zoneLayer:esri.FeatureLayer;
  private ticketLayer: esri.FeatureLayer;
  private usersLayer: esri.FeatureLayer;
  private workOrderLayer: esri.FeatureLayer;
  private weatherLayer: esri.FeatureLayer;
  private filtersContainer: esri.Expand;

  onlineUsersGrowthRenderer;
  onlineUsersHeatmapRenderer;
  currentOnlineUsersRenderer:boolean = false;

  daySliderMax;
  daySliderValue;
  private month = moment().format('MM');
  private year = moment().format('YYYY');
  sliderCurrentMonth;
  totalUsersForDay = 0;
  totalUsersDate;

  private startYear = moment().subtract(1, 'months').format('YYYY');
  private endYear = moment().subtract(13, 'months').format('YYYY');
  private sliderValue = 1100;

  private currentTicketGeometryData;
  private previousTicketGeometryData;
  private ticketGeoDataFirstLoad: boolean = true;
  private currentConsGeometryData;
  private previousConsGeometryData;
  private consGeoDataFirstLoad: boolean = true;
  private currentOnlineUsersGeometryData;
  private previousOnlineUsersGeometryData;
  private onlineUsersGeoDataFirstLoad: boolean = true;
  private currentWorkOrderGeometryData;
  private previousWorkOrderGeometryData;
  private workOrderGeoDataFirstLoad: boolean = true;

  private baseUrl: any = environment.serverUrl;

  private animation;
  private toggledAnimation: boolean = false;
  private showOptions: boolean = true;

  filterAccountStatus      = "All";
  filterAccountType        = "All";
  filterOnlineStatus       = "All";
  filterTicketType         = "All";
  filterTicketStatus       = "All";
  filterWorkOrderType      = "All";
  filterWorkOrderStatus    = "All";
  filterUsersByAccountType = "All";

  globals: any = globals.default;
  environment: any = environment;

  get mapLoaded(): boolean {
    return this._loaded;
  }

  @Input()
  set zoom(zoom: number) {
    this._zoom = zoom;
  }

  get zoom(): number {
    return this._zoom;
  }

  @Input()
  set center(center: Array<number>) {
    this._center = center;
  }

  get center(): Array<number> {
    return this._center;
  }

  @Input()
  set basemap(basemap: string) {
    this._basemap = basemap;
  }

  get basemap(): string {
    return this._basemap;
  }

  constructor(
    private waterConsumptionService: WaterConsumptionService,
    private ticketService: TicketService,
    private userService: UserService,
    private workOrderService: WorkOrderService,
    private weatherService: WeatherService,
    private accountService:AccountService,
  ) {
  }

  ngOnDestroy() {

  }

  async initializeMap() {
    try {
      // Load the modules for the ArcGIS API for JavaScript
      const [EsriMap, EsriMapView, FeatureLayer, GeoJSONLayer, Graphic, Field, Legend, BasemapToggle, Fullscreen, LayerList, Expand, DotDensityRenderer,Color] = await loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/layers/FeatureLayer',
        'esri/layers/GeoJSONLayer',
        'esri/Graphic',
        'esri/layers/support/Field',
        'esri/widgets/Legend',
        'esri/widgets/BasemapToggle',
        // 'esri/views/SceneView',
        'esri/widgets/Fullscreen',
        'esri/widgets/LayerList',
        'esri/widgets/Expand',
        "esri/renderers/DotDensityRenderer",
        "esri/Color"
      ]);

      const zoneTemplate = {
        title:"Zone {account}"
      };

      const consumptionPopupTemplate = {
        title: `{address}, ${this.globals.onlyCityName}, TX`,
        content: [
            {
              type: "fields",
              fieldInfos: [
                {
                  fieldName: "incode_account_no",
                  label: "Incode Account #"
                },
                {
                  fieldName: "name",
                  label: "Name",
                },
                {
                  fieldName: "total_consumption",
                  label: "Water Consumption (Gallons)",
                  format: {
                    digitSeparator: true,
                    places: 0
                  }
                },
                {
                  fieldName: "account_type",
                  label: "Type",
                },
                {
                  fieldName: "date",
                  label: "Date",
                }
              ]
            }
          ]
      };

      const ticketPopupTemplate = {
        title: "{title}",
        content: [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "incode_account_no",
                label: "Incode Account #"
              },
              {
                fieldName: "address",
                label: "Address"
              },
              {
                fieldName: "name",
                label: "Name",
              },
              {
                fieldName: "account_type",
                label: "Account Type",
              },
              {
                fieldName: "ticket_type",
                label: "Ticket Type",
              },
              {
                fieldName: "ticket_status",
                label: "Ticket Status",
              },
              {
                fieldName: "date_created",
                label: "Date Created",
              },
              {
                fieldName: "date_updated",
                label: "Date Updated",
              },
            ]
          }, {
            type: "media",
            mediaInfos: [{
              // title: "{title}",
              type: "image",
              // caption: "Status: {ticket_status}",
              value: {
                sourceURL: "{images}"
              }
            }]
          }
        ]
      };

      const zoneRenderer = {
        type:"unique-value",
        field:"account",
        uniqueValueInfos:[{
          value:"One",
          symbol:{
            "type":"simple-fill",
            "color":new Color([240,255,51,0.3])
          }
        },
        {
          value:"Two",
          symbol:{
            "type":"simple-fill",
            "color":new Color([153,255,51,0.3])
          }
        },
        {
          value:"Three",
          symbol:{
            "type":"simple-fill",
            "color":new Color([51,255,227,0.3])
          }
        },
        {
          value:"Four",
          symbol:{
            "type":"simple-fill",
            "color":new Color([51,141,255,0.3])
          }
        },
        {
          value:"Five",
          symbol:{
            "type":"simple-fill",
            "color":new Color([168,51,255,0.3])
          }
        },
        {
          value:"Six",
          symbol:{
            "type":"simple-fill",
            "color":new Color([255,51,221,0.3])
          }
        }]
      };

      const uniqueRenderer = {
        type: "unique-value",  // autocasts as new UniqueValueRenderer()
        field: "account_type",
        uniqueValueInfos: [{
          // All features with value of "North" will be blue
          value: "Residential",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/account_type/RES.png`,
            "contentType": "image/png",
            "width": 25,
            "height": 25
          }
        },
        {
          value: "Commercial",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/account_type/COM.png`,
            "contentType": "image/png",
            "width": 25,
            "height": 25
          }
        }
      ]};

      const ticketRenderer = {
        type: "unique-value",  // autocasts as new UniqueValueRenderer()
        field: "ticket_type_and_status",
        uniqueValueInfos: [
         {
          value: "NAWSC Garbage - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "NAWSC Garbage - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "NAWSC Garbage - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "NAWSC Garbage - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "NAWSC Garbage - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
         {
          value: "Leaks - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Leaks - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Leaks - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Leaks - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Leaks - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
         {
          value: "Sewer Back Up - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Sewer Back Up - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Sewer Back Up - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Sewer Back Up - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Sewer Back Up - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
         {
          value: "No Brush Pick Up - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Brush Pick Up - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Brush Pick Up - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Brush Pick Up - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Brush Pick Up - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
         {
          value: "No Garbage Pick Up - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Garbage Pick Up - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Garbage Pick Up - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Garbage Pick Up - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Garbage Pick Up - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
         {
          value: "Low Pressure - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Low Pressure - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Low Pressure - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Low Pressure - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Low Pressure - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
         {
          value: "No Water - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Water - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Water - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Water - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "No Water - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Payments - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Payments - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Payments - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Payments - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Payments - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "General Questions - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "General Questions - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "General Questions - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "General Questions - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "General Questions - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Garbage Extra - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Garbage Extra - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Garbage Extra - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Garbage Extra - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Garbage Extra - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        // {
        //   value: "New Account",
        //   symbol: {
        //     "angle": 0,
        //     "xoffset": 0,
        //     "yoffset": 0,
        //     "type": "picture-marker",
        //     "url": `${this.baseUrl}/assets/images/ticket_type_default.png`,
        //     "contentType": "image/png",
        //     "width": 30,
        //     "height": 30
        //   }
        // },
        {
          value: "Disconnect Account - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Disconnect Account - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Disconnect Account - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Disconnect Account - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Disconnect Account - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Pause Account - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Pause Account - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Pause Account - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Pause Account - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Pause Account - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Bad Sewer Smell - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Bad Sewer Smell - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Bad Sewer Smell - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Bad Sewer Smell - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Bad Sewer Smell - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Manhole works - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Manhole works - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Manhole works - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Manhole works - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Manhole works - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Mark locate water/sewer - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Mark locate water/sewer - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Mark locate water/sewer - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Mark locate water/sewer - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Mark locate water/sewer - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Install Meter - Pending",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/pending.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Install Meter - In Progress",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/in_progress.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Install Meter - Resolved",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/resolved.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Install Meter - Reject",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/rejected.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        },
        {
          value: "Install Meter - Forwarded",
          symbol: {
            "angle": 0,
            "xoffset": 0,
            "yoffset": 0,
            "type": "picture-marker",
            "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/forwarded.png`,
            "contentType": "image/png",
            "width": 30,
            "height": 30
          }
        }
        ]
      };

      const workOrderRenderer = {
        type: "unique-value",  // autocasts as new UniqueValueRenderer()
        field: "work_order_type_and_status",
        uniqueValueInfos: [
          {
            value: "NAWSC Garbage - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "NAWSC Garbage - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "NAWSC Garbage - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "NAWSC Garbage - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "NAWSC Garbage - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/nawsc_garbage/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Leaks - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Leaks - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Leaks - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Leaks - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Leaks - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/leaks/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Sewer Back Up - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Sewer Back Up - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Sewer Back Up - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Sewer Back Up - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Sewer Back Up - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/sewer_back_up/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Brush Pick Up - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Brush Pick Up - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Brush Pick Up - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Brush Pick Up - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Brush Pick Up - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_brush_pick_up/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Garbage Pick Up - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Garbage Pick Up - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Garbage Pick Up - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Garbage Pick Up - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Garbage Pick Up - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_garbage_pick_up/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Low Pressure - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Low Pressure - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Low Pressure - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Low Pressure - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Low Pressure - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/low_pressure/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Water - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Water - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Water - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Water - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "No Water - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/no_water/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Payments - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Payments - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Payments - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Payments - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Payments - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/payments/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "General Questions - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "General Questions - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "General Questions - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "General Questions - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "General Questions - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/general_questions/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Garbage Extra - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Garbage Extra - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Garbage Extra - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Garbage Extra - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Garbage Extra - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/garbage_extra/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          // {
          //   value: "New Account",
          //   symbol: {
          //     "angle": 0,
          //     "xoffset": 0,
          //     "yoffset": 0,
          //     "type": "picture-marker",
          //     "url": `${this.baseUrl}/assets/images/ticket_type_default.png`,
          //     "contentType": "image/png",
          //     "width": 30,
          //     "height": 30
          //   }
          // },
          {
            value: "Disconnect Account - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Disconnect Account - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Disconnect Account - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Disconnect Account - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Disconnect Account - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/disconnect/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Pause Account - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Pause Account - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Pause Account - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Pause Account - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Pause Account - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/pause_account/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Bad Sewer Smell - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Bad Sewer Smell - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Bad Sewer Smell - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Bad Sewer Smell - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Bad Sewer Smell - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/bad_sewer_smell/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Manhole works - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Manhole works - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Manhole works - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Manhole works - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Manhole works - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/manhole_works/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Mark locate water/sewer - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Mark locate water/sewer - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Mark locate water/sewer - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Mark locate water/sewer - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Mark locate water/sewer - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/mark_locate_water_sewer/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Install Meter - Pending",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/pending.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Install Meter - In Progress",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/in_progress.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Install Meter - Resolved",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/resolved.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Install Meter - Reject",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/rejected.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          },
          {
            value: "Install Meter - Forwarded",
            symbol: {
              "angle": 0,
              "xoffset": 0,
              "yoffset": 0,
              "type": "picture-marker",
              "url": `${this.baseUrl}/assets/images/map_icons/tickets/install_meter/forwarded.png`,
              "contentType": "image/png",
              "width": 30,
              "height": 30
            }
          }
        ]
      };

      const growthRenderer = this.onlineUsersGrowthRenderer = {
        type: "unique-value",  // autocasts as new UniqueValueRenderer()
        field: "account_type",
        uniqueValueInfos: [{
          value: "Residential",
          symbol: {
            type: "simple-marker",
            color: "#1c84c6",
            size: 8
          }
        },
        {
          value: "Commercial",
          symbol: {
            type: "simple-marker",
            color: "#1ab394",
            size: 8
          }
        }]
      };

      const heatRenderer = this.onlineUsersHeatmapRenderer = {
        type: "heatmap",
        field: "weight",
        colorStops: [
          { ratio: '0.0', color: 'rgba(220, 236, 201, 0)' },
          { ratio: '0.111', color: '#AADACC' },
          { ratio: '0.222', color: '#78C6D0' },
          { ratio: '0.333', color: '#48B3D3' },
          { ratio: '0.444', color: '#3E94C0' },
          { ratio: '0.555', color: '#3474AC' },
          { ratio: '0.666', color: '#2A5599' },
          { ratio: '0.777', color: '#203686' },
          { ratio: '0.888', color: '#18216B' },
          { ratio: '1.0', color: '#11174B' },
          // { ratio: '0.0', color: 'rgba(49,54,149, 0)' },

          // { ratio: '0.2', color: '#FFECB3' },
          // { ratio: '0.65', color: '#E85285' },
          // { ratio: '0.65', color: '#6A1B9A' },
          // { ratio: '1.0', color: '#A50026' },
        ],
        maxPixelIntensity: 50,
        minPixelIntensity: 0
      };

      const simpleRenderer = {
        type: "simple",
        symbol: {
          type: "simple-marker",
          color: "#1c84c6",
          size: 5
        }
      };

      const heatmapRenderer =  {
        type: "heatmap",
        field: "normalized_consumption",
        colorStops: [
          { ratio: '0.0', color: 'rgba(49,54,149, 0)' },
          { ratio: '0.111', color: '#4575B4' },
          { ratio: '0.222', color: '#74ADD1' },
          { ratio: '0.333', color: '#ABD9E9' },
          { ratio: '0.444', color: '#F4F3F8' },
          { ratio: '0.555', color: '#FEE090' },
          { ratio: '0.666', color: '#FDAE61' },
          { ratio: '0.777', color: '#F46D43' },
          { ratio: '0.888', color: '#D73027' },
          { ratio: '1.0', color: '#A50026' },
        ],
        maxPixelIntensity: 10,
        minPixelIntensity: 0
      };

      const zoneFields = [
        new Field({
          name:"ObjectID",
          alias:"Id",
          type:"oid"
        }),
        new Field({
          name:"account",
          alias:"Account",
          type:"string"
        })
      ]

      const consumptionFields = [
       new Field({
          name: "ObjectID",
          alias: "Id",
          type: "oid"
        }),
        new Field({
          name: "normalized_consumption",
          alias: "Consumption",
          type: "double"
        }),
        new Field({
          name: "total_consumption",
          alias: "Total Consumption",
          type: "double"
        }),
        new Field({
          name: "address",
          alias: "Address",
          type: "string"
        }),
        new Field({
          name: "incode_account_no",
          alias: "Incode number",
          type: "string"
        }),
        new Field({
          name: "name",
          alias: "Name",
          type: "string"
        }),
        new Field({
          name: "account_status",
          alias: "Account Status",
          type: "string"
        }),
        new Field({
          name: "date",
          alias: "Date",
          type: "string"
        }),
        new Field({
          name: "account_type",
          alias: "Account Type",
          type: "string"
        }),
        new Field({
          name: "active",
          alias: "active",
          type: "string"
        })
      ];

      const ticketFields = [
        new Field({
          name: "ObjectID",
          alias: "Id",
          type: "oid"
        }),
        new Field({
          name: "address",
          alias: "Address",
          type: "string"
        }),
        new Field({
          name: "incode_account_no",
          alias: "Incode number",
          type: "string"
        }),
        new Field({
          name: "name",
          alias: "Name",
          type: "string"
        }),
        new Field({
          name: "date",
          alias: "Date",
          type: "string"
        }),
        new Field({
          name: "ticket_type_and_status",
          alias: "Ticket Type & Status",
          type: "string"
        }),
        new Field({
          name: "ticket_type",
          alias: "Ticket Type",
          type: "string"
        }),
        new Field({
          name: "account_type",
          alias: "Account Type",
          type: "string"
        }),
        new Field({
          name: "title",
          alias: "Title",
          type: "string"
        }),
        new Field({
          name: "images",
          alias: "Images",
          type: "string"
        }),
        new Field({
          name: "date_created",
          alias: "Date Created",
          type: "string"
        }),
        new Field({
          name: "date_updated",
          alias: "Date Updated",
          type: "string"
        }),
        new Field({
          name: "ticket_status",
          alias: "Ticket Status",
          type: "string"
        }),
      ];

      const workOrderFields = [
        new Field({
          name: "ObjectID",
          alias: "Id",
          type: "oid"
        }),
        new Field({
          name: "address",
          alias: "Address",
          type: "string"
        }),
        new Field({
          name: "incode_account_no",
          alias: "Incode number",
          type: "string"
        }),
        new Field({
          name: "name",
          alias: "Name",
          type: "string"
        }),
        new Field({
          name: "date",
          alias: "Date",
          type: "string"
        }),
        new Field({
          name: "work_order_type_and_status",
          alias: "Work Order Type & Status",
          type: "string"
        }),
        new Field({
          name: "work_order_type",
          alias: "Work Order Type",
          type: "string"
        }),
        new Field({
          name: "account_type",
          alias: "Account Type",
          type: "string"
        }),
        new Field({
          name: "title",
          alias: "Title",
          type: "string"
        }),
        new Field({
          name: "description",
          alias: "Description",
          type: "string"
        }),
        new Field({
          name: "date_created",
          alias: "Date Created",
          type: "string"
        }),
        new Field({
          name: "date_updated",
          alias: "Date Updated",
          type: "string"
        }),
        new Field({
          name: "work_order_status",
          alias: "Work Order Status",
          type: "string"
        }),
      ];

      const usersFields = [
        new Field({
          name: "ObjectID",
          alias: "Id",
          type: "oid"
        }),
        new Field({
          name: "address",
          alias: "Address",
          type: "string"
        }),
        new Field({
          name: "incode_account_no",
          alias: "Incode number",
          type: "string"
        }),
        new Field({
          name: "name",
          alias: "Name",
          type: "string"
        }),
        new Field({
          name: "date",
          alias: "Date",
          type: "string"
        }),
        new Field({
          name: "account_type",
          alias: "Account Type",
          type: "string"
        }),
        new Field({
          name: "date_created",
          alias: "Date Created",
          type: "string"
        }),
        new Field({
          name: "weight",
          alias: "Weight",
          type: "double"
        })
      ];

      const zoneLayer = this.zoneLayer = new FeatureLayer({
        source:[],
        title:"Zones",
        geometryType:"polygon",
        objectIdField:"ObjectID",
        fields:zoneFields,
        popupTemplate:zoneTemplate,
        visible:false,
        renderer:zoneRenderer,
        spatialReference:{
          wkid:4326
        }
      })

      const consumptionLayer = this.consumptionLayer = new FeatureLayer({
        source: [],
        title: "Consumption",
        geometryType: "point",
        objectIdField: "ObjectID",
        renderer: heatmapRenderer,
        fields: consumptionFields,
        popupTemplate: consumptionPopupTemplate,
        visible: true,
        spatialReference: {
          wkid: 4326
        },
      });

      const ticketLayer = this.ticketLayer = new FeatureLayer({
        source: [],
        title: "Support Tickets",
        geometryType: "point",
        objectIdField: "ObjectID",
        renderer: ticketRenderer,
        fields: ticketFields,
        visible: true,
        popupTemplate: ticketPopupTemplate,
        spatialReference: {
          wkid: 4326
        },
      });

      const workOrderLayer = this.workOrderLayer = new FeatureLayer({
        source: [],
        title: "Work Orders",
        geometryType: "point",
        objectIdField: "ObjectID",
        renderer: workOrderRenderer,
        fields: workOrderFields,
        visible: true,
        // popupTemplate: workOrderPopupTemplate,
        spatialReference: {
          wkid: 4326
        },
      });

      const weatherLayer = this.weatherLayer = new FeatureLayer({
        source: [],
        // url: `https://api.weather.gov/gridpoints/BRO/55,17/forecast`,

        // url: "http://services.twdb.texas.gov/arcgis/rest/services/Public/TWDB_Groundwater_database/FeatureServer",
        title: "Weather",
        geometryType: "point",
        renderer: simpleRenderer,
        // fields: weatherFields,
        // spatialReference: {
        //   wkid: 4326
        // }
      })

      const dotDensityRenderer = new DotDensityRenderer({
        // The number of units per dot
        referenceDotValue: 100,
        outline: null,
        // When referenceScale is set, linearly scales the dot value
        // based on the referenceDotValue. The layer view will draw
        // dots using the value in referenceDotValue only at the scale
        // specified here.
        // referenceScale: mapView.scale,
        legendOptions: {
          // Displays "1 dot = 100 people" in the legend
          unit: "people"
        },
        attributes: [
          {
            // One red dot will be drawn for every 100 White people
            field: "B03002_003E",
            color: "#f23c3f",
            label: "White (non-Hispanic)"
          },
          {
            // One yellow dot will be drawn for every 100 Hispanic people
            field: "B03002_012E",
            color: "#e8ca0d",
            label: "Hispanic"
          },
          {
            field: "B03002_004E",
            color: "#00b6f1",
            label: "Black or African American"
          },
          {
            field: "B03002_006E",
            color: "#32ef94",
            label: "Asian"
          },
          {
            field: "B03002_005E",
            color: "#ff7fe9",
            label: "American Indian/Alaskan Native"
          },
          {
            field: "B03002_007E",
            color: "#e2c4a5",
            label: "Pacific Islander/Hawaiian Native"
          },
          {
            field: "B03002_008E",
            color: "#ff6a00",
            label: "Other race"
          },
          {
            field: "B03002_009E",
            color: "#96f7ef",
            label: "Two or more races"
          }
        ]
      });

      const usersLayer = this.usersLayer = new FeatureLayer({
        source: [],
        title: "Online Accounts",
        geometryType: "point",
        objectIdField: "ObjectID",
        renderer: growthRenderer,
        fields: usersFields,
        visible: true,
        // popupTemplate: ticketPopupTemplate,
        spatialReference: {
          wkid: 4326
        }
      });

      // Configure the Map
      const mapProperties: esri.MapProperties = {
        basemap: this._basemap,
        layers: [consumptionLayer, ticketLayer, usersLayer, workOrderLayer],
      };

      const map: esri.Map = new EsriMap(mapProperties);

      // Initialize the MapView
      const mapViewProperties: esri.MapViewProperties = {
        container: this.mapViewEl.nativeElement,
        center: this._center,
        zoom: this._zoom,
        map: map,
      };

      const mapView = new EsriMapView(mapViewProperties);

      const basemapToggle = new BasemapToggle({
        view: mapView,  // The view that provides access to the map's "streets" basemap
        nextBasemap: "hybrid"  // Allows for toggling to the "hybrid" basemap
      });

      const filters = this.filtersContainer = new Expand({
        view: mapView,
        content: document.getElementById("filtersContainer"),
        expandIconClass: 'fa fa-filter',
        group: "bottom-right",
        // expanded: "true"
      });

      const legend = new Legend({
        view: mapView,
        layerInfos: [
          {
            layer: consumptionLayer,
            title: `${this.globals.onlyCityName} Water Consumption`
          },
          {
            layer: usersLayer,
            title: `${this.globals.onlyCityName} Water Online Account Growth`
          }
        ]
      });

      const fullscreen = new Fullscreen({
        view: mapView
      });

      var layerList = new LayerList({
        view: mapView
      });

      mapView.watch("scale", function (newValue) {
        consumptionLayer.renderer =
          newValue <= 25224 ? uniqueRenderer : heatmapRenderer;
      });

      mapView.ui.add(legend,"bottom-left");
      mapView.ui.add(fullscreen, "top-left");
      mapView.ui.add(filters, "top-left");
      mapView.ui.add(basemapToggle, "top-right");
      mapView.ui.add(layerList, { position: "bottom-right" });

      return mapView;
    } catch (error) {
      console.log('EsriLoader: ', error);
    }

  }

  // Finalize a few things once the MapView has been loaded
  houseKeeping(mapView) {
    mapView.when(() => {
      this._loaded = mapView.ready;
      this.mapLoadedEvent.emit(true);

      // $('#filtersContent').slimscroll({position: 'left'});

      const beginDate = moment().subtract(1, 'month');
      const endDate = moment();
      this.getConsumptionData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
      this.getTicketData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
      this.getOnlineUserData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
      this.getWorkOrderData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
      // this.getZoneData();
      // this.getWeatherData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
    });
  }

  ngOnInit() {
    if(this.environment.local){
      this.baseUrl = this.environment.frontendUrl;
    }

    this.sliderCurrentMonth =  `${moment().subtract(1, 'month').format('MMM')} ${this.startYear}`;
    // Initialize MapView and return an instance of MapView
    this.initializeMap().then((mapView) => {
      this.houseKeeping(mapView);
    });
  }

  getZoneData(){
    this.accountService.getZoneMapData()
      .subscribe(
        response=>{
          console.log(response);
          this.zoneLayer.applyEdits({addFeatures: response.features })
          .then((result)=>{
            console.log(result);
          })
          .catch((err)=>{
            console.log(err)
          });
        }
      )
  }

  getConsumptionData(beginMonth, beginYear, endMonth, endYear){
    this.waterConsumptionService.getMapData(beginMonth, beginYear, endMonth, endYear)
      .subscribe(
        response => {
          console.log(response);
          if (this.consGeoDataFirstLoad) {
            this.previousConsGeometryData = this.currentConsGeometryData = response;
            this.consGeoDataFirstLoad = false;
          } else {
            this.previousConsGeometryData = this.currentConsGeometryData;
            this.currentConsGeometryData = response;
          }
          this.filterWaterConsumption(false);
        }
      );
  }

  getTicketData(beginMonth, beginYear, endMonth, endYear){
    this.ticketService.getMapData(beginMonth, beginYear, endMonth, endYear)
      .subscribe(
        response => {
          if(this.ticketGeoDataFirstLoad){
            this.previousTicketGeometryData = this.currentTicketGeometryData = response;
            this.ticketGeoDataFirstLoad = false;
          } else {
            this.previousTicketGeometryData = this.currentTicketGeometryData;
            this.currentTicketGeometryData = response;
          }
          this.filterTickets(false);
        }
      );
  }

  getWorkOrderData(beginMonth, beginYear, endMonth, endYear) {
    this.workOrderService.getMapData(beginMonth, beginYear, endMonth, endYear)
      .subscribe(
        response => {
          // console.log("response: ", response);
          if (this.workOrderGeoDataFirstLoad) {
            this.previousWorkOrderGeometryData = this.currentWorkOrderGeometryData = response;
            this.workOrderGeoDataFirstLoad = false;
          } else {
            this.previousWorkOrderGeometryData = this.currentWorkOrderGeometryData;
            this.currentWorkOrderGeometryData = response;
          }
          this.filterWorkOrders(false);
        }
      );
  }

  getWeatherData(beginMonth, beginYear, endMonth, endYear) {
    this.weatherService.getGageData()
      .subscribe(
        response => {
          console.log("response: ", response);


        }
      )
  }

  getOnlineUserData(beginMonth, beginYear, endMonth, endYear){
    this.month = beginMonth;
    this.year = beginYear;
    this.daySliderMax = this.daySliderValue = moment(beginMonth, 'MM').year(beginYear).daysInMonth();
    // console.log("dayslidermax: ", this.daySliderMax);
    this.userService.getMapData(beginMonth, beginYear, endMonth, endYear)
      .subscribe(
        response => {
          console.log("online user:", response);
          if (this.onlineUsersGeoDataFirstLoad) {
            this.previousOnlineUsersGeometryData = this.currentOnlineUsersGeometryData = response;
            this.onlineUsersGeoDataFirstLoad = false;
          } else {
            this.previousOnlineUsersGeometryData = this.currentOnlineUsersGeometryData;
            this.currentOnlineUsersGeometryData = response;
          }

          this.filterOnlineUsers(false);
        }
      )
  }

  redrawMap(){
    if(this.animation){
      this.sliderValue -= 100;
      this.startAnimation();
    } else {
      this.sliderValue = Math.round(+this.sliderValue / 100) * 100
      const calcMonth = 12 - (+this.sliderValue / 100);
      const beginDate = moment().subtract(calcMonth, 'months');
      const endDate = moment(beginDate).add(1, 'month');

      this.sliderCurrentMonth =  `${moment(beginDate).format('MMM')} ${beginDate.format('YYYY')}`;

      this.getConsumptionData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
      this.getTicketData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
      this.getOnlineUserData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
      this.getWorkOrderData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
    }
  }

  play(){
    if (this.toggledAnimation) {
      this.stopAnimation();
    } else {
      this.startAnimation();
    }
  }

  stopAnimation(){
    if (!this.animation) {
      return;
    }

    this.animation.remove();
    this.animation = null;
    this.toggledAnimation = false;
  }

  startAnimation() {
    this.stopAnimation();
    this.animation = this.animate(this.sliderValue);
    this.toggledAnimation = true;
  }

  animate(startValue) {
    var animating = true;
    var value = Math.round(+startValue / 100) * 100

    const frame = () => {
      if (!animating) {
        return;
      }

      value += 100;
      if (value > 1100){
        value = 0;
      }

      this.setMonth(value);

      // Update at 30fps
      setTimeout(() => {
        requestAnimationFrame(frame);
      }, 4000);
    };

    frame();

    return {
      remove: () => {
        animating = false;
      }
    };
  }

  setMonth(value) {
    console.log("VALUE: ", value);
    const calcMonth = (12 - (+value / 100));
    const beginDate = moment().subtract(calcMonth, 'months');
    const endDate = moment(beginDate).add(1, 'month');

    console.log("CALCMONTH: ", calcMonth);
    console.log("beginDate: ", beginDate);
    console.log("endDate: ", endDate);

    this.sliderCurrentMonth =  `${moment(beginDate).format('MMM')} ${beginDate.format('YYYY')}`;

    this.sliderValue = value;
    this.getConsumptionData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
    this.getTicketData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
    this.getOnlineUserData(beginDate.format('MM'), beginDate.format('YYYY'), endDate.format('MM'), endDate.format('YYYY'));
    // this.consumptionLayer.renderer = this.createRenderer(value);
  }

  filter(local){
    this.filterWaterConsumption(local);
    this.filterTickets(local);
    this.filterOnlineUsers(local);
  }

  filterWaterConsumption(local){
    let filteredWaterConsumptionFeatures = [];
    if(this.filterAccountStatus != 'All'){
      filteredWaterConsumptionFeatures = this.currentConsGeometryData.features.filter(x => x.attributes.account_status == this.filterAccountStatus);
    } else {
      filteredWaterConsumptionFeatures = this.currentConsGeometryData.features;
    }

    if (this.filterAccountType != 'All') {
      filteredWaterConsumptionFeatures = filteredWaterConsumptionFeatures.filter(x => x.attributes.account_type == this.filterAccountType);
    }

    if (this.filterOnlineStatus != 'All') {
      filteredWaterConsumptionFeatures = filteredWaterConsumptionFeatures.filter(x => x.attributes.active == this.filterOnlineStatus.toString());
    }

    this.consumptionLayer.applyEdits({ deleteFeatures: (local? this.currentConsGeometryData.features: this.previousConsGeometryData.features), addFeatures: filteredWaterConsumptionFeatures });
  }

  filterTickets(local){
    let filteredTicketFeatures = [];
    if (this.filterTicketStatus != 'All') {
      filteredTicketFeatures = this.currentTicketGeometryData.features.filter(x => x.attributes.ticket_status == this.filterTicketStatus);
    } else {
      filteredTicketFeatures = this.currentTicketGeometryData.features;
    }

    if (this.filterTicketType != 'All') {
      filteredTicketFeatures = filteredTicketFeatures.filter(x => x.attributes.ticket_type == this.filterTicketType);
    }

    this.ticketLayer.applyEdits({ deleteFeatures: (local? this.currentTicketGeometryData.features: this.previousTicketGeometryData.features), addFeatures: filteredTicketFeatures });
  }

  filterOnlineUsers(local) {
    let filteredOnlineUsersFeatures = [];
    if (this.filterUsersByAccountType != 'All') {
      filteredOnlineUsersFeatures = this.currentOnlineUsersGeometryData.features.filter(x => x.attributes.account_type == this.filterUsersByAccountType);
    } else {
      filteredOnlineUsersFeatures = this.currentOnlineUsersGeometryData.features;
    }

    // this.totalUsersDate = moment(this.month, 'MM').year(parseInt(this.year)).day(this.daySliderValue).format('MMM DD, YYYY');
    // this.totalUsersForDay = filteredOnlineUsersFeatures.filter(x => moment(x.attributes.date_created).format('DD') == this.daySliderValue).length
    // filteredOnlineUsersFeatures = filteredOnlineUsersFeatures.filter(x => moment(x.attributes.date_created) <= moment(this.month, 'MM').year(parseInt(this.year)).day(this.daySliderValue))

    this.usersLayer.applyEdits({ deleteFeatures: (local ? this.currentOnlineUsersGeometryData.features : this.previousOnlineUsersGeometryData.features), addFeatures: filteredOnlineUsersFeatures });
  }

  filterWorkOrders(local) {
    let filteredWorkOrderFeatures = [];
    if (this.filterWorkOrderStatus != 'All') {
      filteredWorkOrderFeatures = this.currentWorkOrderGeometryData.features.filter(x => x.attributes.work_order_status == this.filterWorkOrderStatus);
    } else {
      filteredWorkOrderFeatures = this.currentWorkOrderGeometryData.features;
    }

    if (this.filterWorkOrderType != 'All') {
      filteredWorkOrderFeatures = filteredWorkOrderFeatures.filter(x => x.attributes.work_order_type == this.filterWorkOrderType);
    }

    this.workOrderLayer.applyEdits({ deleteFeatures: (local ? this.currentWorkOrderGeometryData.features : this.previousWorkOrderGeometryData.features), addFeatures: filteredWorkOrderFeatures });
  }

  collapseFilters(){
    this.filtersContainer.collapse();
  }

  refresh(beginMonth, beginYear, endMonth, endYear){
    this.getConsumptionData(beginMonth, beginYear, endMonth, endYear);
    this.getOnlineUserData(beginMonth, beginYear, endMonth, endYear);
    this.getTicketData(beginMonth, beginYear, endMonth, endYear);
    // this.getWeatherData(beginMonth, beginYear, endMonth, endYear);
    this.getWorkOrderData(beginMonth, beginYear, endMonth, endYear);
  }

  toggleOnlineUsersRenderer(){
    if(this.currentOnlineUsersRenderer){
      this.usersLayer.renderer = this.onlineUsersHeatmapRenderer;
      this.usersLayer.refresh();
    } else {
      this.usersLayer.renderer = this.onlineUsersGrowthRenderer;
      this.usersLayer.refresh();
    }
  }
}
