import { Component, OnInit,ViewChild } from '@angular/core';
import { Chart } from "chart.js";
import { ErrorMetricService } from "../../_services";
import * as moment from "moment";
import { forEach } from '@angular/router/src/utils/collection';
@Component({
  selector: 'app-error-codes-breakdown',
  templateUrl: './error-codes-breakdown.component.html',
  styleUrls: ['./error-codes-breakdown.component.css']
})
export class ErrorCodesBreakdownComponent implements OnInit {
  @ViewChild('dateRange') dateRangeDOM;
  log_filters: any = {
    from_date: String,
    to_date: String,
  };

  nameError = [];
  codeError = [];
  countErrors = [];
  colorError = [];
  color;

  totalErrors;

  webErrorsTotal = [];
  dateWebErrors = [];
  monthWebErrors = [];
  inStoreErrorsTotal = [];
  dateInStoreErrors = [];
  monthInStoreErrors = [];
  monthErrors = [];
  months_datas = [];

  rangeMonthsWebErrors = 0;
  rangeMonthsInStoreErrors = 0;
  lifeTimeWebErrors = 0;
  lifeTimeInStoreErrors = 0;

  public domRange: any;
  public toRangeDate=moment().subtract('month').startOf('month').format('YYYY-MM-DD');

  constructor( private errorMetricService: ErrorMetricService) { }

  dateRange: any = {
    from: String,
    to: String
  };

  ngOnInit() {
    this.domRange = this.dateRangeDOM.elementRef.nativeElement;
    this.log_filters.from_date = moment().startOf("week").format("YYYY-MM-DD");
    this.log_filters.to_date = moment().endOf("week").format("YYYY-MM-DD");

    this.getErrorCodeBreakDown();

    // console.log("DOM RANGE : ", this.domRange);
    if (window.location.href.includes('/es/')) { 
      this.domRange.getElementsByClassName('yk-label')[0].innerText = 'Inicio';
      this.domRange.getElementsByClassName('yk-label')[1].innerText = 'Fin';
      this.domRange.getElementsByClassName('yk-btn')[0].innerText = 'Este Mes';
      this.domRange.getElementsByClassName('yk-btn')[1].innerText = 'Ultimo Mes';
      this.domRange.getElementsByClassName('yk-btn')[2].innerText = 'Esta Semana';
      this.domRange.getElementsByClassName('yk-btn')[3].innerText = 'Ultima Semana';
      this.domRange.getElementsByClassName('yk-btn')[4].innerText = 'Este Año';
      this.domRange.getElementsByClassName('yk-btn')[5].innerText = 'Ultimo Año';
    }

  }


  ngAfterViewInit() {

    this.setDateRange({
      from: moment().startOf("week").format("YYYY-MM-DD"),
      to: moment().endOf("week").format("YYYY-MM-DD")
    });

        // let domRange = this.dateRangeDOM.elementRef.nativeElement;
        if (window.location.href.includes('/es/')) {
          this.domRange.getElementsByClassName('yk-weekday')[0].innerText = 'Dom';
          this.domRange.getElementsByClassName('yk-weekday')[1].innerText = 'Lun';
          this.domRange.getElementsByClassName('yk-weekday')[2].innerText = 'Mar';
          this.domRange.getElementsByClassName('yk-weekday')[3].innerText = 'Mie';
          this.domRange.getElementsByClassName('yk-weekday')[4].innerText = 'Jue';
          this.domRange.getElementsByClassName('yk-weekday')[5].innerText = 'Vie';
          this.domRange.getElementsByClassName('yk-weekday')[6].innerText = 'Sab';
        }
  }


  setDateRange(range) {    
    this.log_filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.log_filters.to_date = moment(range.to).format("YYYY-MM-DD");
  }

  getErrorCodeBreakDown() { 
     this.errorMetricService.getErrorCodeBreakDown().subscribe( res => {
    //this.errorMetricService.getJsonData().subscribe( (res:any) => {
      // console.log(res.body);      
      res.body.sort(function (a, b){
        return (b.Cont - a.Cont);
      });
      // console.log('res body = ' + JSON.stringify(res.body));
      let i = 0;
      let j = 0; 
      
      while(res.body[i]){
        // console.log('nombre: ' + res.InfoCount[i].Name);
        if( res.body[i].Cont > 0 && res.body[i].Name !== 'Total_Counts') {
          this.nameError[j] = res.body[i].Name;
          this.codeError[j] = res.body[i].Code;
          this.countErrors[j] = res.body[i].Cont;
          this.generarColor();   
          if (res.body[i].color == "") {
            this.colorError[j] = this.color ;            
          }
          else{
            this.colorError[j] = res.body[i].color;            
          }
          j++;
        } else if ( res.body[i].Name === 'Total_Counts') {
          this.totalErrors = res.body[i].Cont;
          // console.log('Total Errors = ' + this.totalErrors);
        }
        // console.log('valor de i = ' + i);
        i++;
      }      
      // console.log(this.nameError);
      // console.log(this.countErrors);
      // console.log(this.colorError);
    });

    this.getErrorDayAndType();
    setTimeout(() => {
      this.graphicPolarChart();
    }, 2000);
  }

  getErrorCodeBreakDownDates() {

    this.log_filters.from_date = moment(this.domRange.getElementsByClassName('yk-content')[0].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
    this.log_filters.to_date = moment(this.domRange.getElementsByClassName('yk-content')[1].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
   
    if (this.log_filters.from_date=='Invalid date' && this.log_filters.to_date =='Invalid date' ) 
    {
      this.log_filters.from_date =moment().startOf('month').format('YYYY-MM-DD');
      this.log_filters.to_date = moment().endOf('month').format('YYYY-MM-DD');
     }

    var from_date = this.log_filters.from_date + " " + "00:00";
    var to_date = this.log_filters.to_date + " " + "23:59";
    
    this.errorMetricService.getErrorCodeBreakDownDates(from_date, to_date).subscribe(res => {
      // console.log(JSON.stringify(res));
      res.body.sort(function (a, b){
        return (b.Cont - a.Cont);
      });
      // console.log('res body = ' + JSON.stringify(res.body));
      let i = 0;
      let j = 0; 
      
      while(res.body[i]){ 
        // console.log('nombre: ' + res.InfoCount[i].Name);
        if( res.body[i].Cont > 0 && res.body[i].Name !== 'Total_Counts') {
          this.nameError[j] = res.body[i].Name;
          this.codeError[j] = res.body[i].Code;
          this.countErrors[j] = res.body[i].Cont;
          this.generarColor();
          if (res.body[i].color == "") {
            this.colorError[j] = this.color ;
          }
          else{
            this.colorError[j] = res.body[i].color;            
          }
          
          j++;
        } else if ( res.body[i].Name === 'Total_Counts') {
          this.totalErrors = res.body[i].Cont;
          // console.log('Total Errors = ' + this.totalErrors);
        }
        // console.log('valor de i = ' + i);
        i++;
      }      
      // console.log(this.nameError);
      // console.log(this.countErrors);
      // console.log(this.colorError);
    });

    this.getErrorDayAndTypeDates(from_date, to_date);
    setTimeout(() => {
      this.graphicPolarChart();
    }, 2000);
  }


  generarColor() {
    var simbolos;
    simbolos = "0123456789ABCDEF";
    this.color = "#";
  
    for(var i = 0; i < 6; i++){
      this.color = this.color + simbolos[Math.floor(Math.random() * 16)];
    }
  }
  

  graphicPolarChart() {   
    var polarData = {
      labels: this.nameError,
      datasets: [
        {
          label: "Users Error",
          backgroundColor: this.colorError, // ["#3e95cd", "#8e5ea2","#2e95cd", "#8e7ea2"],
          data: this.countErrors,
        },        
      ],
    };
    var polarOptions = {
      responsive: true,
    };

    document.getElementById("ChartpolarChart").innerHTML = '&nbsp;';
    document.getElementById("ChartpolarChart").innerHTML = '<canvas id="polarChart"></canvas>';

    var ctx = document.getElementById("polarChart"); // .getContext("2d");;

    if (myNewChart) {
      myNewChart.clear();
      myNewChart.destroy();
    }
    
    var myNewChart = new Chart(ctx, {
      type: "polarArea",
      data: polarData,
      options: polarOptions,
    });
  }
  // Obtener Errores por tipo y día para separarlos por web  y InStore
  getErrorDayAndType() {
    this.errorMetricService.getErrorDayAndType().subscribe(res => {
      // console.log(JSON.stringify(res));
      let i = 0;
      let j = 0;
      let k = 0;
      this.lifeTimeInStoreErrors = 0;
      this.lifeTimeWebErrors = 0;
      // name , fecha, count
      // makePhysicalTerminalPayment   makeUSIOQuickPayment   makeVirtualTerminalPayment
      while (res.body[i]) {
        // Separar por InStore y Web
        if( res.body[i].name === 'makeVirtualTerminalPayment' || res.body[i].name === 'makePhysicalTerminalPayment' ) {
            
          this.inStoreErrorsTotal[j] =  res.body[i].count;
            this.dateInStoreErrors[j] = res.body[i].fecha;
            this.lifeTimeInStoreErrors += Number(res.body[i].count);
            j++;                    
        } else { // if ( res.body[i].name === 'makeUSIOQuickPayment' ) {
            this.webErrorsTotal[k] = res.body[i].count;
            this.dateWebErrors[k] = res.body[i].fecha;
            this.lifeTimeWebErrors += Number(res.body[i].count);
            k++;
        }
        i++;
      }
      // console.log('Total Web Errors = ' + this.WebErrorsTotal);
      // console.log('Date Web Errors = ' + this.DateWebErrors );
      // console.log('Total InStore Errors = ' + this.InStoreErrorsTotal );
      // console.log('Date InStore Errors = ' + this.DateInStoreErrors );
    });    
    setTimeout(() => {
      this.getCountTypeMonth();      
    }, 1000);
    
  }

  getErrorDayAndTypeDates(from, to) {
    this.errorMetricService.getErrorDayAndTypeDates(from, to).subscribe(res => {
      // console.log(JSON.stringify(res));
      let i = 0;
      let j = 0;
      let k = 0;
      this.lifeTimeInStoreErrors = 0;
      this.lifeTimeWebErrors = 0;
      // name , fecha, count
      // makePhysicalTerminalPayment   makeUSIOQuickPayment   makeVirtualTerminalPayment
      while (res.body[i]) {
        // Separar por InStore y Web
        if( res.body[i].name === 'makeVirtualTerminalPayment' || res.body[i].name === 'makePhysicalTerminalPayment' ) {
          let fecha = new Date(res.body[i].fecha)
          
          if(fecha>= new Date(from) && fecha <= new Date(to)){
              this.inStoreErrorsTotal[j] =  res.body[i].count;
              this.dateInStoreErrors[j] = res.body[i].fecha;
              this.lifeTimeInStoreErrors += Number(res.body[i].count);
            }
            j++;                    
          } else { // if ( res.body[i].name === 'makeUSIOQuickPayment' ) {
            let fecha = new Date(res.body[i].fecha)
            if(fecha>= new Date(from) && fecha <= new Date(to)){
              this.webErrorsTotal[k] = res.body[i].count;
              this.dateWebErrors[k] = res.body[i].fecha;
              this.lifeTimeWebErrors += Number(res.body[i].count);
            }
            k++;
        }
        i++;
      }
      // console.log('Total Web Errors = ' + this.WebErrorsTotal);
      // console.log('Date Web Errors = ' + this.DateWebErrors );
      // console.log('Total InStore Errors = ' + this.InStoreErrorsTotal );
      // console.log('Date InStore Errors = ' + this.DateInStoreErrors );
    });    
    
  }

  // Separar conteo por mes los errores de web y InStore
  getCountTypeMonth() {
    this.rangeMonthsInStoreErrors = 0;
    this.rangeMonthsWebErrors = 0;
    let mmInitial = new Date().getMonth() - 4; // mes actual menos 6 meses
    // Creado arreglo para los últimos 6 meses
    for ( let mm = 0 ; mm < 6; mm++ ) {
      this.monthErrors[mm] = mmInitial;
      this.monthInStoreErrors[mm] = 0;
      this.monthWebErrors[mm] = 0;
      mmInitial++;
    }
    // console.log('month errors = ' + this.monthErrors);
    // cada conteo de error InStore es adicionado a su respectivo mes
    for (let i = 0; i < this.dateInStoreErrors.length ; i++ ) {
      let date = new Date(this.dateInStoreErrors[i]); //resLoginCount.body[i].date_log);
      let month_date = date.getMonth() + 1;
      for ( let j = 0; j < this.monthErrors.length; j++ ) {
          if ( this.monthErrors[j] === month_date ) {
             this.monthInStoreErrors[j] +=  Number(this.inStoreErrorsTotal[i]);
             this.rangeMonthsInStoreErrors += Number(this.inStoreErrorsTotal[i]);
          }
      }
    }
    // console.log('Month in store errors = ' + this.monthInStoreErrors);
    // cada conteo de error de Web es adicionado a su respectivo mes
    for (let i = 0; i < this.dateWebErrors.length ; i++ ) {
      let date = new Date(this.dateWebErrors[i]); //resLoginCount.body[i].date_log);
      let month_date = date.getMonth() + 1;
      for ( let j = 0; j < this.monthErrors.length; j++ ) {
          if ( this.monthErrors[j] === month_date ) {
             this.monthWebErrors[j] +=  Number(this.webErrorsTotal[i]);
             this.rangeMonthsWebErrors += Number(this.webErrorsTotal[i]);
          }
      }
    }
    // console.log('Month web errors = ' + this.monthWebErrors);

    // Asignarle nombre de mes para la gráfica
    for (let i = 0; i < this.monthErrors.length ; i++ ){            
      switch (this.monthErrors[i]) {
        case 1:
          this.months_datas[i] = 'January';
          break;
        case 2:
          this.months_datas[i] = 'February';
          break;
        case 3:
          this.months_datas[i] = 'March';
          break;
        case 4:
          this.months_datas[i] = 'April';
          break;
        case 5:
          this.months_datas[i] = 'May';
          break;
        case 6:
          this.months_datas[i] = 'June';
          break;          
        case 7:
          this.months_datas[i] = 'July';
          break;
        case 8:
          this.months_datas[i] = 'August';
          break;
        case 9:
          this.months_datas[i] = 'September';
          break;
        case 10:
          this.months_datas[i] = 'October';
          break;
        case 11:
          this.months_datas[i] = 'November';
          break;
        case 12:
          this.months_datas[i] = 'December';
          break;
      }
    }
    // console.log('month datas = ' + this.months_datas);

    setTimeout(() => {
      this.graphicMonthErrorType();      
    }, 1000);

  }


  graphicMonthErrorType() {
    // BAR
    // console.log('Datos de los month datas en grafica = ' + this.MonthErrors);
    // console.log('Resultados total month = ' + this.MonthInStoreErrors);
    var barData = {
      labels: this.months_datas,
      datasets: [        
        {
          label: "Web Errors",
          backgroundColor: 'rgba(255,0,0,0.8)',
          borderColor: 'rgba(255,0,0,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          data: this.monthWebErrors,
        },
        {
          label: "InStore Errors",
          backgroundColor: "rgba(160,26,0,0.8)",
          borderColor: "rgba(160,79,48,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff",
          data: this.monthInStoreErrors,
        },
      ],
    };
    var barOptions = {
      responsive: true,
    };

    var ctx = document.getElementById("barChartMonthErrors"); // .getContext("2d");;

    var myNewChart = new Chart(ctx, {
      type: "bar",
      data: barData,
      options: barOptions,
    });
  }


}

