import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { FormBuilder, Validators, FormArray, FormGroup } from "@angular/forms";

import {
  TypeService,
  CustomerService,
  TicketService,
  StatusService,
  UserService,
  WorkOrderService,
  CryptoService,
} from "../../_services";
import { WorkOrderTypes, Account, WorkOrderStatus } from "src/app/_models";
import { mergeMap } from "rxjs/operators";
import Swal from "sweetalert2";

declare var $: any;

@Component({
  selector: "app-edit-work-order",
  templateUrl: "./edit-work-order.component.html",
  styleUrls: ["./edit-work-order.component.css"],
})
export class EditWorkOrderComponent implements OnInit {
  @Output() editWorkOrderModalClose = new EventEmitter();
  @ViewChild("editSuccess") private editSuccessSwal: SwalComponent;
  public editWorkOrderFormGroup: FormGroup;
  public errorMessage: string;

  public workOrder;

  public staffUsers: any;

  public accounts: Account[];
  public workOrderTypes: WorkOrderTypes[];
  public workOrderStatuses: WorkOrderStatus[];
  public user: any;

  public filesToUpload: File[];
  public viewFiles: any[];

  public enterTitlePlaceholder = "Enter Title";
  public enterDescripcionPlaceholder = "Enter Description";

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private customerService: CustomerService,
    private typeService: TypeService,
    private workOrderService: WorkOrderService,
    private statusService: StatusService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private cryptoService: CryptoService
  ) {
    this.editWorkOrderFormGroup = this.fb.group({
      title: ["", Validators.required],
      ticket_id: ["", Validators.required],
      description: ["", Validators.required],
      work_order_type: ["", Validators.required],
      work_order_status: ["", Validators.required],
      assigned_to: [""],
      priority: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);

    if (window.location.href.includes("/es/")) {
      this.enterTitlePlaceholder = "Ingrese un Título";
      this.enterDescripcionPlaceholder = "Ingrese una Descripción";
    }
  }

  setEditWorkOrderForm() {
    console.log(this.workOrder);
    this.editWorkOrderFormGroup.setValue({
      title: this.workOrder.details.title,
      description: this.workOrder.details.description,
      ticket_id: this.workOrder.ticket_id,
      work_order_type: this.workOrder.work_order_type_id,
      work_order_status: this.workOrder.work_order_status_id,
      // account_id: this.workOrder.account_id,
      assigned_to: this.workOrder.details.assigned_to,
      priority: this.workOrder.details.priority,
    });

    this.user = JSON.parse(window.localStorage.current_user);

    // this.userService.getFiltered(1)
    //   .subscribe(
    //     data => {
    //       console.log("users: ", data)
    //       this.staffUsers = data;
    //     }
    //   )

    this.typeService
      .getWorkOrderTypes()
      .subscribe((data) => (this.workOrderTypes = data));

    this.statusService
      .getWorkOrderStatus()
      .subscribe((data) => (this.workOrderStatuses = data));
  }

  submitEditedWorkOrder() {
    const workOrder = {
      ticket_id: this.editWorkOrderFormGroup.value.ticket_id,
      work_order_type_id: this.editWorkOrderFormGroup.value.work_order_type,
      work_order_status_id: this.editWorkOrderFormGroup.value.work_order_status,
      details: {
        title: this.editWorkOrderFormGroup.value.title,
        description: this.editWorkOrderFormGroup.value.description,
        priority: this.editWorkOrderFormGroup.value.priority,
        assigned_to: this.editWorkOrderFormGroup.value.assigned_to,
      },
    };

    console.log("work order edit: ", workOrder);

    if (
      workOrder.work_order_status_id == 4 ||
      workOrder.work_order_status_id == 5
    ) {
      this.workOrderService
        .updateWorkOrder(this.workOrder.id, workOrder)
        .pipe(
          mergeMap((e) =>
            this.workOrderService.updateStatus(this.workOrder.id, {
              id: workOrder.work_order_status_id,
              tid: workOrder.ticket_id,
            })
          )
        )
        .subscribe((data: any) => {
          console.log("Data: ", data);
          if (data.closeTicket) {
            this.editSuccessSwal.title = "Work Order Updated";
            this.editSuccessSwal.text = `Status of ticket with id ${workOrder.ticket_id} has been set to resolved`;
            if (window.location.href.includes("/es/")) {
              this.editSuccessSwal.title = "Orden de trabajo actualizada";
              this.editSuccessSwal.text = `Estado del ticket con id ${workOrder.ticket_id} ha sido actualizado a resuelto`;
            }
            this.editSuccessSwal.show();
          } else {
            this.editSuccessSwal.title = "Work Order Updated";
            if (window.location.href.includes("/es/")) {
              this.editSuccessSwal.title = "Orden de trabajo actualizada";
            }
            this.editSuccessSwal.show();
          }
        });
    } else {
      this.workOrderService
        .updateWorkOrder(this.workOrder.id, workOrder)
        .subscribe((data) => {
          this.editSuccessSwal.title = "Work Order Updated";
          if (window.location.href.includes("/es/")) {
            this.editSuccessSwal.title = "Orden de trabajo actualizada";
          }
          this.editSuccessSwal.show();
        });
    }
  }

  confirmEdit() {
    this.bsModalRef.hide();
    this.editWorkOrderModalClose.emit(true);
    //window.location.reload();
  }
}
