export class Traffic{
    id      : number;
    user_id : number;   
    first_name: string;
    last_name: string;
    //confirmation_id: string;
    confirmation_id: number;
    address: string;
    city: string;
    state: string;
    zip_code: number;
    total_amount: number;
    total_fees: number;
    card_type: string;
    identifier: number;
    date_created:string;
    date_updated:string;    
}