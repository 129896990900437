import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomerService } from '../../_services';


@Component({
  selector: 'app-rental-agreement',
  templateUrl: './rental-agreement.component.html',
  styleUrls: ['./rental-agreement.component.css']
})
export class RentalAgreementComponent implements OnInit {

  url:string = null;
  constructor(public bsModalRef: BsModalRef, public CS:CustomerService) { }

  ngOnInit() {
    console.log()
  }

  getRentalAgreement(account){
    this.url = account.deed_rental_url
  }
}
