import { Component, OnInit, ViewChild } from '@angular/core';
import { Router }	from '@angular/router';
import { UserService, AddressService } from '../_services';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Address } from '../_models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { filter } from 'rxjs-compat/operator/filter';
import { NgSelectConfig } from '@ng-select/ng-select';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-existing-user',
  templateUrl: './existing-user.component.html',
  styleUrls: ['./existing-user.component.css']
})

export class ExistingUserComponent implements OnInit {
  @ViewChild('successfulAccountLink') private successfulAccountLink: SwalComponent;


  existing_user: any = {
    account_number : String(),
    email : String(),
    driver_license_number : String(),
    password : String(),
    confirm_password : String()
  };

  error: any = {
    isJoi: Boolean(false),
    errorMessage: String()
  };

  helpers: any = {
    showAccountNumber: Boolean(true),
    showPassword: Boolean(true),
    showBillAmount: Boolean(true)
  };

  serviceAddresses: Address[] = [];
  filteredAddresses: Address[] = [];
  selectedAddressID: String;

  signupFormGroup: FormGroup;
  passwordFormGroup: FormGroup;

  public successfulAccountLinkTitle = 'Successful';
  public successfulAccountLinkText = 'Account was successfully linked.';
  public confirmButtonText = 'Login';
  public accountNumberInput = 'Account Number (include dashes)';
  public serviceAddresInput = 'Service Address';
  public emailInput = 'Email';
  public passwordInput = 'Password';
  public confirmPasswordInput = 'Confirm Password';
  public currentBillInput = 'Most Recent Bill Statement amount';

  public passwordRequirement = 'Password Requirements';
  public lowerCaseRequirement = 'At least 1 Lowercase';
  public upperCaseRequirement = 'At least 1 Uppercase';
  public numberRequirement = 'At least 1 Number';
  public specialCharacterRequirement = 'At least 1 special character(@!#$%^&+=)';
  public charactersMinimumRequirement = '10 Characters minimum';


  constructor( private US: UserService, private router: Router, private fb: FormBuilder,	private AS: AddressService ) {
    this.passwordFormGroup = this.fb.group({
      password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{10,}$')]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validator: this.matchValidator.bind(this)
    });

    this.signupFormGroup = this.fb.group({
      account_number : new FormControl('', [Validators.required]),
      email : new FormControl('', [Validators.required, Validators.email]),
      //current_bill_amount: new FormControl('', [Validators.required])
    });
  }

  getURL(lang) {
    console.log('url ', window.location.href);
    let url = window.location.href;

    // for localhost development

    if (environment.local) {
      if (url.indexOf('4300') > -1 && lang === 'en') {
        url = url.replace('4300/es/', '4200/');
      } else if (url.indexOf('4200') > -1 && lang === 'es') {
        url = url.replace('4200/', '4300/es/');
      }
    } else {
      if (lang === 'en') {
        lang = '';
      } else if (lang === 'es') {
        lang = '/es';
      }
      const path = url.replace(environment.serverUrl, '').replace('/es/', '/');
      url = `${environment.serverUrl}${lang}${path}`;
    }

    console.log('url replaced', url);
    return url;
  }

  changeLanguage(lang) {
    window.location.href = this.getURL(lang);
  }

  ngOnInit() {
    console.log('touched: ? ', this.password);
    if (window.location.href.includes('/es/')) {
      this.successfulAccountLinkTitle = 'Exito!';
      this.successfulAccountLinkText = 'Cuenta fue enlazada exitosamente.';
      this.confirmButtonText = 'Acceder';
      this.accountNumberInput = 'Número de cuenta (incluya los guiones)';
      this.serviceAddresInput = 'Dirección de Servicio';
      this.emailInput = 'Correo';
      this.passwordInput = 'Contraseña';
      this.confirmPasswordInput = 'Confirmar Contraseña';
      this.currentBillInput = 'Monto de la factura mas reciente';
      this.passwordRequirement = 'Requerimientos de Contraseña ';
      this.lowerCaseRequirement = 'Debe tener al menos una Minuscula';
      this.upperCaseRequirement = 'Debe tener al menos una Mayuscula';
      this.numberRequirement = 'Debe tener al menos un Numero';
      this.specialCharacterRequirement = 'Debe tener al menos un caracter Especial';
      this.charactersMinimumRequirement = '10 Caracteres como mínimo';
    }
  }
  get passwordField() {
    return this.passwordFormGroup.get('password');
  }

  get confirmPasswordField() {
    return this.passwordFormGroup.get('confirmPassword');
  }

  get password() {
    return this.passwordFormGroup.get('password').value;
  }

  get confirmPassword() {
    return this.passwordFormGroup.get('confirmPassword').value;
  }

  get accountNumber() {
    return this.signupFormGroup.get('account_number').value;
  }
  /*
  get currentBillAmount() {
    return this.signupFormGroup.get('current_bill_amount').value;
  }
  */
  get email() {
    return this.signupFormGroup.get('email').value;
  }

  get serviceAddressID() {
    return this.selectedAddressID;
  }

  redirectToLogin() {
    this.router.navigateByUrl('/login');
  }

  onOpen(event) {
    $('body').removeClass('swal2-height-auto');
  }

  createLoginInformation() {
    this.error.errorMessage = '';
    this.error.isJoi = false;
    const data = {
      account_number : this.accountNumber,
      email : this.email,
      service_address_id : this.serviceAddressID,
      //current_bill_amount: parseFloat(this.currentBillAmount),
      password : this.password,
      confirm_password : this.confirmPassword
    };
    console.log('user data: ', data);
    this.US.createLoginForExistingUser(data)
      .subscribe(response => {
        this.successfulAccountLink.show();
        }, error => {
        if (error.message.isJoi && error.message.details[0].message.toLowerCase().includes('account number') && error.message.details[0].message.toLowerCase().includes('pattern')) {
          this.error.errorMessage = 'Account number can only have numbers and dashes';
          if (window.location.href.includes('/es/')) {
            this.error.errorMessage = 'Número de cuenta puede solo tener números y guiones';
          }
        } else if (error.message.isJoi && error.message.details[0].message.toLowerCase().includes('password') && error.message.details[0].message.toLowerCase().includes('pattern')) {
          this.error.errorMessage = 'Password fails to meet the requirements. ';
          if (window.location.href.includes('/es/')) {
            this.error.errorMessage = 'La contraseña no cumple con los requisitos.';
          }
        } else if (error.message.isJoi) {
          this.error.isJoi = error.message.isJoi;
          this.error.errorMessage = error.message.details[0].message;
        } else {
          this.error.errorMessage = error.message;
        }
      });
  }

  matchValidator(formGroup: FormGroup) {
    const values = [];
    for (const prop in formGroup.controls) {
      values.push(formGroup.controls[prop].value);
    }

    if (values[1].length <= 0) {
      return null;
    }

    if (values[1] !== values[0]) {
      return {
        doesMatchValue: true
      };
    }

    return null;
  }

  getFilteredAddress(term: any) {
    console.log('Term: ', term);
    this.AS.getFilteredAddress(term.term)
      .subscribe((response) => {
        console.log('Response: ', response);
        this.filteredAddresses = response.body;
        console.log('FILTERED ADDRSSES: ', this.filteredAddresses);
        }, (error) => {
        console.log('Error getting filtered address: ', error);
      });
  }

  getServiceAddresses() {
    this.AS.getServiceAddresses()
      .subscribe((response) => {
        this.serviceAddresses = response.body;
        console.log('ADDRESES: ', this.serviceAddresses);
        }, (error) => {
        console.log('Error getting service addresses: ', error);
      });
  }

}
