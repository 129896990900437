import { Injectable , Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import {
    WorkOrder, WorkOrderComment
} from '../_models';

@Injectable({
    providedIn: 'root'
})
export class WorkOrderService {
    baseUrl: string = environment.serverUrl;
    @Output() newWorkOrderCreated = new EventEmitter()

    constructor(private http: HttpClient) { }

    getWorkOrderById(workOrderId) {
        return this.http.get<WorkOrder>(`${this.baseUrl}/api/work_orders/${workOrderId}`);
    }

    getFilteredWorkOrders(page, limit, workOrderStatus, workOrderType, search) {
        return this.http.get<any>(`${this.baseUrl}/api/work_orders?page=${page}&limit=${limit}&work_order_status=${workOrderStatus}&work_order_type=${workOrderType}&search=${search}`);
    }

    updateWorkOrder(workOrderId, workOrder) {
        return this.http.put(`${this.baseUrl}/api/work_orders/${workOrderId}`, workOrder);
    }

    updateStatus(workOrderId, workOrder) {
        return this.http.put(`${this.baseUrl}/api/work_orders/${workOrderId}/status`, workOrder);
    }

    getWorkOrderComments(workOrderId) {
        return this.http.get<WorkOrderComment[]>(`${this.baseUrl}/api/work_orders/${workOrderId}/comments`);
    }

    createWorkOrderComment(workOrderId, comment) {
        return this.http.post(`${this.baseUrl}/api/work_orders/${workOrderId}/comments`, comment);
    }

    createWorkOrder(workOrder) {
        return this.http.post(`${this.baseUrl}/api/work_orders`, workOrder);
    }

    newWorkOrder(){
        this.newWorkOrderCreated.emit(true)
    }

    getMapData(beginMonth, beginYear, endMonth, endYear) {
        return this.http.get<any>(`${this.baseUrl}/api/work_orders/map?beginMonth=${beginMonth}&beginYear=${beginYear}&endMonth=${endMonth}&endYear=${endYear}`);
    }
}
