import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import decode from "jwt-decode";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class CryptoService {
  baseUrl: string = environment.serverUrl;
  keyDecript: string = environment.keyDecript;
  constructor(private http: HttpClient) {}

  descriptCurrentUserData() {
    const current_user = localStorage.getItem("current_user");
    const iv = localStorage.getItem("iv");

    if (!current_user || !iv) {
      return null;
    }

    const decodedToken: any = this.handleDecrypt(current_user, iv);

    return JSON.parse(decodedToken);
  }

  handleDecrypt(text: any, _iv: any) {
    const key = CryptoJS.enc.Hex.parse(this.keyDecript);
    const iv = CryptoJS.enc.Hex.parse(_iv);

    const ciphertextBytes = CryptoJS.enc.Base64.parse(text);

    const decryptedBytes = CryptoJS.AES.decrypt(
      { ciphertext: ciphertextBytes },
      key,
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    // Convertir los bytes descifrados a cadena UTF-8
    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    // Verificar si el texto descifrado es válido UTF-8
    if (!decryptedText) {
      throw new Error("Decryption failed: Invalid UTF-8 data");
    }

    return decryptedText;
  }
}
