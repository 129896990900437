import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CryptoService,
  CustomerService,
  PaymentsService,
} from "src/app/_services";
import { User } from "src/app/_models";
import { FeeCalculator } from "../_helpers";
import * as globals from "../globals.json";
import * as moment from "moment";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
  selector: "app-payment-receipt",
  templateUrl: "./payment-receipt.component.html",
  styleUrls: ["./payment-receipt.component.css"],
})
export class PaymentReceiptComponent implements OnInit {
  @ViewChild("printButton") printButton: ElementRef;
  @ViewChild("printGroupButton") printGroupButton: ElementRef;
  @ViewChild("inputNumber") private inputNumber: SwalComponent;

  public paymentId: string;
  public user: User;
  public payment: any;
  public globals: any = globals.default;
  public paymentCount = 0;
  public multiplePayments = false;
  public amountTotals = [];
  public grandTotal = 0;

  public IPayFee = 0;
  public USIOFee = 0;
  public totalAmountWithIPayFee = 0.0;
  public print_type = "page";

  public isIpad: boolean = false;

  constructor(
    private customerService: CustomerService,
    private paymentService: PaymentsService,
    public feeCalculator: FeeCalculator,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService
  ) {}
  // a comment
  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    this.paymentId = this.route.snapshot.params.id;

    if (
      (this.isDeviceIPad() && this.user.user_type_id == 1) ||
      (this.isDeviceIPad() && this.user.user_type_id == 2)
    ) {
      this.isIpad = true;
    } else {
      this.isIpad = false;
    }

    if (this.user.user_type_id == 3) {
      //agregar estos para capturar el nomrbe del usuario cajero (this.user.user_info.first_name+" "+this.user.user_info.last_name)

      this.customerService
        .getCustomerPaymentById(this.user.id, this.paymentId)
        .subscribe(
          (data) => {
            this.payment = data;
            if (this.payment.source_type == "web") {
              // getIPayFeeDelta ONLY ACCOUNTS FOR WEB PAYMENTS
              switch (
                this.payment.pds_info.payment_type // data.amount IS THE FULL PAYMENT AMOUNT, FEES INCLUDED
              ) {
                case "ACH":
                  this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                    this.payment.pds_info.amount,
                    "ach"
                  )["fee"];
                  this.USIOFee = +this.feeCalculator.getUSIOFee(
                    this.payment.pds_info.amount,
                    "ach"
                  )["fee"];
                  this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                    this.payment.pds_info.amount + this.IPayFee + this.USIOFee
                  );
                  break;
                // case "VISA": case "MSTR": case "DISC": case "AMEX":
                case "VISA" || "MSTR" || "DISC" || "AMEX":
                  this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                    this.payment.pds_info.amount,
                    "cc"
                  )["fee"];
                  this.USIOFee = +this.feeCalculator.getUSIOFee(
                    this.payment.pds_info.amount,
                    "cc"
                  )["fee"];
                  this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                    this.payment.pds_info.amount + this.IPayFee + this.USIOFee
                  );
                  break;
                default:
                  this.totalAmountWithIPayFee = +data.amount;
                  break;
              }
            } else if (this.payment.source_type == "terminal") {
              this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                this.payment.pds_info.amount,
                "terminal"
              )["fee"];
              this.USIOFee = +this.feeCalculator.getUSIOFee(
                this.payment.pds_info.amount,
                "terminal"
              )["fee"];
              this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                this.payment.pds_info.amount + this.IPayFee + this.USIOFee
              );
            } else {
              this.totalAmountWithIPayFee = +data.amount; // IF NOT web, NO IPAY FEE
            }

            this.paymentService
              .getNumberOfPaymentsByTransactionId(
                this.payment.pds_info.confirmation_id
              )
              .subscribe(
                (data) => {
                  this.paymentCount = data[0].count;
                  if (this.paymentCount > 1) {
                    // this.printGroupButton.nativeElement.focus()
                    this.multiplePayments = true;
                    this.paymentService
                      .getPaymentsByTransactionId(
                        this.payment.pds_info.confirmation_id
                      )
                      .subscribe(
                        (data) => {
                          data.map((value, index) => {
                            this.amountTotals.push({
                              account_no: value.account.incode_account_no,
                              total:
                                parseFloat(value.pds_info.amount) +
                                parseFloat(value.pds_info.fees),
                            });
                            this.grandTotal +=
                              parseFloat(value.pds_info.amount) +
                              parseFloat(value.pds_info.fees);
                          });
                        },
                        (err) => {
                          if (err.status == 404) {
                            this.router.navigate(["404"]);
                          } else if (err.status === 403) {
                            this.router.navigate(["403"]);
                          }
                        }
                      );
                  }
                },
                (err) => {
                  if (err.status == 404) {
                    this.router.navigate(["404"]);
                  } else if (err.status === 403) {
                    this.router.navigate(["403"]);
                  }
                }
              );
          },
          (err) => {
            if (err.status == 404) {
              this.router.navigate(["404"]);
            } else if (err.status === 403) {
              this.router.navigate(["403"]);
            }
          }
        );
    } else {
      this.paymentService.getPaymentById(this.paymentId).subscribe(
        (data) => {
          this.payment = data;
          if (this.payment.source_type == "web") {
            // getIPayFeeDelta ONLY ACCOUNTS FOR WEB PAYMENTS
            switch (this.payment.pds_info.payment_type) {
              case "ACH":
                this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                  this.payment.pds_info.amount,
                  "ach"
                )["fee"];
                this.USIOFee = +this.feeCalculator.getUSIOFee(
                  this.payment.pds_info.amount,
                  "ach"
                )["fee"];
                this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                  this.payment.pds_info.amount + this.IPayFee + this.USIOFee
                );
                break;
              // case "VISA": case "MSTR": case "DISC": case "AMEX":
              case "VISA" || "MSTR" || "DISC" || "AMEX":
                this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                  this.payment.pds_info.amount,
                  "cc"
                )["fee"];
                this.USIOFee = +this.feeCalculator.getUSIOFee(
                  this.payment.pds_info.amount,
                  "cc"
                )["fee"];
                this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                  this.payment.pds_info.amount + this.IPayFee + this.USIOFee
                );
                break;
              default:
                this.totalAmountWithIPayFee = +data.amount;
                break;
            }
          } else if (this.payment.source_type == "terminal") {
            this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
              this.payment.pds_info.amount,
              "terminal"
            )["fee"];
            this.USIOFee = +this.feeCalculator.getUSIOFee(
              this.payment.pds_info.amount,
              "terminal"
            )["fee"];
            this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
              this.payment.pds_info.amount + this.IPayFee + this.USIOFee
            );
          } else {
            this.totalAmountWithIPayFee = +data.amount; // IF NOT web, NO IPAY FEE
          }
          this.paymentService
            .getNumberOfPaymentsByTransactionId(
              this.payment.pds_info.confirmation_id
            )
            .subscribe(
              (data) => {
                this.paymentCount = data[0].count;
                if (this.paymentCount > 1) {
                  // this.printGroupButton.nativeElement.focus()
                  this.multiplePayments = true;
                  this.paymentService
                    .getPaymentsByTransactionId(
                      this.payment.pds_info.confirmation_id
                    )
                    .subscribe(
                      (data) => {
                        data.map((value, index) => {
                          this.amountTotals.push({
                            account_no: value.account.incode_account_no,
                            total:
                              parseFloat(value.pds_info.amount) +
                              parseFloat(value.pds_info.fees),
                            name:
                              value.user_info.first_name +
                              " " +
                              (value.user_info.last_name || ""),
                          });
                          this.grandTotal +=
                            parseFloat(value.pds_info.amount) +
                            parseFloat(value.pds_info.fees);
                        });
                      },
                      (err) => {
                        if (err.status == 404) {
                          this.router.navigate(["404"]);
                        } else if (err.status === 403) {
                          this.router.navigate(["403"]);
                        }
                      }
                    );
                }
              },
              (err) => {
                if (err.status == 404) {
                  this.router.navigate(["404"]);
                } else if (err.status === 403) {
                  this.router.navigate(["403"]);
                }
              }
            );
        },
        (err) => {
          if (err.status == 404) {
            this.router.navigate(["404"]);
          } else if (err.status === 403) {
            this.router.navigate(["403"]);
          }
        }
      );
    }
  }

  ngAfterViewInit() {
    this.paymentCount > 1
      ? this.printGroupButton.nativeElement.focus()
      : this.printButton.nativeElement.focus();
  }

  print(print_type) {
    this.print_type = print_type;

    // Without delay, classes don't have enough time to hide/show, will appear to show the opposite
    setTimeout(function () {
      window.print();
    }, 250);
  }

  openToGetNumber() {
    this.inputNumber.title = "Enter the number phone";
    this.inputNumber.html =
      "<p> Enter the phone number to send SMS </p>" +
      '<div class="form-group"> ' +
      '<input id="number"  data-mask="(999) 999-9999" class="form-control text-center" type="text" placeholder="Phone Number"> ' +
      "</div>";
    this.inputNumber.show();
  }

  onOpen(event) {
    $("body").removeClass("swal2-height-auto");
    //this.openToGetNumber()
  }

  cancel() {}

  sendSMS() {
    let number = $.trim($("#number").val());

    if (number == "") {
      Swal.fire({
        type: "info",
        title: "Oops...",
        text: "The field number cant empty, try again",
      });
    } else {
      let object = {
        paymentTo: this.globals.city,
        paymentFor: "Water Utilities",
        account: this.payment.account.incode_account_no,
        date:
          this.payment.date_created != ""
            ? moment(this.payment.date_created).format("MMMM D, YYYY")
            : "N/A",
        time:
          this.payment.date_created != ""
            ? moment(this.payment.date_created).format("H:mm a")
            : "N/A",
        transactionID:
          this.payment.pds_info.confirmation_id != ""
            ? this.payment.pds_info.confirmation_id
            : "N/A",
        paymentAmount:
          "$" +
          (this.payment.pds_info.amount != ""
            ? this.payment.pds_info.amount
            : "N/A"),
        adminFee: "$" + this.USIOFee,
        procesingFee: "$" + this.IPayFee,
        totalAmount: "$" + this.totalAmountWithIPayFee,
        number: "+1" + number,
      };
      this.paymentService.sendReceiptSMS(object).subscribe(
        (data) => {
          Swal.fire({
            type: "info",
            title: "Success...",
            text: "The SMS has been sent, only can send two times",
          });
        },
        (err) => {
          Swal.fire({
            type: "info",
            title: "Oops...",
            text: err.message,
          });
        }
      );
    }
  }

  isDeviceIPad() {
    return (
      navigator &&
      navigator.userAgent &&
      navigator.maxTouchPoints &&
      navigator.platform &&
      ((navigator.userAgent.match(/Mac/) !== null &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints >= 1) || // iPad Pro (5 maxTouchPoints, use 2 to check)
        navigator.platform.match(/iPad/i) !== null)
    ); // iPad
  }
}
