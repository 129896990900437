import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { UserGrowthModel, UserLogModel } from "../_models";

import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoginCountService {
  baseUrl: string = environment.serverUrl;
  constructor(private http: HttpClient) { }


  getLoginCount(from, to): Observable<HttpResponse<any>> {
    // console.log('en getlogincountServices');
    // let from = '2020-06-25';
    // let to = '2020-06-27';
    let res = this.http.get<UserGrowthModel[]>(
      `${this.baseUrl}/api/metrics/user_range/${from}/${to}`,
      { observe: "response" });
    console.log('RES LOGIN COUNT SERVICES: '+res);
    return res;
  }

  getUserRange(from, to): Observable<HttpResponse<any>> {
    // console.log('en getUserRange');
    let resUserRange = this.http.get<UserGrowthModel[]>(
      `${this.baseUrl}/api/metrics/user_range/${from}/${to}`,
      { observe: "response" });
    // console.log('resUserRange: ' + resUserRange);
    return resUserRange;
  }

  getUserLogs(from, to): Observable<HttpResponse<any>> {
    // console.log('en getUserLogs');
    let resUserLogs = this.http.get<UserLogModel[]>(
      `${this.baseUrl}/api/metrics/user_logs/${from}/${to}`,
      { observe: "response" });
    // console.log('resUserLogs de Services: ' + resUserLogs);
    return resUserLogs;
  }
//
getUserLogsdate(from, to): Observable<HttpResponse<any>> {
  // console.log('en getUserLogs');
  let resUserLogs = this.http.get<UserLogModel[]>(
    `${this.baseUrl}/api/metrics/user_logsdate/${from}/${to}`,
    { observe: "response" });
  // console.log('resUserLogs de Services: ' + resUserLogs);
  return resUserLogs;
}
  getUserLogsByType(from, to, id): Observable<HttpResponse<any>> {
    // console.log('en getUserLogsByType of service');
    // /user_logs_id/
    let resUserLogs = this.http.get<UserLogModel[]>(
      `${this.baseUrl}/api/metrics/user_logs_id/${from}/${to}/${id}`,
      { observe: "response" });
    // console.log('resUserLogs de Services: ' + resUserLogs);
    return resUserLogs;
  }

}
