import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  baseUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) { }

  uploadPrvFile(body) {
    return this.http.post<any>(`${this.baseUrl}/api/import/prv`, body);
  }

  uploadPdfFile(body) {
  	return this.http.post<any>(`${this.baseUrl}/api/import/import_payments/pdf`, body);
  }

}
