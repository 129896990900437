import { Component, OnInit,Renderer2 } from '@angular/core';
import { CustomerService, PaymentsService, StatusService } 	from '../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import * as globals from '../globals.json';
import * as moment from "moment";
import { Chart } from 'chart.js';

@Component({
  selector: 'app-view-download-log',
  templateUrl: './view-download-log.component.html',
  styleUrls: ['./view-download-log.component.css']
})
export class ViewDownloadLogComponent implements OnInit {

  user_growth;
  users_count = []; 
  months_datas = [];  


  total_web: number;
  total_terminal: number;
  total_fees: number;
  total_pyReport: number;  
  logs;
  from_date;
  to_date;

  loading:boolean = false;
  totalPages: Number;

  filters : any = { 
    from_date: String,
    to_date: String, 
  	search : '',
    limit: 10,
    page: 1
  };


  constructor(
    private Service: PaymentsService,
    private renderer: Renderer2
    ) { }

  ngOnInit() {
    this.loading = true;
    this.getTotalDownloadLogs();
    this.filters.from_date ='';
    this.filters.to_date ='';
    this.getAllLogs(this.filters);   
  } 

// Password Reset Counts by Email Type
getTotalDownloadLogs() {
  this.Service.getTotalDownloadsLogs().subscribe((res) => {
///console.log(res.web)
   this.total_web=res.web
   this.total_terminal=res.terminal
   this.total_fees=res.fees
   this.total_pyReport=res.py_report
  });
}

getAllLogs(query : any){
  this.logs=[];
  this.Service.getAllDownloadsLogs(query)  
    .subscribe(response => {     
      this.loading = false;
      if(response){
        console.log("logs: ", response)
        this.logs = response;
        if(this.logs.length > 0 && response[0].count){
          this.totalPages = response[0].count;
        }else{
          this.totalPages = 0;
        }        
        
        this.filters.from_date ='';
        this.filters.to_date ='';
        this.filters.search='';
      }
    }, error => {
      console.log("Error in all payments admin: ", error);
    })
}
 
getThisWeeks() {
  this.filters.from_date = moment().startOf('week').format('YYYY-MM-DD');
  this.filters.to_date = moment().endOf('week').format('YYYY-MM-DD');

   console.log('from='+this.filters.from_date)
   console.log('to='+this.filters.to_date)
  var this_week = this.getAllLogs(this.filters);
  
}//Quarter
getThisMonths() {
  this.filters.from_date = moment().startOf('month').format('YYYY-MM-DD');
  this.filters.to_date = moment().endOf('month').format('YYYY-MM-DD');

   console.log('from='+this.filters.from_date)
   console.log('to='+this.filters.to_date)
  var this_week = this.getAllLogs(this.filters);
  
}
getThisQuarter() {
  this.filters.from_date = moment().startOf('quarter').format('YYYY-MM-DD');
  this.filters.to_date = moment().endOf('quarter').format('YYYY-MM-DD');

   console.log('from='+this.filters.from_date)
   console.log('to='+this.filters.to_date)
  var this_week = this.getAllLogs(this.filters);
  
}
submitFilters(filters){
  this.loading = true;
  this.getAllLogs(filters);
}

setSearchFilter(value){
  this.filters.search = value.length ? value : '';
}

search(){
  this.submitFilters(this.filters)
}
pageChanged(event: any){
  this.filters.page  = event.page;
  this.search();
}

closeSidebar(){
window.scroll(0,60);
  this.renderer.removeClass(document.body, 'mini-navbar')
}


}
