import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { User, Address } from "../../_models/";
import {
  CustomerService,
  AddressService,
  CryptoService,
} from "../../_services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SwalComponent } from "@toverux/ngx-sweetalert2";

declare var $: any;

@Component({
  selector: "app-link-additional-account",
  templateUrl: "./link-additional-account.component.html",
  styleUrls: ["./link-additional-account.component.css"],
})
export class LinkAdditionalAccountComponent implements OnInit {
  @ViewChild("successfulAccountLink")
  public successfulAccountLink: SwalComponent;
  @ViewChild("areYouSure") public confirmAccountLink: SwalComponent;
  @Output() refreshParentData = new EventEmitter();

  public user: User;

  public signupFormGroup: FormGroup;

  public filteredAddresses: Address[] = [];
  public selectedAddressID: String;

  public helpers: any = {
    showAccountNumber: Boolean(false),
    showPassword: Boolean(false),
    showBillAmount: Boolean(false),
  };

  public error: any = {
    isJoi: Boolean(false),
    errorMessage: String(""),
  };

  public accountNumberPlaceholder = "Account Number (include dashes)";
  public serviceAddressPlaceholder = "Service Address";
  public emailPlaceholder = "Email";
  public currentBillPlaceholder = "Most Recent Bill Statement amount";
  public titlealertSwalAreYouSure = "Are you sure this account belongs to you?";

  constructor(
    private fb: FormBuilder,
    private AS: AddressService,
    private CS: CustomerService,
    private BsModalRef: BsModalRef,
    private cryptoService: CryptoService
  ) {
    this.user = JSON.parse(window.localStorage.current_user);
    console.log("user:", this.user);

    this.signupFormGroup = this.fb.group({
      account_number: new FormControl("", [Validators.required]),
      email: new FormControl({ value: this.user.email, disabled: true }, [
        Validators.required,
        Validators.email,
      ]),
      //current_bill_amount: new FormControl('',[ Validators.required ])
    });
  }

  ngOnInit() {
    console.log("USER : ", this.user);
    $(".touchspin2").TouchSpin({
      min: 0,
      booster: true,
      step: 0.01,
      decimals: 2,
      boostat: 5,
      maxboostedstep: 10,
      prefix: "$",
      buttondown_class: "btn btn-white",
      buttonup_class: "btn btn-white",
    });

    if (window.location.href.includes("/es/")) {
      this.accountNumberPlaceholder =
        "Número de Cuenta(incluyendo los guiones)";
      this.serviceAddressPlaceholder = "Dirección de Servicio";
      this.emailPlaceholder = "Correo";
      this.currentBillPlaceholder = "Monto de la factura más reciente";
      this.titlealertSwalAreYouSure = "¿Esta seguro que esta es su cuenta?";
    }
  }

  submitLinkExistingAccount() {
    this.error.errorMessage = "";
    const data = {
      account_number: this.accountNumber,
      service_address_id: this.selectedAddressID
        ? parseInt(this.selectedAddressID.toString())
        : "",
      //current_bill_amount: parseFloat(this.currentBillAmount),
      user_id: this.user.id,
      email: this.user.email,
    };

    console.log("DATA SENT: ", data);
    this.CS.linkAdditionalAccount(data).subscribe(
      (response) => {
        console.log("Response: ", response);
        this.successfulAccountLink.show();
        // REFRESH PARENT COMPONENT
        // ADD LOADING ICON WHEN SUBMITTED
        // figure out regex for numbers and dashes
      },
      (error) => {
        console.log("Error linking account: ", error);
        if (
          error.message.isJoi &&
          error.message.details[0].message
            .toLowerCase()
            .includes("account number") &&
          error.message.details[0].message.toLowerCase().includes("pattern")
        ) {
          this.error.errorMessage =
            "Account number can only have numbers and dashes";
          if (window.location.href.includes("/es/")) {
            this.error.errorMessage =
              "Número de cuenta puede solo tener números y guiones";
          }
        } else if (error.message.isJoi) {
          this.error.isJoi = error.message.isJoi;
          this.error.errorMessage = error.message.details[0].message;
        } else {
          this.error.errorMessage = error.message;
        }
      }
    );
  }

  getFilteredAddress(term: any) {
    this.AS.getFilteredAddress(term.term).subscribe(
      (response) => {
        this.filteredAddresses = response.body;
      },
      (error) => {
        console.log("Error getting filtered address: ", error);
      }
    );
  }

  hideModal() {
    this.BsModalRef.hide();
  }

  refreshParentInit() {
    this.hideModal();
    this.refreshParentData.emit(true);
  }

  get accountNumber() {
    return this.signupFormGroup.get("account_number").value;
  }
}
