import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA } from "@angular/material";
import { AuditTrailService } from "src/app/_services/audit-trail.service";

interface TimelineItem {
  userId: string;
  userName: string;
  dateTime: string;
  action: string;
  IP: string;
  GeoLocation: string;
  Browser: string;
}

@Component({
  selector: "app-view-adit-trail",
  templateUrl: "./view-adit-trail.component.html",
  styleUrls: ["./view-adit-trail.component.css"],
})
export class ViewAditTrailComponent implements OnInit {
  timelineData: TimelineItem[] = [];

  constructor(
    private router: Router,
    private auditTrailService: AuditTrailService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.auditTrailService
      .getAuditTrailOneUser(this.data.user_id)
      .subscribe((response) => {
        this.timelineData = response.body;
        console.log(response);
      });
  }
}
