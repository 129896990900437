import { Component, OnInit, Inject, Renderer2, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CryptoService, TrafficService } from "../_services";
import * as globals from "../globals.json";
import { DateRangePickerComponent } from "ng-pick-daterange";
import * as moment from "moment";
import { User, Traffic, Status } from "../_models";

@Component({
  selector: "app-traffic-payments",
  templateUrl: "./traffic-payments.component.html",
  styleUrls: ["./traffic-payments.component.css"],
})
export class TrafficPaymentsComponent implements OnInit {
  public globals: any = globals.default;

  @ViewChild("datePicker") private datePicker: DateRangePickerComponent;
  @ViewChild("datePicker") dateRangeDOM;
  user: User;
  traffic: Traffic[] = [];
  status: Status[] = [];
  totalPages: Number;
  filters: any = {
    status_id: Number,
    from_date: String,
    to_date: String,
    search: "",
    limit: 10,
    page: 1,
  };
  public searchBoxPlaceHolder = "Search";
  // desdocumentar el sigueintepor si desean mirar la factura desde la tabla
  //  public receiptButton = "Receipt";
  loading: boolean = false;
  constructor(
    private TR: TrafficService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private cryptoService: CryptoService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.user = JSON.parse(window.localStorage.current_user);
    if (this.route.snapshot.params.userSearch) {
      this.filters.search = this.route.snapshot.params.userSearch;
    }
    this.filters.status_id = Number(0);
    let domRange = this.dateRangeDOM.elementRef.nativeElement;

    if (window.location.href.includes("/es/")) {
      this.searchBoxPlaceHolder = "Buscar";
      // desdocumentar el sigueintepor si desean mirar la factura desde la tabla
      //this.receiptButton= "Recibo";
      domRange.getElementsByClassName("yk-label")[0].innerText = "Inicio";
      domRange.getElementsByClassName("yk-label")[1].innerText = "Fin";
      domRange.getElementsByClassName("yk-btn")[0].innerText = "Este Mes";
      domRange.getElementsByClassName("yk-btn")[1].innerText = "Ultimo Mes";
      domRange.getElementsByClassName("yk-btn")[2].innerText = "Esta Semana";
      domRange.getElementsByClassName("yk-btn")[3].innerText = "Ultima Semana";
      domRange.getElementsByClassName("yk-btn")[4].innerText = "Este Año";
      domRange.getElementsByClassName("yk-btn")[5].innerText = "Ultimo Año";
    }
  }

  ngAfterViewInit() {
    this.datePicker.toggleCalendar("from");
    this.datePicker.selectDate(
      moment().subtract(3, "month").startOf("month").toDate()
    );
    this.datePicker.toggleCalendar("from");

    let domRange = this.dateRangeDOM.elementRef.nativeElement;
    if (window.location.href.includes("/es/")) {
      domRange.getElementsByClassName("yk-weekday")[0].innerText = "Dom";
      domRange.getElementsByClassName("yk-weekday")[1].innerText = "Lun";
      domRange.getElementsByClassName("yk-weekday")[2].innerText = "Mar";
      domRange.getElementsByClassName("yk-weekday")[3].innerText = "Mie";
      domRange.getElementsByClassName("yk-weekday")[4].innerText = "Jue";
      domRange.getElementsByClassName("yk-weekday")[5].innerText = "Vie";
      domRange.getElementsByClassName("yk-weekday")[6].innerText = "Sab";
    }
  }

  ngAfterContentInit() {
    this.filters.from_date = moment()
      .subtract(3, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    this.filters.to_date = moment().endOf("month").format("YYYY-MM-DD");
    if (this.user.user_type_id === 1) {
      this.getAllPayments(this.filters);
    }
  }

  submitFilters(filters) {
    this.loading = true;
    this.filters.from_date = moment(filters.from_date).format("YYYY-MM-DD");
    this.filters.to_date = moment(filters.to_date).format("YYYY-MM-DD");

    if (this.user.user_type_id == 1) {
      this.getAllPayments(filters);
    }
  }

  getAllPayments(query: any) {
    this.traffic = [];
    this.TR.getAllStandaloneterminal(query).subscribe(
      (response) => {
        this.loading = false;
        if (response.body[0]) {
          console.log("PAYEMNTS: ", response.body);
          this.traffic = response.body;
          console.log(this.traffic);
          this.totalPages = response.body[0].count;
        }
      },
      (error) => {
        console.log("Error in all payments admin: ", error);
      }
    );
    this.filters.search = "";
  }
  setSearchFilter(value) {
    this.filters.search = value.length ? value : "";
  }
  search() {
    this.submitFilters(this.filters);
  }
  setDateRange(range) {
    this.filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.filters.to_date = moment(range.to).format("YYYY-MM-DD");
  }
  pageChanged(event: any) {
    this.filters.page = event.page;
    this.search();
  }
  closeSidebar() {
    window.scroll(0, 60);
    this.renderer.removeClass(document.body, "mini-navbar");
  }
}
