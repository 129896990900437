import { Component, OnInit } from '@angular/core';
import { UserGrowthService } from '../../_services';
import { UserGrowthModel } from '../../_models';
import { forEach } from '@angular/router/src/utils/collection';
import { map} from 'rxjs/operators';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-user-growth',
  templateUrl: './user-growth.component.html',
  styleUrls: ['./user-growth.component.css']
})
export class UserGrowthComponent implements OnInit {
  // UGS: UserGrowthService;
  user_growth: UserGrowthModel[] = [];
  users_count = []; 
  months_datas = [];
  loading = true;
  constructor(public UGService: UserGrowthService) {

   }

  ngOnInit() {    
    this.getAllDatas();
    
    
    
  }
  getAllDatas() {    
    this.user_growth = [];
    this.UGService.getAllUG()    
    .subscribe( res => {
      // console.log('Respuesta = ' +  JSON.stringify(res.body) );
      var i  = 0;
      let month_name;
      while(res.body[i]){        
        // this.months_datas.push(res.body[i].date_part);
        switch(res.body[i].date_part) {
          case 1:
            this.months_datas.push('January');
            break;
          case 2:
            this.months_datas.push('February');
            break;
          case 3:
            this.months_datas.push('March');
            break;
          case 4:
            this.months_datas.push('April');
            break;
          case 5:
            this.months_datas.push('May');
            break;
          case 6:
            this.months_datas.push('June');
            break;          
          case 7:
            this.months_datas.push('July');
            break;
          case 8:
            this.months_datas.push('August');
            break;
          case 9:
            this.months_datas.push('September');
            break;
          case 10:
            this.months_datas.push('October');
            break;
          case 11:
            this.months_datas.push('November');
            break;
          case 12:
            this.months_datas.push('December');
            break;
        }
        if(res.body[i].count)
          this.users_count.push(res.body[i].count);
        else
          this.users_count.push(0);
        // console.log("Months Data=" + this.months_datas);
        // console.log("Users count=" + this.users_count);
        i++;
      }      
      let all_datas = JSON.stringify(res.body); // se convierte en cadena
      // console.log('date_part = ' + all_datas);
  
        
      setTimeout(() => {
        this.graphicsUG();
      }, 2000);
        
      }, error => {
        console.log('Error getting users growth: ', error);
      }, () => {
        // console.log('finished getting users growth');
      }
    );
  }
  graphicsUG() {
    // LINE
    this.loading = false;
    var lineData = {
      labels: this.months_datas, 
      datasets: [
        {
          label: "New User Accounts / Month",
          backgroundColor: "rgba(26,179,148,0.5)",
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff",
          data: this.users_count, 
        },
      ],
    };
    var lineOptions = {
      responsive: true,
    };

    // document.getElementById("lineChart").innerHTML = '&nbsp;';
    // document.getElementById("lineChart").innerHTML = '<canvas id="lineChart"  height="100"></canvas>';
    var ctx = document.getElementById("lineChart"); // .getContext("2d");;
   
    var myNewChart =  new Chart(ctx, { type: "line", data: lineData, options: lineOptions });
  }
}
