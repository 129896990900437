import { Component, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { CryptoService, ReversalsService } from "../_services";
import { DateRangePickerComponent } from "ng-pick-daterange";
import { environment } from "../../environments/environment";
import { FormGroup } from "@angular/forms";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { saveAs } from "file-saver";

@Component({
  selector: "app-reversals",
  templateUrl: "./reversals.component.html",
  styleUrls: ["./reversals.component.css"],
})
export class ReversalsComponent implements OnInit {
  @ViewChild("datePicker") private datePicker: DateRangePickerComponent;
  @ViewChild("datePicker") dateRangeDOM;
  @ViewChild("errorAlert") private errorAlertSwal: SwalComponent;
  @ViewChild("successAlert") private successAlertSwal: SwalComponent;
  @ViewChild("questionAlert") private questionAlertSwal: SwalComponent;
  @ViewChild("success") private successSwal: SwalComponent;

  log_filters: any = {
    from_date: String,
    to_date: String,
  };

  public getData: any = [];
  public loading: boolean = false;
  public fbEdit: FormGroup;
  public id: number;
  public domRange: any;

  public init: boolean = true;

  public user: any = JSON.parse(window.localStorage.current_user);

  public html = "";

  constructor(
    private _reversals: ReversalsService,
    private cryptoService: CryptoService
  ) {}

  ngOnInit() {
    this.domRange = this.dateRangeDOM.elementRef.nativeElement;

    this.log_filters.from_date = moment()
      .subtract(3, "month")
      .format("YYYY-MM-DD");
    this.log_filters.to_date = moment().endOf("month").format("YYYY-MM-DD");

    this.getReversals();

    if (window.location.href.includes("/es/")) {
      // desdocumentar el sigueintepor si desean mirar la factura desde la tabla
      //this.receiptButton= "Recibo";
      this.domRange.getElementsByClassName("yk-label")[0].innerText = "Inicio";
      this.domRange.getElementsByClassName("yk-label")[1].innerText = "Fin";
      this.domRange.getElementsByClassName("yk-btn")[0].innerText = "Este Mes";
      this.domRange.getElementsByClassName("yk-btn")[1].innerText =
        "Ultimo Mes";
      this.domRange.getElementsByClassName("yk-btn")[2].innerText =
        "Esta Semana";
      this.domRange.getElementsByClassName("yk-btn")[3].innerText =
        "Ultima Semana";
      this.domRange.getElementsByClassName("yk-btn")[4].innerText = "Este Año";
      this.domRange.getElementsByClassName("yk-btn")[5].innerText =
        "Ultimo Año";
    }
  }

  ngAfterViewInit() {
    // this.filters.from_date = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD');
    // this.filters.to_date = moment().endOf('month').format('YYYY-MM-DD');

    this.domRange = this.dateRangeDOM.elementRef.nativeElement;

    this.datePicker.toggleCalendar("from");
    this.datePicker.selectDate(moment().subtract(3, "month").toDate());
    this.datePicker.toggleCalendar("from");

    this.datePicker.selectDate(moment().endOf("month").toDate());

    if (window.location.href.includes("/es/")) {
      this.domRange.getElementsByClassName("yk-weekday")[0].innerText = "Dom";
      this.domRange.getElementsByClassName("yk-weekday")[1].innerText = "Lun";
      this.domRange.getElementsByClassName("yk-weekday")[2].innerText = "Mar";
      this.domRange.getElementsByClassName("yk-weekday")[3].innerText = "Mie";
      this.domRange.getElementsByClassName("yk-weekday")[4].innerText = "Jue";
      this.domRange.getElementsByClassName("yk-weekday")[5].innerText = "Vie";
      this.domRange.getElementsByClassName("yk-weekday")[6].innerText = "Sab";
    }
  }

  setDateRangeCounter(range) {
    // console.log('setting date ranges: ', range);
    this.log_filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.log_filters.to_date = moment(range.to).format("YYYY-MM-DD");

    //console.log(this.datePicker);
    //console.log('initial dates', this.datePicker.datePick.from, this.datePicker.datePick.to  );
  }

  getReversals() {
    let domRange = this.dateRangeDOM.elementRef.nativeElement;
    if (this.init) {
      this.init = false;
    } else {
      this.log_filters.from_date = moment(
        domRange.getElementsByClassName("yk-content")[0].innerText,
        "MMM D, YYYY"
      ).format("YYYY-MM-DD");
      this.log_filters.to_date = moment(
        domRange.getElementsByClassName("yk-content")[1].innerText,
        "MMM D, YYYY"
      ).format("YYYY-MM-DD");
    }

    this._reversals
      .getReversalsByDate(this.log_filters.from_date, this.log_filters.to_date)
      .subscribe((res) => {
        console.log(res);
        this.getData = res;
      });
  }

  openQuestion(id, i) {
    this.id = id;

    this.html = `
      <li>Name Customer: ${this.getData[i].first_name}</li>
      <li>Account Number: ${this.getData[i].incode_account_no}</li>
      <li>Confirmation ID: ${this.getData[i].confirmation_id}</li>
      <li>Amount: $${this.getData[i].amount}</li>
      <li>Type: ${
        this.getData[i].status_payment_id == 1 ? "Voided" : "Return"
      }</li>
      <li>Date: ${
        moment(this.getData[i].date_created).format("MMMM d, y") || "N/A"
      }</li>
      
    `;
    this.questionAlertSwal.show();
    document.getElementById("1").innerText =
      "Name Customer: " + this.getData[i].first_name;
    document.getElementById("2").innerText =
      "Account Number: " + this.getData[i].incode_account_no;
    document.getElementById("3").innerText =
      "Confirmation ID: " + this.getData[i].confirmation_id;
    document.getElementById("4").innerText =
      "Amount: $" + this.getData[i].amount;
    document.getElementById("5").innerText =
      this.getData[i].status_payment_id == 1 ? "Type: Voided" : "Type: Return";
  }

  sendUpdate() {
    this._reversals.updateReversalById(this.id, this.user.id).subscribe(
      (res) => {
        this.successAlertSwal.title = "Success";
        this.successAlertSwal.text = "Update your information successfully";
        this.successAlertSwal.show();

        this.getReversals();
        this._reversals.sendCount();
      },
      (error) => {
        this.errorAlertSwal.text = "Something went wrong, please try again.";
        this.errorAlertSwal.show();
      }
    );
  }

  downloadReport() {
    this._reversals
      .downloadReport(this.log_filters.from_date, this.log_filters.to_date)
      .subscribe(
        (res) => {
          let filename = `${moment(this.log_filters.from_date).format(
            "MMMM DD YYYY, h:mm:ss a"
          )} - ${moment(this.log_filters.to_date).format(
            "MMMM Do YYYY, h:mm:ss a"
          )}  Reversals Payment.xlsx`;
          saveAs(res, filename);
          this.successSwal.show();
        },
        (err) => {
          this.errorAlertSwal.text = "Something went wrong, please try again.";
          this.errorAlertSwal.show();
        }
      );
  }
}
