import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-request-ra',
  templateUrl: './request-ra.component.html',
  styleUrls: ['./request-ra.component.css']
})

export class RequestRAComponent implements OnInit {

  public imageUrl:any


  constructor(public bsModalRef: BsModalRef, private _sanitizer: DomSanitizer) { }

  ngOnInit() {
  }


  setRequestInfo(imageUrl){
    this.imageUrl =imageUrl;
  }
}
