import { Component, OnInit } from "@angular/core";
import { RestorationPasswordService } from "../../_services";
import { RestorePasswordModel } from "../../_models";
import { Chart } from "chart.js"; // ,  ChartOptions, ChartType, ChartDataSets
// import { MultiDataSet, Label } from 'ng2-charts';

// import { Label } from 'ng2-charts';
import * as moment from "moment";
import { LoginCountService } from '../../_services/login-count.service';

@Component({
  selector: "app-reset-email-type-time",
  templateUrl: "./reset-email-type-time.component.html",
  styleUrls: ["./reset-email-type-time.component.css"],
})
export class ResetEmailTypeTimeComponent implements OnInit {
  log_filters: any = {
    from_date: String,
    to_date: String,
  };
  email_type = [];
  email_count = [];
  result_user = [];
  result_types = [];
  from_last_months = [];
  to_last_months = [];
  data_months = [];
  total_month = [];
  total_users_range = 0;
  total_users = [];
  total_users_log_range = 0;
  total_users_log = [];

  constructor(private restorationS: RestorationPasswordService, 
    private LCS: LoginCountService) {}

  ngOnInit() {
    this.log_filters.from_date = moment().startOf("month").format("YYYY-MM-DD");
    this.log_filters.to_date = moment().endOf("month").format("YYYY-MM-DD");

    this.getDatasTimeRange();
    // this.graphicUserByEmailTypeRangeTime();
  
  }

  getDatasTimeRange() {
    var from_date = this.log_filters.from_date;
    var to_date = this.log_filters.to_date;
    // console.log("from date" + from_date);
    // console.log("to date" + to_date);

    var aux = 5;
    var i;
    while (aux >= 0) {
      // console.log('aux = ' + aux);
      this.from_last_months[aux] = moment(this.log_filters.from_date)
        .subtract(aux, "months")
        .format("YYYY-MM-DD");
      // console.log("from last months = " + this.from_last_months[aux]);
      this.to_last_months[aux] = moment(this.log_filters.to_date)
        .subtract(aux, "months")
        .format("YYYY-MM-DD");
      // console.log("to last months = " + this.to_last_months[aux]);

      // console.log('mes =' + moment(this.from_last_months[aux]).format("MMMM"));
      this.data_months.push(moment(this.from_last_months[aux]).format("MMMM")); // moment(this.from_last_months[aux]).format("MMMM"));
      // console.log("month datas = " + this.data_months[i]);
      aux--;
    }

    var aux2 = 5;
    var id = 0;
    while (aux2 >= 0) {
      this.getUserResetByEmailTypeTimeRange(this.from_last_months[aux2], this.to_last_months[aux2], id);      
      // console.log('en '+aux2+' from ' + this.from_last_months[aux2]);
      // console.log('en '+aux2+' to ' + this.from_last_months[aux2]);
      id++;
      aux2--;
    }
    // console.log('month = ' + this.data_months);
    setTimeout(() => {
      this.graphicUserByEmailTypeRangeTime();
    }, 2000);
  }

  getUserResetByEmailTypeTimeRange(from_date: String, to_date: String, id) {
    this.email_type = [];
    this.email_count = [];
    // console.log('Desde ' + from_date);
    this.restorationS
      .getUserResetByEmailTime(from_date, to_date)
      .subscribe((res) => {
        // console.log("Reset by email type time month range with id " + id + ' =  ' + JSON.stringify(res.body));
        
        // var result_user = parseInt(res.body[0].total);
        // console.log("TOTAL= " + result_user);        
        // this.total_month[id] = result_user;
        var i = 0;

        while (res.body[i]) {
                    
          if (res.body[i].domain && res.body[i].count) {
            /*
            console.log(
              "res.body en domain month with id "+ id + ' ' + i + " = " + res.body[i].domain
            );
            console.log(
              "res.body en count month " + i + " = " + res.body[i].count
            );
            */
            this.email_type.push(res.body[i].domain);
            this.email_count.push(res.body[i].count);
          } else {
            //console.log("TOTAL = " + res.body[i].total);
            this.total_month[id] =  res.body[i].total;
          }
          i++;
        }
        
        this.result_types[id] = this.email_type;
        this.result_user[id] = this.email_count;
      });


      // console.log('llamado a getuserrange');
      this.getUserRange(from_date, to_date, id);
      this.getUserLogTimeRange(from_date, to_date, id);
  }


// Quantity of Logins throughout a time period
getUserRange(from_date: String, to_date: String, id) {  
  this.total_users_range = 0;
  // console.log('entra en getUserRange ')
  this.LCS.getUserRange(
    from_date,
    to_date
  ).subscribe((resUserRange) => {
    if (resUserRange.body[0]) {
      let i = 0;
      while (resUserRange.body[i]) {
        // console.log('valor de  i =' + i);
        i++;
      }
      this.total_users_range = i; // resUserRange.body[0].total_counts;
      // console.log('Total users range = ' + this.total_users_range);
      // this.res_user_range = true;
      this.total_users[id] = this.total_users_range;
    }
  });
}

// Usuarios logueados en un rango de tiempo
getUserLogTimeRange(from_date: String, to_date: String, id) {
  this.total_users_range = 0;
  // console.log('entra en getUserLog ');
  this.LCS.getUserLogs(
    from_date,
    to_date
  ).subscribe((res) => {
    // console.log("Respuesta user log time range = " + JSON.stringify(res.body));
    if (res.body[0]) {
      let i = 0;
      while (res.body[i]) {
        // console.log('valor de  i =' + i);
        i++;
      }
      // console.log('Respuesta user log time range ' + from_date + ' = ' + i);      
      this.total_users_log_range = i;      
      this.total_users_log[id] = this.total_users_log_range; 
    }
  });
}


  graphicUserByEmailTypeRangeTime() {
    // BAR
    // console.log('Datos de los month datas en grafica = ' + this.data_months);
    // console.log('Resultados total month = ' + this.total_users);

    var barData = {
      labels: this.data_months,
      datasets: [
        {
          label: "Reset Users",
          backgroundColor: "rgb(255, 0, 0)",
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff",
          data: this.total_month,
        },
        {
          label: "Login Users",
          backgroundColor: "rgb(0, 0, 255)",
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff",
          data: this.total_users_log,
        },
        {
          label: "Total Users",
          backgroundColor: "#3e95cd",
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff",
          data: this.total_users,
        },
      ],
    };
    var barOptions = {
      responsive: true,
    };

    var ctx = document.getElementById("barChart"); // .getContext("2d");;

    var myNewChart = new Chart(ctx, {
      type: "bar",
      data: barData,
      options: barOptions,
    });
  }

}
