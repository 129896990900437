import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { UserGrowthModel } from "../_models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserGrowthService {
  baseUrl: string = environment.serverUrl;
  constructor(private http: HttpClient) {}

  getAllUG(): Observable<HttpResponse<any>> {
    return this.http.get<UserGrowthModel[]>(
      `${this.baseUrl}/api/metrics/user_growth`,
      { observe: "response" }
    );
  }
}
