import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ErrorCodeModel } from "../_models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ErrorMetricService {

  baseUrl: string = environment.serverUrl;

  configUrl = '../assets/countdataerror.json';
  constructor(private http: HttpClient) {
  }

  getErrorCodeBreakDown(): Observable<HttpResponse<any>> {
    return this.http.get<ErrorCodeModel[]>(
      `${this.baseUrl}/api/metrics/user_getErrorPay`,
      { observe: "response" }
    );
  }

  getErrorCodeBreakDownDates(from, to): Observable<HttpResponse<any>> {
    return this.http.get<ErrorCodeModel[]>(
      `${this.baseUrl}/api/metrics/user_getErrorPayDates/${from}/${to}`,
      { observe: "response" }
    );
  }

  getJsonData(){
    return this.http.get(this.configUrl);
  }


  getErrorDayAndType():Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/api/metrics/user_getErrorPerDayAndType`,
      { observe: "response" }
    );
  }

  getErrorDayAndTypeDates(from, to):Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/api/metrics/user_getErrorDayAndTypeDates/${from}/${to}`,
      { observe: "response" }
    );
  }

  getTotalPaymentsAndErrors(from, to): Observable<HttpResponse<any>> {    
    return  this.http.get<any>(
      `${this.baseUrl}/api/metrics/user_getTotalPaysAndErrorsInRangeDate/${from}/${to}`,
      { observe: "response" });        
  }


}

