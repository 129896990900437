import { Component, OnInit, ViewChild } from '@angular/core';
import { CountPerDaysService } from '../../_services';
import * as moment from "moment";
import { Chart } from "chart.js";
import {DateRangePickerComponent} from "ng-pick-daterange";

@Component({
  selector: 'app-counters-pays',
  templateUrl: './counters-pays.component.html',
  styleUrls: ['./counters-pays.component.css']
})
export class CountersPaysComponent implements OnInit {

  @ViewChild('datePicker') private datePicker: DateRangePickerComponent;
  @ViewChild('datePicker') dateRangeDOM;

  log_filters: any = {
    from_date: String,
    to_date: String,
  };

  days = [];
  _counter_pays = [];
  _counter_ipays = [];
  counters_login = [];
  _system_pays = []; 
  _counter_login = [];
  _counter_terminal = [];
  _counter_visa = [];
  _counter_master = [];
  _counter_amex = [];
  _counter_discover = [];
  _counter_other = [];

  counter_terminal = 0;
  counter_visa = 0;
  counter_master = 0;
  counter_amex = 0;
  counter_discover = 0;
  counter_other = 0;

  counter_system = 0;

  up1 : boolean = false;
  up2 : boolean = false;

  counter_login : number = 0;
  counter_pays : number = 0;
  counter_ipays : number = 0;

  constructor(private _counters : CountPerDaysService) { }

  ngOnInit() {
    //this.getCountsPerDays();

    let domRange = this.dateRangeDOM.elementRef.nativeElement;

    this.log_filters.from_date = moment().startOf("week").format("YYYY-MM-DD");
    this.log_filters.to_date = moment().endOf("week").format("YYYY-MM-DD");

    if(window.location.href.includes("/es/")){
      domRange.getElementsByClassName("yk-label")[0].innerText = "Inicio";
      domRange.getElementsByClassName("yk-label")[1].innerText = "Fin";
      domRange.getElementsByClassName("yk-btn")[0].innerText = "Este Mes";
      domRange.getElementsByClassName("yk-btn")[1].innerText = "Ultimo Mes";
      domRange.getElementsByClassName("yk-btn")[2].innerText = "Esta Semana";
      domRange.getElementsByClassName("yk-btn")[3].innerText = "Ultima Semana";
      domRange.getElementsByClassName("yk-btn")[4].innerText = "Este Año";
      domRange.getElementsByClassName("yk-btn")[5].innerText = "Ultimo Año";
    }

  }

  ngAfterViewInit() {
		// this.filters.from_date = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD');
		// this.filters.to_date = moment().endOf('month').format('YYYY-MM-DD');
    
    let domRange = this.dateRangeDOM.elementRef.nativeElement;
    if(window.location.href.includes("/es/")){
      domRange.getElementsByClassName("yk-weekday")[0].innerText = "Dom";
      domRange.getElementsByClassName("yk-weekday")[1].innerText = "Lun";
      domRange.getElementsByClassName("yk-weekday")[2].innerText = "Mar";
      domRange.getElementsByClassName("yk-weekday")[3].innerText = "Mie";
      domRange.getElementsByClassName("yk-weekday")[4].innerText = "Jue";
      domRange.getElementsByClassName("yk-weekday")[5].innerText = "Vie";
      domRange.getElementsByClassName("yk-weekday")[6].innerText = "Sab";

    }
  }

  setDateRangeCounter(range) {
    // console.log('setting date ranges: ', range);
    this.log_filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.log_filters.to_date = moment(range.to).format("YYYY-MM-DD");

    //console.log(this.datePicker);
    //console.log('initial dates', this.datePicker.datePick.from, this.datePicker.datePick.to  );
  }

  filterDate(date){
    return this.counters_login.filter(
      function(counters_login) {
        return counters_login.fecha == date
      }
    );
  }

  sortDate(a, b){
    return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
  }

  getCountsPerDays(){
    this.log_filters.from_date = moment(this.datePicker.datePick.from).format("YYYY-MM-DD");
    this.log_filters.to_date = moment(this.datePicker.datePick.to).format("YYYY-MM-DD");

    this.up1 = false;
    this.up2 = false;

    this.days = [];
    this._counter_pays = [];
    this._counter_ipays = [];
    this.counters_login = [];
    this._system_pays = [];   
    this._counter_login = [];
    this._counter_terminal = [];
    this._counter_visa = [];
    this._counter_master = [];
    this._counter_amex = [];
    this._counter_discover = [];
    this._counter_other = [];

    this.counter_login = 0;
    this.counter_pays = 0;
    this.counter_ipays=0;

    this.counter_terminal = 0;
    this.counter_visa = 0;
    this.counter_master = 0;
    this.counter_amex = 0;
    this.counter_discover = 0;
    this.counter_other = 0;
    this.counter_system = 0;

    this.setDateRangeCounter({
      from: this.log_filters.from_date,
      to: this.log_filters.to_date,
    });
    
    //console.log("ddddd" + this.log_filters.from_date);
    
    this._counters.getCountLoginPerDays(this.log_filters.from_date, this.log_filters.to_date)
      .subscribe(res => {

        //console.log(res);
        /**
         *  el filtro se saca primero de las fechas que tiene los logs, por que 
         * logica se sabe que si o si deben entrar a la plataforma
         * y luego se comparan las fechas de uno y otro
         * 
         * Primero son los JSON de logins y luego los de payments
        */
        let counters_payments = [];
        let i = 0;
        res.body.forEach(element => {
          //let obj = { total_pays : 0 };
          if(element.total_pays){
            //console.log("pays" +element.total_pays);
            counters_payments.push(res.body[i]);
            //return 0;
          }else{
            res.body[i].total_pays = 0;
            res.body[i].DISC = 0;
            res.body[i].AMEX = 0;
            res.body[i].TERMINAL = 0;
            res.body[i].MSTR = 0;
            res.body[i].VISA = 0;
            //res.body[i].ACH = 0;
            res.body[i].OTHER = 0;
            this.counters_login.push(res.body[i]);
          }
          i++;
        });

        counters_payments.forEach(pays => {
          let filter = this.filterDate(pays.fecha);
          if(filter[0] === undefined){
            //console.log('i');
            let obj = {
              "fecha" : pays.fecha,
              "total_logs" : 0,
              "total_pays" : pays.total_pays,
              "total_ipays" : pays.total_ipay,
              "DISC" : 0,              
              "AMEX" : 0,
              "TERMINAL" : 0,
              "MSTR" : 0,
              "VISA" : 0,
              "OTHER" : 0       
            }
            this.counters_login.push(obj);
          }else{
            //console.log();
            filter[0].total_pays = Number(pays.total_pays);
          }
        });

        this.up1 = true;
          
        this.getPaymentsCounter();
      });

  }

  getPaymentsCounter(){
    this._counters.getCountPaymentsPerDays(this.log_filters.from_date, this.log_filters.to_date)
      .subscribe(res1 => { 
        let evi = true;
        //console.log(res1);
        //console.log("Respuesta  = " + JSON.stringify(res1.body));
        res1.body.forEach(payments => {
          
          if(evi  && payments.payment_source){
            //console.log('payment source = ' + payments.payment_source );
            evi = false;
          }else if (payments.date_created) {
            //console.log('fecha de pago y tipo : ' + payments.date_created +' type '+ payments.payment_type);
            let split = payments.date_created.split("T");
            let filter = this.filterDate(split[0]);
            
            let total = 0;
            let disc= 0, amex = 0, termi = 0, mstr = 0, visa = 0, other = 0;

            if(filter[0] === undefined){
              switch (payments.payment_type) {
                case "DISC":
                  total= Number(payments.total_paysinday);
                  disc = Number(payments.total_paysinday);
                  break;
                case "AMEX":
                  total= Number(payments.total_paysinday);
                  amex = Number(payments.total_paysinday);
                  break;
                case "TERMINAL":
                  total= Number(payments.total_paysinday);
                  termi = Number(payments.total_paysinday);
                  break;
                case "MSTR":
                  total= Number(payments.total_paysinday);
                  mstr = Number(payments.total_paysinday);
                  break;
                case "VISA":
                  total= Number(payments.total_paysinday);
                  visa = Number(payments.total_paysinday);
                  break;
                case "OTHER":
                  total= Number(payments.total_paysinday);
                  other = Number(payments.total_paysinday);

                  break;
              }

              let obj = {
                "fecha" : split[0],
                "total_logs" : 0,
                "total_pays" : total,
                "total_ipays" : 0,
                "DISC" : disc,              
                "AMEX" : amex,
                "TERMINAL" : termi,
                "MSTR" : mstr,
                "VISA" : visa,
                "OTHER" : other      
              }

              this.counters_login.push(obj);
            }else{
              //console.log(filter[0]);
              switch (payments.payment_type) {
                case "DISC":
                  total= Number(payments.total_paysinday);
                  disc = Number(payments.total_paysinday);
                  //console.log("fecha " + split[0]);
                  //console.log("disc " + disc);
                  break;
                case "AMEX":
                  total= Number(payments.total_paysinday);
                  amex = Number(payments.total_paysinday);
                  //console.log("fecha " + split[0]);
                  //console.log("amex " + amex);
                  break;
                case "TERMINAL":
                  total= Number(payments.total_paysinday);
                  termi = Number(payments.total_paysinday);
                  //console.log("fecha " + split[0]);
                  //console.log("termi " + termi);
                  break;
                case "MSTR":
                  total= Number(payments.total_paysinday);
                  mstr = Number(payments.total_paysinday);
                  //console.log("fecha " + split[0]);
                  //console.log("mstr " + mstr);
                  break;
                case "VISA":
                  total= Number(payments.total_paysinday);
                  visa = Number(payments.total_paysinday);
                  //console.log("fecha " + split[0]);
                  //console.log("visa " + visa);
                  break;
                case "OTHER":
                  total= Number(payments.total_paysinday);
                  other = Number(payments.total_paysinday);
                  console.log("fecha " + split[0]);
                  console.log("other " + other);
                  break;
              }
              //console.log();
              if(filter[0].total_pays == 0){
                filter[0].total_pays += total;
              }
              filter[0].DISC = filter[0].DISC == 0 ? disc: filter[0].DISC;
              filter[0].AMEX = filter[0].AMEX == 0 ? amex: filter[0].AMEX;
              filter[0].TERMINAL = filter[0].TERMINAL == 0 ? termi: filter[0].TERMINAL;
              filter[0].MSTR = filter[0].MSTR == 0 ? mstr: filter[0].MSTR;
              filter[0].VISA = filter[0].VISA == 0 ? visa: filter[0].VISA;
              filter[0].OTHER = filter[0].OTHER == 0 ? other: filter[0].OTHER;
            }
          }
        });
    });
    //console.log(this.counters_login);

    this.up2 = true;
    this.plotChartLine();
  }

  plotChartLine(){

    setTimeout(() => {
      this.counters_login.sort(this.sortDate).forEach(element => {
       // console.log(element)
        let split = element.fecha.split(" ")
        let split2 = split[0].split("-")

        let date = new Date(Number(split2[0]),Number(split2[1])-1, Number(split2[2]));

        let month_date = date.getMonth() + 1;
        let day_date = date.getDate();

        let month_day = month_date + "/" + day_date;
        this.days.push(month_day);

        this._counter_login.push(Number(element.total_logs));
        this._counter_pays.push(Number(element.total_pays));
        this._counter_ipays.push(Number(element.total_ipays ? element.total_ipays :0 ));

        this._counter_terminal.push(Number(element.TERMINAL));
        this._counter_visa.push(Number(element.VISA));
        this._counter_master.push(Number(element.MSTR));
        this._counter_amex.push(Number(element.AMEX));
        this._counter_discover.push(Number(element.DISC));
        this._counter_other.push(Number(element.OTHER));

        this._system_pays.push(
          Number(element.TERMINAL) +
          Number(element.VISA) +
          Number(element.MSTR) +
          Number(element.AMEX) +
          Number(element.DISC) +
          Number(element.OTHER)
        );

        this.counter_login = this._counter_login.reduce(function(acc, val) { return acc + val; }, 0);
        this.counter_pays = this._counter_pays.reduce(function(acc, val) { return acc + val; }, 0);
        this.counter_ipays = this._counter_ipays.reduce(function(acc, val) { return acc + val; }, 0);
      });

      //console.log(this._system_pays);
      //console.log("---------->system"+this._counter_pays);
     // console.log("---------->all ipay"+this._counter_ipays);
      
      this.counter_terminal = this._counter_terminal.reduce((a, b) => a + b, 0);
      this.counter_visa = this._counter_visa.reduce((a, b) => a + b, 0);
      this.counter_master = this._counter_master.reduce((a, b) => a + b, 0);
      this.counter_amex = this._counter_amex.reduce((a, b) => a + b, 0);
      this.counter_discover = this._counter_discover.reduce((a, b) => a + b, 0);
      this.counter_other = this._counter_other.reduce((a, b) => a + b, 0);

      var lineData = {
        //eje x
        labels: this.days,
        //eje y
        datasets: [
          {
            label: "Logins ",
            backgroundColor: "rgb(128, 128, 128)",
            borderColor: "rgb(128, 128, 130)",
            pointBackgroundColor: "rgb(105, 105, 105)",
            pointBorderColor: "#fff",
            data: this._counter_login,
            fill: 'origin'
          },
          {
            label: "All Payments ",
            backgroundColor: "rgba(0,87,159,0.5)",
            borderColor: "rgba(0,87,159,0.7)",
            pointBackgroundColor: "rgba(0,87,159,1)",
            pointBorderColor: "#fff",
            data: this._counter_pays, 
            fill: 'origin'
          },         
          {
            label: "Systems Payments ",
            backgroundColor: "rgba(26,179,148,0.5)",//"rgba(100,100,255,0.5)",
            borderColor:"rgba(26,179,148,0.7)", //"rgba(100,100,255,0.7)",
            pointBackgroundColor: "rgba(26,179,148,1)",//"rgba(100,100,255,1)",
            pointBorderColor: "#fff",
            data: this._counter_ipays,
            fill: 'origin'
          },
        ],
      };

      var lineOptions = {
        responsive: true,
      };
  
      document.getElementById("chartContainerCounter").innerHTML = '&nbsp;';
      document.getElementById("chartContainerCounter").innerHTML = '<canvas id="lineContainerCounter"></canvas>';
  
      var ctx = document.getElementById("lineContainerCounter"); // .getContext("2d");;
  
      if (ChartLoginCount) {
        ChartLoginCount.clear();
        ChartLoginCount.destroy();
      }
      var ChartLoginCount = new Chart(ctx, {
        type: "line",
        data: lineData,
        options: lineOptions,
      });

      var barData = {
        //eje x
        labels: this.days,
        //eje y
        datasets: [
          {
            label: "Terminal",
            backgroundColor: "rgba(30,30,35,0.5)",
            borderColor: "rgba(30,30,35,0.7)",
            pointBackgroundColor: "rgba(30,30,35,1)",
            pointBorderColor: "#fff",
            data: this._counter_terminal,
          },
          {
            label: "Visa ",
            backgroundColor: "rgba(0,87,159,0.5)",
            borderColor: "rgba(0,87,159,0.7)",
            pointBackgroundColor: "rgba(0,87,159,1)",
            pointBorderColor: "#fff",
            data: this._counter_visa,
          },
          {
            label: "Amex ",
            backgroundColor: "rgba(82,185,227,0.5)",
            borderColor: "rgba(82,185,227,0.7)",
            pointBackgroundColor: "rgba(82,185,227,1)",
            pointBorderColor: "#fff",
            data: this._counter_amex,
          },
          {
            label: "Mastercard ",
            backgroundColor: "rgba(255,36,0,0.5)",
            borderColor: "rgba(255,36,0,0.7)",
            pointBackgroundColor: "rgba(255,36,0,1)",
            pointBorderColor: "#fff",
            data: this._counter_master,
          },
          {
            label: "Discover ",
            backgroundColor: "rgba(227,96,31,0.5)",
            borderColor: "rgba(227,96,31,0.7)",
            pointBackgroundColor: "rgba(227,96,31,1)",
            pointBorderColor: "#fff",
            data: this._counter_discover,
          },
          {
            label: "Other ",
            backgroundColor: "rgba(100,96,31,0.5)",
            borderColor: "rgba(100,96,31,0.7)",
            pointBackgroundColor: "rgba(100,96,31,1)",
            pointBorderColor: "#fff",
            data: this._counter_other,
          },
        ],
      };
      var barOptions = {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      };
  
      document.getElementById("chartContainerWeb").innerHTML = '&nbsp;';
      document.getElementById("chartContainerWeb").innerHTML = '<canvas id="barChartContainerWeb"></canvas>';
  
      var ctx = document.getElementById("barChartContainerWeb"); // .getContext("2d");;
  
      if (ChartWebCount) {
        ChartWebCount.clear();
        ChartWebCount.destroy();
      }

      var ChartWebCount = new Chart(ctx, {
        type: "bar",
        data: barData,
        options: barOptions,
      });


      
    }, 2000);
  }
}
