import { Component, OnInit ,ViewChild} from '@angular/core';
import { detectBody } from '../../app.helpers';
import * as moment from 'moment'
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.css'],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class BasicLayoutComponent implements OnInit {
  constructor(private router: Router) { }

  public ngOnInit():any {
    detectBody();
  }

  public onResize(){
    detectBody();
  }
}
