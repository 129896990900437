import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserTypes, TicketTypes, AccountTypes } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class TypeService {
    baseUrl: string = environment.serverUrl;

    constructor(private http: HttpClient) { }

    getUserTypes() {
        return this.http.get<UserTypes>(`${this.baseUrl}/api/types/user`);
    }

    getTicketTypes() {
        return this.http.get<TicketTypes[]>(`${this.baseUrl}/api/types/tickets`);
    }

    getWorkOrderTypes() {
        return this.http.get<TicketTypes[]>(`${this.baseUrl}/api/types/work_orders`);
    }
    
    getAccountTypes() : Observable<HttpResponse<any>> {
    	return this.http.get<AccountTypes[]>(`${this.baseUrl}/api/types/account_types`, {observe : 'response'});
    } 

    getAccountTypes_support() {
    	return this.http.get<AccountTypes[]>(`${this.baseUrl}/api/types/account_types`);
    }
}
