import {
  Component,
  OnInit,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AdminEmailsService } from "../../_services";
import { User, UserSettings, Account } from "../../_models";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { FormGroup } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import * as moment from "moment";

declare var $: any;

@Component({
  selector: "app-emails-admin",
  templateUrl: "./notification-emails-admin.component.html",
  styleUrls: ["./notification-emails-admin.component.css"],
})
export class EmailAdminSettings implements OnInit, OnChanges {
  @Output() editUserModalClosed = new EventEmitter();
  @ViewChild("editSuccess") private editSuccessSwal: SwalComponent;
  @ViewChild("editFailure") private editFailureSwal: SwalComponent;
  @ViewChild("editDataFailure") private editDataFailureSwal: SwalComponent;
  @ViewChild("success") private successSwal: SwalComponent;
  @ViewChild("failure") private failureSwal: SwalComponent;
  @ViewChild("400failure") private notFoundFailureSwal: SwalComponent;
  @Output() refreshParentData = new EventEmitter();
  @Input() select: any;
  public sentMessage: string;
  public email: string;
  public loading = false;
  public userSettings: UserSettings;
  public user;
  public logged_in_user: User;
  public accounts: Account[];
  public selectedAccount: Account;
  public updateUserFormGroup: FormGroup;
  public adminEditUserFormGroup: FormGroup;
  public disableMailingAddress = false;
  public bsConfig: Partial<BsDatepickerConfig>;
  public datePickerDate: any;
  public initialBirthDate = moment().subtract(20, "years").format("MM/DD/YYYY");
  public submitSwalAlertTitle = "Save Changes?";
  public editSuccessAlertTitle = "User has been edited";
  public editFailureAlertTitle = "There was an error";
  public editFailureAlertText = "Please try again later";
  public successAlertTitle = "A link has been sent to you";
  public _400failureAlertTitle =
    "The e-mail you entered is invalid or was not found";
  public failureAlertTitle = "Something went wrong, we are very sorry";
  public selectAccountPlaceholder = "Select Account";
  loader = false;
  emails: string[] = [];
  newEmail: string = "";
  switchbillReminder: any = null;
  switchlateFeeReminder: any = null;
  switchlateNoticeReminder: any = null;
  switchdisconnectWarning: any = null;

  textswitchbillReminder: string = "";
  textswitchlateFeeReminder: string = "";
  textswitchlateNoticeReminder: string = "";
  textswitchdisconnectWarning: string = "";

  successSetReminders: boolean = false;
  textSetReminders: string = "";
  errorSetReminders: boolean = false;
  userInfo;
  phone;
  currentUser;
  profileDetails;
  // para pagos fallidos
  newFailedPaymentEmail: string;
  failedPaymentEmails: string[] = [];
  loaderFailedPayments: boolean = false;

  // Para reporte diario
  newDailyReportEmail: string;
  dailyReportEmails: string[] = [];
  loaderDailyReport: boolean = false;

  // Para reporte mensual
  newMonthlyReportEmail: string;
  monthlyReportEmails: string[] = [];
  loaderMonthlyReport: boolean = false;

  //para reporte autoPreRun
  newAutoPreRunReportEmail: string;
  autoPreRunReportEmails: string[] = [];
  loaderAutoPreRunReport: boolean = false;

  //para reporte AutoPay Run Results
  newAutoPayRunResultsReportEmail: string;
  autoPayRunResultsReportEmails: string[] = [];
  loaderAutoPayRunResultsReport: boolean = false;

  //para reporte Voids / Returns Notifications
  newVoidReturnNotificationEmail: string;
  voidReturnNotificationEmails: string[] = [];
  loaderVoidReturnNotification: boolean = false;
  // para reporte Bill Paylink Generation result notice
  newBillPaylinkGenerationResultNoticeEmail: string;
  billPaylinkGenerationResultNoticeEmails: string[] = [];
  loaderBillPaylinkGenerationResultNotice: boolean = false;

  //para reporte Import Bills Result Notice
  newImportBillsResultNoticeEmail: string;
  importBillsResultNoticeEmails: string[] = [];
  loaderImportBillsResultNotice: boolean = false;

  // para reporte Import Consumption Logs Notice Report
  newImportConsumptionLogsNoticeReportEmail: string;
  importConsumptionLogsNoticeReportEmails: string[] = [];
  loaderImportConsumptionLogsNoticeReport: boolean = false;
  // para pagos exitosos
  newSuccessfulPaymentEmail: string;
  successfulPaymentEmails: string[] = [];
  loaderSuccessfulPayments: boolean = false;

  addSuccessfulPaymentEmail() {
    this.loaderSuccessfulPayments = true;
    this.successfulPaymentEmails.push(this.newSuccessfulPaymentEmail);
    this.newSuccessfulPaymentEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.successfulPaymentEmails,
        updateBy: this.logged_in_user.id,
        case: "Successful",
      })
      .subscribe((res) => {
        this.loaderSuccessfulPayments = false;
      });
  }

  removeSuccessfulPaymentEmail(index: number) {
    this.successfulPaymentEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.successfulPaymentEmails,
        updateBy: this.logged_in_user.id,
        case: "Successful",
      })
      .subscribe((res) => {
        this.loaderSuccessfulPayments = false;
      });
  }

  addFailedPaymentEmail() {
    this.loaderFailedPayments = true;
    this.failedPaymentEmails.push(this.newFailedPaymentEmail);
    this.newFailedPaymentEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.failedPaymentEmails,
        updateBy: this.logged_in_user.id,
        case: "Failed",
      })
      .subscribe((res) => {
        this.loaderFailedPayments = false;
      });
  }

  removeFailedPaymentEmail(index: number) {
    this.failedPaymentEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.failedPaymentEmails,
        updateBy: this.logged_in_user.id,
        case: "Failed",
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }

  // Para reporte diario
  addDailyReportEmail() {
    this.loaderDailyReport = true;
    this.dailyReportEmails.push(this.newDailyReportEmail);
    this.newDailyReportEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.dailyReportEmails,
        updateBy: this.logged_in_user.id,
        case: "Daily",
      })
      .subscribe((res) => {
        this.loaderDailyReport = false;
      });
  }

  removeDailyReportEmail(index: number) {
    this.dailyReportEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.dailyReportEmails,
        updateBy: this.logged_in_user.id,
        case: "Daily",
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }

  // Para reporte mensual
  addMonthlyReportEmail() {
    this.loaderMonthlyReport = true;
    this.monthlyReportEmails.push(this.newMonthlyReportEmail);
    this.newMonthlyReportEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.monthlyReportEmails,
        updateBy: this.logged_in_user.id,
        case: "Monthly",
      })
      .subscribe((res) => {
        this.loaderMonthlyReport = false;
      });
  }

  removeMonthlyReportEmail(index: number) {
    this.monthlyReportEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.monthlyReportEmails,
        updateBy: this.logged_in_user.id,
        case: "Monthly",
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }

  addEmail() {
    if (this.newEmail && this.isValidEmail(this.newEmail)) {
      this.loader = true;
      this.emails.push(this.newEmail);
      this.serviceAdminEmail
        .putEmailsAdmin({
          emails: this.emails,
          updateBy: this.logged_in_user.id,
        })
        .subscribe((res) => {
          this.loader = false;
        });
      this.newEmail = "";
    }
  }

  removeEmail(index: number) {
    this.loader = true;
    this.emails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.emails,
        updateBy: this.logged_in_user.id,
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }
  // Función para agregar correo de reporte AutoPreRun
  addAutoPreRunReportEmail() {
    this.loaderAutoPreRunReport = true;
    this.autoPreRunReportEmails.push(this.newAutoPreRunReportEmail);
    this.newAutoPreRunReportEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.autoPreRunReportEmails,
        updateBy: this.logged_in_user.id,
        case: "AutoPreRun",
      })
      .subscribe((res) => {
        this.loaderAutoPreRunReport = false;
      });
  }

  removeAutoPreRunReportEmail(index: number) {
    this.autoPreRunReportEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.autoPreRunReportEmails,
        updateBy: this.logged_in_user.id,
        case: "AutoPreRun",
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }

  // Función para agregar correo de reporte AutoPay Run Results
  addAutoPayRunResultsReportEmail() {
    this.loaderAutoPayRunResultsReport = true;
    this.autoPayRunResultsReportEmails.push(
      this.newAutoPayRunResultsReportEmail
    );
    this.newAutoPayRunResultsReportEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.autoPayRunResultsReportEmails,
        updateBy: this.logged_in_user.id,
        case: "AutoPayRun",
      })
      .subscribe((res) => {
        this.loaderAutoPayRunResultsReport = false;
      });
  }

  removeAutoPayRunResultsReportEmail(index: number) {
    this.autoPayRunResultsReportEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.autoPayRunResultsReportEmails,
        updateBy: this.logged_in_user.id,
        case: "AutoPayRun",
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }

  // Función para agregar correo de Voids / Returns Notifications
  addVoidReturnNotificationEmail() {
    this.loaderVoidReturnNotification = true;
    this.voidReturnNotificationEmails.push(this.newVoidReturnNotificationEmail);
    this.newVoidReturnNotificationEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.voidReturnNotificationEmails,
        updateBy: this.logged_in_user.id,
        case: "VoidReturn",
      })
      .subscribe((res) => {
        this.loaderVoidReturnNotification = false;
      });
  }

  removeVoidReturnNotificationEmail(index: number) {
    this.voidReturnNotificationEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.voidReturnNotificationEmails,
        updateBy: this.logged_in_user.id,
        case: "VoidReturn",
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }

  // Función para agregar correo de Bill Paylink Generation Result Notice
  addBillPaylinkGenerationResultNoticeEmail() {
    this.loaderBillPaylinkGenerationResultNotice = true;
    this.billPaylinkGenerationResultNoticeEmails.push(
      this.newBillPaylinkGenerationResultNoticeEmail
    );
    this.newBillPaylinkGenerationResultNoticeEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.billPaylinkGenerationResultNoticeEmails,
        updateBy: this.logged_in_user.id,
        case: "BillPaylink",
      })
      .subscribe((res) => {
        this.loaderBillPaylinkGenerationResultNotice = false;
      });
  }

  removeBillPaylinkGenerationResultNoticeEmail(index: number) {
    this.billPaylinkGenerationResultNoticeEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.billPaylinkGenerationResultNoticeEmails,
        updateBy: this.logged_in_user.id,
        case: "BillPaylink",
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }

  // Función para agregar correo de Import Bills Result Notice
  addImportBillsResultNoticeEmail() {
    this.loaderImportBillsResultNotice = true;
    this.importBillsResultNoticeEmails.push(
      this.newImportBillsResultNoticeEmail
    );
    this.newImportBillsResultNoticeEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.importBillsResultNoticeEmails,
        updateBy: this.logged_in_user.id,
        case: "ImportBills",
      })
      .subscribe((res) => {
        this.loaderImportBillsResultNotice = false;
      });
  }

  removeImportBillsResultNoticeEmail(index: number) {
    this.importBillsResultNoticeEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.importBillsResultNoticeEmails,
        updateBy: this.logged_in_user.id,
        case: "ImportBills",
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }

  // Función para agregar correo de Import Consumption Logs Notice Report
  addImportConsumptionLogsNoticeReportEmail() {
    this.loaderImportConsumptionLogsNoticeReport = true;
    this.importConsumptionLogsNoticeReportEmails.push(
      this.newImportConsumptionLogsNoticeReportEmail
    );
    this.newImportConsumptionLogsNoticeReportEmail = "";
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.importConsumptionLogsNoticeReportEmails,
        updateBy: this.logged_in_user.id,
        case: "ImportConsumption",
      })
      .subscribe((res) => {
        this.loaderImportConsumptionLogsNoticeReport = false;
      });
  }

  removeImportConsumptionLogsNoticeReportEmail(index: number) {
    this.importConsumptionLogsNoticeReportEmails.splice(index, 1);
    this.serviceAdminEmail
      .putEmailsAdmin({
        emails: this.importConsumptionLogsNoticeReportEmails,
        updateBy: this.logged_in_user.id,
        case: "ImportConsumption",
      })
      .subscribe((res) => {
        this.loader = false;
      });
  }

  private isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }



  constructor(
    public bsModalRef: BsModalRef,
    private BsModalRef: BsModalRef,
    public bsModalService: BsModalService,
    private serviceAdminEmail: AdminEmailsService
  ) {
    this.bsConfig = Object.assign({}, { containerClass: "theme-dark-blue" });
  }

  getEmailInitial(email: string): string {
    return email.charAt(0).toUpperCase();
  }

  ngOnInit() {

    this.loaderDailyReport = true;
    this.loaderMonthlyReport = true;
    this.loaderFailedPayments = true;
    this.loaderAutoPayRunResultsReport = true;
    this.loaderAutoPreRunReport = true;
    this.loaderVoidReturnNotification = true;
    this.loaderBillPaylinkGenerationResultNotice = true;
    this.loaderImportBillsResultNotice = true;
    this.loaderImportConsumptionLogsNoticeReport = true;
    this.loaderSuccessfulPayments = true
    this.serviceAdminEmail.getEmailsAdmin().subscribe((res) => {
      this.loaderDailyReport = false;
      this.loaderMonthlyReport = false;
      this.loaderFailedPayments = false;
      this.loaderAutoPayRunResultsReport = false;
      this.loaderAutoPreRunReport = false;
      this.loaderVoidReturnNotification = false;
      this.loaderBillPaylinkGenerationResultNotice = false;
      this.loaderImportBillsResultNotice = false;
      this.loaderImportConsumptionLogsNoticeReport = false;
      this.loaderSuccessfulPayments = false

      if (res.length > 0) {
        this.dailyReportEmails = res[0].emails_daily ? res[0].emails_daily : [];
        this.successfulPaymentEmails = res[0].emails_success
          ? res[0].emails_success
          : [];
        this.failedPaymentEmails = res[0].emails_failed
          ? res[0].emails_failed
          : [];
        this.monthlyReportEmails = res[0].emails_month
          ? res[0].emails_month
          : [];
        this.autoPayRunResultsReportEmails = res[0].emails_autopayrun
          ? res[0].emails_autopayrun
          : [];
        this.autoPreRunReportEmails = res[0].emails_autoprerun
          ? res[0].emails_autoprerun
          : [];
        this.voidReturnNotificationEmails = res[0].emails_voidreturn
          ? res[0].emails_voidreturn
          : [];
        this.billPaylinkGenerationResultNoticeEmails = res[0].emails_billpaylink
          ? res[0].emails_billpaylink
          : [];
        this.importBillsResultNoticeEmails = res[0].emails_importbills
          ? res[0].emails_importbills
          : [];
        this.importConsumptionLogsNoticeReportEmails = res[0]
          .emails_importconsumption
          ? res[0].emails_importconsumption
          : [];

        this.loader = false;
      }
    });

    this.logged_in_user = JSON.parse(localStorage.getItem("current_user"));

    if (window.location.href.includes("/es/")) {
      this.submitSwalAlertTitle = "Desea guardar los cambios?";
      this.editSuccessAlertTitle = "El Usuario ha sido editado";
      this.editFailureAlertTitle = "Hubo un error";
      this.editFailureAlertText = "Porfavor, intente mas tarde";
      this.successAlertTitle = "Se ha enviado un link";
      this._400failureAlertTitle =
        "El correo ingresado no ha sido encontrado o no es valido";
      this.failureAlertTitle = "Lo sentimos, huno un error";
      this.selectAccountPlaceholder = "Seleccione una Cuenta";
    }
  }

  ngOnChanges() { }

  ngAfterViewInit() {
    // Add chosen to select elements
    $(".chosen-select").chosen({ width: "100%" });
  }

  get emailAddress() {
    return this.updateUserFormGroup.value.email;
  }

  hideModal() {
    this.BsModalRef.hide();
  }

  confirm() {
    this.bsModalRef.hide();
    this.editUserModalClosed.emit(true);
  }
}
