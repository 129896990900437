export class Notification {
    notif_id: number;
    reason: number;
    title: string;
    message: string;
    type: string;
    date_created: string;
    date_updated: string;
    schedule: string;
    account_type: number;
    position: string;
    name_type: string;
}
export class allUserMessage {
    id: number;
    first_name: number;
    last_name: string;
    email: string;
    name: string;
    reason: number;
    title: string;
    message: string;
    type: number;
    position: string;
    schedule:string;
}
