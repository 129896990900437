import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard, RoleGuard, DefaultGuard } from "./_guards";

import { BasicLayoutComponent } from "./_common/basic-layout/basic-layout.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UsersComponent } from "./users/users.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ForgotPasswordComponent } from "./forgot_password/forgot_password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { BillsComponent } from "./bills/bills.component";
import { BillDetailsComponent } from "./bill-details/bill-details.component";
import { TicketsComponent } from "./tickets/tickets.component";
import { TicketDetailsComponent } from "./ticket-details/ticket-details.component";
import { CommunicationComponent } from "./communication/communication.component";
import { EmailDetailComponent } from "./email-detail/email-detail.component";
import { PaymentsComponent } from "./payments/payments.component";
import { SignupRequestsComponent } from "./signup-requests/signup-requests.component";
import { CustProfilePageComponent } from "./cust-profile-page/cust-profile-page.component";
import { CustomerSignupComponent } from "./customer-signup/customer-signup.component";
import { ExistingUserComponent } from "./existing-user/existing-user.component";
import { WorkOrdersComponent } from "./work-orders/work-orders.component";
import { WorkOrderDetailsComponent } from "./work-order-details/work-order-details.component";
import { UserDetailsPanelComponent } from "./_common/user-details-panel/user-details-panel.component";
import { PaymentReceiptComponent } from "./payment-receipt/payment-receipt.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { MakePaymentComponent } from "./make-payment/make-payment.component";
import { PaymentUsioComponent } from "./payment-usio/payment-usio.component";
import { StaffLoginAuthenticationComponent } from "./staff-login-authentication/staff-login-authentication.component";
import { MapComponent } from "./map/map.component";
import { PolicyComponent } from "./policy/policy.component";
import { StandaloneTerminalComponent } from "./_common/standalone-terminal/standalone-terminal.component";
import { PrvImportComponent } from "./prv-import/prv-import.component";
import { LateFeesComponent } from "./late-fees/late-fees.component";
import { TrafficPaymentsComponent } from "./traffic-payments/traffic-payments.component";
import { MetricsModuleComponent } from "./metrics-module/metrics-module.component";

import { UserGrowthComponent } from "./usermetric/user-growth/user-growth.component";
import { ErrorCodesBreakdownComponent } from "./error-metric/error-codes-breakdown/error-codes-breakdown.component";
import { CovidComponent } from "./infoCovid/pages/pageCovid/covid/covid.component";
import { PackagesComponent } from "./packages/packages.component";
import { ReversalsComponent } from "./reversals/reversals.component";
import { SecuryAuditTrailComponent } from "./secury-audit-trail/secury-audit-trail.component";

const ADMIN = 1;
const STAFF = 2;
const CUST = 3;
const COLL = 4;
const PAYMENTS = 5;
const BILLS = 6;
const CUST_SERVI = 7;

const routes: Routes = [
  {
    path: "",
    canActivate: [DefaultGuard],
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "policy",
    component: PolicyComponent,
  },
  {
    path: "homepage",
    component: LandingPageComponent,
  },
  {
    path: "signup",
    children: [
      { path: "", component: CustomerSignupComponent },
      { path: "existing", component: ExistingUserComponent },
    ],
  },
  {
    path: "forgot_password",
    component: ForgotPasswordComponent,
  },
  {
    path: "staff_login",
    component: StaffLoginAuthenticationComponent,
  },
  {
    path: "reset_password",
    component: ResetPasswordComponent,
  },
  {
    path: "dashboard",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [CUST] },
    children: [{ path: "", component: DashboardComponent }],
  },

  {
    path: "covid",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [CUST] },
    children: [{ path: "", component: CovidComponent }],
  },

  {
    path: "network-insights",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN] },
    children: [{ path: "", component: MetricsModuleComponent }],
  },
  {
    path: "users",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, COLL, PAYMENTS, CUST_SERVI] },
    children: [
      { path: "", component: UsersComponent },
      { path: ":id", component: UserDetailsPanelComponent },
    ],
  },
  // {
  //   path: 'payments',
  //   component: BasicLayoutComponent,
  //   canActivate: [AuthGuard, RoleGuard],
  //   data: { expectedRole: [ADMIN, CUST, STAFF] },
  //   children: [
  //     { path: '', component: PaymentsComponent },
  //     { path: 'new/:accountId', component: MakePaymentComponent },
  //     { path: 'new/:accountId/:pdsInfoId', component: MakePaymentComponent },
  //     { path: ':userSearch', component: PaymentsComponent },
  //     { path: ':id/receipt', component: PaymentReceiptComponent },
  //   ]
  // },
  {
    path: "payments",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, CUST, STAFF, COLL, PAYMENTS, CUST_SERVI] },
    children: [
      { path: "", component: PaymentsComponent },
      { path: "new/:accountId", component: PaymentUsioComponent },
      { path: "new/:accountId/:pdsInfoId", component: MakePaymentComponent },
      { path: ":userSearch", component: PaymentsComponent },
      { path: ":id/receipt", component: PaymentReceiptComponent },
    ],
  },
  {
    path: "bills",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, CUST, BILLS, CUST_SERVI] },
    children: [
      { path: "", component: BillsComponent },
      { path: ":id", component: BillDetailsComponent },
      { path: ":id/:pdsInfoId", component: BillDetailsComponent },
    ],
  },
  {
    path: "signup/requests",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, STAFF] },
    children: [{ path: "", component: SignupRequestsComponent }],
  },
  {
    path: "tickets",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, CUST, STAFF, CUST_SERVI] },
    children: [
      { path: "", component: TicketsComponent },
      { path: ":id", component: TicketDetailsComponent },
    ],
  },
  {
    path: "communication",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, CUST_SERVI] },
    children: [
      { path: "", component: CommunicationComponent },
      { path: ":id", component: EmailDetailComponent },
    ],
  },
  {
    path: "work_orders",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, STAFF] },
    children: [
      { path: "", component: WorkOrdersComponent },
      { path: ":id", component: WorkOrderDetailsComponent },
    ],
  },
  {
    path: "profile",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [CUST] },
    children: [{ path: "", component: CustProfilePageComponent }],
  },
  {
    path: "map",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN] },
    children: [{ path: "", component: MapComponent }],
  },
  {
    path: "standalone/terminal",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, PAYMENTS] },
    children: [{ path: "", component: StandaloneTerminalComponent }],
  },
  {
    path: "prv",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, PAYMENTS, BILLS] },
    children: [{ path: "", component: PrvImportComponent }],
  },
  {
    path: "late-fees",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, STAFF, CUST_SERVI] },
    children: [{ path: "", component: LateFeesComponent }],
  },
  //   nueva metrica de usuarios
  {
    path: "usergrowth",
    component: BasicLayoutComponent,
    data: { expectedRole: [ADMIN] },
    children: [{ path: "", component: UserGrowthComponent }],
  },
  {
    path: "security-audit-trail",
    component: BasicLayoutComponent,
    data: { expectedRole: [ADMIN] },
    children: [{ path: "", component: SecuryAuditTrailComponent }],
  },
  {
    path: "network-insights",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN] },
    children: [{ path: "", component: MetricsModuleComponent }],
  },
  {
    path: "reversals",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN] },
    children: [{ path: "", component: ReversalsComponent }],
  },
  //   nueva metrica de errores
  {
    path: "errormetric",
    component: BasicLayoutComponent,
    data: { expectedRole: [ADMIN] },
    children: [{ path: "", component: ErrorCodesBreakdownComponent }],
  },

  {
    path: "traffic-payments",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN] },
    children: [{ path: "", component: TrafficPaymentsComponent }],
  },

  {
    path: "packages",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: [ADMIN, STAFF] },
    children: [{ path: "", component: PackagesComponent }],
  },
  {
    path: "404",
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [{ path: "", component: PageNotFoundComponent }],
  },
  {
    path: "403",
    component: ForbiddenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    component: PageNotFoundComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
  declarations: [CovidComponent],
})
export class AppRoutingModule {}
