import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap }  from 'rxjs/operators'
import { AuthService, VerifyUsersService } from '../_services';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent implements OnInit {

  @ViewChild('success') private successAlertSwal: SwalComponent;


  public loading : boolean;
  public errorMessage : string;


  constructor(
    private ActiveRoute : ActivatedRoute,
    private auth : AuthService,
    private router: Router,
    private verifyUserService: VerifyUsersService,

  ) { 
    this.loading = true; 
    this.errorMessage = "";
  }

  ngOnInit() {
    this.ActiveRoute.queryParamMap
      .pipe(switchMap((results: any) => {
        return this.verifyUserService.verifyUser(results.params);
      }))
  		.subscribe((res: any) => {
        setTimeout(() => {
          this.loading = false;
          this.successAlertSwal.title = "Congratulations";
          this.successAlertSwal.text = "Thank you for verifying your email!"
          this.successAlertSwal.show();
        }, 3000)
      }, error => {
        setTimeout(() => {
          this.loading = false;
          if(error.message){
            this.errorMessage = error.message;
          }else{
            this.errorMessage = 'Something went wrong, please try again later';
          }  
        }, 3000);
      });
  }

  goToLogin(){
    this.router.navigate(['/login']);
  }
}
