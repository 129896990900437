import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import {
  UsioService,
  TerminalService,
  CustomerService,
  PaymentsService,
  AccountService,
  AddressService,
  CryptoService,
} from "src/app/_services";
import { Payments, User, Address } from "src/app/_models";

import { SwalComponent } from "@toverux/ngx-sweetalert2";

import { UsioConstants } from "../../usio-constants";

import * as moment from "moment";
import * as globals from "../../globals.json";
import { EventReceiptService } from "../../_services/event-receipt.service";
import { keyframes } from "@angular/animations";
import { FeeCalculator } from "../../_helpers";
import { MONTHS } from "src/app/constants.util";

declare var $: any;

@Component({
  selector: "app-virtual-terminal",
  templateUrl: "./virtual-terminal.component.html",
  styleUrls: ["./virtual-terminal.component.css"],
  providers: [CurrencyPipe],
})
export class VirtualTerminalComponent implements OnInit {
  @ViewChild("terminal") private terminalTemplate: ElementRef;
  @ViewChild("errorAlert") private errorAlertSwal: SwalComponent;
  @ViewChild("successAlert") private successAlertSwal: SwalComponent;
  @ViewChild("confirmPayment") private confirmPaymentSwal: SwalComponent;
  // @ViewChild('select') printButton : ElementRef

  @Input() user: User;
  @Input("openTerminal") openTerminal;
  @Input() isActive;
  @Input() selectedAccount;
  @Output() refreshParentData = new EventEmitter();

  public bsModalRef: BsModalRef;
  public error: any = {};
  public activeAccounts = [];
  public acceptedCards: any = {};
  public loading: boolean;
  public paymentFormGroup: FormGroup;
  public ccFormGroup: FormGroup;
  public achFormGroup: FormGroup;
  public billingFormGroup: FormGroup;

  public cardType: string;
  public payType: string;
  public payAmount: number;
  public amount: number;
  public finalAmount: number;
  public IPAYFee: number; // KEEPS THE IPAY FEE
  public totalFees: number; // KEEPS THE USIO FEE
  public totalFeesWithIPay: number; // ADDS USIO AND IPAY FEE
  public totalAmount: number;
  public maxPaymentAmount: number;
  public paymentMethod: string;
  public paymentId: string;

  public chosenTerminal: any;
  // public chosenCode: any;
  public isPhysicalTerminal: boolean;
  public terminals: any = [];
  public Codes: any = [];
  public todaysDate: any;
  public creditCardNumber;
  public logged_in_user;
  public hideAccountInfoDiv: boolean;
  public globals: any = globals.default;

  public filteredAddresses: Address[] = [];

  public accounts: {
    account_number: string;
    account_id?: string;
    balance: number;
    amount: number;
    total_fees: number;
    user_email?: string;
    user_id?: string;
    full_address?: string;
    chosenCode: number;
  }[];

  public defaultAddress: {
    street_address: string;
    city: string;
    zip_code: string;
    state: string;
  };

  // public payMethod = 'cc';
  public achSource = "checking";

  public titleSuccess = "Success";
  public textSuccess = "Payment was successful";
  public textLoginSuccess = "Payment was logged successfully";
  public failText = "Something went wrong, please try again later";

  public isIpad: boolean;

  public months: Array<object> = [];

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private usioService: UsioService,
    private terminalService: TerminalService,
    private customerService: CustomerService,
    private paymentService: PaymentsService,
    private accountService: AccountService,
    private addressService: AddressService,
    public feeCalculator: FeeCalculator,
    private CP: CurrencyPipe,
    private router: Router,
    private event: EventReceiptService,
    private cryptoService: CryptoService
  ) {
    this.error.isJoi = false;
    this.error.errorMessage = String();
    this.loading = false;
    this.cardType = String();
    // this.payType = 'othr';
    this.payAmount = 0.0;
    this.amount = 0.0;
    this.IPAYFee = 0.0;
    this.totalFees = 0.0;
    this.totalFeesWithIPay = 0.0;
    this.totalAmount = 0.0;
    this.chosenTerminal = {};
    // this.chosenCode = {};
    this.isPhysicalTerminal = true;
    this.terminals = [];
    //usage of CODE can be factored out to retrieve from a reference table. Traffic Payments should have been carried over with a reference table for "CODES" .
    //Codes in theory can be interchangeable for "Categories".
    //Este Objecto de CODE puede ser una tabla de referencias la cual nos permite "modificar" ala carta . Esto es como se debio replicar Traffic Payments.
    this.Codes = [
      {
        code: 1,
        Short_Code: "Upay",
        description: "Utility Payment",
      },
      {
        code: 2,
        Short_Code: "CCF Fee",
        description: "Credit card Fee (Offline)",
      },
      {
        code: 3,
        Short_Code: "Op Fee",
        description: "Online Pay Fee (Offline)",
      },
      {
        code: 8,
        Short_Code: "Travelin",
        description: "Traveling MTR Dep",
      },
      {
        code: 9,
        Short_Code: "Renters",
        description: "Renter's Deposit",
      },
      {
        code: 10,
        Short_Code: "NS DEP",
        description: "New Service DEposit",
      },
      {
        code: 11,
        Short_Code: "Inspect",
        description: "Inspection Fee",
      },
      {
        code: 12,
        Short_Code: "Impact",
        description: "Impact Fee",
      },
      {
        code: 13,
        Short_Code: "Meter Fee",
        description: "Meter Fee",
      },
      {
        code: 14,
        Short_Code: "Wtr Rght",
        description: "Water Rights",
      },
      {
        code: 15,
        Short_Code: "Member",
        description: "Membership Fee",
      },
      {
        code: 16,
        Short_Code: "Boring F",
        description: "Boring Fees",
      },
      {
        code: 17,
        Short_Code: "Relocate",
        description: "Meter Relocation Fee",
      },
      {
        code: 18,
        Short_Code: "Construct",
        description: "Construction Inspect",
      },
      {
        code: 19,
        Short_Code: "Civil Fl",
        description: "Civil Fines",
      },
      {
        code: 20,
        Short_Code: "Res Fee",
        description: "Reservice Fee",
      },
      {
        code: 21,
        Short_Code: "SWRCNFEE",
        description: "Sewer Connection Fee",
      },
      {
        code: 24,
        Short_Code: "IPAY",
        description: "CC Fee Ipay",
      },
      {
        code: 101,
        Short_Code: "History",
        description: "History Report Fee",
      },
      {
        code: 200,
        Short_Code: "AR",
        description: "A/R Payments",
      },
      {
        code: 900.2,
        Short_Code: "OV/SH200",
        description: "200-Cash Over / Short",
      },
      {
        code: 900.3,
        Short_Code: "OV/SH300",
        description: "300 Cash Over/Short",
      },
    ];

    this.finalAmount = 0.0;
    this.maxPaymentAmount = 0.0;
    this.paymentMethod = "";
    this.hideAccountInfoDiv = false;
    this.accounts = [];
    this.todaysDate = moment().format("MMM Do, YYYY");
    this.acceptedCards = {
      visa: "VISA",
      mastercard: "MSTR",
      discover: "DISC",
      amex: "AMEX",
    };

    this.defaultAddress = {
      street_address: globals.default.paymentAddress,
      city: globals.default.paymentCity,
      state: globals.default.paymentState,
      zip_code: globals.default.paymentZip,
    };

    this.ccFormGroup = this.fb.group({
      ccNum: [
        "",
        [
          Validators.minLength(UsioConstants.CARD_NUMBER.MIN),
          Validators.maxLength(UsioConstants.CARD_NUMBER.MAX),
          Validators.required,
        ],
      ],
      exp_month: [
        "",
        [Validators.minLength(2), Validators.maxLength(2), Validators.required],
      ],
      exp_year: [
        "",
        [Validators.minLength(2), Validators.maxLength(2), Validators.required],
      ],
      security_code: ["", [Validators.minLength(3), Validators.maxLength(4)]],
    });

    this.achFormGroup = this.fb.group({
      routingNumber: [
        "",
        [Validators.minLength(9), Validators.maxLength(9), Validators.required],
      ],
      accountNumber: [
        "",
        [
          Validators.minLength(5),
          Validators.maxLength(17),
          Validators.required,
        ],
      ],
    });

    this.billingFormGroup = this.fb.group({
      street_address: [""],
      city: [""],
      state: [""],
      zip_code: [""],
      name: ["", Validators.required],
      email: [""],
    });

    this.months = MONTHS;
  }

  click() {
    this.event.clickDrop();
  }

  set street_address(street_address: string) {
    this.billingFormGroup.get("street_address").setValue(street_address);
  }
  set city(city: string) {
    this.billingFormGroup.get("city").setValue(city);
  }
  set state(state: string) {
    this.billingFormGroup.get("state").setValue(state);
  }
  set zip_code(zip_code: string) {
    this.billingFormGroup.get("zip_code").setValue(zip_code);
  }

  get street_address() {
    return this.billingFormGroup.get("street_address").value;
  }
  get city() {
    return this.billingFormGroup.get("city").value;
  }
  get state() {
    return this.billingFormGroup.get("state").value;
  }
  get zip_code() {
    return this.billingFormGroup.get("zip_code").value;
  }
  get name() {
    return this.billingFormGroup.get("name").value;
  }
  get email() {
    return this.billingFormGroup.get("email").value;
  }

  set ccNum(newCCNum: any) {
    this.ccFormGroup.get("ccNum").setValue(newCCNum);
  }
  get ccNum() {
    return this.ccFormGroup.get("ccNum");
  }
  get exp_month() {
    return this.ccFormGroup.get("exp_month");
  }
  set setExp_month(newExpMonth: any) {
    this.ccFormGroup.get("exp_month").setValue(newExpMonth);
  }
  get exp_year() {
    return this.ccFormGroup.get("exp_year");
  }
  get security_code() {
    return this.ccFormGroup.get("security_code");
  }

  ngOnInit() {
    this.isIpad = this.isDeviceIPad();
    this.getTerminalsInfo();
    this.logged_in_user = JSON.parse(window.localStorage.current_user);

    if (window.location.href.includes("/es/")) {
      this.titleSuccess = "Excelente";
      this.textSuccess = "El pago fue exitoso";
      this.textLoginSuccess = "El pago fue logueado con éxito";
      this.failText = "Algo salió mal, por favot intente después.";
    }
  }

  ngOnChanges() {
    this.openTerminal ? this.openVirtualModal() : null;
  }

  ngAfterViewInit() {
    // this.printButton.nativeElement.focus();
  }

  cardKeyIsPressed(e) {
    if (e.shiftKey) {
      return e.which == 9;
    }
    if ((e.which > 57 && e.which < 95) || e.which > 105) {
      return false;
    }
    return e.which != 32;
  }

  formatCC() {
    let number = "";
    let displayNumber = "";
    // let formattedNumber;
    $("#ccNumber").val(function (index, value) {
      number = value;
      // formattedNumber = value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
      if (
        number.length >= UsioConstants.CARD_NUMBER.MIN &&
        number.length <= UsioConstants.CARD_NUMBER.MAX
      ) {
        for (let i = 0; i < number.length - 4; i++) {
          displayNumber += "•";
        }
        displayNumber += number.substring(number.length - 4);
      }
      return displayNumber;
    });
    this.creditCardNumber = number;
  }

  cleanCCNumber() {
    console.log("CLEAN ");
    const number = this.creditCardNumber;
    $("#ccNumber").val(function (index, value) {
      return value ? number : "";
    });
  }

  postPayment() {
    this.loading = true;
    this.finalAmount = this.feeCalculator.moneyRound(
      this.totalAmount + this.totalFees + this.IPAYFee
    ); // FINALAMOUNT USED TO DISABLE SUBMIT BUTTON
    let mainAccount = JSON.parse(JSON.stringify(this.accounts[0]));
    const full_name = this.name;
    let first_name = full_name.split(" ")[0];
    let last_name =
      full_name.indexOf(" ") == -1
        ? first_name
        : full_name.slice(full_name.indexOf(" "), full_name.length);

    if (/\d/.test(first_name)) {
      first_name = last_name = first_name + " " + last_name;
    }
    console.log(mainAccount.chosenCode);

    console.log("POST ", this.street_address);
    console.log("POST 1", this.defaultAddress.street_address);
    let paymentInfo = {
      account_id: mainAccount.account_id.toString(),
      amount: this.regulateStringLength(
        this.totalAmount.toString(),
        UsioConstants.AMOUNT.MIN,
        UsioConstants.AMOUNT.MAX
      ), //changed to totalAmount
      total_fees: this.regulateStringLength(
        this.totalFees.toFixed(2),
        UsioConstants.CONV_FEE.MIN,
        UsioConstants.CONV_FEE.MAX
      ), //changed to totalFees
      ccNum: this.regulateStringLength(
        this.creditCardNumber,
        UsioConstants.CARD_NUMBER.MIN,
        UsioConstants.CARD_NUMBER.MAX
      ),
      payment_type: this.regulateStringLength(
        this.acceptedCards[this.cardType.toString()] || "other",
        UsioConstants.CARD_TYPE.MIN,
        UsioConstants.CARD_TYPE.MAX
      ),
      exp_date: this.regulateStringLength(
        this.exp_month.value + "20" + this.exp_year.value,
        UsioConstants.EXP_DATE.MIN,
        UsioConstants.EXP_DATE.MAX
      ),
      user_id: this.user.id.toString(),
      user_first_name: this.regulateStringLength(
        first_name,
        UsioConstants.FIRST_NAME.MIN,
        UsioConstants.FIRST_NAME.MAX
      ),
      user_last_name: this.regulateStringLength(
        last_name,
        UsioConstants.LAST_NAME.MIN,
        UsioConstants.LAST_NAME.MAX
      ),
      user_email: this.regulateStringLength(
        this.email || globals.default.replacementUserEmail,
        UsioConstants.EMAIL.MIN,
        UsioConstants.EMAIL.MAX
      ),
      //service_address: this.regulateStringLength(this.street_address ? this.defaultAddress.street_address : this.street_address, UsioConstants.ADDRESS.MIN, UsioConstants.ADDRESS.MAX),
      service_address: this.regulateStringLength(
        this.street_address,
        UsioConstants.ADDRESS.MIN,
        UsioConstants.ADDRESS.MAX
      ),
      city: this.regulateStringLength(
        this.city || this.defaultAddress.city,
        UsioConstants.CITY.MIN,
        UsioConstants.CITY.MAX
      ),
      state: this.regulateStringLength(
        this.state || this.defaultAddress.state,
        UsioConstants.STATE.MIN,
        UsioConstants.STATE.MAX
      ),
      zip_code: this.regulateStringLength(
        this.zip_code || this.defaultAddress.zip_code,
        UsioConstants.ZIP_CODE.MIN,
        UsioConstants.ZIP_CODE.MAX
      ),
      security_code: this.regulateStringLength(
        this.security_code.value || "",
        UsioConstants.SECURITY_CODE.MIN,
        UsioConstants.SECURITY_CODE.MAX
      ),
      notes: this.regulateStringLength(
        mainAccount.account_number
          ? mainAccount.account_number
          : mainAccount.account_id,
        UsioConstants.NOTES_1.MIN,
        UsioConstants.NOTES_1.MAX
      ),
      terminal_id: this.regulateStringLength(
        "",
        UsioConstants.TERMINAL_ID.MIN,
        UsioConstants.TERMINAL_ID.MAX
      ),
      code: mainAccount.chosenCode,
      cashier_id: this.logged_in_user.id,
      payments: [],
    };

    console.log("PAYMENT INFO : ", paymentInfo);

    if (this.paymentMethod == "cash" || this.paymentMethod == "check") {
      const logInfo = {
        user_id: paymentInfo.user_id,
        user_email: paymentInfo.user_email,
        account_id: paymentInfo.account_id,
        amount: this.totalAmount, // should be amount without fees
        total_fees: this.totalFees,
        payment_source: this.paymentMethod,
        source_type: this.paymentMethod,
      };
      this.logCashOrCheckPayment(logInfo);
      return;
    }

    if (this.isPhysicalTerminal) {
      delete paymentInfo.security_code;
      delete paymentInfo.payment_type;
      delete paymentInfo.ccNum;
      delete paymentInfo.exp_date;
      paymentInfo.terminal_id = this.chosenTerminal.terminal_id;
      // paymentInfo.code = this.chosenCode.code;
      paymentInfo["payment_source"] =
        "Terminal " +
        this.chosenTerminal.terminal_id +
        ": " +
        this.chosenTerminal.name_given;
      paymentInfo["source_type"] = "terminal";
      console.log(this.accounts.length);
      if (this.accounts && this.accounts.length > 1) {
        delete paymentInfo.account_id;
        delete paymentInfo.amount;
        delete paymentInfo.total_fees;
        delete paymentInfo.user_id;
        delete paymentInfo.code;
        let tmpAccounts = [];

        for (var i = 0; i < this.accounts.length; i++) {
          tmpAccounts.push({
            account_id: String(),
            amount: Number(),
            total_fees: Number(),
            user_id: String(),
            code: Number(),
          });

          for (const [key, value] of Object.entries(this.accounts[i])) {
            if (key == "account_id") {
              tmpAccounts[i].account_id = value;
            } else if (key == "amount") {
              tmpAccounts[i].amount = value;
            } else if (key == "total_fees") {
              tmpAccounts[i].total_fees = value;
            } else if (key == "user_id") {
              tmpAccounts[i].user_id = value;
            } else if (key == "chosenCode") {
              tmpAccounts[i].code = value;
            }
          }
        }

        for (var i = 0; i < this.accounts.length; i++) {
          for (const [key, value] of Object.entries(this.accounts[i])) {
            if (key == "amount" && value <= 0) {
              this.accounts.splice(i, 1);
              tmpAccounts.splice(i, 1);
              continue;
            }
          }
        }
        paymentInfo.payments = tmpAccounts;
        this.makeMultiplePhysicalVirtualPayment(paymentInfo);
      } else {
        delete paymentInfo.payments;
        this.makePhysicalTerminalPayment(paymentInfo);
      }
    } else {
      delete paymentInfo.terminal_id;
      paymentInfo["payment_source"] = "Virtual Terminal";
      paymentInfo["source_type"] = "terminal";
      paymentInfo["payment_method"] =
        this.paymentMethod.toLowerCase() == "virtual terminal"
          ? "cc"
          : this.paymentMethod.toLowerCase();
      paymentInfo["routing_number"] = this.regulateStringLength(
        this.achFormGroup.get("routingNumber").value,
        UsioConstants.ROUTING_NUMBER.MIN,
        UsioConstants.ROUTING_NUMBER.MAX
      );
      paymentInfo["account_number"] = this.regulateStringLength(
        this.achFormGroup.get("accountNumber").value,
        UsioConstants.ACCOUNT_NUMBER.MIN,
        UsioConstants.ACCOUNT_NUMBER.MAX
      );
      paymentInfo["ach_source"] = this.achSource;
      if (this.accounts && this.accounts.length > 1) {
        delete paymentInfo.account_id;
        delete paymentInfo.amount;
        delete paymentInfo.total_fees;
        delete paymentInfo.user_id;
        delete paymentInfo.code;
        let tmpAccounts = [];

        for (var i = 0; i < this.accounts.length; i++) {
          tmpAccounts.push({
            account_id: String(),
            amount: Number(),
            total_fees: Number(),
            user_id: String(),
            user_email: String(),
            code: Number(),
          });
          for (const [key, value] of Object.entries(this.accounts[i])) {
            if (key == "account_id") {
              tmpAccounts[i].account_id = value;
            } else if (key == "amount") {
              tmpAccounts[i].amount = value;
            } else if (key == "total_fees") {
              tmpAccounts[i].total_fees = value;
            } else if (key == "user_id") {
              tmpAccounts[i].user_id = value;
            } else if (key == "user_email") {
              tmpAccounts[i].user_email = value;
            } else if (key == "chosenCode") {
              tmpAccounts[i].code = value;
            }
          }
        }
        for (var i = 0; i < this.accounts.length; i++) {
          for (const [key, value] of Object.entries(this.accounts[i])) {
            if (key == "amount" && value <= 0) {
              this.accounts.splice(i, 1);
              tmpAccounts.splice(i, 1);
              continue;
            }
          }
        }

        paymentInfo.payments = tmpAccounts;
        this.makeMultipleVirtualTerminalPayment(paymentInfo);
      } else {
        delete paymentInfo.payments;
        this.makeVirtualTerminalPayment(paymentInfo);
      }
    }
  }

  logCashOrCheckPayment(logInfo) {
    // console.log("LOGINFO : ", logInfo);

    this.paymentService.logCashOrCheckPayment(logInfo).subscribe(
      (results) => {
        // console.log('RESULTS : ', results);
        this.click();
        this.loading = false;
        this.successAlertSwal.title = this.titleSuccess;
        this.successAlertSwal.text = this.textLoginSuccess;
        this.successAlertSwal.show();
        this.closeVirtualModal();
        this.resetTerminalForm();
        this.refreshParent();
      },
      (error) => {
        console.log("Error logging in cash/check payment: ", error);
        this.loading = false;
        if (error.message.isJoi) {
          this.errorAlertSwal.text = error.message.details[0].message;
        } else if (error.message) {
          this.errorAlertSwal.text = error.message;
        } else {
          this.errorAlertSwal.text = this.failText;
        }
        this.errorAlertSwal.show();
      }
    );
  }

  private makeVirtualTerminalPayment(paymentData: any) {
    this.customerService
      .makeCustomerPaymentVirtualTerminal(paymentData)
      .subscribe(
        (results) => {
          // console.log('MAKE PAYMENT RESULTS: ', results);
          this.click();
          this.paymentId = results.id;
          this.loading = false;
          this.successAlertSwal.title = this.titleSuccess;
          this.successAlertSwal.text = this.textSuccess;
          this.successAlertSwal.show();
          this.closeVirtualModal();
          this.resetTerminalForm();
          this.refreshParent();
        },
        (error) => {
          console.log("MAKE PAYMENT ERROR: ", error);
          this.loading = false;
          if (error.message && error.message.isJoi) {
            this.errorAlertSwal.text = error.message.details[0].message;
          } else if (error.message) {
            this.errorAlertSwal.text = error.message;
          } else {
            this.errorAlertSwal.text = this.failText;
          }
          this.errorAlertSwal.show();
        }
      );
  }

  private makePhysicalTerminalPayment(paymentData: any) {
    console.log("MAKEPHYSICAL TEMRIANL : ", paymentData);
    this.customerService
      .makeCustomerPaymentPhysicalTerminal(paymentData)
      .subscribe(
        (result) => {
          console.log("RESULTS : ", result);
          this.click();
          this.paymentId = result.id;
          this.loading = false;
          this.successAlertSwal.title = this.titleSuccess;
          this.successAlertSwal.text = this.textSuccess;
          this.successAlertSwal.show();
          this.closeVirtualModal();
          this.resetTerminalForm();
          this.refreshParent();
        },
        (error) => {
          console.log("Error : ", error);
          this.loading = false;
          if (error.message.isJoi) {
            this.errorAlertSwal.text = error.message.details[0].message;
          } else if (error.message) {
            this.errorAlertSwal.text = error.message;
          } else {
            this.errorAlertSwal.text = this.failText;
          }
          this.errorAlertSwal.show();
        }
      );
  }

  makeMultipleVirtualTerminalPayment(paymentInfo) {
    this.customerService
      .makeMultiplePaymentsVirtualTerminal(paymentInfo)
      .subscribe(
        (results) => {
          console.log("RESULTS : ", results);
          this.click();
          this.loading = false;
          this.paymentId = results[0][0].id;
          this.loading = false;
          this.successAlertSwal.title = this.titleSuccess;
          this.successAlertSwal.text = this.textSuccess;
          this.successAlertSwal.show();
          this.closeVirtualModal();
          this.resetTerminalForm();
          this.refreshParent();
        },
        (error) => {
          console.log("Error : ", error);
          this.loading = false;
          if (error.message.isJoi) {
            this.errorAlertSwal.text = error.message.details[0].message;
          } else if (
            error.message.message.includes(
              '"Card Number" must be a credit card'
            )
          ) {
            this.errorAlertSwal.text = "Card number must be valid";
            if (window.location.href.includes("/es/")) {
              this.errorAlertSwal.text = "Número de Tarjeta debe ser válida";
            }
          } else if (error.message) {
            this.errorAlertSwal.text = error.message;
          } else {
            this.errorAlertSwal.text = this.failText;
          }
          this.errorAlertSwal.show();
        }
      );
  }

  makeMultiplePhysicalVirtualPayment(paymentInfo) {
    this.customerService
      .makeMultiplePaymentsPhysicalTerminal(paymentInfo)
      .subscribe(
        (results) => {
          console.log("RESULTS : ", results);
          this.click();
          this.paymentId = results[0][0].id;
          this.loading = false;
          this.successAlertSwal.title = this.titleSuccess;
          this.successAlertSwal.text = this.textSuccess;
          this.successAlertSwal.show();
          this.closeVirtualModal();
          this.resetTerminalForm();
          this.refreshParent();
        },
        (error) => {
          console.log("Error : ", error);
          this.loading = false;
          if (error.message.isJoi) {
            this.errorAlertSwal.text = error.message.details[0].message;
          } else if (error.message) {
            this.errorAlertSwal.text = error.message;
          } else {
            this.errorAlertSwal.text = this.failText;
          }
          this.errorAlertSwal.show();
        }
      );
  }

  goToReceipt(payment_id: string) {
    this.router.navigate([`/payments/${payment_id}/receipt`]);
  }

  getFilteredAcconts(account_number: string) {
    // console.log("ACCOUNTNUMBER: ", account_number);
  }

  resetTerminalForm() {
    // console.log("RESSETING FORM GROUP")
    this.chosenTerminal = this.terminals[0];
    this.isPhysicalTerminal =
      this.terminals[0].terminal_id == null ? false : true;
    this.paymentMethod = this.terminals[0].name_given.toLowerCase();
    // this.amount = this.selectedAccount.balance;
    this.ccFormGroup.reset();
    this.accounts = [];
  }

  closeVirtualModal() {
    this.bsModalRef.hide();
  }

  openVirtualModal() {
    console.log(this.selectedAccount.service_address.street_address);
    console.log("IN OPENVIRTUALMODAL ");
    console.log("ACCOUNTS : ", this.selectedAccount);
    console.log(this.user);
    this.IPAYFee =
      this.paymentMethod == "cash" || this.paymentMethod == "check"
        ? 0.0
        : this.feeCalculator.getIPayFeeDelta(
            this.selectedAccount.balance,
            "terminal"
          )["fee"];
    this.accounts.push(
      // if fee is not calculated off of balance instead of amount on modal open, will not properly calculate fee
      {
        account_number: this.selectedAccount.incode_account_no,
        balance: this.selectedAccount.balance,
        amount: this.selectedAccount.balance,
        total_fees:
          this.paymentMethod == "cash" || this.paymentMethod == "check"
            ? 0.0
            : this.feeCalculator.getUSIOFee(
                this.selectedAccount.balance,
                "terminal"
              )["fee"],
        user_id: this.selectedAccount.user_id.toString(),
        account_id: this.selectedAccount.id.toString(),
        user_email: this.selectedAccount.user_email,
        full_address:
          this.selectedAccount.service_address
            .street_address /*+ ", " + this.selectedAccount.service_address.city + ", " + this.selectedAccount.service_address.state*/,
        chosenCode: 1,
      }
    );
    this.totalFeesWithIPay = this.feeCalculator.moneyRound(
      this.totalFees + this.IPAYFee
    );
    this.finalAmount = this.feeCalculator.moneyRound(
      this.totalAmount + this.totalFeesWithIPay
    ); // FINALAMOUNT USED TO DISABLE SUBMIT BUTTON

    this.billingFormGroup.setValue({
      street_address:
        this.selectedAccount.service_address.street_address ||
        this.defaultAddress.street_address,
      city:
        this.selectedAccount.service_address.city || this.defaultAddress.city,
      state:
        this.selectedAccount.service_address.state || this.defaultAddress.state,
      zip_code:
        this.selectedAccount.service_address.zip_code ||
        this.defaultAddress.zip_code,
      name: this.user.user_info.first_name,
      email: this.user.email || globals.default.replacementUserEmail,
    });

    this.totalFees = this.calculateTotalFees();
    this.totalAmount = this.calculateTotalAmounts();
    this.maxPaymentAmount = this.amount + UsioConstants.PAY_LIMIT;

    this.bsModalRef = this.modalService.show(this.terminalTemplate, {
      backdrop: "static",
      keyboard: false,
    });
  }

  updateFees(index: number = null, amount: number = null) {
    console.log("UPDATING FEES ");
    if (index != null) {
      this.accounts[index].total_fees =
        this.paymentMethod == "cash" || this.paymentMethod == "check"
          ? 0.0
          : this.feeCalculator.getUSIOFee(amount, "terminal")["fee"];
    }
    this.totalFees = this.calculateTotalFees();
    this.totalAmount = this.calculateTotalAmounts();
    this.IPAYFee =
      this.paymentMethod == "cash" || this.paymentMethod == "check"
        ? 0.0
        : this.feeCalculator.getIPayFeeDelta(this.totalAmount, "terminal")[
            "fee"
          ];
    this.totalFeesWithIPay = this.feeCalculator.moneyRound(
      this.totalFees + this.IPAYFee
    );
    this.finalAmount = this.feeCalculator.moneyRound(
      this.totalAmount + this.totalFees + this.IPAYFee
    );
  }

  setTerminal() {
    this.chosenTerminal.terminal_id == null
      ? (this.isPhysicalTerminal = false)
      : (this.isPhysicalTerminal = true);
    this.paymentMethod = this.chosenTerminal.name_given.toLowerCase();
    console.log("FINAL AMOUNT IN SETTERMIANL: ", this.finalAmount);
  }

  setCode(i) {
    // this.accounts[i].chosenCode.code== null ? (this.isPhysicalTerminal = false) : (this.isPhysicalTerminal = true);
    //this.paymentMethod = this.chosenTerminal.name_given.toLowerCase();
    console.log("FINAL Code: ", this.accounts[i].chosenCode);
  }
  //CALCULATING THE TOTAL FEES
  calculateTotalFees() {
    return this.accounts.reduce((total, curr_value) => {
      return (total += curr_value.total_fees);
    }, 0.0);
  }

  calculateTotalAmounts() {
    // sums amount from all accounts
    console.log("accounts", this.accounts);
    return this.accounts.reduce((total, curr_value) => {
      return (total += curr_value.amount);
    }, 0.0);
  }

  validateAccount(index: number, incodeAccountNumber: string = null) {
    let accountNumber =
      incodeAccountNumber == null
        ? this.accounts[index].account_number
        : incodeAccountNumber;
    console.log("INDEX: ", index);
    console.log("selectedAddressNumber: ", accountNumber);

    this.accountService
      .getAccountByIncodeAccountNumber(accountNumber)
      .subscribe(
        (results) => {
          console.log("RESULTS : ", results);
          this.accounts[index].balance = parseFloat(
            results[0].balance.replace(/,/g, "")
          );
          this.accounts[index].amount = parseFloat(
            results[0].balance.replace(/,/g, "")
          );
          this.accounts[index].account_id = results[0].id.toString();
          this.accounts[index].user_id = results[0].user_id.toString();
          this.accounts[index].user_email =
            results[0].email || globals.default.replacementUserEmail;
          // this.accounts[index].full_address = results[0].service_address.street_address + ", " + results[0].service_address.city + ", " + results[0].service_address.state;
          this.accounts[index].full_address =
            this.selectedAccount.service_address.street_address +
            ", " +
            this.selectedAccount.service_address.city +
            ", " +
            this.selectedAccount.service_address.state;
          this.accounts[index].account_number = results[0].incode_account_no;
          if (index === 0) {
            this.billingFormGroup.setValue({
              street_address:
                this.selectedAccount.service_address.street_address ||
                this.defaultAddress.street_address,
              city:
                this.selectedAccount.service_address.city ||
                this.defaultAddress.city,
              state:
                this.selectedAccount.service_address.state ||
                this.defaultAddress.state,
              zip_code:
                this.selectedAccount.service_address.zip_code ||
                this.defaultAddress.zip_code,
              name: this.user.user_info.first_name,
              email: this.user.email || globals.default.replacementUserEmail,
            });
          }
          console.log("ACCOUNTS AFTER : ", this.accounts);
          this.updateFees(index, results[0].balance);
        },
        (error) => {
          console.log("ERROR : ", error);
          this.accounts[index].balance = -1;
          this.accounts[index].amount = -1;
        }
      );
  }

  addRowFromAddress(selectedAddressNumber: string) {
    console.log("selectedAddressNumber: ", selectedAddressNumber);
    this.addAccountRow();
    this.validateAccount(this.accounts.length - 1, selectedAddressNumber);
  }

  openConfirmPaymentSwal() {
    this.totalFees = this.calculateTotalFees();
    this.totalAmount = this.calculateTotalAmounts();
    this.IPAYFee =
      this.paymentMethod == "cash" || this.paymentMethod == "check"
        ? 0.0
        : this.feeCalculator.getIPayFeeDelta(this.totalAmount, "terminal")[
            "fee"
          ];
    this.totalFeesWithIPay = this.feeCalculator.moneyRound(
      this.feeCalculator.getUSIOFee(this.totalAmount, "terminal")["fee"] +
        this.IPAYFee
    );
    this.finalAmount = this.feeCalculator.moneyRound(
      this.totalFeesWithIPay + this.totalAmount
    ); // finalAmount ONLY USED TO DISPLAY GRAND TOTAL PAYMENT (DOES NOT DISPLAY AMOUNT CALCULATED AFTER IPAY FEES)
    this.cleanCCNumber();
    console.log("FINAL AMOUNT IN COFNRM: ", this.finalAmount);
    if (this.paymentMethod == "virtual terminal") {
      $("#ccNumber").validateCreditCard((result) => {
        console.log("RESULT : ", result);
        this.cardType = result.card_type.name || "CC";
        this.confirmPaymentSwal.text = `Submit payment for a total of ${this.CP.transform(
          this.finalAmount
        )}?`;
        if (window.location.href.includes("/es/")) {
          this.confirmPaymentSwal.text = `Pago enviado por un total de ${this.CP.transform(
            this.finalAmount
          )}?`;
        }
        this.confirmPaymentSwal.show();
      });
    } else {
      this.confirmPaymentSwal.text = `Submit payment for a total of ${this.CP.transform(
        this.finalAmount
      )}?`;
      if (window.location.href.includes("/es/")) {
        this.confirmPaymentSwal.text = `Pago enviado por un total de ${this.CP.transform(
          this.finalAmount
        )}?`;
      }
      this.confirmPaymentSwal.show();
    }
    this.formatCC();
  }

  regulateStringLength(value: string, min_length: number, max_length: number) {
    if (value.trim().length < min_length) {
      return "";
    } else if (
      value.trim().length <= max_length &&
      value.trim().length >= min_length
    ) {
      return value.trim();
    } else if (value.trim().length > max_length) {
      return value.trim().substring(0, max_length);
    }
  }

  refreshParent() {
    this.refreshParentData.emit(true);
  }

  getTerminalsInfo() {
    this.terminalService.getTerminalsInfo().subscribe(
      (results) => {
        this.terminals = results;
        if (this.isIpad)
          this.chosenTerminal = this.terminals.find(
            (t) => t.name_given == "Front Desk"
          );
        else this.chosenTerminal = this.terminals[0];
        this.isPhysicalTerminal =
          this.terminals[0].terminal_id == null ? false : true;
        this.paymentMethod = this.chosenTerminal.name_given.toLowerCase();
        console.log("ISPHYSCIAL : ", this.isPhysicalTerminal);
        console.log("METHOD: ", this.paymentMethod);
      },
      (error) => {
        // console.log("Error getting terminals: ", error);
      }
    );
  }

  addAccountRow() {
    this.accounts.push({
      account_number: "",
      balance: 0.0,
      amount: 0.0,
      total_fees: 0.0,
      chosenCode: 1,
    });
  }

  removeAccountRow(index: number) {
    this.accounts.splice(index, 1);
    this.updateFees();
    // this.billingFormGroup.setValue({
    //   street_address : this.accounts[0].service_address.street_address,
    //   city : this.selectedAccount.service_address.city,
    //   state : this.selectedAccount.service_address.state,
    //   zip_code : this.selectedAccount.service_address.zip_code,
    //   name : this.user.user_info.first_name,
    //   email : this.user.email || globals.default.replacementUserEmail,
    // })
  }

  trackByIndex(index: number, obj: any) {
    return index;
  }

  getFilteredAddress(term: any) {
    // USES any DUE TO type 'never' ISSUE WHEN SETTING term = term.term
    if (typeof term !== "string") term = term.term;
    console.log("Term: ", term);
    this.addressService.getFilteredAddress(term).subscribe(
      (response) => {
        console.log("Response: ", response);
        this.filteredAddresses = response.body;
        console.log("FILTERED ADDRSSES: ", this.filteredAddresses);
      },
      (error) => {
        console.log("Error getting filtered address: ", error);
      }
    );
  }

  isDeviceIPad() {
    return (
      navigator &&
      navigator.userAgent &&
      navigator.maxTouchPoints &&
      navigator.platform &&
      ((navigator.userAgent.match(/Mac/) !== null &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints >= 1) || // iPad Pro (5 maxTouchPoints, use 2 to check)
        navigator.platform.match(/iPad/i) !== null)
    ); // iPad
  }

  setMonth(month: any) {
    this.ccFormGroup.patchValue({ exp_month: month.value });
    console.log(this.exp_month.value);
  }

  // changePayMethod(method) {
  //   this.payMethod = method;
  // }

  // changeAchSource(source) {
  //   this.achSource = source;
  // }

  // disableSubmitButton() {
  //   switch (this.payMethod) {
  //     case 'ach':
  //       return !this.achFormGroup.valid || this.finalAmount <= 0.00 || this.amount >  this.maxPaymentAmount
  //     case 'cc':
  //       return !this.ccFormGroup.valid || this.finalAmount <= 0.00 || this.amount >  this.maxPaymentAmount
  //   }
  // }
}
