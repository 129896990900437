import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { first } from "rxjs/operators";
import { SwalComponent } from "@toverux/ngx-sweetalert2";

import { UserService, StatusService, CryptoService } from "../../_services";
import { User, UserSettings, Account, AccountStatus } from "../../_models";
import { Router, ActivatedRoute } from "@angular/router";
import { ObservableMedia } from "@angular/flex-layout";

declare var $: any;

@Component({
  selector: "app-user-details-panel",
  templateUrl: "./user-details-panel.component.html",
  styleUrls: ["./user-details-panel.component.css"],
})
export class UserDetailsPanelComponent implements OnInit, OnChanges {
  @ViewChild("createSuccess") private createSuccessSwal: SwalComponent;
  @ViewChild("deleteSuccess") private deleteUserSuccess: SwalComponent;
  @ViewChild("deleteUserSwal") private deleteUserSwal: SwalComponent;
  @ViewChild("deleteUserFailed") private deleteUserFailedSwal: SwalComponent;
  @ViewChild("1") private tabUserDetails: ElementRef;
  @ViewChild("2") private tabAccountDetails: ElementRef;

  @Output() refreshParentData = new EventEmitter();
  @Input("user") user;
  @Input("openTerminal") openTerminal;
  public userSettings: UserSettings;
  public selectedAccount: Account;
  public loading: boolean = false;
  public errorMessage = String();

  public accountStatuses: AccountStatus[];

  pwExpirationDate = "08/30/2018";
  pwIsExpired: boolean = true;
  initialMobile: boolean = false;
  public user_id: string;
  public logged_in_user;
  public userDetails = "User Details";
  public accountDetails = "Account Details";
  public deleteFailed = "Something went wrong, please try again later";

  constructor(
    private userService: UserService,
    private statusService: StatusService,
    private route: ActivatedRoute,
    private router: Router,
    public media: ObservableMedia,
    private cryptoService: CryptoService
  ) {}

  ngOnInit() {
    this.logged_in_user = JSON.parse(window.localStorage.current_user);

    if (this.router.url !== "/users") {
      this.user_id = this.route.snapshot.params.id;
      console.log("da user id", this.user_id);
    }
    if (this.media.isActive("xs")) {
      this.initialMobile = true;
    }

    this.userService.reload.subscribe((reload) => {
      if (reload && this.router.url == "/users" && this.user) {
        this.loading = true;
        console.log("reloading");
        this.reload(this.user.id);
      }
    });

    if (window.location.href.includes("/es/")) {
      this.userDetails = "Detalles de Usuario";
      this.accountDetails = "Detalles de Cuenta";
      this.deleteFailed = "Algo salió mal, por favor intente después.";
    }
  }

  ngAfterViewInit() {
    // Add slimscroll to element
    console.log("USER : ", this.user);
    if (!this.user && this.router.url !== "/users") {
      this.userService.getById(this.user_id).subscribe((data) => {
        this.user = data;
        console.log("da user ", this.user);
        this.getInfo();
      });
    }
    $(".full-height-scroll").slimscroll({ height: "100%" });
  }

  ngOnChanges() {
    console.log("USER ", this.user);
    if (this.user && this.router.url == "/users") {
      this.selectedAccount = this.user.accounts[0];
      this.selectedAccount.user_email = this.user.email;
      this.userService
        .getSettingsById(this.user.id)
        .subscribe((data: UserSettings) => {
          this.userSettings = data;
        });
    }

    this.statusService
      .getAccountStatus()
      .subscribe((data) => (this.accountStatuses = data));
  }

  showDeleteUserSwal() {
    this.deleteUserSwal.show();
  }

  deleteUser() {
    const payload = {
      user_id: this.logged_in_user.id,
      user_to_delete_id: this.user.id,
      reason: $.trim($("#deleteUserReason").val()),
    };

    this.errorMessage = String();
    this.userService.deleteUser(payload).subscribe(
      (response) => {
        this.deleteUserSuccess.show();
      },
      (error) => {
        if (error.message && error.message.isJoi) {
          this.deleteUserFailedSwal.title =
            error.message.details[0].message || this.deleteFailed;
          this.deleteUserFailedSwal.show();
        } else {
          this.deleteUserFailedSwal.title = this.deleteFailed;
          this.deleteUserFailedSwal.show();
        }
      }
    );
  }

  refreshParent() {
    this.refreshParentData.emit(true);
  }

  totalUserBalance() {
    if (this.user && this.user.user_type_id == 3) {
      let sum = 0.0;
      this.user.accounts.forEach((x) => (sum += x.balance));
      return sum;
    }
  }

  getInfo() {
    if (this.user) {
      console.log("THIS USER : ", this.user);
      this.selectedAccount = this.user.accounts[0];
      this.selectedAccount.user_email = this.user.email;
      this.userService
        .getSettingsById(this.user_id)
        .subscribe((data: UserSettings) => {
          this.userSettings = data;
        });
      this.statusService.getAccountStatus().subscribe((data) => {
        this.accountStatuses = data;
        this.loading = false;
      });
    }
  }

  reload(id) {
    this.userService.getById(id).subscribe((data) => {
      this.user = data;
      console.log("da user ", this.user);
      this.getInfo();
    });
  }

  saveSettings() {
    const userSettings = {
      autopay: this.userSettings.autopay,
      save_payment_method: this.userSettings.save_payment_method,
      email_notifications: this.userSettings.email_notifications,
    };

    this.userService
      .updateSettingsById(this.user.id, userSettings)
      .subscribe((data) => {
        this.createSuccessSwal.show();
      });
  }

  getAccountStatusName(status_id) {
    let status = [];
    if (this.accountStatuses) {
      status = this.accountStatuses.filter(
        (accountStatuses) => accountStatuses.id === status_id
      );
    }

    return status.length > 0 ? status[0].name : "N/A";
  }

  setClass(status_id) {
    return {
      "label-info": status_id == 1, //PENDING APPROVAL
      "label-primary": status_id == 2, //PENDING DEPOSIT
      "label-success": status_id == 3, //ACTIVE
      "label-warning": status_id == 4, //PAUSED
      "label-danger": status_id == 5, //DISCONNECTED
    };
  }
}
