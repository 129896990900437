import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from "@angular/material";
import * as globals from "../globals.json";
import { ViewAditTrailComponent } from "../_common/view-adit-trail/view-adit-trail.component";
import { AuditTrailService } from "../_services/audit-trail.service";
import { LocationModalComponent } from "../_common/location-modal/location-modal.component";
import * as moment from "moment";

export interface UserData {
  userId: string;
  userName: string;
  dateTime: string;
  action: string;
}

@Component({
  selector: "app-secury-audit-trail",
  templateUrl: "./secury-audit-trail.component.html",
  styleUrls: ["./secury-audit-trail.component.css"],
})
export class SecuryAuditTrailComponent implements OnInit {
  displayedColumns: string[] = [
    "user_id",
    "first_name",
    "ip",
    "event_name",
    "event_name_sub",
    "date_created",
    "location",
    "coordinates",
  ];
  dataSource = new MatTableDataSource<UserData>();
  public paginationTotalitem = 0;
  searchBoxPlaceHolder: string = "Search";
  filters: any = {
    search: "",
    limit: 10,
    page: 1,
    date_start: "",
    date_end: "",
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public globals: any = globals.default;
  users: string[] = ["username1", "username2", "username3"];
  actions: string[] = ["Login", "Failed login", "User updated a dashboard"];
  searchText = "";
  constructor(
    private auditTrailService: AuditTrailService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.filters.date_start = moment()
      .subtract(2, "days")
      .format("YYYY-MM-DD HH:mm:ss");
    this.filters.date_end = moment()
      .add(4, "days")
      .format("YYYY-MM-DD HH:mm:ss");
    this.getServiceTrail(this.filters);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getServiceTrail(filters) {
    this.auditTrailService.getAuditTrail(filters).subscribe((trail) => {
      this.dataSource = trail.body.events as any;
      this.dataSource = new MatTableDataSource(trail.body.events as any);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.paginationTotalitem = trail.body.total;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setSearchFilter(value) {
    this.filters.search = value.length ? value : "";
  }

  clearSearchText() {
    this.searchText = "";
    this.search();
  }

  submitFilters(filters) {
    this.getServiceTrail(filters);
  }

  search() {
    this.submitFilters(this.filters);
  }

  pageChanged(event: any) {
    this.filters.page = event.page;
    this.search();
  }

  setDateRange(event) {
    this.filters.date_start = moment(event.from).format("YYYY-MM-DD HH:mm:ss");
    this.filters.date_end = moment(event.to).format("YYYY-MM-DD HH:mm:ss");
  }

  closeSidebar() {}

  viewTimeLine(data) {
    const dialogRef = this.dialog.open(ViewAditTrailComponent, {
      height: "800px",
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openLocationModal(location: any): void {
    if (location.location) {
      const dialogRef = this.dialog.open(LocationModalComponent, {
        width: "500px",
        data: location,
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log("The dialog was closed");
      });
    } else {
      return;
    }
  }
}
