import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { saveAs } from "file-saver";
import * as moment from "moment";
declare var $: any;

import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { CryptoService, PaymentsService } from "src/app/_services";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { format } from "url";
import { User } from "../../_models";
@Component({
  selector: "app-admin-report",
  templateUrl: "./admin-report.component.html",
  styleUrls: ["./admin-report.component.css"],
})
export class AdminReportComponent implements OnInit {
  @ViewChild("report") private reportSwal: SwalComponent;
  @ViewChild("failure") private failureSwal: SwalComponent;
  @ViewChild("success") private successSwal: SwalComponent;

  private month = moment().format("MM");
  private year = moment().format("YYYY");
  public titleAlert: string = "Incode Payment Report";
  public confirmButtonTextAlert: string = "Download";
  public cancelButtonTextAlert: string = "Cancel";
  public failTextAlert: string = "Something went wrong, we are very sorry";
  public successTextAlert: string = "Downloading...";
  public bsConfig: Partial<BsDatepickerConfig>;
  public modalRef: BsModalRef;
  public reportDateRange;
  public datePickerDate;
  public optionExtension: string = "";
  public isChecked: boolean = false;

  type_list: String = "Web";
  lista: string[] = ["Web", "Terminal", "BOTH"];

  user: User;

  constructor(
    private paymentService: PaymentsService,
    private modalService: BsModalService,
    private cryptoService: CryptoService
  ) {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: "theme-dark-blue",
        rangeInputFormat: "MMMM Do YYYY, h:mm:ss a",
        dateInputFormat: "MMMM Do YYYY, h:mm:ss a",
        showWeekNumbers: false,
      }
    );
    this.reportDateRange = [moment().startOf("month").toDate()];
  }

  ngOnInit() {
    if (window.location.href.includes("/es/")) {
      this.titleAlert = "Reporte para Incode de Admin Fees";
      this.confirmButtonTextAlert = "Descargar";
      this.cancelButtonTextAlert = "Cancelar";
      this.failTextAlert = "Lo sentimos, ha ocurrido un error";
      this.successTextAlert = "Descargando...";
    }
    this.submitExtension(".txt");
    this.user = JSON.parse(window.localStorage.current_user);
  }
  submitExtension(option) {
    this.optionExtension = option;
    //console.log(this.optionExtension)
  }

  generateAdminReportRange() {
    let documentdate = moment().startOf("day").format("MMDD");

    //let startDate = moment(this.reportDateRange[0]);
    let startDate = new Date(this.datePickerDate);
    //console.log(startDate)
    // let endDate = moment(this.reportDateRange[1]);
    let filename = "";
    //, endDate.toISOString(),
    this.paymentService
      .generateAdminReport(
        startDate.toISOString(),
        this.optionExtension,
        this.type_list,
        this.user.id
      )
      .subscribe(
        (response) => {
          console.log(response);
          // let filename = `${startDate.format('MMMM DD YYYY, h:mm:ss a')} - ${endDate.format('MMMM Do YYYY, h:mm:ss a')} Incode Report.csv`
          if (this.type_list == "BOTH") {
            filename = `Inc-${documentdate}${this.optionExtension}`;
          } else if (this.type_list == "Web") {
            filename = `ic-wb-${documentdate}${this.optionExtension}`;
          } else if (this.type_list == "Terminal") {
            filename = `ic-ter-${documentdate}${this.optionExtension}`;
          }
          saveAs(response, filename);
          this.reportDateRange = [moment().startOf("month").toDate()];
          this.modalRef.hide();
          this.successSwal.show();
          this.type_list = "Web";
          this.optionExtension = ".txt";
        },
        (error) => {
          console.log("error", error);
          if (error.status == 404) {
            this.failureSwal.title = "No Payment(s) for selected date.";
            this.failureSwal.show();
          } else {
            this.failureSwal.show();
          }
        }
      );
  }
  generateAdminReportDay() {
    let documentdate = moment().startOf("day").format("MMDD");
    let startDate = moment().subtract(1, "days").format();
    //console.log(startDate)
    let endDate = moment().endOf("day").format();
    let filename = "";
    this.paymentService
      .generateAdminReport(
        startDate.toString(),
        this.optionExtension,
        this.type_list,
        this.user.id
      )
      .subscribe(
        (response) => {
          console.log(response);
          // let filename = `${startDate.format('MMMM DD YYYY, h:mm:ss a')} - ${endDate.format('MMMM Do YYYY, h:mm:ss a')} Incode Report.csv`
          if (this.type_list == "BOTH") {
            filename = `Inc-${documentdate}${this.optionExtension}`;
          } else if (this.type_list == "Web") {
            filename = `ic-wb-${documentdate}${this.optionExtension}`;
          } else if (this.type_list == "Terminal") {
            filename = `ic-ter-${documentdate}${this.optionExtension}`;
          }
          saveAs(response, filename);
          this.reportDateRange = [moment().startOf("month").toDate()];
          this.modalRef.hide();
          this.successSwal.show();
          this.type_list = "";
          this.optionExtension = ".txt";
        },
        (error) => {
          console.log("error", error);
          if (error.status == 404) {
            this.failureSwal.title = "No Payment(s) for selected date.";
            if (window.location.href.includes("/es/")) {
              this.failureSwal.title =
                "No hay Pagos para la fecha seleccionada.";
            }
            this.failureSwal.show();
          } else {
            this.failureSwal.show();
          }
        }
      );
  }
  selecteReport() {
    if (this.isChecked == true) {
      this.generateAdminReportDay();
    } else {
      this.generateAdminReportRange();
    }
  }
  getTypeSelect() {
    // Pasamos el valor seleccionado a la variable verSeleccion
    console.log(this.type_list);
    if (this.type_list != "ALL") {
      this.type_list = this.type_list;
    } else {
      this.type_list = "";
    }
  }

  openAlert() {
    this.reportSwal.show();
    $("#paymentMonth").val(this.month);
    $("#paymentYear").val(this.year);
  }

  onOpen(event) {
    $("body").removeClass("swal2-height-auto");
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        {
          class: "modal-lg gray inmodal report-modal ",
          backdrop: true,
          ignoreBackdropClick: false,
        }
      )
    );
  }
}
