import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserService } from 'src/app/_services';
import { Subject } from 'rxjs';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

declare var $: any;

@Component({
  selector: 'app-incode-review',
  templateUrl: './incode-review.component.html',
  styleUrls: ['./incode-review.component.css']
})
export class IncodeReviewComponent implements OnInit {
  public incodeAccountNo;
  public incodeData;
  public ticket;
  public loading;
  public reviewDone: Subject<boolean>;
  public error;

  constructor(public bsModalRef: BsModalRef, private userService: UserService) { }

  ngOnInit() {
    this.reviewDone = new Subject();
  }

  setReview(){
    this.loading = true;
    this.userService.getIncodeInfoById(this.incodeAccountNo)
      .subscribe(
        data => {
          this.loading = false;
          this.incodeData = {
            name: 'N/A',
            mailing_address: {
              street_address: 'N/A',
              city: 'N/A',
              state: 'N/A',
              zip_code: 'N/A'
            },
            service_address: {
              street_address: 'N/A',
              city: 'N/A',
              state: 'N/A',
              zip_code: 'N/A'
            },
            status: 'N/A'
          }

          try{
            this.incodeData.name = data.name[0]
            this.incodeData.status = data.status[0]

            this.incodeData.mailing_address.street_address = data.mailingAddress1[0]
            this.incodeData.mailing_address.city = data.mailingAddressCity[0]
            this.incodeData.mailing_address.state = data.mailingAddressState[0]
            this.incodeData.mailing_address.zip_code = data.mailingAddressZip5[0]

            this.incodeData.service_address.street_address = data.address[0].serviceAddress[0]
            this.incodeData.service_address.city = data.mailingAddressCity[0]
            this.incodeData.service_address.state = data.mailingAddressState[0]
            this.incodeData.service_address.zip_code = data.mailingAddressZip5[0]
          } catch(e){}
      },
      err => {
        console.log("error: ", err);
        if(err.message && err.message.isJoi){
          this.loading = false;
          if(err.message.details[0].message.trim().toLowerCase().includes("incode account number") && err.message.details[0].message.trim().toLowerCase().includes("pattern")){
            this.error = "Account number may only contain numbers and dashes."
            if (window.location.href.includes('/es/')) {
              this.error = "Número de cuanta solo puede contener números y guiones."
            }
          }else{
            this.error = err.message;  
          }
        }else if(err.status == 400){
          this.loading = false;
          this.error = err.message;
        }
      })

  }

  ngAfterViewInit() {
    // Add slimscroll to element
    $('.scroll').slimscroll({ height: '500px' });
  }

  finishReview(){
    this.reviewDone.next(true);
    this.bsModalRef.hide();
  }
  

}
