import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {
  transform(last_update: string): any {
    var diff_in_seconds = moment.duration(moment().diff(moment(last_update))).asSeconds();
    var difference = moment.duration(Math.abs(diff_in_seconds), 'seconds')

    var years = difference.get('y');
    var months = difference.get('M');
    var weeks = difference.get('w');
    var days = difference.get('d');
    var hours = difference.get('h');
    var minutes = difference.get('m');
    var seconds = difference.get('s');

    var time_since_update = '';

    if (years > 0) {
      years == 1 ? time_since_update += `${years} year `: time_since_update += `${years} years `
    }
    if (months > 0 && (years == 0)) {
      months == 1 ? time_since_update += `${months} month ` : time_since_update += `${months} months `
    }
    if (weeks > 0 && (years == 0 && months == 0)) {
      weeks == 1 ? time_since_update += `${weeks} week ` : time_since_update += `${weeks} weeks `
    }
    if (days > 0 && (years == 0 && months == 0 && weeks == 0)) {
      days == 1 ? time_since_update += `${days} day ` : time_since_update += `${days} days `
    }
    if (hours > 0 && (years == 0 && months == 0 && weeks == 0 && days == 0)) {
      hours == 1 ? time_since_update += `${hours} hour ` : time_since_update += `${hours} hours `
    }
    if (minutes > 0 && (years == 0 && months == 0 && weeks == 0 && days == 0)) {
      minutes == 1 ? time_since_update += `${minutes} minute ` : time_since_update += `${minutes} minutes `
    }
    if (seconds > 0 && (years == 0 && months == 0 && weeks == 0 && days == 0 && hours == 0 && minutes == 0)) {
      seconds == 1 ? time_since_update += `${seconds} second ` : time_since_update += `${seconds} seconds `
    }

    return time_since_update += `ago`;    
  }
}
