import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend }	from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsioService {

  baseUrl: string = environment.serverUrl;
  
  constructor(private http : HttpClient, private handler : HttpBackend) { 
    this.http = new HttpClient(handler);
  }


  submitCCPayment(ccInfo : any){
    console.log("CCINFO: ", ccInfo);

    return this.http.post<any>('https://api.securepds.com/2.0/payments.svc/JSON/SubmitCCPayment',ccInfo)
  }
}
