import { Component, ViewChild } from '@angular/core';
import * as globals from './globals.json';
import {environment} from '../environments/environment';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { AuthService } from 'src/app/_services';
import { Router } from '@angular/router';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public globals: any = globals.default;
  public environment: any = environment;
  @ViewChild('renewLogin') private renewLoginSwal: SwalComponent;
  @ViewChild('successfulRenewal') private successfulRenewal: SwalComponent;
  @ViewChild('loggedOut') private loggedOutSwal: SwalComponent;  
  public renewLoginTitle:string="Session Reminder";
  public renewLoginText:string="Your current work session will expire in 5 minutes, would you like to renew it?"
  public renewLoginCancelButton:string="Logout";
  public successfulRenewalTitle:string="Renewed";
  public successfulRenewalText:string="Session renewed.";
  public loggedOutTitle:string="Logged Out";
  public loggedOutText:string="You've been logged out due to inactivity.";



  constructor(private auth: AuthService, private router: Router, private titleService: Title){}

  ngOnInit(){
    this.titleService.setTitle(this.globals.onlyCityName);    
    $('#checkoutscript').attr('pds-name', this.globals.city);
    $('#checkoutscript').attr('pds-key', this.globals.PDSMerchantKey);
    $('#checkoutscript').attr('pds-image', `${this.environment.frontendUrl || this.environment.serverUrl}/assets/images/logo.png`);

    this.auth.loginEndModalOpen.subscribe(open => {
      if (!this.renewLoginSwal.nativeSwal.isVisible() && open && this.router.url.indexOf("login") == -1) {
        this.renewLoginSwal.show()
      }
      if (this.renewLoginSwal.nativeSwal.isVisible() && !open) {
        this.renewLoginSwal.nativeSwal.close();
        setTimeout(() => {
          this.loggedOutSwal.show();
        }, 100);
      }
    });

    var timer = setInterval(() => {
      // console.log(moment().format('hh:mm:ss a'))
      if (this.auth.isAuthenticated() && (this.auth.getTokenExpirationTime() - (60 * 5)) <= moment().unix()) {
        this.auth.openLoginModal(true);
      }

      if (
        (!this.auth.isAuthenticated() || (this.auth.getTokenExpirationTime() - 5) <= moment().unix()) 
        && !this.router.url.includes('/login') 
        && !this.router.url.includes('/signup/existing') 
        && !this.router.url.includes('/forgot_password') 
        && !this.router.url.includes('/homepage') 
        && !(this.router.url.includes('/signup') && !this.router.url.includes('/signup/requests'))
        && !this.router.url.includes('/reset_password')
        && !this.router.url.includes('/staff_login')
        && !this.router.url.includes('/policy')
      ){        
        this.auth.openLoginModal(false);
        this.router.navigate(['login']);
      }
    }, 1000, [this])

    if(window.location.href.includes("/es/")){
      this.renewLoginTitle="Recordatorio";
      this.renewLoginText="Tu sesion actual expirara en 5 minutos, desea renovarla?"
      this.renewLoginCancelButton="Cerrar Sesion";
      this.successfulRenewalTitle="Renovada";
      this.successfulRenewalText="Sesion Renovada.";
      this.loggedOutTitle="Sesion Cerrada";
      this.loggedOutText="Su sesion ha sido cerrada debido a inactividad.";

    }
  }


  public updateToken() {
    this.auth.refreshToken()
      .subscribe(
        result => {
          this.successfulRenewal.show()
        }
      )
  }

  public logout() {
    this.router.navigate(['login']);
  }

  public void() {}

  onOpen(event) {
    $('body').removeClass("swal2-height-auto");
  }
}
