import { Injectable , Output, EventEmitter} from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import {
    Ticket, TicketComment
} from '../_models';

@Injectable({
    providedIn: 'root'
})
export class TicketService {
    baseUrl: string = environment.serverUrl;
    @Output() ticketCreated = new EventEmitter();
    constructor(private http: HttpClient) { }

    getTicketById(ticket_id) {
        return this.http.get<Ticket>(`${this.baseUrl}/api/tickets/${ticket_id}`);
    }

    getCountSignup(){
        return this.http.get<any>(`${this.baseUrl}/api/tickets/pending-signup?`);
    }

    getCountSupport(){
        return this.http.get<any>(`${this.baseUrl}/api/tickets/pending-support?`);
    }

    getFilteredTickets(page, limit, ticketStatus, ticketType, search) {
        return this.http.get<any>(`${this.baseUrl}/api/tickets?page=${page}&limit=${limit}&ticket_status=${ticketStatus}&ticket_type=${ticketType}&search=${search}`);
    }

    updateTicket(ticket_id, ticket) {
        console.log("ticket ", ticket);
        
        return this.http.put(`${this.baseUrl}/api/tickets/${ticket_id}`, ticket);
    }

    updateTicketStatus(ticket_id, ticket) {
        return this.http.put(`${this.baseUrl}/api/tickets/${ticket_id}/status`, ticket);
    }

    getTicketComments(ticket_id) {
        return this.http.get<TicketComment[]>(`${this.baseUrl}/api/tickets/${ticket_id}/comments`);
    }

    createTicketComment(ticket_id, comment) {
        return this.http.post(`${this.baseUrl}/api/tickets/${ticket_id}/comments`, comment);
    }

    newTicketCreated(){
      this.ticketCreated.emit(true)
    }

    getMapData(beginMonth, beginYear, endMonth, endYear) {
        return this.http.get<any>(`${this.baseUrl}/api/tickets/map?beginMonth=${beginMonth}&beginYear=${beginYear}&endMonth=${endMonth}&endYear=${endYear}`);
    }
}
