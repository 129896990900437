import { Component, OnInit, Renderer2, Inject, ViewChild } from "@angular/core";
import { BillsService, StatusService, CustomerService } from "../_services";
import { User, Bills, Status } from "../_models";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import * as globals from "../globals.json";
import { MatDialog } from "@angular/material";
import { SendPaylinkComponent } from "../_common/send-paylink/send-paylink.component";
import { DateRangePickerComponent } from "ng-pick-daterange";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { environment } from "src/environments/environment";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

declare var $: any;

@Component({
  selector: "app-bills",
  templateUrl: "./bills.component.html",
  styleUrls: ["./bills.component.css"],
})
export class BillsComponent implements OnInit {
  @ViewChild("dateRange") private datePicker: DateRangePickerComponent;
  @ViewChild("dateRange") dateRangeDOM;
  @ViewChild("successReset") private successResetSwal: SwalComponent;
  @ViewChild("failureReset") private failureResetSwal: SwalComponent;
  @ViewChild("successAlertCopyLink")
  private successAlertCopyLink: SwalComponent;
  @ViewChild("successAlertPaylink") private successAlertPaylink: SwalComponent;
  @ViewChild("confirmGenerate") private confirmGenerateSwal: SwalComponent;
  @ViewChild("enter2FA") private enter2FASwal: SwalComponent;
  @Inject(DOCUMENT) private document: Document;
  public failureUpdateTitle: string =
    "Something went wrong in the process, we are very sorry";
  public successResetTitle: string = "Paylink generated";
  public successAlertTextCopyLink: string = "Link Copied";
  public successAlertTextPaylink: string = "Payment Link Send";
  public globals: any = globals.default;
  public paginationMaxSize = 5;
  public paginationPageLimit = 10;
  public paginationTotalUsers = 0;
  public paginationCurrentPage = 1;
  public loading = false;
  public filterPlaceholder = "Search";
  public domRange: any;
  public message: string;
  public lastPaylink = "";
  public toRangeDate = moment()
    .subtract("month")
    .startOf("month")
    .format("YYYY-MM-DD");
  bills: Bills[] = [];
  status: Status[] = [];
  user: User;
  filters: any = {
    search: " ",
    status_id: Number,
    from_date: moment().startOf("month").format("YYYY-MM-DD"),
    to_date: moment().endOf("month").format("YYYY-MM-DD"),
    page: this.paginationCurrentPage,
    limit: this.paginationPageLimit,
  };
  startDate = moment().startOf("month").format("YYYY-MM-DD");
  endDate = moment().endOf("month").format("YYYY-MM-DD");

  dateRange: any = {
    from: String,
    to: String,
  };
  notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });
  successAlertTitle: string = "";

  constructor(
    private BS: BillsService,
    private SS: StatusService,
    private CS: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {
    this.startDate = moment().startOf("month").format("YYYY-MM-DD");
    this.endDate = moment().endOf("month").format("YYYY-MM-DD");
  }

  ngOnInit() {
    // this.domRange = this.dateRangeDOM.elementRef.nativeElement;
    this.loading = true;
    if (this.route.snapshot.params.userSearch) {
      this.filters.search = this.route.snapshot.params.userSearch;
    }
    this.filters.status_id = Number(0);
    this.filters.from_date = moment().startOf("month").format("YYYY-MM-DD");
    this.filters.to_date = moment().endOf("month").format("YYYY-MM-DD");
    this.user = JSON.parse(window.localStorage.current_user);

    this.BS.dateCreatedPaylink().subscribe(
      (response) => {
        this.lastPaylink = response[0].date_created;
      },
      (err) => {
        console.log("Error generating paylink: ", err);
      }
    );

    // console.log("DOM RANGE : ", this.domRange);
    if (window.location.href.includes("/es/")) {
      this.filterPlaceholder = "Buscar";
      // this.domRange.getElementsByClassName("yk-label")[0].innerText = "Inicio";
      // this.domRange.getElementsByClassName("yk-label")[1].innerText = "Fin";
      // this.domRange.getElementsByClassName("yk-btn")[0].innerText = "Este Mes";
      // this.domRange.getElementsByClassName("yk-btn")[1].innerText =
      //   "Ultimo Mes";
      // this.domRange.getElementsByClassName("yk-btn")[2].innerText =
      //   "Esta Semana";
      // this.domRange.getElementsByClassName("yk-btn")[3].innerText =
      //   "Ultima Semana";
      // this.domRange.getElementsByClassName("yk-btn")[4].innerText = "Este Año";
      // this.domRange.getElementsByClassName("yk-btn")[5].innerText =
      //   "Ultimo Año";
    }
  }

  ngAfterViewInit() {
    if (this.user.user_type_id === 1) {
      this.filters.search = " ";
      this.filters.status_id = Number(0);
      this.setDateRange({
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
      });
      this.getFilteredBills(this.filters);
      // this.getPaginatedBills(this.filters)
    } else {
      // call customer route

      // this.filters.search = ' ' changed on 1-26-2019, filters.search definition changed '' => ' '
      this.setDateRange({
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
      });
      this.getCustomerFilteredBills(this.user.id, this.filters);
    }
    this.getBillStatus();
    // this.getPaginatedBills(this.paginationCurrentPage,this.paginationPageLimit)

    // let domRange = this.dateRangeDOM.elementRef.nativeElement;
    if (window.location.href.includes("/es/")) {
      // this.domRange.getElementsByClassName("yk-weekday")[0].innerText = "Dom";
      // this.domRange.getElementsByClassName("yk-weekday")[1].innerText = "Lun";
      // this.domRange.getElementsByClassName("yk-weekday")[2].innerText = "Mar";
      // this.domRange.getElementsByClassName("yk-weekday")[3].innerText = "Mie";
      // this.domRange.getElementsByClassName("yk-weekday")[4].innerText = "Jue";
      // this.domRange.getElementsByClassName("yk-weekday")[5].innerText = "Vie";
      // this.domRange.getElementsByClassName("yk-weekday")[6].innerText = "Sab";
    }
  }

  sendBillDetails(id: number) {
    this.router.navigate(["/bills", id]);
  }

  searchFromUser(user) {
    this.filters.search = user;
  }

  submitFilters(filters) {
    this.filters.from_date = this.startDate;
    this.filters.to_date = this.endDate;

    if (this.user.user_type_id === 1) {
      this.loading = true;
      if (this.filters.search === "") {
        this.filters.search = " ";
      }
      this.getFilteredBills(filters);
    } else {
      this.loading = true;
      if (this.filters.search === "") {
        this.filters.search = " ";
      }
      this.getCustomerFilteredBills(this.user.id, filters);
    }
  }

  clearFilters() {
    this.filters = {
      search: "",
      status_id: Number,
      from_date: String,
      to_date: String,
      page: Number,
      limit: Number,
    };

    this.filters.status_id = Number(0);
    this.filters.search = this.route.snapshot.params.userSearch;
    this.filters.status_id = Number(0);
    this.filters.from_date = moment().startOf("month").format("YYYY-MM-DD");
    this.filters.to_date = moment().endOf("month").format("YYYY-MM-DD");
    this.filters.page = 1;
    this.filters.limit = 10;
    this.dateRange.to = this.filters.to_date;
    this.dateRange.from = this.filters.from_date;
    this.paginationPageLimit = this.filters.limit;
    return;
  }

  setDateRange(range) {
    this.filters.from_date = range.from;
    this.filters.to_date = range.to;
  }

  pageChanged(event: any) {
    this.paginationCurrentPage = event.page;
    this.search();
  }

  search() {
    this.paginationPageLimit = this.filters.limit;
    this.loading = true;
    this.submitFilters(this.filters);
  }

  getCustomerFilteredBills(id: Number, filters: Object) {
    filters = { ...filters, page: this.paginationCurrentPage };
    this.CS.getAllBills(id, filters).subscribe(
      (response) => {
        this.loading = false;
        this.bills = response.body.bills;
        this.lastPaylink = response.body.lastPaylink
          ? response.body.lastPaylink
          : null;
        this.paginationTotalUsers = response.body.total;
      },
      (err) => {
        console.log("Error getting customer filtered bills", err);
      },
      () => {
        if (this.filters.search == " ") {
          this.filters.search = "";
        }
        console.log("Finished getting customer filtered bills");
      }
    );
  }

  getFilteredBills(filters) {
    filters = { ...filters, page: this.paginationCurrentPage };
    this.BS.getBillsFiltered(filters).subscribe(
      (response) => {
        this.loading = false;
        this.bills = response.body.bills;
        this.lastPaylink = response.body.lastPaylink
          ? response.body.lastPaylink
          : null;
        this.paginationTotalUsers = response.body.total;
      },
      (err) => {
        console.log("Error getting filtered bills: ", err);
      },
      () => {
        if (this.filters.search == " ") {
          this.filters.search = "";
        }
      }
    );
  }

  getAllBills() {
    this.BS.getAllBills().subscribe(
      (response) => {
        this.loading = false;
        this.bills = response.body;
      },
      (err) => {
        console.log("Error gettings bills: ", err);
      }
    );
  }

  spanishStatus(status) {
    switch (status) {
      case "Pending":
        return "Pendiente";
      case "Paid":
        return "Pagado";
      case "Partially Paid":
        return "Pagado Parcialmente";
      case "Overdue":
        return "Vencido";
    }
  }
  getBillStatus() {
    this.SS.getBillStatus().subscribe(
      (response) => {
        this.status = response.body;
        if (window.location.href.includes("/es/")) {
          this.status.map((item) => {
            item.name = this.spanishStatus(item.name);
            return item;
          });
        }
      },
      (err) => {
        console.log("Error gettings bills: ", err);
      }
    );
  }

  closeSidebar() {
    window.scroll(0, 60);
    this.renderer.removeClass(document.body, "mini-navbar");
  }

  sendBillpaylink(id: number) {
    const dialogRef = this.dialog.open(SendPaylinkComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.BS.sendPaylink(id, result).subscribe(
          (response) => {
            this.showToast("Paylink sent successfully", "success");
          },
          (err) => {
            this.showToast("Error sending paylink", "error");
            console.log("Error sending paylink: ", err);
          },
          () => {
            console.log("Finished sending paylink");
          }
        );
      }
      // Process the result here
    });
  }

  copyBillpaylink(uuid: string): void {
    if (uuid == null || uuid == "") {
      this.showToast("No link to copy", "error");
      return;
    }
    const url = `${environment.serverUrlPaylink}/paylink/${uuid}`;
    this.copyToClipboard(url);
  }

  // Método auxiliar para copiar el texto al portapapeles
  private copyToClipboard(text: string): void {
    this.successAlertCopyLink.show;
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    this.showToast("Link copied to clipboard!", "Success");
    this.successAlertCopyLink.show;
  }

  showToast(text: string, status: string): void {
    if (status == "error") this.notyf.error("No link to copy");
    else this.notyf.success(text ? text : "Link copied to clipboard!");
  }

  confirmGeneratePaylink() {
    this.confirmGenerateSwal.show().then((result) => {
      const data = {
        email: this.user.email,
        user_id: this.user.id,
      };
      this.BS.login2fa(data).subscribe((response) => {
        if (result.value) {
          this.enter2FASwal.show().then((result2) => {
            if (result2.value) {
              const dataValidate = {
                email: this.user.email,
                token: result2.value,
              };
              this.BS.valide2fa(dataValidate).subscribe((response) => {
                if (response.body.user) {
                  this.generatePaylink();
                } else {
                  this.failureUpdateTitle = "Invalid 2FA code";
                  this.failureResetSwal.text =
                    "Invalid 2FA code. Please try again.";
                  this.failureResetSwal.show();
                }
              });
            }
          });
        }
      });
    });
  }

  generatePaylink() {
    this.BS.generatePaylink().subscribe(
      (response) => {
        this.successResetTitle = "Paylink generated";
        this.message = "";
        this.successResetSwal.show();
      },
      (err) => {
        this.message = "";
        this.failureResetSwal.show();
      }
    );
  }
}
