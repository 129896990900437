import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WeatherService {
    baseUrl: string = environment.serverUrl;

    constructor(private http: HttpClient) { }

    //TEXMESONET API
    getGageData(){
        return this.http.get<any>('https://www.texmesonet.org/api/CurrentData')
    }

}
