import { UserInfo, Deserializable } from "./"

export class User{
    id: number;
    email: string;
    user_info: UserInfo;
    user_type_id : Number;
    date_created: string;
    date_updated: string;
    active: boolean;

    constructor(email?: string, public password?: string) { 
        this.email = email || '';
        this.password = password || '';
    }   

}
