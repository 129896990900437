import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CreateGuestTicketComponent } from 'src/app/_common/create-guest-ticket/create-guest-ticket.component';
import * as globals from '../globals.json';
import {EditUserComponent} from "../_common/edit-user/edit-user.component";
import {FaqComponent} from "../faq/faq.component";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

declare var $:any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  public bsModalRef: BsModalRef;
  public baseUrl: String;
  public emailFormGroup: FormGroup;
  public globals: any = globals.default;
  public vimeoVideos = [
    'https://player.vimeo.com/video/316659529',
    'https://player.vimeo.com/video/316659214',
    'https://player.vimeo.com/video/316657882', 
    'https://player.vimeo.com/video/316654581', 
    'https://player.vimeo.com/video/316652501', 
    'https://player.vimeo.com/video/316653545'
  ];
  public safeVideos : SafeResourceUrl[];
  // @HostListener("window:scroll", ['$event']) onWindowScroll(event){
  //     const offset = event.target.scrollTop;
  //     if(offset > 200){
  //         $('#navbar').addClass('navbar-scroll');
  //     }else{
  //       $('#navbar').removeClass('navbar-scroll');
  //     }
  // }

  @ViewChild('success') private successSwal: SwalComponent;
  public yourEmailText ="Your Email";
  public writeText="Write your message here...";

  constructor(private modalService: BsModalService, private fb: FormBuilder, private sanitizer: DomSanitizer) {

    this.emailFormGroup = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [Validators.required])
    });

    this.safeVideos = this.vimeoVideos.map(video => sanitizer.bypassSecurityTrustResourceUrl(video))
  }

  get email() { return this.emailFormGroup.get('email'); }
  get message() { return this.emailFormGroup.get('message'); }

  goTo(elem_id){
    var elem = document.getElementById(elem_id);
    elem.scrollIntoView({behavior: "smooth",block: "start", inline: "start"});
  }


  ngOnInit() {
    console.log('global app data: ', this.globals)
    if(window.location.href.includes("/es/")){
      this.yourEmailText ="Su correo";
      this.writeText="Escriba su mensaje aqui...";
    }

  }

  sendEmail(){
    this.successSwal.show();
  }

  openCreateTicketModal() {
    this.bsModalRef = this.modalService.show(CreateGuestTicketComponent, Object.assign({}, { class: 'gray inmodal animated slideDown' }));
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  copyToClipboard(element, el_tooltip) {

    //element.addClass('data-o')
    console.log(el_tooltip.attributes['title']);
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    var successful= document.execCommand("copy");
    var msg = successful ? 'Copied!' : 'Whoops, not copied!';
    console.log(msg);
    //el_tooltip.attributes['title'].tooltip('show');
    //element.attr('data-original-title', msg).tooltip('show');

    $temp.remove();
  }

  // displayFAQ() {
  //   this.bsModalRef = this.modalService.show(FaqComponent, Object.assign({}, { class: 'gray modal-lg inmodal animated bounceInRight', backdrop: true, ignoreBackdropClick: true}))
  // }



}
