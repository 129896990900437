import { Component, OnInit } from "@angular/core";
import {
  CustomerService,
  TypeService,
  StatusService,
  TicketService,
  WorkOrderService,
  CryptoService,
} from "src/app/_services";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";

import {
  Ticket,
  TicketStatus,
  TicketComment,
  WorkOrder,
  WorkOrderTypes,
  WorkOrderStatus,
} from "src/app/_models";
import { switchMap } from "rxjs/operators";
import { ParamMap, Router } from "@angular/router";

import { EditTicketComponent } from "src/app/_common/edit-ticket/edit-ticket.component";
import { CreateWorkOrderComponent } from "src/app/_common/create-work-order/create-work-order.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { EditWorkOrderComponent } from "src/app/_common/edit-work-order/edit-work-order.component";

declare var $: any;

@Component({
  selector: "app-work-order-details",
  templateUrl: "./work-order-details.component.html",
  styleUrls: ["./work-order-details.component.css"],
})
export class WorkOrderDetailsComponent implements OnInit {
  public workOrderTypes: WorkOrderTypes[];
  public workOrderStatus: WorkOrderStatus[];
  public workOrderComments: any;

  public ticket: Ticket;
  public workOrder: WorkOrder;
  public user: any;
  public workOrderId: string;

  public loading: boolean = true;

  public newComment: string;
  public newCommentLoading: boolean = false;
  public newCommentError: string;
  public interval;
  public bsModalRef: BsModalRef;

  public workOrderText: string = "Work Order No.";
  public workOrderCommentText: string = "Comments";
  public writeCommentText: string = "Write comment...";

  constructor(
    private customerService: CustomerService,
    private typeService: TypeService,
    private statusService: StatusService,
    private workOrderService: WorkOrderService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService
  ) {}

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    this.workOrderId = this.route.snapshot.params.id;
    this.getDetails();
    if (window.location.href.includes("/es/")) {
      this.workOrderText = "Orden de Trabajo Num.";
      this.workOrderCommentText = "Comentarios";
      this.writeCommentText = "Escriba un comentario...";
    }
  }

  ngAfterViewInit() {
    // Add slimscroll to element
    this.interval = setInterval(() => {
      this.getDetails();
    }, 15000);
    $(".comment-height-scroll").slimscroll({ height: "500px" });
  }

  getDetails() {
    this.workOrderService.getWorkOrderById(this.workOrderId).subscribe(
      (data) => {
        this.workOrder = data;
        console.log("workOrder: ", this.workOrder);

        setTimeout(() => {
          this.loading = false;
          $(".product-images").slick({
            dots: true,
          });
        }, 200);
      },
      (err) => {
        this.router.navigate(["404"]);
      }
    );

    this.workOrderService
      .getWorkOrderComments(this.workOrderId)
      .subscribe((data) => (this.workOrderComments = data));

    this.typeService
      .getWorkOrderTypes()
      .subscribe((data) => (this.workOrderTypes = data));

    this.statusService
      .getWorkOrderStatus()
      .subscribe((data) => (this.workOrderStatus = data));
  }

  printView() {
    window.print();
  }

  submitNewComment() {
    if (this.newComment == null || this.newComment.trim().length == 0) {
      return;
    }
    this.newCommentLoading = true;
    const newComment = {
      wid: this.workOrderId,
      user_id: this.user.id,
      comment: this.newComment,
    };

    this.workOrderService
      .createWorkOrderComment(this.workOrderId, newComment)
      .subscribe(
        (data: any) => {
          setTimeout(() => {
            this.newComment = "";
            this.newCommentLoading = false;
            data.first_name = this.user.first_name;
            data.last_name = this.user.last_name;
            data.profile_image_url = this.user.profile_image_url;
            this.workOrderComments.comments.unshift(data);
          }, 1000);
        },
        (err) => {
          this.newCommentError = err;
        }
      );
  }

  openEditWorkOrderModal() {
    this.bsModalRef = this.modalService.show(
      EditWorkOrderComponent,
      Object.assign({}, { class: "gray inmodal animated slideDown" })
    );
    this.bsModalRef.content.workOrder = this.workOrder;
    this.bsModalRef.content.setEditWorkOrderForm();
    this.bsModalRef.content.editWorkOrderModalClose.subscribe((data) => {
      this.getDetails();
    });
  }

  setClass(statusId) {
    return {
      "label-warning": statusId == 1,
      "label-primary": statusId == 2,
      "label-success": statusId == 3,
      "label-danger": statusId == 4,
    };
  }

  getWorkOrderTypeName(typeId) {
    let type = [];
    if (this.workOrderTypes) {
      type = this.workOrderTypes.filter(
        (workOrderType) => workOrderType.id === typeId
      );
    }

    return type.length > 0 ? type[0].name : "N/A";
  }

  getWorkOrderStatusName(statusId) {
    let status = [];
    if (this.workOrderStatus) {
      status = this.workOrderStatus.filter(
        (workOrderStatus) => workOrderStatus.id === statusId
      );
    }

    return status.length > 0 ? status[0].name : "N/A";
  }
}
