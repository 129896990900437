import { Injectable } 	from '@angular/core';
import { HttpClient, HttpResponse }	from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Address } from '../_models';
import { Observable } from 'rxjs';
import * as moment from 'moment' 
import { ObserveOnSubscriber } from 'rxjs/internal/operators/observeOn';

@Injectable({
  providedIn: 'root'
})

export class AddressService {
  baseUrl: string = environment.serverUrl;
  addressURL : string = this.baseUrl + '/api/address';

  constructor(private http: HttpClient) { }

  getServiceAddresses(): Observable<HttpResponse<any>>{
  	return this.http.get<Address[]>(`${this.addressURL}/service_addresses`, {observe: 'response'});
  }

  getFilteredAddress(street_address: string): Observable<HttpResponse<any>>{
    return this.http.get<Address>(`${this.addressURL}/${street_address}`, {observe: 'response'});
  }

  syncAddressesWithIncode(account_number : string, mailing_address_id : number, service_address_id : number){
    return this.http.get(`${this.addressURL}/${account_number}/${mailing_address_id}/${service_address_id}/from_incode`);
  }
}
