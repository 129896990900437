import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse }	from '@angular/common/http';
import { Terminal } from '../_models'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TerminalService {
  private baseUrl: string = environment.serverUrl


  constructor(private http: HttpClient) { }

  getTerminalsInfo() {
  	return this.http.get<Terminal>(`${this.baseUrl}/api/terminals/`)
  }

}