import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import {
  first,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
  tap,
  delay,
  finalize,
  share,
} from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { DOCUMENT } from "@angular/common";

import {
  UserService,
  StatusService,
  PaymentsService,
  TypeService,
  ZoneService,
  CryptoService,
} from "../_services";
import { User, AccountStatus, AccountTypes, Zone } from "../_models";
import { EditUserComponent } from "src/app/_common/edit-user/edit-user.component";
import { UserLinkedComponent } from "src/app/_common/user-linked/user-linked.component";
import { CreateUserComponent } from "src/app/_common/create-user/create-user.component";
import { ObservableMedia } from "@angular/flex-layout";
import { Router } from "@angular/router";
import { CreateCustomerComponent } from "./../_common/create-customer/create-customer.component";

import * as globals from "../globals.json";
import { EventReceiptService } from "../_services/event-receipt.service";

declare var $: any;

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  @ViewChild("importIncodeAccountAlert")
  private importIncodeAccountSwal: SwalComponent;
  @ViewChild("success") private successSwal: SwalComponent;

  @Inject(DOCUMENT) private document: Document;
  user: User;

  public importIncodeAccountSwalHtml =
    "Enter incode account number with <b>dashes</b>";
  public importIncodeAccountSwalTitle = "Import Incode Account";
  public importIncodeAccountSwalconfirmButtonText = "Import";
  public importIncodeAccountSwalcancelButtonText = "Cancel";
  public searchBoxPlaceHolder = "Search";

  public globals: any = globals.default;
  public bsModalRef: BsModalRef;
  public viewUser: User;

  public activeTab: string;
  public activeTabContent: any;

  public users$: Observable<User[]>;
  private searchTerms = new Subject<string>();
  public accountStatuses: AccountStatus[] = [];
  public accountTypes: AccountTypes[] = [];
  public accountZones: Zone[] = [];
  public linked: any = [];

  public initialMobile = false;
  public loading = false;
  public band = false;
  public paginationMaxSize = 5;
  public paginationPageLimit = 10;
  public paginationTotalUsers = 0;
  public paginationCurrentPage = 1;
  public searchText = "";
  public searchAccountStatus = "";
  public searchAccountType = "";
  public searchAccountZone = "";
  public userActive = "";
  public openTerminal = false;

  public btnDis = true;
  public drop = false;

  public pageContent = {
    customers: {
      title: "Customers",
      content: "Able to view customers and edit their information.",
    },
    users: {
      title: "Staff & Admin Users",
      content:
        "Able to view all staff and admin users and edit their information.",
    },
    Linked: {
      title: "Linked Acccounts",
      content: 'Searching by "Linked" Acccounts.',
    },
  };

  public isIpad: boolean;

  constructor(
    private userService: UserService,
    private modalService: BsModalService,
    private statusService: StatusService,
    private typeService: TypeService,
    private zoneService: ZoneService,
    public media: ObservableMedia,
    private router: Router,
    private event: EventReceiptService,
    private cryptoService: CryptoService
  ) {
    if (this.media.isActive("xs")) {
      this.initialMobile = true;
    }

    this.importIncodeAccount = this.importIncodeAccount.bind(this);
    this.allowOutsideClick = this.allowOutsideClick.bind(this);
  }

  spanishStatus(status) {
    switch (status) {
      case "Final":
        return "Final";
      case "Inactive":
        return "Inactivo";
      case "Active":
        return "Activo";
      case "Disconnect":
        return "Desconectado";
    }
  }

  ngOnInit(): void {
    this.isIpad = this.isDeviceIPad();
    console.log("isIpad", this.isIpad);
    this.event.change.subscribe((drop) => {
      console.log("DROPPPPPP ", drop);
      this.drop = drop;

      if (this.drop) {
        this.searchText = "";
        this.search();
      }
    });
    this.user = JSON.parse(window.localStorage.current_user);
    if (this.user.user_type_id == 4 || this.user.user_type_id == 5) {
      this.btnDis = false;
    }
    if (window.location.href.includes("/es/")) {
      this.importIncodeAccountSwalHtml =
        "Ingrese numero de cuenta con <b>guiones</b>";
      this.importIncodeAccountSwalTitle = "Importar Codigo de Cuenta";
      this.importIncodeAccountSwalconfirmButtonText = "Importar";
      this.importIncodeAccountSwalcancelButtonText = "Cancelar";

      // this.pageContent.commercial.title = "Clientes Comerciales";
      // this.pageContent.commercial.content = "Esta permitido ver clientes comerciales y editar su informacion.";
      this.pageContent.customers.title = "Clientes";
      this.pageContent.customers.content =
        "Esta permitido ver clientes y editar su información.";
      this.pageContent.users.title = "Usuarios Admin & Personal";
      this.pageContent.users.content =
        "Esta permitido ver Usuarios Admin & Personal y editar su información.";
      this.searchBoxPlaceHolder = "Buscar";
    }

    this.activeTab = "customers";
    this.activeTabContent = this.pageContent.customers;

    this.statusService.getAccountStatus().subscribe((data) => {
      if (window.location.href.includes("/es/")) {
        data.map((item) => {
          item.name = this.spanishStatus(item.name);
          return item;
        });
      }
      this.accountStatuses = data;
    });
    this.typeService.getAccountTypes_support().subscribe((data) => {
      this.accountTypes = data;
    });
    this.zoneService.getZones().subscribe((data) => {
      console.log(data);
      this.accountZones = data;
    });

    this.users$ = this.searchTerms.pipe(
      debounceTime(200),

      // ignore new term if same as previous term
      // distinctUntilChanged(s),

      switchMap((term: string) =>
        this.userService
          .getFiltered(
            this.paginationCurrentPage,
            this.paginationPageLimit,
            term,
            this.activeTab,
            this.searchAccountType,
            this.searchAccountStatus,
            this.searchAccountZone,
            this.userActive
          )
          .pipe(
            tap((data) => {
              this.loading = false;
              this.paginationTotalUsers = data.total;
              this.viewUser = data.users[0];
              this.openTerminal = data.openTerminal;
              console.log("data ", data);
            })
          )
      ),
      share()
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.search();
    }, 200);

    // Add chosen to select elements
    $(".chosen-select").chosen({ width: "100%" });

    // Add slimscroll to element
    $(".user-height-scroll").slimscroll({ height: "500px" });
  }

  search() {
    console.log("search", this.searchText);
    this.loading = true;
    this.searchTerms.next(this.searchText);
  }

  reload(): void {
    this.search();
  }

  clearSearchText() {
    this.searchText = "";
    this.search();
  }

  switchTab(tab) {
    console.log("tab: ", tab);
    if (tab == "Linked" && this.band == false) {
      this.band = true;
      // tab='customers'
    } else if (tab == "Linked" && this.band == true) {
      this.band = true;
      //tab='customers'
    } else {
      this.band = false;
    }
    console.log("band: ", this.band);
    this.activeTab = tab;
    this.search();

    //Hide current active tab title
    const activeTabTitle = $("#tab-title").find("li.active");
    activeTabTitle.removeClass("active");

    //Show new tab title
    const newActiveTabTitle = $(`#tab-title-${tab}`);
    newActiveTabTitle.addClass("active");

    this.activeTabContent = this.pageContent[tab];
  }

  showUser(user) {
    if (!this.initialMobile) {
      this.viewUser = user;
    } else if (this.initialMobile) {
      this.router.navigate(["/users/", user.id]);
    }
  }

  pageChanged(event: any) {
    this.paginationCurrentPage = event.page;
    this.search();
  }

  importIncodeAccount(event) {
    return new Promise((resolve, reject) => {
      this.userService.importIncodeAccount(event).subscribe(
        (response) => {
          console.log("response", response);
          resolve();
        },
        (err) => {
          console.log("error: ", err);
          this.importIncodeAccountSwal.nativeSwal.showValidationMessage(
            `Error: ${err.message}`
          );
          resolve();
        }
      );
    });
  }

  importIncodeAccountConfirm(event) {
    this.search();
    this.successSwal.title = "Success!";
    this.successSwal.text = `Succesfully imported account ${event}`;
    if (window.location.href.includes("/es/")) {
      this.successSwal.title = "Excelente!";
      this.successSwal.text = `Cuenta importada correctamente ${event}`;
    }
    this.successSwal.show();
  }

  allowOutsideClick() {
    return false;
  }

  openEditModal(user) {
    console.log(user);
    this.bsModalRef = this.modalService.show(
      EditUserComponent,
      Object.assign(
        {},
        {
          class: "gray modal-lg inmodal animated bounceInRight",
          backdrop: true,
          ignoreBackdropClick: true,
          initialState: {
            user: { ...user },
          },
        }
      )
    );
    // this.bsModalRef.content.user = user;
    this.bsModalRef.content.subscribeToSettings();
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.editUserModalClosed.subscribe((result) => {
      if (result) {
        this.reload();
      }
    });
  }
  openEditModallinked(user) {
    console.log(user);
    this.bsModalRef = this.modalService.show(
      UserLinkedComponent,
      Object.assign(
        {},
        {
          class: "gray modal-lg inmodal animated bounceInRight",
          backdrop: true,
          ignoreBackdropClick: true,
          initialState: {
            user: { ...user },
          },
        }
      )
    );
    // this.bsModalRef.content.user = user;
    this.bsModalRef.content.subscribeToSettings();
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.editUserLinkedModalClosed.subscribe((result) => {
      if (result) {
        this.reload();
      }
    });
  }
  openCreateModal() {
    this.bsModalRef = this.modalService.show(
      CreateUserComponent,
      Object.assign(
        {},
        {
          class: "gray modal-lg inmodal animated bounceInRight",
          backdrop: true,
          ignoreBackdropClick: true,
        }
      )
    );
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.reload.subscribe((result) => {
      if (result == "reload") {
        this.reload();
      }
    });
  }

  openCreateAccountModal() {
    this.bsModalRef = this.modalService.show(
      CreateCustomerComponent,
      Object.assign(
        {},
        {
          class: "gray modal-lg inmodal animated bounceInRight",
          backdrop: true,
          ignoreBackdropClick: true,
        }
      )
    );
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.reload.subscribe((result) => {
      if (result === "reload") {
        this.reload();
      }
    });
  }

  isDeviceIPad() {
    return (
      navigator &&
      navigator.userAgent &&
      navigator.maxTouchPoints &&
      navigator.platform &&
      ((navigator.userAgent.match(/Mac/) !== null &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints >= 1) || // iPad Pro (5 maxTouchPoints, use 2 to check)
        navigator.platform.match(/iPad/i) !== null)
    ); // iPad
  }
}
