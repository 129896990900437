import { Component, OnInit,ViewChild } from '@angular/core';
import * as moment from "moment";
import { Chart } from "chart.js";
import { ErrorMetricService } from "../../_services";

@Component({
  selector: 'app-time-series-error-code',
  templateUrl: './time-series-error-code.component.html',
  styleUrls: ['./time-series-error-code.component.css']
})
export class TimeSeriesErrorCodeComponent implements OnInit {
  @ViewChild('dateRange') dateRangeDOM;
  log_filters: any = {
    from_date: String,
    to_date: String,
  };
  
  from_last_months = [];
  to_last_months = [];
  data_months = [];

  totalErrorsMonths = [];
  totalPaymentsMonths = [];
  allTotalErrors = 0;
  allTotalPayments = 0;

  default_count = [];
  month_day_datas_types = [];

  totalErrorsDay = [];
  totalPaymentsDay = [];

  totalErrors = [];
  totalPayments = [];  
  timeGraphic = [];

  AVG_Errors=0.0;
  AVG_Payments=0.0;
  total_day_range=0;

  public domRange: any;
  public toRangeDate=moment().subtract('month').startOf('month').format('YYYY-MM-DD');


  constructor( private errorMetricService: ErrorMetricService  ) {     
  }

  dateRange: any = {
    from: String,
    to: String
  };
  

  ngOnInit() {
    this.domRange = this.dateRangeDOM.elementRef.nativeElement;
    this.log_filters.from_date = moment().startOf("week").format("YYYY-MM-DD");
    this.log_filters.to_date = moment().endOf("week").format("YYYY-MM-DD");

    this.getRangeTime();
     
    // console.log("DOM RANGE : ", this.domRange);
    if (window.location.href.includes('/es/')) { 
      this.domRange.getElementsByClassName('yk-label')[0].innerText = 'Inicio';
      this.domRange.getElementsByClassName('yk-label')[1].innerText = 'Fin';
      this.domRange.getElementsByClassName('yk-btn')[0].innerText = 'Este Mes';
      this.domRange.getElementsByClassName('yk-btn')[1].innerText = 'Ultimo Mes';
      this.domRange.getElementsByClassName('yk-btn')[2].innerText = 'Esta Semana';
      this.domRange.getElementsByClassName('yk-btn')[3].innerText = 'Ultima Semana';
      this.domRange.getElementsByClassName('yk-btn')[4].innerText = 'Este Año';
      this.domRange.getElementsByClassName('yk-btn')[5].innerText = 'Ultimo Año';
    }
  }
  
  ngAfterViewInit() {

    this.setDateRange({
      from: moment().startOf("week").format("YYYY-MM-DD"),
      to: moment().endOf("week").format("YYYY-MM-DD")
    });

        // let domRange = this.dateRangeDOM.elementRef.nativeElement;
        if (window.location.href.includes('/es/')) {
          this.domRange.getElementsByClassName('yk-weekday')[0].innerText = 'Dom';
          this.domRange.getElementsByClassName('yk-weekday')[1].innerText = 'Lun';
          this.domRange.getElementsByClassName('yk-weekday')[2].innerText = 'Mar';
          this.domRange.getElementsByClassName('yk-weekday')[3].innerText = 'Mie';
          this.domRange.getElementsByClassName('yk-weekday')[4].innerText = 'Jue';
          this.domRange.getElementsByClassName('yk-weekday')[5].innerText = 'Vie';
          this.domRange.getElementsByClassName('yk-weekday')[6].innerText = 'Sab';
        }
  }



  setDateRange(range) {    
    this.log_filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.log_filters.to_date = moment(range.to).format("YYYY-MM-DD");
  }

  getRangeTime () {

   // this.data_months=[];
    //this.month_day_datas_types=[]

    this.log_filters.from_date = moment(this.domRange.getElementsByClassName('yk-content')[0].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
    this.log_filters.to_date = moment(this.domRange.getElementsByClassName('yk-content')[1].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
 

   if (this.log_filters.from_date=='Invalid date' && this.log_filters.to_date =='Invalid date' ) {
    this.log_filters.from_date =moment().startOf('month').format('YYYY-MM-DD');
    this.log_filters.to_date = moment().endOf('month').format('YYYY-MM-DD');
   }

    var dif_dates = moment(this.log_filters.to_date).diff(
      moment(this.log_filters.from_date),
      "days"
    );

    this.total_day_range=dif_dates;

    console.log("Days between dates: " + dif_dates);

    if ( dif_dates > 120 ) {
      this.getDatasMonth();
    } else {
      this.getErrorsDay();
    }
    
  }

  getErrorsDay() {
    var from_date = this.log_filters.from_date + " " + "00:00";
    var to_date = this.log_filters.to_date + " " + "23:59";    
    this.totalErrorsDay = [];
    this.allTotalErrors = 0;
    this.errorMetricService.getTotalPaymentsAndErrors(from_date, to_date)
    .subscribe( res => {
      // console.log(JSON.stringify(res));
      this.defaultCountAndDate();
      this.totalErrorsDay = this.default_count;
      let i = 0;
      while ( res.body[i]) {
        let date = new Date(res.body[i].fecha);
        date.setDate(date.getDate() + 1);
        // console.log('---date = ' + date );
        let month_date = date.getMonth() + 1;
        let day_date = date.getDate() ;
        let month_day = month_date + "/" + day_date;
           
        let j;
        if ( res.body[i].total_error ) {        
          for (j = 0; j < this.month_day_datas_types.length; j++) {
            if (this.month_day_datas_types[j] === month_day) {
              // console.log('Dia = ' + this.month_day_datas_types[j])              
              // console.log('errors in ' + month_day + ' = ' + res.body[i].total_error );
              this.totalErrorsDay[j] = res.body[i].total_error;
              this.allTotalErrors += Number(res.body[i].total_error);
              break;              
            }; 
          };  
        };     
        i++;
      }      
    });
    this.getPaymentsDay();
  }

  getPaymentsDay() {
    var from_date = this.log_filters.from_date + " " + "00:00";
    var to_date = this.log_filters.to_date + " " + "23:59";    
    this.totalPaymentsDay = [];
    this.allTotalPayments = 0;
    this.errorMetricService.getTotalPaymentsAndErrors(from_date, to_date)
    .subscribe( res => {
      // console.log(JSON.stringify(res));
      this.defaultCountAndDate();      
      this.totalPaymentsDay = this.default_count;
      let i = 0;
      while ( res.body[i]) {
        let date = new Date(res.body[i].fecha);
        date.setDate(date.getDate() + 1);
        // console.log('---date = ' + date );
        let month_date = date.getMonth() + 1;
        let day_date = date.getDate() ;
        let month_day = month_date + "/" + day_date;
           
        let j;
        if ( res.body[i].total_payment ) {     
          for (j = 0; j < this.month_day_datas_types.length; j++) {
            if (this.month_day_datas_types[j] === month_day) {
              // console.log('Dia = ' + this.month_day_datas_types[j])              
              // console.log('payments in ' + month_day + ' = ' + res.body[i].total_payment );
              this.totalPaymentsDay[j] = res.body[i].total_payment;
              this.allTotalPayments += Number(res.body[i].total_payment);
              break;              
            }; 
          };  
        };               
        i++;
      }
      setTimeout(() => {        
        let rangeTime = 'days';
        this.graphicTimeSeriesError(rangeTime);
      }, 2000);      
    });
  }



  defaultCountAndDate() {
    // dias entre dos fechas
    var dif_dates = moment(this.log_filters.to_date).diff(
      moment(this.log_filters.from_date),
      "days"
    );
    // console.log("days between dates: " + dif_dates);
    this.month_day_datas_types = [];
    this.default_count = [];
    var day = 0;    
    var day = 0;
    const aux_date = new Date(this.log_filters.from_date);
    const ant = 86399.9; //dia en segundos
    // llenar las variables
    for (day = 0; day <= dif_dates; day++) {
      let finalDate = new Date(aux_date.setSeconds(ant));
      // console.log('finalDate = ' + finalDate);
      let month_date_all = finalDate.getMonth() + 1;
      let day_date_all = finalDate.getDate();
      let month_day_all = month_date_all + "/" + day_date_all;
      //  console.log('var month day all=' + month_day_all );
      this.month_day_datas_types[day] = month_day_all;
      this.default_count[day] = 0;
    }
  }


  getDatasMonth() {
    var from_date = this.log_filters.from_date;
    var to_date = this.log_filters.to_date;
    // console.log("from date" + from_date);
    // console.log("to date" + to_date);
    var dif_months = moment(this.log_filters.to_date).diff(
      moment(this.log_filters.from_date),
      "months"
    );
    console.log("Months between dates: " + dif_months);    
    let numMonths = dif_months;  // 5 es 6 meses
    this.data_months = [];
    this.allTotalErrors = 0;
    this.allTotalPayments = 0;
    var aux = numMonths;
    let addMonths = 0;
    
    while (aux >= 0) {
      // console.log('aux = ' + aux);
      this.from_last_months[aux] = moment(this.log_filters.from_date)
        .add( addMonths , "months")
        .startOf('month')
        .format("YYYY-MM-DD");
     // console.log("from last months = " + this.from_last_months[aux]);
      this.to_last_months[aux] = moment(this.log_filters.to_date)
        .subtract(aux, "months")
        .endOf('month')
        .format("YYYY-MM-DD");
     // console.log("to last months = " + this.to_last_months[aux]);
      // console.log('mes =' + moment(this.from_last_months[aux]).format("MMMM"));
      this.data_months.push(moment(this.from_last_months[aux]).format("MMMM")); // moment(this.from_last_months[aux]).format("MMMM"));
      // console.log("month datas = " + this.data_months);
      addMonths++; 
      aux--;
    }

    var aux2 = numMonths;
    var id = 0;
    while (aux2 >= 0) {
      this.getTotalPaymentsAndErrors(this.from_last_months[aux2], this.to_last_months[aux2], id);      
      // console.log('en '+aux2+' from ' + this.from_last_months[aux2]);
      // console.log('en '+aux2+' to ' + this.from_last_months[aux2]);
      id++;
      aux2--;
    }
    // console.log('month = ' + this.data_months);
    setTimeout(() => {
      let rangeTime = 'months';
      this.graphicTimeSeriesError(rangeTime);
    }, 2000);
  }



  getTotalPaymentsAndErrors(from_date: String, to_date: String, id) {    

    from_date = from_date + " " + "00:00";
    to_date = to_date + " " + "23:59";

    //console.log('from_ en get total payments and errors' + from_date);
    //console.log('to_ en get total payments and errors' + to_date);
    this.errorMetricService.getTotalPaymentsAndErrors(from_date, to_date)
    .subscribe( res => {
      // console.log(JSON.stringify(res));
      let i = 0;
      let countTotalErrors = 0;
      let countTotalPayments = 0;
      while (res.body[i]) {
        if ( res.body[i].total_error ) {
          // this.totalErrors.push(res.body[i].total_error);
          let  countTotal = Number(res.body[i].total_error);
          // console.log('count = ' + countTotal);
          countTotalErrors += countTotal;
          // console.log('count total errors = ' + countTotalErrors);
        } else if ( res.body[i].total_payment ) {
          let countPayments = Number(res.body[i].total_payment);
          countTotalPayments += countPayments;
        }
        i++;
      }
      this.totalErrorsMonths[id] = countTotalErrors;
      this.totalPaymentsMonths[id] = countTotalPayments;
      this.allTotalErrors += countTotalErrors;
      this.allTotalPayments += countTotalPayments;
    });    
  }


  graphicTimeSeriesError(rangeTime: string) {
    console.log('en graphic time series error');
    this.totalErrors = [];
    this.totalPayments = [];
    this.timeGraphic = [];

    this.AVG_Errors=Number.parseFloat((this.allTotalErrors/this.total_day_range).toPrecision(3));
    this.AVG_Payments=Number.parseFloat((this.allTotalPayments/this.total_day_range).toPrecision(3));

    if ( rangeTime === 'months') {
     // console.log('data months = ' + this.data_months);
     // console.log('total errors months = ' + this.totalErrorsMonths);
     // console.log('total payments months = ' + this.totalPaymentsMonths);
      this.timeGraphic = this.data_months;
      this.totalErrors = this.totalErrorsMonths;
      this.totalPayments = this.totalPaymentsMonths;
    } else if ( rangeTime === 'days' ) {
     // console.log("month day datas: " + this.month_day_datas_types);
     // console.log("count total error day: " + this.totalErrorsDay);  
     // console.log("count total payment day: " + this.totalPaymentsDay);
      this.timeGraphic = this.month_day_datas_types;
      this.totalErrors = this.totalErrorsDay;   
      this.totalPayments = this.totalPaymentsDay;
    }

    var barData = {
      labels: this.timeGraphic,
      datasets: [
        {
          label: "Total Errors",
          backgroundColor: 'rgba(100, 100, 100, 0.5)',
          borderColor: 'rgba(14,159,177,1)',
          pointBackgroundColor: 'rgba(100, 100, 100, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(100, 100, 100, 0.8)',
          data: this.totalErrors,
        },
        {
          label: "Total Payments",
          backgroundColor: "rgba(26,179,148,0.5)",
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff",
          data: this.totalPayments,
        },       
      ],
    };
    var barOptions = {
      responsive: true,
    };

    // se limpia los anteriores datos del div en html
    document.getElementById("barChartErrorsAndPayments").innerHTML = "&nbsp;";
    document.getElementById("barChartErrorsAndPayments").innerHTML =
      '<canvas id="barChart"></canvas>';

    var ctx = document.getElementById("barChart"); // .getContext("2d");;

    var myNewChart = new Chart(ctx, {
      type: "bar",
      data: barData,
      options: barOptions,
    });
  }
}
