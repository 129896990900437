import {
  Component,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AccountService, CustomerService } from "../_services";
import { User, UserSettings, Account } from "../_models";
import { FormGroup } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import * as moment from "moment";
import { CryptoService } from "src/app/_services/crypto.service";

declare var $: any;

@Component({
  selector: "app-notification-settings",
  templateUrl: "./notification-settings.component.html",
  styleUrls: ["./notification-settings.component.css"],
})
export class NotificationSettings implements OnInit {
  @Output() editUserModalClosed = new EventEmitter();
  @Output() refreshParentData = new EventEmitter();
  @Input() select: any;
  public sentMessage: string;
  public email: string;
  public phone_number: string;
  public loading = false;
  public userSettings: UserSettings;
  public user;
  public logged_in_user: User;
  public accounts: Account[];
  public selectedAccount: Account;
  public updateUserFormGroup: FormGroup;
  public adminEditUserFormGroup: FormGroup;
  public disableMailingAddress = false;
  public bsConfig: Partial<BsDatepickerConfig>;
  public datePickerDate: any;
  public initialBirthDate = moment().subtract(20, "years").format("MM/DD/YYYY");
  public submitSwalAlertTitle = "Save Changes?";
  public editSuccessAlertTitle = "User has been edited";
  public editFailureAlertTitle = "There was an error";
  public editFailureAlertText = "Please try again later";
  public successAlertTitle = "A link has been sent to you";
  public _400failureAlertTitle =
    "The e-mail you entered is invalid or was not found";
  public failureAlertTitle = "Something went wrong, we are very sorry";
  public selectAccountPlaceholder = "Select Account";

  reminderCron = {
    billReminder: false,
    lateFeeReminder: false,
    lateNoticeReminder: false,
    disconnectWarning: false,
  };

  switchbillReminder: any = null;
  switchlateFeeReminder: any = null;
  switchlateNoticeReminder: any = null;
  switchdisconnectWarning: any = null;

  textswitchbillReminder: string = "";
  textswitchlateFeeReminder: string = "";
  textswitchlateNoticeReminder: string = "";
  textswitchdisconnectWarning: string = "";

  successSetReminders: boolean = false;
  textSetReminders: string = "";
  errorSetReminders: boolean = false;
  userInfo;

  currentUser;
  profileDetails;
  constructor(
    public bsModalRef: BsModalRef,
    private BsModalRef: BsModalRef,
    public bsModalService: BsModalService,
    private accountService: AccountService,
    private cryptoService: CryptoService,
    private CS: CustomerService
  ) {
    this.bsConfig = Object.assign({}, { containerClass: "theme-dark-blue" });
  }

  ngOnInit() {
    this.getCronReminder();
    this.logged_in_user = JSON.parse(window.localStorage.current_user);

    if (window.location.href.includes("/es/")) {
      this.submitSwalAlertTitle = "Desea guardar los cambios?";
      this.editSuccessAlertTitle = "El Usuario ha sido editado";
      this.editFailureAlertTitle = "Hubo un error";
      this.editFailureAlertText = "Porfavor, intente mas tarde";
      this.successAlertTitle = "Se ha enviado un link";
      this._400failureAlertTitle =
        "El correo ingresado no ha sido encontrado o no es valido";
      this.failureAlertTitle = "Lo sentimos, huno un error";
      this.selectAccountPlaceholder = "Seleccione una Cuenta";
    }
  }

  sendReminders() {
    const user_id = JSON.parse(window.localStorage.current_user);
    const id = this.select.user_id ? this.select.user_id : user_id.id;
    let data = {
      data: {
        "bill-reminder": this.reminderCron.billReminder,
        "late-fee-warning": this.reminderCron.lateFeeReminder,
        "late-notice": this.reminderCron.lateNoticeReminder,
        "disconnect-warning": this.reminderCron.disconnectWarning,
      },
      email: this.email,
      phone: this.phone_number,
    };

    this.CS.setReminders(id, data).subscribe(
      (info) => {
        this.textSetReminders = "Your changes were successfully saved";
        this.successSetReminders = true;
        this.errorSetReminders = true;
      },
      (error) => {
        this.textSetReminders = "Something went wrong, try again";
        this.successSetReminders = true;
        this.errorSetReminders = true;
      }
    );
  }

  _switchbillReminder(event) {
    this.textswitchbillReminder = event ? "ON" : "OFF";
    this.reminderCron.billReminder = event;
  }

  _switchlateFeeReminder(event) {
    this.textswitchlateFeeReminder = event ? "ON" : "OFF";
    this.reminderCron.lateFeeReminder = event;
  }

  _switchlateNoticeReminder(event) {
    this.textswitchlateNoticeReminder = event ? "ON" : "OFF";
    this.reminderCron.lateNoticeReminder = event;
  }

  _switchdisconnectWarning(event) {
    this.textswitchdisconnectWarning = event ? "ON" : "OFF";
    this.reminderCron.disconnectWarning = event;
  }

  hideModal() {
    this.BsModalRef.hide();
  }

  changeFlagUse() {
    const checkbox = document.getElementById(
      "flagUseCheckbox"
    ) as HTMLInputElement;
    if (checkbox) {
      const isChecked = checkbox.checked;

      this.accountService
        .updateAccountFlagUser(this.user.id, isChecked)
        .subscribe(
          (data) => {},
          (err) => {}
        );
    }
  }

  getCronReminder() {
    const user_id = JSON.parse(window.localStorage.current_user);
    const id = this.select.user_id ? this.select.user_id : user_id.id;

    this.CS.getCronReminder(id).subscribe((info) => {
      console.log(info[0]);
      this.reminderCron.billReminder = info[0].cron_reminder["bill-reminder"];
      this.reminderCron.disconnectWarning =
        info[0].cron_reminder["disconnect-warning"];
      this.reminderCron.lateFeeReminder =
        info[0].cron_reminder["late-fee-warning"];
      this.reminderCron.lateNoticeReminder =
        info[0].cron_reminder["late-notice"];

      this.email = this.logged_in_user.email;
      this.phone_number = info[0].phone;

      //  console.log(this.reminderCron);

      this.switchbillReminder = this.reminderCron.billReminder;
      this.switchlateFeeReminder = this.reminderCron.lateFeeReminder;
      this.switchlateNoticeReminder = this.reminderCron.lateNoticeReminder;
      this.switchdisconnectWarning = this.reminderCron.disconnectWarning;

      this.textswitchbillReminder = this.switchbillReminder ? "ON" : "OFF";
      this.textswitchlateFeeReminder = this.switchlateFeeReminder
        ? "ON"
        : "OFF";
      this.textswitchlateNoticeReminder = this.switchlateNoticeReminder
        ? "ON"
        : "OFF";
      this.textswitchdisconnectWarning = this.switchdisconnectWarning
        ? "ON"
        : "OFF";
    });
  }
}
