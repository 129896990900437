import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private router: Router) {}
    public processingLogout : boolean = false;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ((err.status === 401 || err.status === 0 ) && this.router.url != '/login' && !this.processingLogout) {
                // auto logout if 401 response returned from api
                // console.log("logging out. Session over")
                this.processingLogout = true;
                // this.authenticationService.logout();
                // this.router.navigate(['/login']).then(() => {
                //   this.processingLogout = false
                // })
            }
            console.log('error on intercepter: ', err);
            const error = {status: err.status, message: err.error.message || err.error.error};
            return throwError(error);
        }))
    }
}
