import { Component, OnInit } from "@angular/core";
import { RestorationPasswordService } from "../../_services";
import { RestorePasswordModel } from "../../_models";
import { Chart } from "chart.js";
import * as moment from "moment";

@Component({
  selector: "app-restore-password",
  templateUrl: "./restore-password.component.html",
  styleUrls: ["./restore-password.component.css"],
})
export class RestorePasswordComponent implements OnInit {
  log_filters: any = {
    from_date: String,
    to_date: String,
  };
  count_by_email: number;
  avg_user_reset;
  percent_user_reset;  
  total_user_reset: number;

  result_user: number;
  result_email_type = [];
  from_date;
  to_date;
  
  total_this_week: number;
  total_current_week: number;
  percent_current_week: number;
  avg_current_week: number;

  from_date_current_week;
  to_date_current_week;
  from_date_last_week;
  to_date_last_week;
  total_active_users;

  total_last_week: number;
  percent_last_week: number;
  avg_last_week: number;

  rate_of_change;
  change_up;

  email_type = ['Gmail', 'Yahoo', 'Hotmail', 'Aol', 'Icloud', 'Others'];
  email_count = [];
  percentage =  [];
  total_percentage = [];
  total_users: number;

  user_active: number;
  user_inactive: number;
  total_user_active: number;
  percent_reset_active: number;
  percent_total;
  constructor(private restorationS: RestorationPasswordService) {}
  //
  ngOnInit() {
    this.log_filters.from_date = moment().subtract(7, "days")
    .format("YYYY-MM-DD");
    this.log_filters.to_date = moment().format("YYYY-MM-DD");

    this.from_date_current_week = moment().subtract(7, "days")
    .format("YYYY-MM-DD");
    
    this.to_date_current_week = moment().format("YYYY-MM-DD");

    this.from_date_last_week = moment(this.log_filters.from_date)      
    .subtract(7, "days")
    .format("YYYY-MM-DD");

    this.to_date_last_week = moment(this.log_filters.to_date)    
    .subtract(8, "days")
    .format("YYYY-MM-DD");

    this.getUserResetByEmail();
    this.getAvgUserReset();
    this.getUserResetWeeks();
    //this.getUserResetMonths();
    
    this.getCurrentWeek();
    this.getLastWeek();
    this.getTotalUserActive();
  }

  // Password Reset Counts by Email Type
  getUserResetByEmail() {
    this.restorationS.getUserResetByEmail().subscribe((res) => {

      var i = 0;
      while (res.body[i]) {
      if (res.body[i].total_resets) {
        // console.log('en count by email')
        this.count_by_email = res.body[i].total_resets;
      }
      i++;
      }
      let all_datas = JSON.stringify(res.body); // se convierte en cadena
      // console.log('Respuesta del servidor reset by email = ' + all_datas);
      // console.log('Total por email= ' + this.count_by_email );
    });
  }

  getUserResetWeeks() {
    this.email_count = ['0','0','0','0','0','0'];
    this.percentage =  ['0','0','0','0','0','0'];
    this.from_date = this.log_filters.from_date;  // 7 days ago
    this.to_date = this.log_filters.to_date;  // today
    // console.log("from date week" + this.from_date);
    // console.log("to date week" + this.to_date);
    var this_week = this.getUserResetByEmailTime(this.from_date, this.to_date);
    setTimeout(() => {
      this.graphicUserByEmailType();
    }, 2000);
  }

  getUserResetMonths() {
    this.email_count = ['0','0','0','0','0','0'];
    this.percentage =  ['0','0','0','0','0','0'];
    this.from_date = moment().subtract(1, "months")
    .format("YYYY-MM-DD");
    this.to_date = this.log_filters.to_date;  // today
    // console.log("from date month" + this.from_date);
    // console.log("to date month" + this.to_date);
    var this_week = this.getUserResetByEmailTime(this.from_date, this.to_date);
    setTimeout(() => {
      this.graphicUserByEmailType();
    }, 2000);
  }

  getUserResetQuarter() {
    this.email_count = ['0','0','0','0','0','0'];
    this.percentage =  ['0','0','0','0','0','0'];
    this.from_date = moment().subtract(3, "months")
    .format("YYYY-MM-DD");
    this.to_date = this.log_filters.to_date;  // today
    // console.log("from date month" + this.from_date);
    // console.log("to date month" + this.to_date);
    var this_week = this.getUserResetByEmailTime(this.from_date, this.to_date);
    setTimeout(() => {
      this.graphicUserByEmailType();
    }, 2000);
  }
  // Total User Actives
  getTotalUserActive() {
    // getUserActivation
    this.restorationS.getUserActivation().subscribe( res => {
        this.user_active = res.body.active;
        // console.log('User active = ' + this.user_active);
        // count by email es reseteados en total
        // console.log('reseteados en total = ' + this.count_by_email);
        this.percent_reset_active = ( this.count_by_email * 100 ) /  this.user_active;
        this.percent_total =  this.percent_reset_active.toPrecision(2);
        if (isNaN(this.percent_total)) {
          this.percent_total = '0';
        }          
        // console.log('this.percent_reset_active = ' + this.percent_reset_active);
    });
  }


  // Password Reset Count by Email Type within Time Range
  getUserResetByEmailTime(from_date: String, to_date: String) {
    from_date = from_date + ' ' + '00:00';
    to_date = to_date + ' ' + '23:59';
    // console.log('from en getuserresetbyemailandtime = ' + from_date);
    // console.log('to en getuserresetbyemailandtime = ' + to_date);
    this.restorationS
      .getUserResetByEmailTime(from_date, to_date)
      .subscribe((res) => {
        // console.log("Respuesta reset by email time = " + JSON.stringify(res.body));       
        var i = 0;
        while (res.body[i]) {
          // console.log("res.body en domain " + i + " = " + res.body[i].domain);
          // console.log("res.body en count " + i + " = " + res.body[i].count);          
          this.total_users = parseInt( res.body[i].total );
          // console.log("total users= " + res.body[i].total );
          if (res.body[i].domain && res.body[i].count) {

            var e_type = res.body[i].domain;
            switch( e_type) {
              case 'gmail.com':
                this.email_count[0] = res.body[i].count;
                break;
              case 'yahoo.com':
                this.email_count[1] = res.body[i].count;
                break;
              case 'hotmail.com':
                this.email_count[2] = res.body[i].count;
                break;
              case 'aol.com':
                this.email_count[3] = res.body[i].count;
                break;
              case 'icloud.com':
                this.email_count[4] = res.body[i].count;
                 break;
              default:
                var others = parseInt(this.email_count[5]) + parseInt(res.body[i].count);
                this.email_count[5] = others.toString();
            }
          }

          i++;
        }
        var j = 0;
        for (j = 0; j <= 5 ; j++) {
          var perc = 0;
          perc =  ( parseInt( this.email_count[j] ) * 100) / this.total_users;
          
          if ( !isNaN(perc) ){
            if(perc!= 100){
              var percent = perc.toPrecision(2); 
            }
            else{
              var percent =perc.toString();
            }
             
            this.percentage[j] =  percent.toString();
          }
        }
      });

  }
  // Avg Password Reset per User (scenario 1)
  getAvgUserReset() {
    this.restorationS.getAvgUserReset().subscribe((res) => {
      if (res.body) {
        this.avg_user_reset = res.body.Promedio.toPrecision(2);
        this.total_user_reset = res.body.total_user_resets;
        this.percent_user_reset = res.body.porcentaje;
      }
      let all_datas = JSON.stringify(res.body); // se convierte en cadena
      /*
      console.log('Respuesta del servidor en AVG = ' + all_datas);
      console.log('Total promedio= ' + this.avg_user_reset );
      console.log('Total usuarios reseteados= ' + this.total_user_reset );
      console.log('Porcentaje usuarios reseteados= ' + this.percent_user_reset );
      */
    });
  }

  graphicUserByEmailType() {

    // this.total_percentage = [];
    this.total_percentage = this.percentage;
    // DOUGHNUT
    var doughnutChart;
    if( doughnutData ){
      doughnutData.datasets.pop(); // borrar datos
      // this.chart.update(); ng2-chart
      // console.log('Dentro de prueba doughnutData!');
    }
    // console.log('Dentro de prueba de gráfica!');
    // console.log('conteo en graphic = ' + this.email_count);
    // console.log('tipos en graphic = ' + this.email_type);
    var doughnutData = {
      labels: this.email_type,
      datasets: [
        {
          label: "USERS",
          backgroundColor: ["#FF3333", "#4d00ae", "#0078d4",  "#37373F", "#0DF0E2",
          ],
          borderColor: "#F3F3F8",
          // pointBackgroundColor: "#c45850",
          // pointBorderColor: "#c45850",
          data: this.total_percentage,
          //data: this.email_count,
        },
      ],
    };
    var doughnutOptions = {  responsive: true,
      title: {
        display: true,
        text: '% Percentaje'
      }
    };


    document.getElementById("chartContainerDoughnut").innerHTML = '&nbsp;';
    document.getElementById("chartContainerDoughnut").innerHTML = '<canvas id="doughnutChart"></canvas>';

    var ctx = document.getElementById("doughnutChart"); // .getContext("2d");;

    doughnutChart = new Chart(ctx, {
      type: "doughnut",
      data: doughnutData,
      options: doughnutOptions,
    });
  }
// obtener datos de la semana actual 
  getCurrentWeek(){
    var from = this.from_date_current_week + ' ' + '00:00';
    var to = this.to_date_current_week + ' ' + '23:59';
    // console.log('from current week = ' + from);
    // console.log('to current week = ' + to);
    this.restorationS.getAvgUserResetOnTimeScenario1(from, to)
      .subscribe((res) => {
        // console.log("Respuesta current week = " + JSON.stringify(res.body) );        
        let i = 0;    
        while (res.body[i]) {
          if(res.body[i].total_user_resets) {
            this.total_active_users = res.body[i].User_Actived;
            this.total_current_week = res.body[i].total_user_resets;
            this.percent_current_week = (res.body[i].porcentaje).toPrecision(2);                    
            this.avg_current_week = (res.body[i].Promedio).toPrecision(2);
            // console.log('resultados avg Current week= ' + this.avg_current_week);
          }
          i++;
        }
        // console.log('total current week= ' + this.total_current_week);
        
      });
  }
  // obtener datos de la semana anterior a la actual
  getLastWeek(){
    var from = this.from_date_last_week + ' ' + '00:00';
    var to = this.to_date_last_week + ' ' + '23:59';

    this.restorationS.getAvgUserResetOnTimeScenario1(from, to)
      .subscribe((res) => {
        // console.log("Respuesta getLastWeek = " + JSON.stringify(res.body) );    
        let i = 0;    
        while (res.body[i]) {
          if(res.body[i].total_user_resets) {            
            this.total_last_week = res.body[i].total_user_resets;
            this.percent_last_week = (res.body[i].porcentaje).toPrecision(2);
            this.avg_last_week = (res.body[i].Promedio).toPrecision(2);
            // console.log('resultados avg last week= ' + this.avg_last_week);
          }
          i++;
        }
      });    

      setTimeout(() => {
        this.getComparisonWeeks();
      }, 1000);
      
  }
  // se comparan la semana actual a la anterior para verificar la subida o bajada
  getComparisonWeeks(){    
    
    if ( this.avg_current_week  === undefined ) {
      this.avg_current_week = 0;
    }
    this.change_up = true;
    if ( this.avg_last_week === undefined) {
      this.avg_last_week = 0;
    }
    // console.log('this week in comparison = ' + this.avg_current_week);
    // console.log('last week in comparison = ' + this.avg_last_week);
    if ( this.avg_last_week >= this.avg_current_week ) {
      this.rate_of_change  =  ((this.avg_current_week * 100) / this.avg_last_week).toPrecision(2);

      this.change_up = false;
    } else {
      this.rate_of_change  =  ((this.avg_last_week * 100) / this.avg_current_week).toPrecision(2);
      this.change_up = true;
    }
    if ( isNaN(this.rate_of_change)) this.rate_of_change = '0';
  }

  // 
  getAvgUserRangeTime(from_date: String, to_date: String) {     
    
    // console.log('---fecha ini = ' + from_date);
    // console.log('---fecha fin = ' + to_date);
    this.restorationS.getAvgUserResetOnTimeScenario1(from_date, to_date)
      .subscribe((res) => {
        // console.log("Respuesta getAvg = " + JSON.stringify(res.body) );        
        if (res.body) {
          this.total_last_week = res.body.total_user_resets;
          this.percent_last_week = (res.body.porcentaje).toPrecision(2); // sum todos/ num todos
          this.avg_last_week = (res.body.Promedio).toPrecision(2);  //
        }
      });
  }
}
