import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { StatusService } from "../../_services";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";

import * as globals from "../../globals.json";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.css"],
})
export class TermsComponent implements OnInit {
  public globals: any = globals.default;
  public environment: any = environment;
  public email =
    "water@cityof" +
    globals.default.onlyCityName.replace(" ", "").toLowerCase();

  @Input("openFromModal") openFromModal: boolean;
  @Output() termsValueEvent = new EventEmitter<boolean>();
  termsAreRead: boolean = false;

  constructor(
    private bsModalRef: BsModalRef,
    private SS: StatusService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // this.openFromModal = false;
    console.log("environgment : ", this.environment);
    console.log("OPEN FROM MODAL: ", this.openFromModal);
    // console.log('ROUTE : ', this.route.snapshot);
  }

  ngOnInit() {}

  markAsRead() {
    this.SS.setTermsAndConditionsValue(true);
    this.bsModalRef.hide();
  }

  getRouterUrl(): string {
    return this.router.url;
  }

  returnToLogin() {
    console.log("reload");
    this.bsModalRef.hide();
  }
}
