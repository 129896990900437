import { Injectable } from "@angular/core";
import {
  HttpClient,
  /*HttpRequest,*/ HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";

// import { LateFee } from '../_models';

@Injectable({
  providedIn: "root",
})
export class LateFeeService {
  baseUrl: string = environment.serverUrl;
  private reloadSource = new BehaviorSubject<boolean>(false);
  reload = this.reloadSource.asObservable();

  constructor(private http: HttpClient) {}

  getAllLateFees(q: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/api/late-fees/get_filter?from_date=${
        q.from_date
      }&to_date=${q.to_date}&status_id=0&search=${
        q.search || "undefined"
      }&limit=${q.limit ? q.limit : "10"}&page=${q.page ? q.page : "1"}`,
      { observe: "response" }
    );
  }
  getAllLateBywived(waivved): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/api/late-fees/get_waived/${waivved}`,
      { observe: "response" }
    );
  }
  getAll() {
    return this.http.get<any[]>(`${this.baseUrl}/api/late-fees/get_all`);
  }

  updateCreditByID(id, data) {
    return this.http.put(`${this.baseUrl}/api/late-fees/update_credit`, {
      id: id,
      data: data,
    });
  }

  private data;

  setData(data) {
    this.data = data;
  }

  getData() {
    let temp = this.data;
    this.clearData();
    return temp;
  }

  clearData() {
    this.data = undefined;
  }

  generateLateFeesReport(startDate, endDate) {
    return this.http.get(
      `${this.baseUrl}/api/late-fees/report?start_date=${startDate}&end_date=${endDate}`,
      { responseType: "blob" }
    );
  }

  setLateFee(user_id) {
    return this.http.post(`${this.baseUrl}/api/late-fees/all/apply`, {
      user_id,
    });
  }

  getInfoUserLateFee() {
    return this.http.get(`${this.baseUrl}/api/late-fees/last`);
  }
}
