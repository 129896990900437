import { Component, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { LateFeeService } from '../../_services/late-fees.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

declare var $: any;

@Component({
  selector: 'app-late-fee-report',
  templateUrl: './late-fee-report.component.html',
  styleUrls: ['./late-fee-report.component.css']
})
export class LateFeeReportComponent implements OnInit {


  @ViewChild('report') private reportSwal: SwalComponent;
  @ViewChild('failure') private failureSwal: SwalComponent;
  @ViewChild('success') private successSwal: SwalComponent;



  private month = moment().format('MM');
  private year = moment().format('YYYY');
  public titleAlert : string = "<h2>Late Fees Report</h2>";
  public yearsAlert : string = `<option value='01'>January</option>
  <option value='02'>February</option>              
  <option value='03'>March</option>              
  <option value='04'>April</option>
  <option value='05'>May</option>
  <option value='06'>June</option>
  <option value='07'>July</option>
  <option value='08'>August</option>
  <option value='09'>September</option>
  <option value='10'>October</option>
  <option value='11'>November</option>
  <option value='12'>December</option>`;
  public yearAlert : string = "Year";
  public monthAlert : string = "Month";
  public confirmButtonTextAlert : string ="Download";
  public cancelButtonTextAlert : string ="Cancel";
  public failTextAlert : string = "Something went wrong, we are very sorry";
  public successTextAlert : string = "Downloading...";
  public notFounds : string = 'No Late Fees found for selected month.';
  public yearText : string = "Year";


  constructor( private latefeeService: LateFeeService) { }

  ngOnInit() {

    if (window.location.href.includes('/es/')) {
      this.titleAlert = "<h2>Reporte de Pagos Atrasados</h2>";

      this.yearsAlert = `<option value='01'>Enero</option>
      <option value='02'>Febrero</option>              
      <option value='03'>Marzo</option>              
      <option value='04'>Abril</option>
      <option value='05'>Mayo</option>
      <option value='06'>Junio</option>
      <option value='07'>Julio</option>
      <option value='08'>Agosto</option>
      <option value='09'>Septiembre</option>
      <option value='10'>Octubre</option>
      <option value='11'>Noviembre</option>
      <option value='12'>Diciembre</option>`;
      this.yearAlert = 'Año';
      this.monthAlert = 'Mes';
      this.confirmButtonTextAlert = 'Descargar';
      this.cancelButtonTextAlert = 'Cancelar';
      this.failTextAlert = 'Algo salió mal, lo sentimos mucho';
      this.successTextAlert = 'Descargando...';
      this.notFounds = 'No encontrados pagos atrasados en el mes seleccionado.';
    };
  }

  generateLateFeesReport() {
    let month = $('#paymentMonth').val();
    let nextMonth = Number(month) + 1;
    let year = $('#paymentYear').val();
    let endDate;
    let startDate = year + '-' + month + '-01';
    if ( nextMonth < 10 ) {
      endDate =  year + '-0' + nextMonth + '-01';
    } else {
      endDate =  year + '-' + nextMonth + '-01';
    }
    

    this.latefeeService.generateLateFeesReport(startDate, endDate)
    .subscribe(
      response => {
        let filename = `${moment(month, 'MM').format('MMMM')} ${year} Late Fees.xlsx`
        saveAs(response, filename);
        this.successSwal.show()
      },
      error => {
        console.log("error", error);
        if(error.status == 404){
          this.failureSwal.title = this.notFounds
          this.failureSwal.show()
        } else {
          this.failureSwal.show()            
        }
      }
    )


  }



  openAlert() {
    this.reportSwal.show()
    $('#paymentMonth').val(this.month);
    $('#paymentYear').val(this.year);    
  }

  onOpen(event) {
    $('body').removeClass("swal2-height-auto");
  }

}
