import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  TemplateRef,
  Inject,
} from "@angular/core";
// import {Component, OnInit, ViewChild} from '@angular/core';
import * as globals from "../globals.json";
import { FileService } from "../_services/file.service";
import { NgxSpinnerService } from "ngx-spinner";
// import CSVFileValidator from 'prv-file-validator';
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import * as moment from "moment";
//import { userInfo } from 'os';
import { DOCUMENT } from "@angular/common";
import { User } from "../_models";
import { CryptoService } from "../_services";

@Component({
  selector: "app-prv-import",
  templateUrl: "./prv-import.component.html",
  styleUrls: ["./prv-import.component.css"],
})
export class PrvImportComponent implements OnInit {
  @ViewChild("successSwal") private successSwal: SwalComponent;
  @ViewChild("serverError") private serverErrorSwal: SwalComponent;

  @Inject(DOCUMENT) private document: Document;
  user: User;

  public bsConfig: Partial<BsDatepickerConfig>;
  public datePickerDate;
  public globals: any = globals.default;
  public activeTab: string;
  public activeTabContent: any;
  public files: File[] = [];
  public serverErrorTitle =
    "The server encountered an error processing the file.";
  public successTitle = "File uploaded successfully.";
  public loading = false;
  public fileName = "";
  public acceptedFiles = [".zip", ".prv"].join(","); // NEEDS TO HAVE .join OR WILL ERROR
  public AlertDate;
  public due_date;
  public date_penalty_applied;
  public date_late_notice_send;
  public date_late_notice_due;

  // public paymentsHeaders =  ['TYPE PYMT', 'ACCOUNT', 'PAYMENT', 'DATE'];
  // public billsHeaders = ['ACCOUNT', 'ACCOUNT BAL', 'CURRENT BILL'];
  public insertions;
  public failedInsertions;
  public log_payments_only = false;

  constructor(
    private fileService: FileService,
    private spinner: NgxSpinnerService,
    private cryptoService: CryptoService
  ) {}

  onSelect(event) {
    this.files = [...event.addedFiles];
    console.log(this.files);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    if (this.user.user_type_id == 5) {
      this.activeTab = "payments";
    } else {
      this.activeTab = "consumption";
    }

    if (window.location.href.includes("/es/")) {
      this.serverErrorTitle =
        "El servidor encontró un error al procesar el archivo.";
      this.successTitle = "El archivo ha subido correctamente.";
    }
  }

  changeFileType() {}

  uploadFile() {
    console.log(this.activeTab);
    this.fileName = this.files[0].name;
    this.spinner.show();
    this.loading = true;
    const formData = new FormData();
    const context = this;
    // THIS PART DEPENDS ON THE ACTIVE TAB
    if (this.activeTab == "consumption") {
      formData.append("txt", this.files[0], this.files[0].name);
      formData.append("date", new Date(this.datePickerDate).toISOString());
      this.fileService.uploadPrvFile(formData).subscribe(
        (data) => {
          this.spinner.hide();
          this.loading = false;
          this.files = [];
          this.insertions = data["total"]["total"];
          this.failedInsertions = data["total"]["failed"];
          this.successSwal.show();
        },
        (err) => {
          this.spinner.hide();
          this.loading = false;
          this.serverErrorSwal.show();
        }
      );
    } else if (this.activeTab == "payments") {
      formData.append("txt", this.files[0], this.files[0].name);
      formData.append(
        "logOnly",
        this.log_payments_only === true ? "true" : "false"
      );
      this.fileService.uploadPdfFile(formData).subscribe(
        (data) => {
          this.spinner.hide();
          this.loading = false;
          this.files = [];
          this.insertions = data["total"]["successful"];
          this.failedInsertions = data["total"]["failed"];
          this.successSwal.title = "File processed successfully";
          if (window.location.href.includes("/es/")) {
            this.successSwal.title = "Archivo procesado exitosamente";
          }
          this.successSwal.show();
        },
        (err) => {
          this.spinner.hide();
          this.loading = false;
          this.serverErrorSwal.show();
        }
      );
    } else {
      console.log("TAB DOES NOT EXIST");
    }
  }

  clear() {
    this.files = [];
  }

  writeFailedCsv() {
    const rows = [
      ["ACCOUNT", "NAME", "ADDRESS", "BALANCE", "CURRENT BILL", "ERROR"],
    ];

    this.failedInsertions.map((insertion) => {
      rows.push([
        insertion["data"]["account"],
        insertion["data"]["name"],
        insertion["data"]["address"],
        insertion["data"]["balance"],
        insertion["data"]["current_bill"],
        insertion["error"],
      ]);
    });
    this.downloadFailedCsv(rows);
  }

  downloadFailedCsv(rows) {
    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    this.downloadFile(
      csvContent,
      this.fileName.substring(0, this.fileName.length - 4) + "_FAILED.csv"
    );
  }

  downloadFile(content, filename) {
    const encodedUri = encodeURI(content);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  switchTab(tab) {
    console.log("tab: ", tab);
    this.activeTab = tab;
    // this.search();

    //Hide current active tab title
    const activeTabTitle = $("#tab-title").find("li.active");
    activeTabTitle.removeClass("active");

    //Show new tab title
    const newActiveTabTitle = $(`#tab-title-${tab}`);
    newActiveTabTitle.addClass("active");

    //Clear file on tab switch
    this.files.splice(0, 1);
  }

  updateDate(date) {
    this.due_date = moment(date).format("L");
    this.AlertDate =
      "Are you sure the\n Due Date selected is correct? \n" + this.due_date;
    if (window.location.href.includes("/es/")) {
      this.AlertDate =
        "¿Esta seguro que la \n Fecha de Vencimiento es correcta? \n" +
        this.due_date;
    }
    this.date_penalty_applied = moment(date).add(4, "day").format("L");
    this.date_late_notice_send = moment(date).add(8, "day").format("L");
    this.date_late_notice_due = moment(date).add(8, "day").format("L");

    var holidays = [
      "01/01/2020",
      "01/10/2020",
      "02/17/2020",
      "05/25/2020",
      "07/03/2020",
      "09/07/2020",
      "10/12/2020",
      "11/11/2020",
      "11/26/2020",
      "12/25/2020",
    ];

    holidays.forEach((holiday) => {
      if (this.date_penalty_applied === holiday) {
        if (moment(this.date_penalty_applied).day() === 6) {
          this.date_penalty_applied = moment(this.date_penalty_applied)
            .subtract(1, "day")
            .format("L");
        } else {
          this.date_penalty_applied = moment(this.date_penalty_applied)
            .add(1, "day")
            .format("L");
        }
      }
      if (this.date_late_notice_send === holiday) {
        if (moment(this.date_late_notice_send).day() === 6) {
          this.date_late_notice_send = moment(this.date_late_notice_send)
            .subtract(1, "day")
            .format("L");
        } else {
          this.date_late_notice_send = moment(this.date_late_notice_send)
            .add(1, "day")
            .format("L");
        }
      }
      if (this.date_late_notice_due === holiday) {
        if (moment(this.date_late_notice_due).day() === 6) {
          this.date_late_notice_due = moment(this.date_late_notice_due)
            .subtract(1, "day")
            .format("L");
        } else {
          this.date_late_notice_due = moment(this.date_late_notice_due)
            .add(1, "day")
            .format("L");
        }
      }
    });

    if (
      moment(this.date_penalty_applied).day() === 6 ||
      moment(this.date_penalty_applied).day() === 0
    ) {
      this.date_penalty_applied = moment(this.date_penalty_applied)
        .add(1, "weeks")
        .isoWeekday(1)
        .format("L");
    }

    if (
      moment(this.date_late_notice_send).day() === 6 ||
      moment(this.date_late_notice_send).day() === 0
    ) {
      this.date_late_notice_send = moment(this.date_late_notice_send)
        .add(1, "weeks")
        .isoWeekday(1)
        .format("L");
    }

    if (
      moment(this.date_late_notice_due).day() === 6 ||
      moment(this.date_late_notice_due).day() === 0
    ) {
      this.date_late_notice_due = moment(this.date_late_notice_due)
        .add(1, "weeks")
        .isoWeekday(1)
        .format("L");
    }

    holidays.forEach((holiday) => {
      if (this.date_penalty_applied === holiday) {
        if (moment(this.date_penalty_applied).day() === 6) {
          this.date_penalty_applied = moment(this.date_penalty_applied)
            .subtract(1, "day")
            .format("L");
        } else {
          this.date_penalty_applied = moment(this.date_penalty_applied)
            .add(1, "day")
            .format("L");
        }
      }
      if (this.date_late_notice_send === holiday) {
        if (moment(this.date_late_notice_send).day() === 6) {
          this.date_late_notice_send = moment(this.date_late_notice_send)
            .subtract(1, "day")
            .format("L");
        } else {
          this.date_late_notice_send = moment(this.date_late_notice_send)
            .add(1, "day")
            .format("L");
        }
      }
      if (this.date_late_notice_due === holiday) {
        if (moment(this.date_late_notice_due).day() === 6) {
          this.date_late_notice_due = moment(this.date_late_notice_due)
            .subtract(1, "day")
            .format("L");
        } else {
          this.date_late_notice_due = moment(this.date_late_notice_due)
            .add(1, "day")
            .format("L");
        }
      }
    });
  }
}
