import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-location-modal",
  templateUrl: "./location-modal.component.html",
  styleUrls: ["./location-modal.component.css"],
})
export class LocationModalComponent implements OnInit {
  latitude: number;
  longitude: number;

  constructor(
    public dialogRef: MatDialogRef<LocationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const [lat, lng] = data.location.split(",").map(Number);
    this.latitude = lat;
    this.longitude = lng;
  }

  ngOnInit() {
    console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
