export class UsioConstants {
  public static readonly PRICE_CEILING_TERMINAL_PERCENT: number = 3.15; // 4 CC
  public static readonly PRICE_CEILING_TERMINAL_DECIMAL: number =
    UsioConstants.PRICE_CEILING_TERMINAL_PERCENT / 100; // CC
  public static readonly PRICE_CEILING_TERMINAL_FEE_FLAT: number = 2.95; // CC USIO RATE
  public static readonly PRICE_CEILING_WEB_PERCENT: number = 3.15; // 5 CC
  public static readonly PRICE_CEILING_WEB_DECIMAL: number =
    UsioConstants.PRICE_CEILING_WEB_PERCENT / 100;
  public static readonly PRICE_CEILING_WEB_FEE_FLAT: number = 2.95; // CC USIO RATE
  public static readonly PRICE_CEILING_PERCENT_ACH: number = 2.7;
  public static readonly PRICE_CEILING_PERCENT_ACH_DECIMAL: number =
    UsioConstants.PRICE_CEILING_PERCENT_ACH / 100;
  public static readonly PRICE_CEILING_FLAT_ACH: number = 2.7; // ACH
  public static readonly FEE_PERCENT_WEB: number = 2.75; //2.5 CC USIO RATE
  public static readonly FEE_DECIMAL_WEB: number =
    UsioConstants.FEE_PERCENT_WEB / 100; // CC
  public static readonly FEE_PERCENT_TERMINAL: number = 2.65; // 2.4 CC USIO RATE
  public static readonly FEE_DECIMAL_TERMINAL: number =
    UsioConstants.FEE_PERCENT_TERMINAL / 100; // CC
  public static readonly ACH_CONV_FEE_FLAT: number = 1;
  public static readonly ACH_FEE_PERCENT: number = 1; // ACH
  public static readonly ACH_FEE_PERCENT_DECIMAL: number =
    UsioConstants.ACH_FEE_PERCENT / 100; // ACH
  public static readonly FEE_PERCENT: number = 5;
  public static readonly FEE_DECIMAL: number = UsioConstants.FEE_PERCENT / 100;

  public static readonly DELTA_THRESHOLD: number[] = [100];
  public static readonly PAY_LIMIT: number = 500;

  public static readonly CONV_FEE = { MIN: 1, MAX: 6 };
  public static readonly CARD_NUMBER = { MIN: 14, MAX: 17 };
  public static readonly CARD_TYPE = { MIN: 4, MAX: 4 };
  public static readonly EXP_DATE = { MIN: 6, MAX: 6 };
  public static readonly ROUTING_NUMBER = { MIN: 9, MAX: 9 };
  public static readonly ACCOUNT_NUMBER = { MIN: 5, MAX: 17 };
  public static readonly AMOUNT = { MIN: 1, MAX: 8 };
  public static readonly FIRST_NAME = { MIN: 2, MAX: 19 };
  public static readonly LAST_NAME = { MIN: 2, MAX: 19 };
  public static readonly EMAIL = { MIN: 2, MAX: 39 };
  public static readonly ADDRESS = { MIN: 2, MAX: 39 };
  public static readonly CITY = { MIN: 2, MAX: 39 };
  public static readonly STATE = { MIN: 2, MAX: 2 };
  public static readonly ZIP_CODE = { MIN: 4, MAX: 10 };
  public static readonly COUNTRY = { MIN: 2, MAX: 2 };
  public static readonly SECURITY_CODE = { MIN: 3, MAX: 4 };
  public static readonly NOTES_1 = { MIN: 0, MAX: 20 };
  public static readonly TERMINAL_ID = { MIN: 0, MAX: 10 };
  public static readonly codes = { MIN: 0, MAX: 10 };
}
