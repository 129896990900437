import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { RestorePasswordModel } from "../_models/restore-password.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RestorationPasswordService {
  baseUrl: string = environment.serverUrl;
  constructor(private http: HttpClient) {}

  // ruta Password Reset Counts by Email Type =
  // http://localhost:3000/api/metrics/user_resetByEmail
  getUserResetByEmail(): Observable<HttpResponse<any>> {
    // console.log("en getUser ResetBy Email Services");
    let res = this.http.get<any>(
      `${this.baseUrl}/api/metrics/user_resetByEmail`,
      { observe: "response" }
    );
    // console.log("RES UserResetBy Email SERVICES: " + res);
    return res;
  }

  // ruta Password Reset Count by Email Type within Time Range  =
  // http://localhost:3000/api/metrics/user_resetByEmailandTime/{{from}}/{{to}}
  getUserResetByEmailTime(from, to) {
    // console.log("en getUser Reset email time Services");
    let res = this.http.get<RestorePasswordModel[]>(
      `${this.baseUrl}/api/metrics/user_resetByEmailandTime/${from}/${to}`,
      { observe: "response" }
    );

    return res;
  }

  // ruta Avg Password Reset per User (scenario 1) =
  // http://localhost:3000/api/metrics/user_avgperuser

  getAvgUserReset(): Observable<HttpResponse<any>> {
    // console.log("en get AVG User  ResetBy Email Services");
    let res = this.http.get<any>(
      `${this.baseUrl}/api/metrics/user_avgperuser`,
      { observe: "response" }
    );
    // console.log("RES AVG UserResetBy Email SERVICES: " + res);
    return res;
  }

  // ruta Avg Password Reset per User that HAS Password Reset at least 1 or more. (scenario 2) =
  // http://localhost:3000/api/metrics/user_avgperuserMost
  getAvgUserResetAtLeastOne(): Observable<HttpResponse<any>> {
    // console.log("en get AVG User At least One ResetBy Email Services");
    let res = this.http.get<any>(
      `${this.baseUrl}/api/metrics/user_avgperuserMost`,
      { observe: "response" }
    );
    // console.log("RES AVG User At least One ResetBy Email SERVICES: " + res);
    return res;
  }

  // ruta Avg for Password Reset based on Time Ranges for scenario (1) =
  // http://localhost:3000/api/metrics/user_getAvgPasswordResetInRangeTime/{{from}}/{{to}}
  getAvgUserResetOnTimeScenario1(from, to) {
    // console.log("en Avg User Reset email on time range Services");
    let res = this.http.get<any>(
      `${this.baseUrl}/api/metrics/user_getAvgPasswordResetInRangeTime/${from}/${to}`,
      { observe: "response" }
    );
    return res;
  }

// ruta Avg for Password Reset based on Time Ranges for scenario (2) =
// http://localhost:3000/api/metrics/user_getAvgPerUserMostInRangeTime/{{from}}/{{to}}

  getAvgUserResetOnTimeScenario2(from, to) {
    // console.log("en Avg User Reset email on time range Services");
    let res = this.http.get<RestorePasswordModel[]>(
      `${this.baseUrl}/api/metrics/user_getAvgPerUserMostInRangeTime/${from}/${to}`,
      { observe: "response" }
    );
    return res;
  }
// Total User Activations 
  getUserActivation(): Observable<HttpResponse<any>> {    
    let res = this.http.get<any>(
      `${this.baseUrl}/api/metrics/user_GeneralAnalytics`,
      { observe: "response" }
    );
    return res;
  }  
}

