import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpBackend }	from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerifyUsersService {

  baseUrl: string = environment.serverUrl;


  constructor(private http : HttpClient, private handler : HttpBackend) {
  }

  sendVerifyUserEmail(data : any){
    return this.http.post(`${this.baseUrl}/api/users/send_verify_user_email`,data);
  }

  verifyUser(data : any){
    return this.http.post(`${this.baseUrl}/api/users/verify_user`, data)
  }
}
