import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { User, UserInfo } from "../_models";
import { UserSettings } from "../_models";
import { Account } from "../_models";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  baseUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) {}

  getAccountById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/api/accounts/${id}`);
  }

  updateAccountById(id: number, account) {
    return this.http.put(`${this.baseUrl}/api/accounts/${id}`, account);
  }

  updateAccountZoneById(id: number, account) {
    return this.http.put(`${this.baseUrl}/api/accounts/${id}/zone`, account);
  }

  updateUnlinkedAccountZoneById(id: number, account) {
    return this.http.put(
      `${this.baseUrl}/api/accounts/unlinked/${id}/zone`,
      account
    );
  }

  getZoneMapData() {
    return this.http.get<any>(`${this.baseUrl}/api/accounts/zone/map`);
  }

  getAccountByIncodeAccountNumber(account_number: string) {
    return this.http.get<any>(
      `${this.baseUrl}/api/accounts/incode_account_no/${account_number}`
    );
  }

  updateAccountFlagUser(id: number, flag: boolean) {
    return this.http.put(`${this.baseUrl}/api/accounts/${id}/${flag}`, {});
  }

  setPaymentMethod(accountId: number, methodId: number) {
    return this.http.put(
      `${this.baseUrl}/api/accounts/set-payment-method/${accountId}/${methodId}`,
      {}
    );
  }

  getBalanceIncode(account_number: number) {
    return this.http.get(
      `${this.baseUrl}/api/accounts/seed-account/${account_number}`
    );
  }

  getAccountFlagUse(accountId: number, flag_use) {
    return this.http.put(`${this.baseUrl}/api/accounts/flag-use/${accountId}`, {
      flag_use: flag_use,
    });
  }
}
