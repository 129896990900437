import {Address, WaterConsumption} from './';
export class Bills {
	id 				: number;
	user_id 		: number;
	account_id  	: number;
  	bill_status     : string;
	bill_status_id 	: number;
	typer_tech_id	: string;
	balance         : number;
	amount_due 		: number;
	amount_due_late : number;
  	first_name      : string;
  	last_name       : string;
  	mailing_address? : Address;
  	service_address? : Address;
  	water_consumption?: WaterConsumption;
  	mobile_phone    : string;
  	due_date        : string;
  	incode_account_no: number;
}
