import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { FormBuilder, Validators, FormArray, FormGroup } from "@angular/forms";

import {
  TypeService,
  CustomerService,
  StatusService,
  UserService,
  WorkOrderService,
  DepartmentsService,
  CryptoService,
} from "../../_services";
import {
  TicketTypes,
  Ticket,
  Account,
  TicketStatus,
  WorkOrderTypes,
  Department,
} from "src/app/_models";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-create-work-order",
  templateUrl: "./create-work-order.component.html",
  styleUrls: ["./create-work-order.component.css"],
})
export class CreateWorkOrderComponent implements OnInit {
  ticketStatuses: TicketStatus[];
  @ViewChild("createSuccess") private createSuccessSwal: SwalComponent;
  public newWorkOrderFormGroup: FormGroup;
  public errorMessage: string;

  public ticket;
  public departments: Department[];

  public accounts: Account[];
  public ticketTypes: TicketTypes[];
  public workOrderTypes: WorkOrderTypes[];

  public user: any;
  public staffUsers: any;

  public filesToUpload: File[];
  public viewFiles: any[];
  public loading: boolean = false;

  public createSuccessAlertTitle = "Work Order Generated ";
  public enterTitlePlaceholder = "Enter Title";
  public enterDescriptionPlaceholder = "Enter Description";

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private customerService: CustomerService,
    private typeService: TypeService,
    private cd: ChangeDetectorRef,
    private statusService: StatusService,
    private userService: UserService,
    private workOrderService: WorkOrderService,
    private departmentService: DepartmentsService,
    private cryptoService: CryptoService
  ) {
    this.newWorkOrderFormGroup = this.fb.group({
      title: ["", Validators.required],
      ticket_id: ["", Validators.required],
      description: ["", Validators.required],
      work_order_type: ["", Validators.required],
      work_order_status: ["", Validators.required],
      assigned_to: [""],
      priority: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    this.getDepartments();

    if (window.location.href.includes("/es/")) {
      this.createSuccessAlertTitle = "Orden de Trabajo Generada";
      this.enterTitlePlaceholder = "Ingrese un Título";
      this.enterDescriptionPlaceholder = "Ingrese una Descripción";
    }
  }

  setCreateWorkOrderForm() {
    console.log("ticket: ", this.ticket);
    this.newWorkOrderFormGroup.setValue({
      title: "",
      description: "",
      assigned_to: "",
      ticket_id: this.ticket.id,
      work_order_type: this.ticket.ticket_type_id,
      work_order_status: 1,
      priority: "Medium",
    });

    this.user = JSON.parse(window.localStorage.current_user);

    this.userService.getAccountsById(this.ticket.user_id).subscribe((data) => {
      console.log("accounts: ", data);
      this.accounts = data;
    });

    this.userService.getUsersByAccountType(1).subscribe((data) => {
      console.log("users: ", data);
      this.staffUsers = data;
    });

    this.typeService
      .getWorkOrderTypes()
      .subscribe((data) => (this.workOrderTypes = data));

    this.typeService
      .getTicketTypes()
      .subscribe((data) => (this.ticketTypes = data));

    this.statusService
      .getTicketStatus()
      .subscribe((data) => (this.ticketStatuses = data));
  }

  submitWorkOrder() {
    this.loading = true;
    const workOrder = {
      ticket_id: this.newWorkOrderFormGroup.value.ticket_id,
      work_order_type_id: this.newWorkOrderFormGroup.value.work_order_type,
      work_order_status_id: this.newWorkOrderFormGroup.value.work_order_status,
      details: {
        title: this.newWorkOrderFormGroup.value.title,
        description: this.newWorkOrderFormGroup.value.description,
        priority: this.newWorkOrderFormGroup.value.priority,
        assigned_to: this.newWorkOrderFormGroup.value.assigned_to,
      },
    };

    //testing purposes
    // workOrder.details.assigned_to = workOrder.details.assigned_to == "undefined" ? "cerebrus.perficientur@gmail.com" : workOrder.details.assigned_to;

    console.log("Work order : ", workOrder);

    this.workOrderService.createWorkOrder(workOrder).subscribe((data) => {
      this.loading = false;
      this.createSuccessSwal.show();
      this.workOrderService.newWorkOrder();
    });
  }

  getDepartments() {
    this.departmentService.getAllDepartments().subscribe(
      (results) => {
        console.log("Department results: ", results);
        this.departments = results;
      },
      (error) => {
        console.log("Error: ", error);
      }
    );
  }
}
