import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-request-id',
  templateUrl: './request-id.component.html',
  styleUrls: ['./request-id.component.css']
})
export class RequestIDComponent implements OnInit {
  public imageUrl: any;

  constructor(public bsModalRef: BsModalRef, private _sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  setRequestInfo(imageUrl){
    this.imageUrl = imageUrl;
  }
}
