import { Component, OnInit, ViewChild } 				from '@angular/core';
import { ResetPasswordService}				from '../_services';
import { ActivatedRoute, Router }			from '@angular/router';
import { flatMap } 						from 'rxjs/operators';
import { SwalComponent } 					from '@toverux/ngx-sweetalert2';
import { viewClassName } from '@angular/compiler';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

declare var $:any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  @ViewChild('success') private successSwal: SwalComponent;
  @ViewChild('error') private errorSwal: SwalComponent;
  errorMessage : string = String();
  reset_password_data = {
	  password : String(),
	  confirm_password : String()
  };
  passwordResetFormGroup : FormGroup;
  public successAlertTitle = "Successful";
  public successAlertText ="Your new password was sucessfully saved.";
  public errorAlertTitle = "Error";
  public errorAlertText="There was a server error.";
  public newPasswordPlaceholder ="New password";
  public confirmPasswordPlaceholder = "Confirm password";

  constructor(
  	private RPS: ResetPasswordService,
  	private ActiveRoute : ActivatedRoute,
	  private router : Router,
	  private fb : FormBuilder
  ) {
	this.passwordResetFormGroup = this.fb.group({
		password :  new FormControl('',[Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{10,}$')]),
		confirmPassword : new FormControl('',[Validators.required])
	},{
		validator: this.matchValidator.bind(this)
	})
  }

  ngOnInit() {
    if(window.location.href.includes("/es/")){
      this.successAlertTitle = "Exito";
      this.successAlertText ="Su password ha sido guardado correctamente.";
      this.errorAlertTitle = "Error";
      this.errorAlertText="Hubo un error con el servidor";
      this.newPasswordPlaceholder ="Nueva Contraseña";
      this.confirmPasswordPlaceholder = "Confirme su contraseña";

    }
  }

  onOpen(event){
	$('body').removeClass("swal2-height-auto");
  }

  get password() {
	return this.passwordResetFormGroup.get('password');
  }

  reset_password(passData){
  	// console.log("passData: ", passData);

  	this.ActiveRoute.queryParams
  		.subscribe(params => {
  			console.log("params: ", params);
  			passData.email = params.email;
  			passData.token = params.token;
  			console.log("passData: ", passData);


			this.RPS.resetPassword(passData)
		  		.subscribe(response => {
		  			console.log("response", response);
		  			if(response.status === 200){
		  				this.successSwal.show();
		  			}
		  		},error => {
					  console.log("Errro: ", error);
					  if(error.status === 400){
						this.errorMessage = error.message;
					  }else{
						  this.errorSwal.show();
					  }
		  		});
  		})
  }

  matchValidator(fg: FormGroup) {
    let values = []
    for (let prop in fg.controls){
      values.push(fg.controls[prop].value)
    }

    if (values[1].length <= 0) {
      return null;
    }

    if (values[1] !== values[0]) {
      return {
        doesMatchValue: true
      };
    }

    return null;
  }

  navigateToLogin(){
    this.router.navigate(['/login'])
  }

  void(){}

}
