export class Payments {
    id                : number;
    user_id           : number;
    account_id 		  : number;
    bill_status_id 	  : number;
    tyler_tech_id 	  : number;
    amount 	  		  : number;
    date_created      : string;
    date_updated      : string;
}
