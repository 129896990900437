import { Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormBuilder, Validators, FormArray, FormGroup, FormControl} from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

import { CustomerService, TypeService } from '../../_services';
import {AccountTypes} from '../../_models';

declare var $: any;

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.css']
})
export class CreateCustomerComponent implements OnInit {
  @ViewChild('createSuccess') private createSuccessSwal: SwalComponent;
  @ViewChild('createFailure') private createFailureSwal: SwalComponent;
  @ViewChild('createDataFailure') private createDataFailureSwal: SwalComponent;
  @ViewChild('pop') private passwordpop: any;
  @Output() reload = new EventEmitter();
  public newCustomerFormGroup: FormGroup;
  public emailFormGroup: FormGroup;
  public nameFormGroup: FormGroup;
  public accountFormGroup: FormGroup;
  public addressFormGroup: FormGroup;
  public errorMessage: string;
  loading = false;
  public createSuccessAlertTitle = 'Created New Customer ';
  public createFailureAlertTitle = 'Something went wrong, we are very sorry ';
  public createDataFailureAlertTitle = 'Something went wrong, please check the data entered ';
  public accountTypes: AccountTypes[] = [];
  public states = [
    {
      'name': 'Alabama',
      'abbreviation': 'AL'
    },
    {
      'name': 'Alaska',
      'abbreviation': 'AK'
    },
    {
      'name': 'American Samoa',
      'abbreviation': 'AS'
    },
    {
      'name': 'Arizona',
      'abbreviation': 'AZ'
    },
    {
      'name': 'Arkansas',
      'abbreviation': 'AR'
    },
    {
      'name': 'California',
      'abbreviation': 'CA'
    },
    {
      'name': 'Colorado',
      'abbreviation': 'CO'
    },
    {
      'name': 'Connecticut',
      'abbreviation': 'CT'
    },
    {
      'name': 'Delaware',
      'abbreviation': 'DE'
    },
    {
      'name': 'District Of Columbia',
      'abbreviation': 'DC'
    },
    {
      'name': 'Federated States Of Micronesia',
      'abbreviation': 'FM'
    },
    {
      'name': 'Florida',
      'abbreviation': 'FL'
    },
    {
      'name': 'Georgia',
      'abbreviation': 'GA'
    },
    {
      'name': 'Guam',
      'abbreviation': 'GU'
    },
    {
      'name': 'Hawaii',
      'abbreviation': 'HI'
    },
    {
      'name': 'Idaho',
      'abbreviation': 'ID'
    },
    {
      'name': 'Illinois',
      'abbreviation': 'IL'
    },
    {
      'name': 'Indiana',
      'abbreviation': 'IN'
    },
    {
      'name': 'Iowa',
      'abbreviation': 'IA'
    },
    {
      'name': 'Kansas',
      'abbreviation': 'KS'
    },
    {
      'name': 'Kentucky',
      'abbreviation': 'KY'
    },
    {
      'name': 'Louisiana',
      'abbreviation': 'LA'
    },
    {
      'name': 'Maine',
      'abbreviation': 'ME'
    },
    {
      'name': 'Marshall Islands',
      'abbreviation': 'MH'
    },
    {
      'name': 'Maryland',
      'abbreviation': 'MD'
    },
    {
      'name': 'Massachusetts',
      'abbreviation': 'MA'
    },
    {
      'name': 'Michigan',
      'abbreviation': 'MI'
    },
    {
      'name': 'Minnesota',
      'abbreviation': 'MN'
    },
    {
      'name': 'Mississippi',
      'abbreviation': 'MS'
    },
    {
      'name': 'Missouri',
      'abbreviation': 'MO'
    },
    {
      'name': 'Montana',
      'abbreviation': 'MT'
    },
    {
      'name': 'Nebraska',
      'abbreviation': 'NE'
    },
    {
      'name': 'Nevada',
      'abbreviation': 'NV'
    },
    {
      'name': 'New Hampshire',
      'abbreviation': 'NH'
    },
    {
      'name': 'New Jersey',
      'abbreviation': 'NJ'
    },
    {
      'name': 'New Mexico',
      'abbreviation': 'NM'
    },
    {
      'name': 'New York',
      'abbreviation': 'NY'
    },
    {
      'name': 'North Carolina',
      'abbreviation': 'NC'
    },
    {
      'name': 'North Dakota',
      'abbreviation': 'ND'
    },
    {
      'name': 'Northern Mariana Islands',
      'abbreviation': 'MP'
    },
    {
      'name': 'Ohio',
      'abbreviation': 'OH'
    },
    {
      'name': 'Oklahoma',
      'abbreviation': 'OK'
    },
    {
      'name': 'Oregon',
      'abbreviation': 'OR'
    },
    {
      'name': 'Palau',
      'abbreviation': 'PW'
    },
    {
      'name': 'Pennsylvania',
      'abbreviation': 'PA'
    },
    {
      'name': 'Puerto Rico',
      'abbreviation': 'PR'
    },
    {
      'name': 'Rhode Island',
      'abbreviation': 'RI'
    },
    {
      'name': 'South Carolina',
      'abbreviation': 'SC'
    },
    {
      'name': 'South Dakota',
      'abbreviation': 'SD'
    },
    {
      'name': 'Tennessee',
      'abbreviation': 'TN'
    },
    {
      'name': 'Texas',
      'abbreviation': 'TX'
    },
    {
      'name': 'Utah',
      'abbreviation': 'UT'
    },
    {
      'name': 'Vermont',
      'abbreviation': 'VT'
    },
    {
      'name': 'Virgin Islands',
      'abbreviation': 'VI'
    },
    {
      'name': 'Virginia',
      'abbreviation': 'VA'
    },
    {
      'name': 'Washington',
      'abbreviation': 'WA'
    },
    {
      'name': 'West Virginia',
      'abbreviation': 'WV'
    },
    {
      'name': 'Wisconsin',
      'abbreviation': 'WI'
    },
    {
      'name': 'Wyoming',
      'abbreviation': 'WY'
    }
  ];

  constructor(
    public customerService: CustomerService,
    public typeService: TypeService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder
  ) {

    this.emailFormGroup = this.fb.group({
      email: ['', Validators.required],
      confirmEmail: ['', Validators.required]
    }, {
        validator: this.matchValidator.bind(this)
      });

    this.nameFormGroup = this.fb.group({
      full_name: ['', Validators.required],
      account_number: ['', Validators.required]
    });

    this.accountFormGroup = this.fb.group({
      account_balance: ['', Validators.required],
      account_type_id: ['', Validators.required]
    });

    this.addressFormGroup = this.fb.group({
      service_address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip_code: ['', Validators.required]
    });

    this.newCustomerFormGroup = this.fb.group({
      emailFormGroup: this.emailFormGroup,
      addressFormGroup: this.addressFormGroup,
      nameFormGroup: this.nameFormGroup,
      accountFormGroup: this.accountFormGroup
    });
  }

  ngOnInit() {
    this.typeService.getAccountTypes_support()
      .subscribe(
        data => {
          this.accountTypes = data;
        }
      );

      if (window.location.href.includes('/es/')) {
        this.createSuccessAlertTitle = 'Crear Nuevo Cliente ';
        this.createFailureAlertTitle = 'Algo salió mal, lo sentimos mucho';
        this.createDataFailureAlertTitle = 'Algo salió mal, verifique los datos ingresados';
        
      }
  }

  ngAfterViewInit() {
    // Add chosen to select elements
    $('.chosen-select').chosen({ width: '100%' });
  }

  returnToUsers() {
    console.log('reload');
    this.reload.emit('reload');
    this.bsModalRef.hide();
  }

  submitNewCustomer() {
    this.loading = true;
    const user = {
      full_name: this.nameFormGroup.value.full_name,
      email: this.emailFormGroup.value.email,
      account_number: this.nameFormGroup.value.account_number,
      service_address: this.addressFormGroup.value.service_address,
      city: this.addressFormGroup.value.city,
      state: this.addressFormGroup.value.state,
      zip_code: this.addressFormGroup.value.zip_code,
      account_balance: parseFloat(this.accountFormGroup.value.account_balance).toFixed(2),
      account_type_id: parseInt(this.accountFormGroup.value.account_type_id),
    };

    console.log(user);
    this.customerService.createCustomerWithAccount(user)
      .subscribe(
        data => {
          this.loading = false;
          delete this.errorMessage;
          this.createSuccessSwal.show();
        },
        err => {
          console.log('error: ', err);
          this.loading = false;
          if (err.status == 422) {
            this.errorMessage = err.message;
            this.createFailureSwal.title = err.message;
            this.createFailureSwal.show();
          }else if (err.status == 400) {
            this.createDataFailureSwal.title = "Error"
            this.createDataFailureSwal.text = err.message;
            this.createDataFailureSwal.show();
          }else{
            this.createFailureSwal.title = "Error";
            this.createFailureSwal.text = err.message;
            this.createFailureSwal.show();
          }
          
        }
      );
  }

  matchValidator(registrationFormGroup: FormGroup) {
    const values = [];
    for (const prop in registrationFormGroup.controls) {
      values.push(registrationFormGroup.controls[prop].value);
    }

    if (values[1].length <= 0) {
      return null;
    }

    if (values[1] !== values[0]) {
      return {
        doesMatchValue: true
      };
    }

    return null;
  }

  balanceKeyIsPressed(e) {
    console.log(e.which);
    if (e.shiftKey ) {
      return e.which == 9;
    }
    if (e.which > 57 && e.which < 95 || (e.which > 105 && e.which != 110 && e.which != 190)) {
      return false;
    }
    return e.which != 32;
  }
}
