import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Department } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  baseUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) { }

  getAllDepartments(){
    return this.http.get<Department[]>(`${this.baseUrl}/api/departments/`);
  }
}