import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse }	from '@angular/common/http';
import { Zone } from '../_models'
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ZoneService {
  private baseUrl: string = environment.serverUrl


  constructor(private http: HttpClient) { }

  getZones() {
  	return this.http.get<Zone[]>(`${this.baseUrl}/api/zones`)
  }

  getEmailsByZoneId(zone_id : number){
    return this.http.get(`${this.baseUrl}/api/zones/get_email_by_zone/${zone_id}`);
  }

}