import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AdminEmailsService {
  baseUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) {}

  getEmailsAdmin() {
    return this.http.get<any>(`${this.baseUrl}/api/emails-admin/`);
  }

  putEmailsAdmin(data) {
    return this.http.post<any>(`${this.baseUrl}/api/emails-admin/`, {
      ...data,
    });
  }

  getAccounts() {
    return this.http.get<any>(`${this.baseUrl}/api/accounts`);
  }

  updateAccountById(id: number, account) {
    return this.http.put(`${this.baseUrl}/api/accounts/${id}`, account);
  }
}
