import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../_services";
import decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class DefaultGuard implements CanActivate {
  constructor(private router: Router, public auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isAuthenticated()) {
      const token = localStorage.getItem("access_token");
      const tokenPayload = decode(token);

      if (tokenPayload.userType == 3) {
        this.router.navigate(["dashboard"]);
      } else if (tokenPayload.userType == 2) {
        this.router.navigate(["work_orders"]);
      } else {
        this.router.navigate(["users"]);
      }

      return true;
    }

    this.router.navigate(["login"]);
    return false;
  }
}
