import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-link-sent',
  templateUrl: './link-sent.component.html',
  styleUrls: ['./link-sent.component.css']
})
export class LinkSentComponent implements OnInit {

  confirmation;

  constructor(public bsModalRef: BsModalRef) { 
    this.checkOutput(this.confirmation)
  }

  ngOnInit() {
    
  }

  onConfirm(){
  this.bsModalRef.hide() 
  }

  checkOutput(confirmation){
    console.log(confirmation)
  }

}
