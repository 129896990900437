import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import * as globals from "../globals.json";
import * as moment from "moment";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { CryptoService, PackageService } from "../_services";
declare var $: any;

@Component({
  selector: "app-packages",
  templateUrl: "./packages.component.html",
  styleUrls: ["./packages.component.css"],
})
export class PackagesComponent implements OnInit, OnDestroy {
  public globals: any = globals.default;
  public today: any = moment().format("DD-MM-YY");

  public btnMorning: boolean = true;
  public btnAfternoon: boolean = true;
  public btnAnother: boolean = true;
  public loading: boolean = false;

  public morning: boolean = false;
  public afternoon: boolean = false;
  public another: boolean = false;

  public fbMorning: FormGroup;
  public fbAfternoon: FormGroup;
  public fbAnother: FormGroup;
  public fbEdit: FormGroup;

  public user: any = JSON.parse(window.localStorage.current_user);

  public dataToSend: any = {
    date: this.today,
    time: "",
    user_id: this.user.id,
    qty_package: 0,
    value: 0,
    type_time_day_id: 0,
  };

  public getData: any = [];
  public getToday: any = [];

  public interval: any;

  public id: any;
  //public i:any

  @ViewChild("errorAlert") private errorAlertSwal: SwalComponent;
  @ViewChild("successAlert") private successAlertSwal: SwalComponent;

  constructor(
    private fb: FormBuilder,
    private service: PackageService,
    private cryptoService: CryptoService
  ) {
    this.fbMorning = this.fb.group({
      timeMorning: [{ value: "08:30", disabled: this.btnMorning }, []],
      qtyMorning: [{ value: "", disabled: this.btnMorning }, []],
      depositMorning: [{ value: "", disabled: this.btnMorning }, []],
    });

    this.fbAfternoon = this.fb.group({
      timeAfternoon: [{ value: "17:00", disabled: this.btnAfternoon }, []],
      qtyAfternoon: [{ value: "", disabled: this.btnAfternoon }, []],
      depositAfternoon: [{ value: "", disabled: this.btnAfternoon }, []],
    });

    this.fbAnother = this.fb.group({
      timeAnother: [{ value: "00:00", disabled: this.btnAnother }, []],
      qtyAnother: [{ value: "", disabled: this.btnAnother }, []],
      depositAnother: [{ value: "", disabled: this.btnAnother }, []],
    });

    this.fbEdit = this.fb.group({
      timeEdit: [],
      qtyEdit: [],
      depositEdit: [],
    });
  }

  getAllData() {
    this.service.getPackagesById(this.user.id).subscribe((res) => {
      this.getData = res;
    });
  }

  ngOnInit() {
    this.getAllData();

    this.evaluateIfSendPackage();

    this.interval = setInterval(() => {
      console.log("eval");
      this.evaluateIfSendPackage();
    }, 180000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  setBooleanEvaluate(type) {
    switch (type) {
      case 1:
        //morning
        this.morning = true;
        break;
      case 2:
        //afternoon
        this.afternoon = true;
        break;
      case 3:
        //another
        this.another = true;
        break;
    }
  }

  evaluateIfSendPackage() {
    this.service
      .getPackagesByIdToday(this.user.id, this.today)
      .subscribe((res) => {
        console.log(res);
        this.getToday = res;
        if (this.getToday.length > 0) {
          this.getToday.forEach((ele) => {
            if (ele.date == this.today) {
              this.setBooleanEvaluate(ele.type_time_day_id);
            }
          });
        }
      });
  }

  changeCheck(check, event) {
    switch (check) {
      case 1:
        if (this.morning) {
          return;
        }
        //morning
        if (event.currentTarget.checked) {
          this.btnMorning = false;
          this.fbMorning.get("timeMorning").enable();
          this.fbMorning.get("qtyMorning").enable();
          this.fbMorning.get("depositMorning").enable();
        } else {
          this.btnMorning = true;
          this.fbMorning.get("timeMorning").disable();
          this.fbMorning.get("qtyMorning").disable();
          this.fbMorning.get("depositMorning").disable();
        }
        break;
      case 2:
        if (this.afternoon) {
          return;
        }
        //afternoon
        if (event.currentTarget.checked) {
          this.btnAfternoon = false;
          this.fbAfternoon.get("timeAfternoon").enable();
          this.fbAfternoon.get("qtyAfternoon").enable();
          this.fbAfternoon.get("depositAfternoon").enable();
        } else {
          this.btnAfternoon = true;
          this.fbAfternoon.get("timeAfternoon").disable();
          this.fbAfternoon.get("qtyAfternoon").disable();
          this.fbAfternoon.get("depositAfternoon").disable();
        }
        break;
      case 3:
        if (this.another) {
          return;
        }
        //another
        if (event.currentTarget.checked) {
          this.btnAnother = false;
          this.fbAnother.get("timeAnother").enable();
          this.fbAnother.get("qtyAnother").enable();
          this.fbAnother.get("depositAnother").enable();
        } else {
          this.btnAnother = true;
          this.fbAnother.get("timeAnother").disable();
          this.fbAnother.get("qtyAnother").disable();
          this.fbAnother.get("depositAnother").disable();
        }
        break;
    }
  }

  onlyNumbers(e) {
    if (e.shiftKey) {
      return e.which == 9;
    }
    if ((e.which > 57 && e.which < 95) || e.which > 105) {
      return false;
    }
    return e.which != 32;
  }

  clearForm(i) {
    switch (i) {
      case 1:
        //morning
        this.fbMorning.get("timeMorning").setValue("08:30");
        this.fbMorning.get("qtyMorning").setValue("");
        this.fbMorning.get("depositMorning").setValue("");

        this.fbMorning.get("timeMorning").disable();
        this.fbMorning.get("qtyMorning").disable();
        this.fbMorning.get("depositMorning").disable();

        this.btnMorning = true;
        break;
      case 2:
        //afternoon
        this.fbAfternoon.get("timeAfternoon").setValue("17:00");
        this.fbAfternoon.get("qtyAfternoon").setValue("");
        this.fbAfternoon.get("depositAfternoon").setValue("");

        this.fbAfternoon.get("timeAfternoon").disable();
        this.fbAfternoon.get("qtyAfternoon").disable();
        this.fbAfternoon.get("depositAfternoon").disable();

        this.btnAfternoon = true;
        break;
      case 3:
        //another
        this.fbAnother.get("timeAnother").setValue("00:00");
        this.fbAnother.get("qtyAnother").setValue("");
        this.fbAnother.get("depositAnother").setValue("");

        this.fbAnother.get("timeAnother").disable();
        this.fbAnother.get("qtyAnother").disable();
        this.fbAnother.get("depositAnother").disable();

        this.btnAnother = true;
        break;
    }
  }

  sendData(i) {
    this.loading = true;
    switch (i) {
      case 1:
        //morning
        this.dataToSend.time = this.fbMorning.get("timeMorning").value;
        this.dataToSend.qty_package = Number(
          this.fbMorning.get("qtyMorning").value
        );
        this.dataToSend.value = Number(
          this.fbMorning.get("depositMorning").value
        );
        this.dataToSend.type_time_day_id = 1;
        break;
      case 2:
        //afternoon
        this.dataToSend.time = this.fbAfternoon.get("timeAfternoon").value;
        this.dataToSend.qty_package = Number(
          this.fbAfternoon.get("qtyAfternoon").value
        );
        this.dataToSend.value = Number(
          this.fbAfternoon.get("depositAfternoon").value
        );
        this.dataToSend.type_time_day_id = 2;
        break;
      case 3:
        //another
        this.dataToSend.time = this.fbAnother.get("timeAnother").value;
        this.dataToSend.qty_package = Number(
          this.fbAnother.get("qtyAnother").value
        );
        this.dataToSend.value = Number(
          this.fbAnother.get("depositAnother").value
        );
        this.dataToSend.type_time_day_id = 3;
        break;
    }

    this.service.insertPackage(this.dataToSend).subscribe(
      (res) => {
        this.successAlertSwal.title = "Success";
        this.successAlertSwal.text = "Inserted your information successfully";
        this.successAlertSwal.show();

        this.clearForm(i);
        this.evaluateIfSendPackage();
      },
      (error) => {
        if (error.message.isJoi) {
          this.errorAlertSwal.text = error.message.details[0].message;
        } else if (error.message) {
          this.errorAlertSwal.text = error.message;
        } else {
          this.errorAlertSwal.text = "Something went wrong, please try again.";
        }
        this.errorAlertSwal.show();
        this.evaluateIfSendPackage();
      }
    );
    //colocar boton en disabled mienstras inserta la peticion

    this.loading = false;
  }

  openModal(id, i) {
    this.id = id;
    //this.i = i
    console.log(i);
    this.fbEdit.get("timeEdit").setValue(this.getData[i].time);
    this.fbEdit.get("qtyEdit").setValue(this.getData[i].qty_package);
    this.fbEdit.get("depositEdit").setValue(this.getData[i].value);
    //$('#staticBackdrop').toggle()
  }

  editData(id) {
    this.loading = true;

    let data = {
      time: this.fbEdit.get("timeEdit").value,
      qty_package: Number(this.fbEdit.get("qtyEdit").value),
      value: Number(this.fbEdit.get("depositEdit").value),
    };

    this.service.updatePackageById(id, data).subscribe(
      (res) => {
        this.successAlertSwal.title = "Success";
        this.successAlertSwal.text = "Update your information successfully";
        this.successAlertSwal.show();

        this.evaluateIfSendPackage();
        this.getAllData();
        this.closeModal();
      },
      (error) => {
        if (error.message.isJoi) {
          this.errorAlertSwal.text = error.message.details[0].message;
        } else if (error.message) {
          this.errorAlertSwal.text = error.message;
        } else {
          this.errorAlertSwal.text = "Something went wrong, please try again.";
        }
        this.errorAlertSwal.show();
        this.evaluateIfSendPackage();
        this.getAllData();
      }
    );
    //colocar boton en disabled mienstras inserta la peticion

    this.loading = false;
  }

  closeModal() {
    $("#close").click();
  }
}
