import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class BillsService {
  baseUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) {}

  getAllBills(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/api/bills/`, {
      observe: "response",
    });
  }

  getFilteredBills(filters): Observable<HttpResponse<any>> {
    let from = moment(filters.from_date).format("YYYY-MM-DD");
    let to = moment(filters.to_date).format("YYYY-MM-DD");
    return this.http.get<any>(
      `${this.baseUrl}/api/bills/${filters.search}/${filters.status_id}/${from}/${to}`,
      { observe: "response" }
    );
  }

  getBillsFiltered(filters): Observable<HttpResponse<any>> {
    let from = moment(filters.from_date).format("YYYY-MM-DD");
    let to = moment(filters.to_date).format("YYYY-MM-DD");
    return this.http.get<any>(
      `${this.baseUrl}/api/bills/${filters.search}/${filters.status_id}/${from}/${to}?page=${filters.page}&limit=${filters.limit}`,
      { observe: "response" }
    );
    /*return this.http.get<any>(`${this.baseUrl}/api/bills/?page=${filters.page}&limit=${filters.limit}`, {observe : 'response'});*/
  }

  getAdminBillByBillId(id: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/api/bills/${id}`, {
      observe: "response",
    });
  }

  sendPaylink(id, data): Observable<HttpResponse<any>> {
    const dataToSend = {
      email: data.email,
      phone: data.phone,
      bill_id: id,
    };
    return this.http.post<any>(
      `${this.baseUrl}/api/bills/paylink/send`,
      dataToSend,
      {
        observe: "response",
      }
    );
  }

  generatePaylink(): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.baseUrl}/api/paylink/generate`,
      {},
      {
        observe: "response",
      }
    );
  }

  login2fa(data): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}/api/login/2fa/temporal`, data, {
      observe: "response",
    });
  }

  valide2fa(data): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}/api/login/2fa/validate`, data, {
      observe: "response",
    });
  }

  dateCreatedPaylink(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/api/payments/last-paylink`);
  }
}
