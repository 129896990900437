import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
declare var $: any;

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { PaymentsService } from 'src/app/_services';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

@Component({
  selector: 'app-incode-report',
  templateUrl: './incode-report.component.html',
  styleUrls: ['./incode-report.component.css']
})
export class IncodeReportComponent implements OnInit {
  @ViewChild('report') private reportSwal: SwalComponent;
  @ViewChild('failure') private failureSwal: SwalComponent;
  @ViewChild('success') private successSwal: SwalComponent;  
  
  private month = moment().format('MM');
  private year = moment().format('YYYY');
  public titleAlert : string = "Incode Report IVR";
  public confirmButtonTextAlert : string ="Download";
  public cancelButtonTextAlert : string ="Cancel";
  public failTextAlert : string = "Something went wrong, we are very sorry";
  public successTextAlert : string = "Downloading...";
  public bsConfig: Partial<BsDatepickerConfig>;
  public modalRef: BsModalRef;
  public reportDateRange;
  public optionExtension: string = '';
  constructor(private paymentService: PaymentsService, private modalService: BsModalService) { 
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', rangeInputFormat : 'MMMM Do YYYY, h:mm:ss a', dateInputFormat: 'MMMM Do YYYY, h:mm:ss a', showWeekNumbers: false });
    this.reportDateRange = [moment().startOf('month').toDate(), moment().endOf('month').toDate()];
  }
 
  ngOnInit() {
    if(window.location.href.includes("/es/")){
      this.titleAlert = "Reporte para Incode";
      this.confirmButtonTextAlert="Descargar";
      this.cancelButtonTextAlert="Cancelar";
      this.failTextAlert = "Lo sentimos, ha ocurrido un error";
      this.successTextAlert = "Descargando...";
     
     
    }
    this.optionExtension='.txt'
    
  }
  submitExtension(option){
    this.optionExtension=option; 
    
    console.log(this.optionExtension)    
  }
  generateIncodeReport() {
    let startDate = moment(this.reportDateRange[0]);
    let endDate = moment(this.reportDateRange[1]);
    this.paymentService.generateIncodeReport(startDate.toISOString(), endDate.toISOString(),this.optionExtension)
      .subscribe( 
        response => {
          console.log(response);
         // let filename = `${startDate.format('MMMM DD YYYY, h:mm:ss a')} - ${endDate.format('MMMM Do YYYY, h:mm:ss a')} Incode Report.csv`
          let filename = `IVR${moment().format('MMDDYYHH')}${this.optionExtension}`
          saveAs(response, filename);
          this.reportDateRange = [moment().startOf('month').toDate(), moment().endOf('month').toDate()];
          this.modalRef.hide()
          this.successSwal.show()
        },  
        error => {
          console.log("error", error);
          if(error.status == 404){
            this.failureSwal.title = 'No payments found for selected date range.'
            if (window.location.href.includes('/es/')) {
              this.failureSwal.title = 'No hay pagos encontrados para el rango de fecha seleccionado.'
            }
            this.failureSwal.show()
          } else {
            this.failureSwal.show()            
          }
        }
      )
  }

  openAlert() {
    this.reportSwal.show()
    $('#paymentMonth').val(this.month);
    $('#paymentYear').val(this.year);    
  }

  onOpen(event) {
    $('body').removeClass("swal2-height-auto");
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg gray inmodal report-modal ', backdrop: true, ignoreBackdropClick: false}));
  }
}