import { Address, WaterConsumption } from "./";

export class Account {
  id: number;
  user_id: number;
  balance: number;
  account_status_id: number;
  account_type_id: number;
  incode_account_no: string;
  service_address: Address;
  mailing_address: Address;
  renting: boolean;
  require_trashbin: boolean;
  deed_rental_url: string;
  date_created: string;
  date_updated: string;
  zone_id: number;
  user_email?: string;
  flag_use?: boolean;
}
