import { Component, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, switchMap, tap, share } from "rxjs/operators";

import { WorkOrderTypes, WorkOrderStatus, WorkOrder } from "src/app/_models";
import {
  CryptoService,
  CustomerService,
  StatusService,
  TypeService,
  WorkOrderService,
} from "src/app/_services";
import { ObservableMedia } from "@angular/flex-layout";
import { Router } from "@angular/router";
import * as globals from "../globals.json";
import * as moment from "moment";

declare var $: any;

@Component({
  selector: "app-work-orders",
  templateUrl: "./work-orders.component.html",
  styleUrls: ["./work-orders.component.css"],
})
export class WorkOrdersComponent implements OnInit {
  public globals: any = globals.default;
  public workOrders$: Observable<WorkOrder[]>;
  private searchTerms = new Subject<string>();

  public user: any;
  public workOrderStatuses: WorkOrderStatus[] = [];
  public workOrderTypes: WorkOrderTypes[] = [];

  public initialMobile: boolean = false;
  public loading: boolean = false;
  public paginationMaxSize = 5;
  public paginationPageLimit = 10;
  public paginationTotalWorkOrders = 0;
  public paginationCurrentPage = 1;
  public searchText = "";
  public searchCategory = "";
  public searchWorkOrderStatus = "";
  public searchWorkOrderType = "";
  public interval;
  public searchBoxPlaceHolder = "Search";

  constructor(
    private customerService: CustomerService,
    private statusService: StatusService,
    private typeService: TypeService,
    private workOrderService: WorkOrderService,
    public media: ObservableMedia,
    private router: Router,
    private cryptoService: CryptoService
  ) {
    if (this.media.isActive("xs")) {
      this.initialMobile = true;
    }
  }

  capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  changeTicketType(type) {
    switch (type) {
      case "Garbage Extra":
        return "Basura Extra";
      case "NAWSC Garbage":
        return "Basura NAWSC";
      case "Leaks":
        return "Fugas";
      case "Sewer Back Up":
        return "Respaldo con Alcantarilla";
      case "No Brush Pick Up":
        return "Sin Recoleccion por Escoba";
      case "No Garbage Pick Up":
        return "Sin Recoleccion de Basura";
      case "Low Pressure":
        return "Baja Presion";
      case "No Water":
        return "Sin agua";
      case "Payments":
        return "Pagos";
      case "General Questions":
        return "Preguntas Frecuentes";
      case "New Account":
        return "Nueva Cuenta";
      case "Disconnect Account":
        return "Desconectar Cuenta";
      case "Pause Account":
        return "Pausar Cuenta";
      case "Bad Sewer Smell":
        return "Mal olor de Alcantarilla";
      case "Manhole works":
        return "Trabajos de Alcantarilla";
      case "Mark locate water/sewer":
        return "Marcar Alcatarilla/Agua";
      case "Install Meter":
        return "Instalar Medidor";
    }
  }

  changeTicketStatus(status) {
    console.log("status ", status);
    switch (status) {
      case "Pending":
        return "Pendiente";
      case "In Review":
        return "En Revision";
      case "In Progress":
        return "En Progreso";
      case "Resolved":
        return "Completada";
      case "Reject":
        return "Rechazada";
      case "Closed":
        return "Cerrada";
    }
  }

  prioritySpanish(priority) {
    switch (priority) {
      case "High":
        return "Alta";
      case "Medium":
        return "Media";
      case "Low":
        return "Baja";
    }
  }
  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);

    this.statusService.getWorkOrderStatus().subscribe((data) => {
      if (window.location.href.includes("/es/")) {
        data.map((item) => {
          item.name = this.changeTicketStatus(item.name);
          return item;
        });
      }
      this.workOrderStatuses = data;
    });

    this.typeService.getWorkOrderTypes().subscribe((data) => {
      if (window.location.href.includes("/es/")) {
        data.map((item) => {
          item.name = this.changeTicketType(item.name);
          return item;
        });
      }
      this.workOrderTypes = data;
    });

    this.workOrderService.newWorkOrderCreated.subscribe((data) => {
      this.search();
    });
    this.workOrders$ = this.searchTerms.pipe(
      debounceTime(200),
      switchMap((term: string) =>
        this.workOrderService
          .getFilteredWorkOrders(
            this.paginationCurrentPage,
            this.paginationPageLimit,
            this.searchWorkOrderStatus,
            this.searchWorkOrderType,
            term
          )
          .pipe(
            tap((data) => {
              console.log("work orders: ", data);
              this.loading = false;
              if (window.location.href.includes("/es/")) {
                data = data.work_orders.map((item) => {
                  item.work_order_type_name = this.changeTicketType(
                    item.work_order_type_name
                  );
                  item.work_order_status_name = this.changeTicketStatus(
                    item.work_order_status_name
                  );
                  item.details.priority = this.prioritySpanish(
                    item.details.priority
                  );
                  moment.locale("es");
                  item["diff"] = this.capitalize(
                    moment(item.date_created).fromNow()
                  );
                  return item;
                });
              }
              this.paginationTotalWorkOrders = data.total;
            })
          )
      ),
      share()
    );

    if (window.location.href.includes("/es/")) {
      this.searchBoxPlaceHolder = "Buscar";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.search();
    }, 200);

    this.interval = setInterval(() => {
      this.search();
    }, 10000);
    // Add slimscroll to element
    $(".user-height-scroll").slimscroll({ height: "500px" });
  }

  clearSearchText() {
    this.searchText = "";
    this.search();
  }

  search() {
    console.log("search type: ", this.searchWorkOrderType);
    this.loading = true;
    this.searchTerms.next(this.searchText);
  }

  showDetail(workorderid) {
    if (this.initialMobile)
      this.router.navigate(["/work_orders/", workorderid]);
  }

  pageChanged(event: any) {
    this.paginationCurrentPage = event.page;
    this.search();
  }

  setClass(status_id) {
    return {
      "label-warning": status_id == 1,
      "label-primary": status_id == 2,
      "label-success": status_id == 3,
      "label-danger": status_id == 4,
    };
  }
}
