import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { saveAs } from "file-saver";
import * as moment from "moment";
declare var $: any;

import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { CryptoService, PaymentsService } from "src/app/_services";
import { SwalComponent } from "@toverux/ngx-sweetalert2";

import { User } from "../_models";

@Component({
  selector: "app-incode-admin-fee-report",
  templateUrl: "./incode-admin-fee-report.component.html",
  styleUrls: ["./incode-admin-fee-report.component.css"],
})
export class IncodeAdminFeeReportComponent implements OnInit {
  @ViewChild("report") private reportSwal: SwalComponent;
  @ViewChild("failure") private failureSwal: SwalComponent;
  @ViewChild("success") private successSwal: SwalComponent;

  private month = moment().format("MM");
  private year = moment().format("YYYY");
  public titleAlert: string = "Incode Admin Fee Report";
  public confirmButtonTextAlert: string = "Download";
  public cancelButtonTextAlert: string = "Cancel";
  public failTextAlert: string = "Something went wrong, we are very sorry";
  public successTextAlert: string = "Downloading...";
  public bsConfig: Partial<BsDatepickerConfig>;
  public modalRef: BsModalRef;
  public reportDateRange;
  public optionExtension: string = "";
  public isChecked: boolean = false;

  user: User;

  constructor(
    private paymentService: PaymentsService,
    private modalService: BsModalService,
    private cryptoService: CryptoService
  ) {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: "theme-dark-blue",
        rangeInputFormat: "MMMM Do YYYY, h:mm:ss a",
        dateInputFormat: "MMMM Do YYYY, h:mm:ss a",
        showWeekNumbers: false,
      }
    );
    this.reportDateRange = [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ];
  }

  ngOnInit() {
    if (window.location.href.includes("/es/")) {
      this.titleAlert = "Reporte para Incode de Admin Fees";
      this.confirmButtonTextAlert = "Descargar";
      this.cancelButtonTextAlert = "Cancelar";
      this.failTextAlert = "Lo sentimos, ha ocurrido un error";
      this.successTextAlert = "Descargando...";
    }
    this.submitExtension(".txt");
    this.user = JSON.parse(window.localStorage.current_user);
  }
  submitExtension(option) {
    this.optionExtension = option;
    //console.log(this.optionExtension)
  }
  generateIncodeAdminFeeReportInRange() {
    let documentdate = moment().startOf("day").format("MMDD");
    let startDate = moment(this.reportDateRange[0]);
    let endDate = moment(this.reportDateRange[1]);
    this.paymentService
      .generateIncodeAdminFeeReport(
        startDate.toISOString(),
        endDate.toISOString(),
        this.optionExtension,
        this.user.id
      )
      .subscribe(
        (response) => {
          console.log(response);
          // let filename = `${startDate.format('MMMM DD YYYY, h:mm:ss a')} - ${endDate.format('MMMM Do YYYY, h:mm:ss a')} Incode Report.csv`
          let filename = `ic-adfee-${documentdate}${this.optionExtension}`;
          saveAs(response, filename);
          this.reportDateRange = [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
          ];
          this.modalRef.hide();
          this.successSwal.show();
          this.optionExtension = ".txt";
        },
        (error) => {
          console.log("error", error);
          if (error.status == 404) {
            this.failureSwal.title = "No Admin Fees for selected date.";
            this.failureSwal.show();
          } else {
            this.failureSwal.show();
          }
        }
      );
  }
  generateAdminReportDay() {
    let documentdate = moment().startOf("day").format("MMDD");
    let startDate = moment().startOf("day").format();
    let endDate = moment().endOf("day").format();
    this.paymentService
      .generateIncodeAdminFeeReport(
        startDate.toString(),
        endDate.toString(),
        this.optionExtension,
        this.user.id
      )
      .subscribe(
        (response) => {
          console.log(response);
          // let filename = `${startDate.format('MMMM DD YYYY, h:mm:ss a')} - ${endDate.format('MMMM Do YYYY, h:mm:ss a')} Incode Report.csv`
          let filename = `ic-adfee-${documentdate}${this.optionExtension}`;
          saveAs(response, filename);
          this.reportDateRange = [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
          ];
          this.modalRef.hide();
          this.successSwal.show();
          this.optionExtension = ".txt";
        },
        (error) => {
          console.log("error", error);
          if (error.status == 404) {
            this.failureSwal.title = "No Admin Fees for selected date.";
            if (window.location.href.includes("/es/")) {
              this.failureSwal.title =
                "No hay tarifas administrativas para la fecha seleccionada.";
            }
            this.failureSwal.show();
          } else {
            this.failureSwal.show();
          }
        }
      );
  }
  selecteReport() {
    if (this.isChecked == true) {
      this.generateAdminReportDay();
    } else {
      this.generateIncodeAdminFeeReportInRange();
    }
  }
  openAlert() {
    this.reportSwal.show();
    $("#paymentMonth").val(this.month);
    $("#paymentYear").val(this.year);
  }

  onOpen(event) {
    $("body").removeClass("swal2-height-auto");
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        {
          class: "modal-lg gray inmodal report-modal ",
          backdrop: true,
          ignoreBackdropClick: false,
        }
      )
    );
  }
}
