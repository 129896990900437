import { Component, OnInit,ViewChild } from "@angular/core";
import { UserActivationService } from "../../_services";
import { Chart } from "chart.js";
import * as moment from "moment";

@Component({
  selector: "app-activations",
  templateUrl: "./activations.component.html",
  styleUrls: ["./activations.component.css"],
})
export class ActivationsComponent implements OnInit {
  @ViewChild('dateRange') dateRangeDOM;
  log_filters: any = {
    from_date: String,
    to_date: String,
  };

  ini: number;
  fin: number;
  total_user_activations = [];
  percent: number;
  increment;
  total;
  month_day = [];
  user_count = [];
  default_count = [];
  month_day_datas_types = [];

  loading = false;

  name_user_type = [];
  total_user_type = [];

  public domRange: any;
  public toRangeDate=moment().subtract('month').startOf('month').format('YYYY-MM-DD');

  constructor(private ActivationS: UserActivationService) {}

  dateRange: any = {
    from: String,
    to: String
  };

 

  ngOnInit() {
    this.domRange = this.dateRangeDOM.elementRef.nativeElement;

    this.log_filters.from_date = moment().startOf("week").format("YYYY-MM-DD");
    this.log_filters.to_date = moment().endOf("week").format("YYYY-MM-DD");
    this.percent = 0;
    this.total = 0;
    this.getUserCountByUserType();


    // console.log("DOM RANGE : ", this.domRange);
    if (window.location.href.includes('/es/')) { 
      this.domRange.getElementsByClassName('yk-label')[0].innerText = 'Inicio';
      this.domRange.getElementsByClassName('yk-label')[1].innerText = 'Fin';
      this.domRange.getElementsByClassName('yk-btn')[0].innerText = 'Este Mes';
      this.domRange.getElementsByClassName('yk-btn')[1].innerText = 'Ultimo Mes';
      this.domRange.getElementsByClassName('yk-btn')[2].innerText = 'Esta Semana';
      this.domRange.getElementsByClassName('yk-btn')[3].innerText = 'Ultima Semana';
      this.domRange.getElementsByClassName('yk-btn')[4].innerText = 'Este Año';
      this.domRange.getElementsByClassName('yk-btn')[5].innerText = 'Ultimo Año';
    }

  }
  ngAfterViewInit() {

    this.setDateRange({
      from: moment().startOf("week").format("YYYY-MM-DD"),
      to: moment().endOf("week").format("YYYY-MM-DD")
    });

        // let domRange = this.dateRangeDOM.elementRef.nativeElement;
        if (window.location.href.includes('/es/')) {
          this.domRange.getElementsByClassName('yk-weekday')[0].innerText = 'Dom';
          this.domRange.getElementsByClassName('yk-weekday')[1].innerText = 'Lun';
          this.domRange.getElementsByClassName('yk-weekday')[2].innerText = 'Mar';
          this.domRange.getElementsByClassName('yk-weekday')[3].innerText = 'Mie';
          this.domRange.getElementsByClassName('yk-weekday')[4].innerText = 'Jue';
          this.domRange.getElementsByClassName('yk-weekday')[5].innerText = 'Vie';
          this.domRange.getElementsByClassName('yk-weekday')[6].innerText = 'Sab';
        }
  }
 
  setDateRange(range) {
    // console.log('setting date ranges: ', range);
    this.log_filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.log_filters.to_date = moment(range.to).format("YYYY-MM-DD");
    // console.log('initial dates', this.log_filters.from_date, this.log_filters.to_date );
  }

  getDatasUserActivations() {
    this.log_filters.from_date = moment(this.domRange.getElementsByClassName('yk-content')[0].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
    this.log_filters.to_date = moment(this.domRange.getElementsByClassName('yk-content')[1].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
 
    this.loading = true;
    var from_date = this.log_filters.from_date;
    var to_date = this.log_filters.to_date;

    var dif_dates = moment(this.log_filters.to_date).diff(
      moment(this.log_filters.from_date),
      "days"
    );
    // console.log("days between dates : " + dif_dates);
    if (dif_dates > 2) {
      var days_middle = dif_dates / 2;
      // console.log("dates middle: " + days_middle);
      var to_date_middle = moment(this.log_filters.from_date)
        .add(days_middle, "days")
        .format("YYYY-MM-DD");
      var from_date_middle = moment(to_date_middle)
        .add(1, "days")
        .format("YYYY-MM-DD");

      this.getUserActivations(from_date, to_date_middle, 0);
      this.getUserActivations(from_date_middle, to_date, 1);

      setTimeout(() => {
        var start = this.total_user_activations[0];
        // console.log('start=' + start);
        var end = this.total_user_activations[1];
        this.getAverageActivations(start, end);
      }, 2000);
    }
  }

  getAverageActivations(ini1: number, fin1: number) {
    console.log("ini=" + ini1);
    console.log("fin=" + fin1);
    this.increment = true;
    let inicio = Number(ini1);
    let final = Number(fin1);
    let sum = inicio + final;
    this.total = sum;
    if ( inicio === final) {
      this.percent = 0;
      this.increment = true;
    } else if (inicio < final) {
      if (ini1 === 0) {
        this.percent = 100;
      } else {
        this.percent = (final * 100) / inicio;
      }
      console.log("Increment: " + this.percent + "%");
      this.increment = true;
    } else {
      if (final === 0) {
        this.percent = 100;
      } else {
        this.percent = (inicio * 100) / final;
      }
      console.log("Decrement: " + this.percent + "%");
      this.increment = false;
    } 
    // setTimeout(() => {
      // this.graphicActivates();
    // }, 2000);
  }

  getUserActivations(from_date: String, to_date: String, id: number) {
    from_date = from_date + " " + "00:00";
    to_date = to_date + " " + "23:59";
    // console.log("from date en getuseractivations" + from_date);
    // console.log("to date en getuseractivations" + to_date);
    // console.log("id = " + id);
    this.ActivationS.getUserActivation(from_date, to_date).subscribe(
      (resUserActivations) => {
        if (resUserActivations.body[0]) {
          let datas = JSON.stringify(resUserActivations.body); // se convierte en cadena
          // console.log( datas );
          this.total_user_activations[id] =
            resUserActivations.body[0].total_counts;
          // console.log("Resultado existe=" + this.total_user_activations[id]);
          this.ini = resUserActivations.body[0].total_counts;
        } else {
          this.total_user_activations[id] = 0;
          // console.log("Resultado no existe= " + this.total_user_activations[id]);
          this.fin = 0;
        }
      }
    );
  }
  // obtner usuarios activos por día entre dos fechas
  getUserActivationDay() {   
    this.log_filters.from_date = moment(this.domRange.getElementsByClassName('yk-content')[0].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
    this.log_filters.to_date = moment(this.domRange.getElementsByClassName('yk-content')[1].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
 
    var from_date = this.log_filters.from_date + " " + "00:00";
    var to_date = this.log_filters.to_date + " " + "23:59";
    
    this.user_count = [];
    this.month_day_datas_types = [];
    // this.user_count = [];
    this.ActivationS.getUserActivation(from_date, to_date).subscribe((res) => {
      if (res.body[0]) {
        // let datas = JSON.stringify(res.body); // se convierte en cadena
        // console.log('res body en get user activation day = ' + datas);
        this.defaultCountAndDate();
        this.user_count = this.default_count;
        var i = 0;
        while (res.body[i]) {
          let date = new Date(res.body[i].date_created);
            // console.log('---date = ' + date );
            let month_date = date.getMonth() + 1;
            let day_date = date.getDate();
            let month_day = month_date + "/" + day_date;
            // console.log('---month day = ' + month_day);
            var j;
            for (j = 0; j < this.month_day_datas_types.length; j++) {                            
              if (this.month_day_datas_types[j] === month_day) {
                this.user_count[j]++;
                // console.log('Existe! ' + month_day + '. Suma = ' +this.users_count_types[j]);
                break;
              }; 
            };           
          i++;
        }
        console.log("month day datas: " + this.month_day_datas_types);
        console.log("users count: " + this.user_count);
      }
    });
    // se envían los datos para crear la gráfica dos segundos después
    setTimeout(() => {
      this.graphicsUserActivationDay();
    }, 2000);
  }

  defaultCountAndDate() {
    // dias entre dos fechas
    var dif_dates = moment(this.log_filters.to_date).diff(
      moment(this.log_filters.from_date),
      "days"
    );
    console.log("days between dates: " + dif_dates);
    this.month_day_datas_types = [];
    this.default_count = [];
    var day = 0;    
    var day = 0;
    const aux_date = new Date(this.log_filters.from_date);
    const ant = 86399.9; //dia en segundos
    // llenar las variables
    for (day = 0; day <= dif_dates; day++) {
      let finalDate = new Date(aux_date.setSeconds(ant));
      // console.log('finalDate = ' + finalDate);
      let month_date_all = finalDate.getMonth() + 1;
      let day_date_all = finalDate.getDate();
      let month_day_all = month_date_all + "/" + day_date_all;
      //  console.log('var month day all=' + month_day_all );
      this.month_day_datas_types[day] = month_day_all;
      this.default_count[day] = 0;
    }
  }

  graphicActivates() {
    var range1 = this.total_user_activations[0];
    var range2 = this.total_user_activations[1];


    var dataChart = {      
        labels: ["Range1", "Range2"],
        datasets: [
          {
            label: "Activations",
            data: [range1, range2],
            backgroundColor: ["rgb(26, 179, 148)"],
            borderColor: ["rgb(26, 179, 148)"],
            borderWidth: 1,
          },
        ],
      
    }

    var optionChart =  {
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }  

    var ctx = document.getElementById("myChart");
    var myChart = new Chart(ctx,{ 
      type: "line", 
      data: dataChart, 
      options: optionChart
    }  );
  }

  // Graphic login time frecuence
  graphicsUserActivationDay() {
    this.loading = false;
    // LINE
    var lineData = {
      labels: this.month_day_datas_types,
      datasets: [
        {
          label: "ACTIVATION USERS / DAY",
          borderColor: "#3cba9f",
          fill: false,
          data: this.user_count,
        },
      ],
    };
    var lineOptions = {
      responsive: true,
    };
    // se limpia los anteriores datos del div en html
    document.getElementById("chartUAD").innerHTML = "&nbsp;";
    document.getElementById("chartUAD").innerHTML =
      '<canvas id="lineChartUAD"></canvas>';

    var ctx = document.getElementById("lineChartUAD"); // .getContext("2d");;
    // posible solución para limpiar datos anteriores
    if (ChartUserActivationDay) {
      ChartUserActivationDay.clear();
      ChartUserActivationDay.destroy();
    }
    // se crea la gráfica con los datos anteriores
    var ChartUserActivationDay = new Chart(ctx, {
      type: "line",
      data: lineData,
      options: lineOptions,
    });
  }

  getUserCountByUserType() {
    this.ActivationS.getUserCountByUserType()
    .subscribe(res => {
      // console.log('UserCountByUserType: ' + JSON.stringify(res) );
      let i = 0 ;
      while (res.body[i]) {
        // this.name_user_type[i] = res.body[i].name;
        let user_type = res.body[i].name;
        console.log(user_type)
        switch( user_type) {
          case 'Residential':
            this.name_user_type[i] = 'Residential';
            break;
          case 'Commercial':
            this.name_user_type[i] = 'Commercial';
            break;
          case 'Apartment':
            this.name_user_type[i] = 'Apartment';
            break;
          case 'Government':
            this.name_user_type[i] = 'Government';
            break;
          case 'Church':
            this.name_user_type[i] = 'Church';
             break;
          case 'Renting':
            this.name_user_type[i] = 'Renting';
              break;
          case 'Other TBD':
            this.name_user_type[i] = 'Other TBD';
              break;
          case 'Con TBD':
            this.name_user_type[i] = 'Con TBD';
              break;
          default:            
            this.name_user_type[i] = 'Other';
              break;
        }

        this.total_user_type[i] = res.body[i].total;        
        i++;
      }
      console.log('name types = ' + this.name_user_type );
      console.log('total user types = ' +this.total_user_type);
    });
    this.graphicUserCountByUserType();
  }
  graphicUserCountByUserType() {
    var barData = {
      labels: this.name_user_type,
      datasets: [
        {
          backgroundColor: ["#3e95cd", "#8e5ea2","#3cba9f","#e8c3b9","#c45850","#FF3333", "#4d00ae", "#0078d4"],
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff",
          data: this.total_user_type,
        },
       
      ],
    };
    var barOptions = {
      responsive: true,   
      legend: { display: false },   
      title: {
        display: true,
        text: 'Total User Count By User Type'
      },
      scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    }
    };

    var ctx = document.getElementById("barChartUserCountByUserType"); // .getContext("2d");;

    var myNewChart = new Chart(ctx, {
      type: "bar",
      data: barData,
      options: barOptions,
    });
  }
}
