import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { smoothlyMenu } from "../../app.helpers";
import { AuthService } from "../../_services/auth.service";
import { Router } from "@angular/router";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { CreateTicketComponent } from "src/app/_common/create-ticket/create-ticket.component";
import { DOCUMENT } from "@angular/common";
import { environment } from "src/environments/environment";
import { BillsService } from "src/app/_services";
import { NotificationSettings } from "src/app/notification-settings/notification-settings.component";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { EmailAdminSettings } from "../notification-emails-admin/notification-emails-admin.component";

declare var $: any;

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  public bsModalRef: BsModalRef;
  public user: any;
  @ViewChild("confirmGenerate") private confirmGenerateSwal: SwalComponent;
  @ViewChild("enter2FA") private enter2FASwal: SwalComponent;
  @ViewChild("failureReset") private failureResetSwal: SwalComponent;
  @Inject(DOCUMENT) private document: Document;
  @ViewChild("navbarbutton") navbarbutton: ElementRef;
  sidebarCollapsed: boolean = true;
  public failureUpdateTitle: string =
    "Something went wrong in the process, we are very sorry";
  public isIpad: boolean;
  public message: string = "";

  constructor(
    private auth: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private bsModalService: BsModalService,
    private BS: BillsService
  ) {}

  getRouterUrl(): string {
    return this.router.url;
  }

  getURL(lang) {
    let url = window.location.href;

    //for localhost development
    if (environment.local) {
      if (url.indexOf("4300") > -1 && lang === "en") {
        url = url.replace("4300/es/", "4200/");
      } else if (url.indexOf("4200") > -1 && lang === "es") {
        url = url.replace("4200/", "4300/es/");
      }
    } else {
      if (lang === "en") {
        lang = "";
      } else if (lang === "es") {
        lang = "/es";
      }

      let path = url.replace(environment.serverUrl, "").replace("/es/", "/");
      url = `${environment.serverUrl}${lang}${path}`;
    }

    return url;
  }

  changeLanguage(lang) {
    window.location.href = this.getURL(lang);
  }

  ngOnInit() {
    //this.renderer.removeClass(document.body, 'mini-navbar')
    this.isIpad = this.isDeviceIPad();
    this.user = JSON.parse(window.localStorage.current_user);
  }

  ngAfterViewInit(): void {
    let nbbuttonelement = this.navbarbutton.nativeElement as HTMLElement;
    //setTimeout(nbbuttonelement.click(), 1000)

    setTimeout(() => {
      if ($("body").hasClass("mini-navbar")) {
        $(".navbar").addClass("slide-navbar");
      } else {
        $(".navbar").removeClass("slide-navbar");
      }
      if (this.isIpad) this.toggleNavigation();
    });
  }

  logout() {
    this.auth.logout();
  }

  toggleNavigation(): void {
    if ($("body").hasClass("mini-navbar")) {
      setTimeout(() => {
        $("body").removeClass("mini-navbar");
        $(".navbar").removeClass("slide-navbar");
      });
    } else {
      setTimeout(() => {
        $("body").addClass("mini-navbar");
        $(".navbar").addClass("slide-navbar");
      });
    }
    smoothlyMenu();
  }

  openCreateTicketModal() {
    this.bsModalRef = this.modalService.show(
      CreateTicketComponent,
      Object.assign({}, { class: "gray inmodal animated slideDown" })
    );
    this.bsModalRef.content.closeBtnName = "Close";
  }

  isDeviceIPad() {
    return (
      navigator &&
      navigator.userAgent &&
      navigator.maxTouchPoints &&
      navigator.platform &&
      ((navigator.userAgent.match(/Mac/) !== null &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints >= 1) || // iPad Pro (5 maxTouchPoints, use 2 to check)
        navigator.platform.match(/iPad/i) !== null)
    ); // iPad
  }

  triggerNotificationSettings(user) {
    // Verificar que userInfo tiene datos aquí
    this.bsModalRef = this.bsModalService.show(NotificationSettings, {
      class: "modal-lg inmodal animated bounceInRight",
      backdrop: "static",
      initialState: {
        select: this.user,
      },
    });
    this.bsModalRef.content.refreshParentData.subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  confirmGeneratePaylink() {
    this.confirmGenerateSwal.show().then((result) => {
      const data = {
        email: this.user.email,
        user_id: this.user.id,
      };
      this.BS.login2fa(data).subscribe((response) => {
        if (result.value) {
          this.enter2FASwal.show().then((result2) => {
            if (result2.value) {
              const dataValidate = {
                email: this.user.email,
                token: result2.value,
              };
              this.BS.valide2fa(dataValidate).subscribe((response) => {
                if (response.body.user) {
                  this.triggerEmailsAdminSettings("");
                } else {
                  this.failureUpdateTitle = "Invalid 2FA code";
                  this.failureResetSwal.text =
                    "Invalid 2FA code. Please try again.";
                  this.failureResetSwal.show();
                }
              });
            }
          });
        }
      });
    });
  }

  triggerEmailsAdminSettings(user) {
    this.bsModalRef = this.bsModalService.show(EmailAdminSettings, {
      class: "modal-lg inmodal animated bounceInRight",
      backdrop: "static",
      initialState: {
        select: this.user,
      },
    });
    this.bsModalRef.content.refreshParentData.subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
}
