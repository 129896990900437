import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  UserService,
  CustomerService,
  CryptoService,
  AccountService,
} from "../../_services";
import { UserSettings } from "../../_models";
import { mergeMap } from "rxjs/operators";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import * as moment from "moment";
import { UpdateProfilePaymetMethod } from "../update-payment-method/update-payment-method.component";
declare var $: any;
import * as globals from "../../globals.json";

@Component({
  selector: "app-update-profile",
  templateUrl: "./update-profile.component.html",
  styleUrls: ["./update-profile.component.css"],
})
export class UpdateProfileComponent implements OnInit {
  user: any;
  userInfo: any;
  userSettings: UserSettings;
  testbool = true;
  idURL: any;
  newId: any;
  newIdURL: string;
  loading: boolean = false;
  uploadLoading: boolean = false;
  resetPasswordLoading: boolean = false;
  updateLoading: boolean = false;
  message: string;
  idFile;
  hideID: boolean = false;
  autopay_checkbox;
  paymentsMethods;
  accountData;
  cardListVisible: any = null;
  public globals: any = globals.default;
  @Output() updatedProfile = new EventEmitter();
  @ViewChild("successUpdate") private successUpdateSwal: SwalComponent;
  @ViewChild("failureUpdate") private failureUpdateSwal: SwalComponent;
  @ViewChild("successReset") private successResetSwal: SwalComponent;
  @ViewChild("failureReset") private failureResetSwal: SwalComponent;

  public profileStatusGroupForm: FormGroup = this.formBuilder.group({
    email: null,
    full_name: null,
    home_phone: null,
    mobile_phone: null,
    paymentMethod: null,
    dob: null,
    notificationRecieveCheck: null,
    autoPaymentCheck: null,
    savePaymentCheck: null,
  });

  public successUpdateTitle: string =
    "Your profile settings have been updated!";
  public failureUpdateTitle: string =
    "Something went wrong in the process, we are very sorry";
  public successResetTitle: string =
    "A link to reset your password has been sent to your e-mail";

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    public US: UserService,
    public CS: CustomerService,
    private bsModalService: BsModalService,
    private cryptoService: CryptoService,
    private AC: AccountService
  ) {
    this.loading = true;
    this.user = JSON.parse(window.localStorage.current_user);
  }

  ngOnInit() {
    this.getProfileUpdateData();
    if (window.location.href.includes("/es/")) {
      this.successUpdateTitle =
        "Tus configuraciones de perfil han sido actualizadas!";
      this.failureUpdateTitle = "Lo sentimos, hubo un error durante el proceso";
      this.successResetTitle =
        "Un enlace para recuperar su contraseña, ha sido enviado a su correo";
    }
  }

  getUserInfo(id) {
    this.US.getInfoById(id).subscribe((info) => {
      this.userInfo = info;

      this.idURL = info.driver_license_url;
    });
  }

  getParentUserInfo(info) {
    this.userInfo = info;

    this.idURL = info.driver_license_url;
  }

  toggleCheck(checkbox) {
    switch (checkbox) {
      case "notificationRecieveCheck":
        this.profileStatusGroupForm.patchValue({
          notificationRecieveCheck:
            !this.profileStatusGroupForm.value.notificationRecieveCheck,
        });
        break;
      case "autoPaymentCheck":
        this.profileStatusGroupForm.patchValue({
          autoPaymentCheck: !this.profileStatusGroupForm.value.autoPaymentCheck,
        });

        if (this.profileStatusGroupForm.value.autoPaymentCheck == true) {
          this.autopay_checkbox = true;
          this.getPaymentMethod();
        } else {
          this.autopay_checkbox = false;
        }
        break;
      case "savePaymentCheck":
        this.profileStatusGroupForm.patchValue({
          savePaymentCheck: !this.profileStatusGroupForm.value.savePaymentCheck,
        });
        break;
    }
  }

  getProfileUpdateData() {
    this.US.getSettingsById(this.user.id).subscribe((data) => {
      this.userSettings = data;
      var userDataObj = {
        ...this.userSettings,
        ...this.user,
        ...this.userInfo,
      };

      this.showProfileStatus(userDataObj);
    });
  }

  showProfileStatus(userData) {
    this.profileStatusGroupForm.setValue({
      email: userData.email,
      full_name:
        userData.first_name +
        " " +
        (userData.last_name && $.trim(userData).length > 0
          ? userData.last_name
          : ""),
      home_phone: userData.home_phone,
      mobile_phone: userData.mobile_phone,
      dob: moment(userData.date_of_birth).format("MM/DD/YYYY"),
      paymentMethod: "N/A",
      notificationRecieveCheck: userData.email_notifications,
      autoPaymentCheck: userData.autopay,
      savePaymentCheck: userData.save_payment_method,
    });

    this.CS.getPaymentMethods(this.user.id).subscribe(
      (response) => {
        if (response.length > 0) {
          if (userData.autopay == true) {
            this.autopay_checkbox = true;
            this.CS.getAllAccounts(userData.user_id).subscribe((res) => {
              this.accountData = res;
              this.paymentsMethods = response;

              this.accountData = this.combineData(this.paymentsMethods, res);
            });
          }
        } else {
          this.autopay_checkbox = false;
        }
      },
      (error) => {
        console.log("Error getting Payment Methods", error);
      }
    );

    this.loading = false;
  }

  toggleHideID() {
    this.hideID = !this.hideID;
  }

  copyOfIDOnFileChange(event) {
    this.uploadLoading = true;
    if (event.target.files) {
      let files = event.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        let preview = { data: reader.result, name: files[0].name };
        this.getIDURL(files[0], files[0].name, preview);
      };
    }
  }

  getIDURL(file, fileName, newID) {
    const formData: any = new FormData();

    formData.append("files", file, file["name"]);

    //WE LEFT OFF HERE
    this.CS.uploadID(this.user.email, formData).subscribe(
      (data) => {
        this.uploadLoading = false;

        this.newId = newID;
        this.newIdURL = data.location;
      },
      (error) => {
        this.uploadLoading = false;
        console.log("error while trying to upload img: ", error);
        this.newId = newID;
      }
    );
  }

  get savePaymentCheck() {
    return this.profileStatusGroupForm.value["savePaymentCheck"];
  }

  get notificationReceiveCheck() {
    return this.profileStatusGroupForm.value["notificationRecieveCheck"];
  }

  get paymentMethod() {
    return this.profileStatusGroupForm.value["paymentMethod"];
  }
  get email() {
    return this.profileStatusGroupForm.value["email"];
  }

  get home_phone() {
    return this.profileStatusGroupForm.value["home_phone"];
  }

  get mobile_phone() {
    return this.profileStatusGroupForm.value["mobile_phone"];
  }

  get autoPaymentCheck() {
    return this.profileStatusGroupForm.value["autoPaymentCheck"];
  }

  updateProfile() {
    this.updateLoading = true;

    const userInfo = {
      mobile_phone: $("#mobile_phone")
        .val()
        .replace(/[()-\s]/g, ""),
      home_phone: $("#home_phone")
        .val()
        .replace(/[()-\s]/g, ""),
    };

    const customerSettings = {
      save_payment_method: this.savePaymentCheck,
      email_notifications: this.notificationReceiveCheck,
      autopay: this.autoPaymentCheck,
    };

    this.CS.updateCustomerInfo(this.user.id, userInfo)
      .pipe(
        mergeMap((e) =>
          this.CS.updateCustomerSettings(this.user.id, customerSettings)
        )
      )
      .subscribe(
        (data) => {
          this.updateLoading = false;
          this.message = "";
          this.successUpdateSwal.show();
        },
        (err) => {
          this.updateLoading = false;
          this.message = "";
          this.failureUpdateSwal.show();
          console.log(err);
        }
      );
  }

  sendLink() {
    this.resetPasswordLoading = true;

    this.CS.resetPassword({ email: this.user.email }).subscribe(
      (response) => {
        this.resetPasswordLoading = false;
        this.message = "";
        this.successResetSwal.show();
      },
      (err) => {
        this.resetPasswordLoading = false;
        this.message = "";
        this.failureResetSwal.show();
        console.log("There was an error on the request.", err);
      }
    );
  }

  hideModal() {
    this.bsModalRef.hide();
    this.updatedProfile.emit(true);
  }

  newPaymentMethod() {
    this.hideModal();
    const uModalRef = (this.bsModalRef = this.bsModalService.show(
      UpdateProfilePaymetMethod,
      {
        ...{
          class: "gray modal-lg inmodal animated bounceInRight",
          backdrop: "static",
        },
      }
    ));
    uModalRef.content.getParentUserInfo(this.userInfo);
    uModalRef.content.updatedProfile.subscribe((data) => {
      this.ngOnInit();
    });
  }

  combineData(paymentMethods, accounts) {
    const combinedData = [];

    for (const account of accounts) {
      const paymentMethod = paymentMethods.find(
        (method) => method.id === parseInt(account.payment_method_id)
      );

      if (paymentMethod) {
        combinedData.push({
          ...account,
          paymentMethod,
        });
      } else {
        combinedData.push(account);
      }
    }

    return combinedData;
  }

  selectCard(account: any, method: any) {
    account.selectedPaymentMethod = method;

    this.AC.setPaymentMethod(account.id, method.id).subscribe((response) => {});
    this.cardListVisible = null;
    this.getProfileUpdateData();
  }

  toggleCardList(account: any) {
    if (this.cardListVisible === account) {
      this.cardListVisible = null;
    } else {
      this.cardListVisible = account;
    }
  }

  getPaymentMethod() {
    this.CS.getPaymentMethods(this.user.id).subscribe(
      (response) => {
        if (response.length > 0) {
          this.autopay_checkbox = true;
          this.CS.getAllAccounts(this.user.id).subscribe((res) => {
            this.accountData = res;
            this.paymentsMethods = response;

            this.accountData = this.combineData(this.paymentsMethods, res);
          });
        } else {
          this.autopay_checkbox = false;
        }
      },
      (error) => {
        console.log("Error getting Payment Methods", error);
      }
    );
  }
}
