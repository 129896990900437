import { Component, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, switchMap, tap } from "rxjs/operators";

import { Ticket, TicketStatus, TicketTypes } from "src/app/_models";
import {
  CryptoService,
  CustomerService,
  StatusService,
  TicketService,
  TypeService,
} from "src/app/_services";
import { share } from "rxjs/internal/operators/share";
import { ObservableMedia } from "@angular/flex-layout";
import { Router } from "@angular/router";
import * as globals from "../globals.json";
import * as moment from "moment";

import { DomSanitizer } from "@angular/platform-browser";

declare var $: any;

@Component({
  selector: "app-tickets",
  templateUrl: "./tickets.component.html",
  styleUrls: ["./tickets.component.css"],
})
export class TicketsComponent implements OnInit {
  public globals: any = globals.default;
  public tickets$: Observable<Ticket[]>;
  private searchTerms = new Subject<string>();

  public user: any;
  public ticketStatuses: TicketStatus[] = [];
  public ticketTypes: TicketTypes[] = [];

  public initialMobile: boolean = false;
  public loading: boolean = false;
  public paginationMaxSize = 5;
  public paginationPageLimit = 10;
  public paginationTotalTickets = 0;
  public paginationCurrentPage = 1;
  public searchText = "";
  public searchCategory = "";
  public searchTicketStatus = "";
  public searchTicketType = "";
  public interval = null;

  public goToRequestsSwalTitle = "Go to the signup requests page?";
  public goToRequestsSwalText =
    "Click OK to navigate to the signup requests page";
  public searchBoxPlaceHolder = "Search";

  constructor(
    private customerService: CustomerService,
    private statusService: StatusService,
    private ticketService: TicketService,
    private typeService: TypeService,
    public media: ObservableMedia,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private cryptoService: CryptoService
  ) {
    if (this.media.isActive("xs")) {
      this.initialMobile = true;
    }
  }

  changeTicketType(type) {
    switch (type) {
      case "Garbage Extra":
        return "Basura Extra";
      case "NAWSC Garbage":
        return "Basura NAWSC";
      case "Leaks":
        return "Fugas";
      case "Sewer Back Up":
        return "Respaldo con Alcantarilla";
      case "No Brush Pick Up":
        return "Sin Recoleccion por Escoba";
      case "No Garbage Pick Up":
        return "Sin Recoleccion de Basura";
      case "Low Pressure":
        return "Baja Presion";
      case "No Water":
        return "Sin agua";
      case "Payments":
        return "Pagos";
      case "General Questions":
        return "Preguntas Frecuentes";
      case "New Account":
        return "Nueva Cuenta";
      case "Disconnect Account":
        return "Desconectar Cuenta";
      case "Pause Account":
        return "Pausar Cuenta";
      case "Bad Sewer Smell":
        return "Mal olor de Alcantarilla";
      case "Manhole works":
        return "Trabajos de Alcantarilla";
      case "Mark locate water/sewer":
        return "Marcar Alcatarilla/Agua";
      case "Install Meter":
        return "Instalar Medidor";
    }
  }

  changeTicketStatus(status) {
    switch (status) {
      case "Pending":
        return "Pendiente";
      case "In Progress":
        return "En progreso";
      case "Resolved":
        return "Completado";
      case "Reject":
        return "Rechazado";
      case "Forwarded":
        return "Reenviado";
    }
  }

  capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);

    this.statusService.getTicketStatus().subscribe((data) => {
      console.log("data status ", status);

      if (window.location.href.includes("/es/")) {
        data.map((item) => {
          item.name = this.changeTicketStatus(item.name);
          return item;
        });
      }
      this.ticketStatuses = data;
    });

    this.ticketService.ticketCreated.subscribe((data) => {
      if (data) {
        this.search();
      }
    });

    this.typeService.getTicketTypes().subscribe((data) => {
      if (window.location.href.includes("/es/")) {
        data.map((item) => {
          item.name = this.changeTicketType(item.name);
          return item;
        });
      }
      this.ticketTypes = data;
    });

    if (this.user.user_type_id == 3) {
      //Customer
      this.tickets$ = this.searchTerms.pipe(
        debounceTime(200),
        switchMap((term: string) =>
          this.customerService
            .getFilteredTickets(
              this.user.id,
              this.paginationCurrentPage,
              this.paginationPageLimit,
              this.searchTicketStatus,
              this.searchTicketType,
              term
            )
            .pipe(
              tap((data) => {
                this.loading = false;

                if (window.location.href.includes("/es/")) {
                  data.tickets.map((item) => {
                    item.ticket_type_name = this.changeTicketType(
                      item.ticket_type_name
                    );
                    item.ticket_status_name = this.changeTicketStatus(
                      item.ticket_status_name
                    );
                    moment.locale("es");
                    item["diff"] = this.capitalize(
                      moment(item.date_created).fromNow()
                    );
                    // item.details.images = item.details.images ? item.details.images.length > 0 ? item.details.images.map((image)=>{
                    //   return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + image);
                    // }) : null : null;
                    return item;
                  });
                } else {
                  data.tickets.map((item) => {
                    item["diff"] = this.capitalize(
                      moment(item.date_created).fromNow()
                    );
                    // item.details.images = item.details.images ? item.details.images.length > 0 ? item.details.images.map((image)=>{
                    //   return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + image);
                    // }) : null : null;
                    return item;
                  });
                }
                this.paginationTotalTickets = data.total;
              })
            )
        ),
        share()
      );
    } else {
      // Admin or Staff
      this.tickets$ = this.searchTerms.pipe(
        debounceTime(200),
        switchMap((term: string) =>
          this.ticketService
            .getFilteredTickets(
              this.paginationCurrentPage,
              this.paginationPageLimit,
              this.searchTicketStatus,
              this.searchTicketType,
              term
            )
            .pipe(
              tap((data) => {
                this.loading = false;

                if (window.location.href.includes("/es/")) {
                  data = data.tickets.map((item) => {
                    item.ticket_type_name = this.changeTicketType(
                      item.ticket_type_name
                    );
                    item.ticket_status_name = this.changeTicketStatus(
                      item.ticket_status_name
                    );
                    // item.details.images = item.details.images ? item.details.images.length > 0 ? item.details.images.map((image)=>{
                    //   return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + image);
                    // }) : null : null;
                    moment.locale("es");
                    item["diff"] = this.capitalize(
                      moment(item.date_created).fromNow()
                    );
                    return item;
                  });
                } else {
                  data.tickets.map((item) => {
                    item["diff"] = this.capitalize(
                      moment(item.date_created).fromNow()
                    );

                    // item.details.images = item.details.images ? item.details.images.length > 0 ? item.details.images.map((image)=>{
                    //   return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + image);
                    // }) : null : null;
                    return item;
                  });
                }

                console.log("ticket ", data);

                this.paginationTotalTickets = data.total;
              })
            )
        ),
        share()
      );
    }

    if (window.location.href.includes("/es/")) {
      this.goToRequestsSwalTitle = "Ir a la pagina de solicitudes de registro?";
      this.goToRequestsSwalText =
        "Haga click en OK para navegar a la página de solicitudes de servicio";
      this.searchBoxPlaceHolder = "Buscar";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.search();
    }, 200);
    this.interval = setInterval(() => {
      this.search();
    }, 10000);

    // Add slimscroll to element
    $(".user-height-scroll").slimscroll({ height: "500px" });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  clearSearchText() {
    this.searchText = "";
    this.search();
  }

  search() {
    console.log("search type: ", this.searchTicketType);
    this.loading = true;
    this.searchTerms.next(this.searchText);
  }

  showTicketDetail(ticketid) {
    if (this.initialMobile) this.router.navigate(["/tickets/", ticketid]);
  }

  goToSignupRequests() {
    this.router.navigate(["/signup/requests"]);
  }

  pageChanged(event: any) {
    this.paginationCurrentPage = event.page;
    this.search();
  }

  setClass(status_id) {
    return {
      "label-warning": status_id == 1,
      "label-primary": status_id == 2,
      "label-success": status_id == 3,
      "label-danger": status_id == 4,
    };
  }
}
