import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TermsComponent } from "../_common/terms/terms.component";

import { BillsService, CryptoService } from "../_services";
import { CustomerService } from "../_services";
import { Bills, User, Address } from "../_models";
import * as moment from "moment";
import { UserSettings } from "src/app/_models/user-settings.model";
import * as globals from "../globals.json";
import { SendPaylinkComponent } from "../_common/send-paylink/send-paylink.component";
import { MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
export const LATE_FEE_PERCENT = 10;

declare var $: any;

@Component({
  selector: "app-bill-details",
  templateUrl: "./bill-details.component.html",
  styleUrls: ["./bill-details.component.css"],
})
export class BillDetailsComponent implements OnInit {
  @ViewChild("successAlert") private successAlert: SwalComponent;
  @ViewChild("errorAlert") private errorAlert: SwalComponent;
  @ViewChild("paymentSettings") private paymentSettingsSwal: SwalComponent;

  public globals: any = globals.default;

  bill_detail: Bills = new Bills();
  user: User;
  userSettings: UserSettings;
  current_path: string;
  bill_id: number;
  service_dates: any = {
    from: String,
    to: String,
    service_month: String,
  };
  testAddress: String = globals.default.cityText;
  bsModalRef: BsModalRef;

  paymentConfirmation: any;

  pdsInfoId: string;
  savePaymentMethod: boolean = false;
  autopay: boolean = false;

  showPayNowPanel: boolean = false;
  showConfirmationPanel: boolean = false;
  disabledPayNowButton: boolean = false;

  payType: string = "full";
  payAmount: number = 0.0;
  payFees: string = "0.0275 | 0.00";
  payFeesFirstEntry = parseInt(this.payFees.split("|")[0]);
  authToken: string;
  notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });
  public lateFeePercent = 0.1;
  public dueValue = "";

  public quickpayAlertTitle: string = "Quickpay";
  public quickpayAlertText1: string = "Continue with ";
  public quickpayAlertText2: string = "payment using credit card on file?";
  public successAlertTitle: string = "Success";
  public successAlertText: string = "Settings Updated";

  public errorAlertTitle: string = "Error";
  public errorAlertText: string = "Something went wrong!";
  public paymentSettingsTitle: string = "Update Payment Settings";

  constructor(
    private BS: BillsService,
    private CS: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private cryptoService: CryptoService,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (window.location.href.includes("/es/")) {
      this.quickpayAlertTitle = "Pago Rápido";
      this.quickpayAlertText1 = "Continuar con el pago ";
      this.quickpayAlertText2 = "usando la tarjeta de credito del archivo?";
      this.errorAlertTitle = "Error";
      this.errorAlertText = "Ocurrio un error!";
      this.successAlertTitle = "Proceso Exitoso!";
      this.successAlertText = "Configuraciones actualizadas";
    }

    if (this.route.snapshot.params.pdsInfoId) {
      this.pdsInfoId = this.route.snapshot.params.pdsInfoId;
      setTimeout(() => {
        if (this.pdsInfoId == "error") {
          this.errorAlert.show();
        } else {
          this.showConfirmationPanel = true;
          if (this.user.user_type_id == 3) {
            this.CS.getPDSDetails(this.pdsInfoId).subscribe(
              (data) => {
                this.paymentConfirmation = data;

                let fee = this.paymentConfirmation.fees.split("|");
                this.paymentConfirmation.total_amount =
                  +(this.paymentConfirmation.amount * +fee[0]) +
                  +this.paymentConfirmation.amount;
                this.paymentConfirmation.service_fee =
                  fee[0] * this.paymentConfirmation.amount;
              },
              (err) => {
                this.errorAlert.show();
              }
            );
          } else {
            this.errorAlert.show();
          }
        }
      }, 200);
    }

    this.authToken = window.localStorage.access_token;
    this.user = JSON.parse(window.localStorage.current_user);
    console.log("user: ", this.user);

    this.bill_id = this.route.snapshot.params.id;
    this.service_dates.from = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    this.service_dates.to = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
  }

  ngAfterViewInit() {
    if (this.user.user_type_id == 1) {
      this.getAdminBillByBillId(this.bill_id);
    } else {
      //call customer route
      this.getCustomerBillById(this.user.id, this.bill_id);
      this.getCustomerSettingsById(this.user.id);
    }
  }

  getAdminBillByBillId(id: number) {
    this.BS.getAdminBillByBillId(id).subscribe(
      (response) => {
        this.bill_detail = response.body[0];
        console.log("BILL DETAILS", this.bill_detail);
      },
      (error) => {
        console.log("Error getting bill detail: ", error);
      },
      () => {
        // console.log("Finished getting bill detail");
        if (this.bill_detail) {
          this.bill_detail.amount_due_late = +this.bill_detail.amount_due + 5;
          this.bill_detail.amount_due = +this.bill_detail.amount_due;
          this.dueValue = (
            +this.bill_detail.amount_due +
            +this.bill_detail.amount_due * +this.lateFeePercent
          ).toFixed(2);
          console.log(this.dueValue);
          if (!this.bill_detail.water_consumption) {
            this.service_dates.service_month = "N/A";
            this.bill_detail.water_consumption.amount =
              this.bill_detail.water_consumption.amount;
          } else {
            this.service_dates.service_month =
              moment(this.bill_detail.due_date)
                .subtract(1, "months")
                .format("MMMM") || "N/A";
            this.bill_detail.water_consumption.amount =
              this.bill_detail.water_consumption.amount;
          }
        }
      }
    );
  }

  getCustomerBillById(user_id: number, bill_id: number) {
    this.CS.getCustomerBillById(user_id, bill_id).subscribe(
      (response) => {
        this.bill_detail = response.body;
      },
      (error) => {
        console.log("Error getting bill detail: ", error);
        error.status === 403 && this.router.navigate(["/403"]);
      },
      () => {
        this.bill_detail.amount_due_late = +this.bill_detail.amount_due + 5;
        this.bill_detail.amount_due = +this.bill_detail.amount_due;
        if (!this.bill_detail.water_consumption) {
          this.service_dates.service_month = "N/A";
          this.bill_detail.water_consumption.amount =
            this.bill_detail.water_consumption.amount;
        } else {
          this.service_dates.service_month =
            moment(this.bill_detail.due_date)
              .subtract(1, "months")
              .format("MMMM") || "N/A";
          this.bill_detail.water_consumption.amount =
            this.bill_detail.water_consumption.amount;
        }
      }
    );
  }

  getCustomerSettingsById(user_id) {
    this.CS.getCustomerSettingsById(user_id).subscribe(
      (data) => {
        this.userSettings = data;
        // console.log("userSettingsf: ", this.userSettings);
      },
      (err) => console.log("error: ", err)
    );
  }

  triggerTermsModal() {
    const tModalRef = (this.bsModalRef = this.bsModalService.show(
      TermsComponent,
      Object.assign(
        {},
        {
          class: "gray modal-lg inmodal",
          backdrop: true,
          ignoreBackdropClick: false,
        }
      )
    ));
  }

  printView() {
    window.print();
  }

  viewPayPanel() {
    this.showPayNowPanel = true;
    this.payAmount = this.bill_detail.balance;

    $("#checkoutscript").attr("pds-amount", this.payAmount);
    $("#checkoutscript").attr("pds-email", this.user.email);
    $("#checkoutscript").attr("pds-fees", this.payFees);
  }

  setPayAmount() {
    if (this.payType == "full") {
      this.payAmount = this.bill_detail.balance;
    }

    if (this.payAmount <= this.bill_detail.balance && this.payAmount > 1) {
      $("#checkoutscript").attr("pds-amount", this.payAmount);
      $(".themeBlue").attr("disabled", false);
      this.disabledPayNowButton = false;
    } else {
      $(".themeBlue").attr("disabled", true);
      this.disabledPayNowButton = true;
    }
  }

  acceptPayment() {
    let payment = {
      bill_id: this.bill_id,
      pds_info_id: this.pdsInfoId,
    };

    this.CS.makeCustomerPayment(payment).subscribe(
      (data) => {
        this.router.navigate(["/payments", data.id, "receipt"]);
      },
      (err) => {
        this.errorAlert.show();
      }
    );
  }

  declinePayment() {
    this.showPayNowPanel = false;
    this.showConfirmationPanel = false;
    this.disabledPayNowButton = false;

    // this.router.navigate(['/bills', this.bill_id]);
  }

  makeQuickPayment() {
    let payment = {
      bill_id: this.bill_id,
      amount: this.payAmount,
      fees: this.payFees,
    };

    // console.log("payment: ", payment);

    this.CS.makeQuickPayment(payment).subscribe(
      (data) => {
        this.router.navigate(["/payments", data.id, "receipt"]);
      },
      (err) => {
        this.errorAlert.show();
      }
    );
  }

  setSettings() {
    $("#save_payment_method_checkbox").attr(
      "checked",
      this.userSettings.save_payment_method
    );
    $("#autopay_checkbox").attr("checked", this.userSettings.autopay);
  }

  saveSettings() {
    let user_settings = {
      save_payment_method: $("#save_payment_method_checkbox").is(":checked"),
      autopay: $("#autopay_checkbox").is(":checked"),
      email_notifications: this.userSettings.email_notifications || false,
    };

    this.CS.updateCustomerSettings(this.user.id, user_settings).subscribe(
      (data) => {
        this.userSettings = data[0];

        this.successAlert.show();
      },
      (err) => {
        this.errorAlert.show();
      }
    );
  }

  sendBillpaylink(id: number) {
    const dialogRef = this.dialog.open(SendPaylinkComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.BS.sendPaylink(id, result).subscribe(
          (response) => {
            this.showToast("Paylink sent successfully", "success");
            console.log("Response in send paylink: ", response);
          },
          (err) => {
            this.showToast("Error sending paylink", "error");
            console.log("Error sending paylink: ", err);
          },
          () => {
            console.log("Finished sending paylink");
          }
        );
      }
      // Process the result here
    });
  }

  copyBillpaylink(uuid: string): void {
    if (uuid == null || uuid == "") {
      this.showToast("No link to copy", "error");
      return;
    }
    const url = `${environment.serverUrlPaylink}/paylink/${uuid}`;
    this.copyToClipboard(url);
  }

  // Método auxiliar para copiar el texto al portapapeles
  private copyToClipboard(text: string): void {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    this.showToast("¡Link copied to clipboard!", "success");
  }

  showToast(text: string, status: string): void {
    if (status == "error") this.notyf.error("No link to copy");
    else this.notyf.success(text ? text : "Link copied to clipboard!");
  }
}
