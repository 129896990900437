import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterById'
})
export class FilterByIdPipe implements PipeTransform {

  transform(originalArray: any, comparisonArray: any, expression?: boolean): any {
    if (!originalArray) {
      return null;
    }

    let returnArray = [];

    comparisonArray.forEach((el, index) => {
      if (returnArray.length) {
        returnArray = returnArray.filter(x => expression ? x.id == el : x.id != el);
      } else {
        returnArray = originalArray.filter(x => expression ? x.id == el : x.id != el);
      }
    })

    return returnArray;
  }

}