import { Component, OnInit, OnChanges, ViewEncapsulation, ViewChild , Output, EventEmitter} from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { mergeMap } from 'rxjs/operators';

import { LateFeeService } from '../../_services/late-fees.service';
// import { LateFee } from '../../_models';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RequestIDComponent } from 'src/app/_common/request-id/request-id.component';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-edit-late-fee',
  templateUrl: './edit-late-fee.component.html',
  styleUrls: ['./edit-late-fee.component.css']
})
export class EditLateFeeComponent implements OnInit, OnChanges {
  @Output() editLateFeeModalClosed = new EventEmitter();
  @ViewChild('editSuccess') private editSuccessSwal: SwalComponent;
  @ViewChild('editFailure') private editFailureSwal: SwalComponent;
  @ViewChild('editDataFailure') private editDataFailureSwal: SwalComponent;
  @ViewChild('success') private successSwal: SwalComponent;
  @ViewChild('failure') private failureSwal: SwalComponent;
  @ViewChild('400failure') private notFoundFailureSwal: SwalComponent;

  public sentMessage: string
  public email: string
  public loading:boolean = false;
  public latefee; // user
  // public userSettings: UserSettings;
  // public logged_in_user: User;
  // public accounts : Account[];
  // public selectedAccount :Account;
  public adminEditLateFeeFormGroup: FormGroup;
  // public updateUserFormGroup: FormGroup;
  // public adminEditUserFormGroup: FormGroup;
  public disableMailingAddress: boolean = false;
  public bsConfig: Partial<BsDatepickerConfig>;
  public datePickerDate:any
  public initialBirthDate = moment().subtract(20,'years').format("MM/DD/YYYY");
  public submitSwalAlertTitle :string = "Save Changes?";
  public editSuccessAlertTitle:string =  "Late Fee has been Updated"; 
  public editFailureAlertTitle: string = "There was an error";
  public editFailureAlertText: string = "Please try again later";
  public successAlertTitle:string = "A link has been sent to you";
  public _400failureAlertTitle:string = "The e-mail you entered is invalid or was not found";
  public failureAlertTitle = "Something went wrong, we are very sorry";
  public selectAccountPlaceholder = "Select Account";
  // public zones: Zone[] = [];

  datasLateFee; 
  waived:  string;
  date_waived:  string;
  notes:  string;

  constructor(
    private LS: LateFeeService,
    public bsModalRef: BsModalRef,
    public bsModalService: BsModalService,
    private fb: FormBuilder,
  ) {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });
    this.adminEditLateFeeFormGroup = this.fb.group({
      waived :  ['false',],
      date_waived : ['',],
      notes : ['',]
    });
  }

  ngOnInit() {    
    if(window.location.href.includes("/es/")){
      this.submitSwalAlertTitle="Desea guardar los cambios?";
      this.editSuccessAlertTitle = "El Usuario ha sido editado";
      this.editFailureAlertTitle = "Hubo un error";
      this.editFailureAlertText = "Por favor, intente más tarde";
      this.successAlertTitle = "Se ha enviado un link";
      this._400failureAlertTitle= "El correo ingresado no ha sido encontrado o no es válido";
      this.failureAlertTitle = "Lo sentimos, hubo un error";
      this.selectAccountPlaceholder = "Seleccione una Cuenta";
    }
    this.waived =  this.latefee.waived;
    this.notes =  this.latefee.notes;
    if( this.latefee.date_waived) {
      this.date_waived =  moment(this.latefee.date_waived).format("MM-DD-YYYY");
    }    
    this.datasLateFee =  this.LS.getData();
  }

  ngOnChanges() {
  }

  ngAfterViewInit() {
    // Add chosen to select elements
    $('.chosen-select').chosen({ width: "100%" });
  }

  /*get emailAddress(){
    return this.updateUserFormGroup.value.email;
  }*/

  /*subscribeToSettings() {
    console.log("user: ", this.user);
    // this.accounts = this.user.accounts;
    if(this.user.user_type_id == 1 || this.user.user_type_id == 2){
      this.adminEditUserFormGroup.patchValue({
        firstName: this.user.user_info.first_name,
        homePhone: this.user.user_info.home_phone,
        mobilePhone: this.user.user_info.mobile_phone,
        email: this.user.email
      });
    }

    if(this.user.user_type_id == 3){
      console.log("birthday: ", this.user.user_info.date_of_birth);
      this.updateUserFormGroup.patchValue({
        firstName: this.user.user_info.first_name,
        lastName: this.user.user_info.last_name || '',
        dateOfBirth: this.user.user_info.date_of_birth? moment(this.user.user_info.date_of_birth).format("MM/DD/YYYY") : null,
        driverLicenseNumber: this.user.user_info.driver_license_number,
        homePhone: this.user.user_info.home_phone,
        mobilePhone: this.user.user_info.mobile_phone,
        email: this.user.email
      });
      
      this.updateUserFormGroup.patchValue({
        selectedAccount: this.accounts[0],
        incodeAccountNo: this.accounts[0].incode_account_no || '',
        zone: this.accounts[0].zone_id || '',
        mailingAddress: this.accounts[0].mailing_address.street_address || 'N/A',
        mailingCity: this.accounts[0].mailing_address.city || 'N/A',
        mailingState: this.accounts[0].mailing_address.state || 'N/A',
        mailingZipcode: this.accounts[0].mailing_address.zip_code || 'N/A',
        serviceAddress: this.accounts[0].service_address.street_address || 'N/A',
        serviceCity: this.accounts[0].service_address.city || 'N/A',
        serviceState: this.accounts[0].service_address.state || 'N/A',
        serviceZipcode: this.accounts[0].service_address.zip_code || 'N/A',
        useServiceAddrForMailing: this.accounts[0].mailing_address.street_address == this.accounts[0].service_address.street_address ? true : false
      })

      this.userService.getSettingsById(this.user.id)
        .subscribe(
          (data: UserSettings) => {
            this.userSettings = data;
            this.updateUserFormGroup.patchValue({
              emailNotifications: this.userSettings.email_notifications,
              savePaymentMethod: this.userSettings.save_payment_method,
              autopay: this.userSettings.autopay
            })
          }
        );
    }
    console.log("userformgroup: ", this.updateUserFormGroup);

  }*/

  /*changeAccount(){
    this.updateUserFormGroup.patchValue({
      incodeAccountNo: this.updateUserFormGroup.value.selectedAccount.incode_account_no,
      mailingAddress: this.updateUserFormGroup.value.selectedAccount.mailing_address.street_address,
      mailingCity: this.updateUserFormGroup.value.selectedAccount.mailing_address.city,
      mailingState: this.updateUserFormGroup.value.selectedAccount.mailing_address.state,
      mailingZipcode: this.updateUserFormGroup.value.selectedAccount.mailing_address.zip_code,
      serviceAddress: this.updateUserFormGroup.value.selectedAccount.service_address.street_address,
      serviceCity: this.updateUserFormGroup.value.selectedAccount.service_address.city,
      serviceState: this.updateUserFormGroup.value.selectedAccount.service_address.state,
      serviceZipcode: this.updateUserFormGroup.value.selectedAccount.service_address.zip_code
    })
  }*/

  /*setMobileNumber(number: string){
    let mobileNumber = number.trim().replace(/[()\-_\s]/g,"");
    if(this.user.user_type_id == 3){
      this.updateUserFormGroup.patchValue({mobilePhone: mobileNumber.length == 10 ? mobileNumber : null })
    }else if(this.user.user_type_id == 1 || this.user.user_type_id == 2){
      this.adminEditUserFormGroup.patchValue({mobilePhone : mobileNumber.length == 10 ? mobileNumber : null})
    }
  }

  setHomeNumber(number: string){
    let mobileNumber = number.trim().replace(/[()\-_\s]/g,"");
    if(this.user.user_type_id == 3){
      this.updateUserFormGroup.patchValue({homePhone: mobileNumber.length == 10 ? mobileNumber : null })
    }else if(this.user.user_type_id == 1 || this.user.user_type_id == 2){
      this.adminEditUserFormGroup.patchValue({homePhone : mobileNumber.length == 10 ? mobileNumber : null})
    }
  }

  toggleMailingAddress() {
    if (this.updateUserFormGroup.value.useServiceAddrForMailing) {
      this.disableMailingAddress = true
      this.updateUserFormGroup.controls['mailingAddress'].disable()
      this.updateUserFormGroup.controls['mailingCity'].disable()
      this.updateUserFormGroup.controls['mailingState'].disable()
      this.updateUserFormGroup.controls['mailingZipcode'].disable()

    } else {
      this.disableMailingAddress = false
      this.updateUserFormGroup.controls['mailingAddress'].enable()
      this.updateUserFormGroup.controls['mailingCity'].enable()
      this.updateUserFormGroup.controls['mailingState'].enable()
      this.updateUserFormGroup.controls['mailingZipcode'].enable()
    }
    this.updateMailingAddress()

  }

  updateMailingAddress() {
    if (this.updateUserFormGroup.value.useServiceAddrForMailing) {
      this.updateUserFormGroup.patchValue({
        mailingAddress: this.updateUserFormGroup.value.serviceAddress,
        mailingCity: this.updateUserFormGroup.value.serviceCity,
        mailingState: this.updateUserFormGroup.value.serviceState,
        mailingZipcode: this.updateUserFormGroup.value.serviceZipcode
      });
    }
  }*/


  submitEdit(){
    this.loading = true;
    let latefee = null;

    latefee = {
      waived: this.adminEditLateFeeFormGroup.value.waived,
      date_waived: this.adminEditLateFeeFormGroup.value.date_waived ,
      notes:  this.adminEditLateFeeFormGroup.value.notes
    }

    this.LS.updateCreditByID(this.latefee.id, latefee)
      .subscribe(response => {
        this.loading = false;
        this.editSuccessSwal.show()        
        this.latefee = response;
        this.adminEditLateFeeFormGroup.patchValue({
          waived : this.latefee.waived,
          date_waived : this.latefee.date_waived,
          notes : this.latefee.notes
        })        
      }, error => {
        this.loading = false;
        this.editFailureSwal.show();
        console.log("Error updating late fee:", error);
      });
  }

  setDate(){
    setTimeout(function() {
      var d = $('bs-datepicker-container, bs-daterangepicker-container').css("z-index", "9999");      
    },0);
  }

  confirm(){
    this.bsModalRef.hide();
    this.editLateFeeModalClosed.emit(true);
  }

  /*showRequestID() {
    const raModalRef = this.bsModalService.show(RequestIDComponent, Object.assign({}, { class: 'gray modal-md inmodal'}));
    raModalRef.content.setRequestInfo(this.user.user_info.driver_license_url);
  }

  showRequestRA() {
    const raModalRef = this.bsModalService.show(RequestIDComponent, Object.assign({}, { class: 'gray modal-md inmodal' }));
    raModalRef.content.setRequestInfo(this.updateUserFormGroup.value.selectedAccount.deed_rental_url);
  }*/
}
