import { Component, OnInit, ViewChild } from '@angular/core';
import * as globals from '../globals.json';
import * as moment from "moment";
import { Chart } from "chart.js";
import { BrowserService } from '../_services/browser.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-metrics-module',
  templateUrl: './metrics-module.component.html',
  styleUrls: ['./metrics-module.component.css']
})

export class MetricsModuleComponent implements OnInit {

  @ViewChild('datePicker') dateRangeDOM;

  public globals: any = globals.default;
  
  public _browsers = [];
  public _os = [];
  public _platform = [];

  public COLORS = [
    "btn btn-danger m-r-sm",
    "btn btn-primary m-r-sm",
    "btn btn-info m-r-sm",
    "btn btn-success m-r-sm",
    "btn btn-warning m-r-",
    "btn btn-default m-r-sm"
  ]

  public loading_browser: boolean = false;
  public loading_os: boolean = false;
  public loading_platform: boolean = false;

  public log_filters: any = {
  	from_date : String,
  	to_date : String
  };

  constructor(private _browser : BrowserService) { }

  ngOnInit() {
    this.chargeCharts();

    this.log_filters.from_date = moment().startOf('month').format('YYYY-MM-DD');
    this.log_filters.to_date = moment().endOf('month').format('YYYY-MM-DD');
  }

  chargeCharts(){
    try {
      this._browsers = [];
      this._os = [];
      this._platform = [];
      
      this._browser.getDataForCharts().subscribe(res => {
        //La consulta llega de manera ordenada, solo es distribuir
        console.log(res);
        res.body.forEach(element => {
          this._browsers.push(element.browser);
          this._os.push(element.os);
          this._platform.push(element.platform);
        })

        let counter_browsers = {};
        let counter_os = {};
        let counter_platform = {};

        this._browsers.forEach(function(i){
          counter_browsers[i] = (counter_browsers[i]||0) +1;
        });

        this._os.forEach(function(i){
          counter_os[i] = (counter_os[i]||0) +1;
        });
        
        this._platform.forEach(function(i){
          counter_platform[i] = (counter_platform[i]||0) +1;
        });

        this.generateBrowser(counter_browsers);
        this.generateOS(counter_os);
        this.generatePlatform(counter_platform);

        console.log(counter_browsers);
        console.log(counter_os);
        console.log(counter_platform);
      });
    } catch (error) {
      
    }
  }

  generateBrowser(data: any){
    setTimeout(() => {
      //primero tenemos el nombre de los elementos para los labels
      let keys = Object.keys(data);
      let labels = [];
      let _data = [];
      let backgroundColor = [];

      let html = "";

      keys.forEach((element, index) => {
        //console.log(element);
        const R = Math.floor(Math.random() * 256);
        const G = Math.floor(Math.random() * 256);
        const B = Math.floor(Math.random() * 256);

        const COLOR = Math.floor(Math.random() * 5);

        _data.push(data[element]);
        labels.push(element);
        let _back = "rgba("+ R.toString() +","+ G.toString() +","+ B.toString() +",0.5)";
        backgroundColor.push(_back);
      
        html+=`
        <div class='row'>
        <hr>
        <button type='button' class='${this.COLORS[COLOR]}' style='background-color: ${_back}'>${data[element]}</button>
              ${element}
        </div>`;
        //console.log(html)
      })

      console.log(backgroundColor);
      
      let doughnut = {
        labels: labels,
        datasets: [{
            data: _data,
            backgroundColor: backgroundColor
        }]
      }

      document.getElementById("chartContainerBrowser").innerHTML = '&nbsp;';
      document.getElementById("chartContainerBrowser").innerHTML = '<canvas id="doughnutChartBrowser" height="400"></canvas>';

      var ctx = document.getElementById("doughnutChartBrowser"); // .getContext("2d");;

      if (chartBrowser) {
        chartBrowser.clear();
        chartBrowser.destroy();
      }
      var chartBrowser = new Chart(ctx, {
        type: "doughnut",
        data: doughnut,
      });
      
      document.getElementById("tableBrowser").innerHTML = html

      this.loading_browser = true;
    },2000)
  }

  generateOS(data: any){
    setTimeout(() => {
      //primero tenemos el nombre de los elementos para los labels
      let keys = Object.keys(data);
      let labels = [];
      let _data = [];
      let backgroundColor = [];

      let html = "";

      keys.forEach((element, index) => {
        //console.log(element);
        const R = Math.floor(Math.random() * 256);
        const G = Math.floor(Math.random() * 256);
        const B = Math.floor(Math.random() * 256);

        const COLOR = Math.floor(Math.random() * 5);

        _data.push(data[element]);
        labels.push(element);
        let _back = "rgba("+ R.toString() +","+ G.toString() +","+ B.toString() +",0.5)";
        backgroundColor.push(_back);
        
        html+=`
        <div class='row'>
        <hr>
        <button type='button' class='${this.COLORS[COLOR]}' style='background-color: ${_back}'>${data[element]}</button>
              ${element}
        </div>`;
      })

      console.log(backgroundColor);
      
      let doughnut = {
        labels: labels,
        datasets: [{
            data: _data,
            backgroundColor: backgroundColor
        }]
      }

      document.getElementById("chartContainerOS").innerHTML = '&nbsp;';
      document.getElementById("chartContainerOS").innerHTML = '<canvas id="barChartOS" height="400"></canvas>';

      var ctx = document.getElementById("barChartOS"); // .getContext("2d");;

      if (chartOS) {
        chartOS.clear();
        chartOS.destroy();
      }
      var chartOS = new Chart(ctx, {
        type: "doughnut",
        data: doughnut,
      });

      document.getElementById("tableOS").innerHTML = html

      this.loading_os = true;
    },2000)
  }

  generatePlatform(data: any){
    setTimeout(() => {
      //primero tenemos el nombre de los elementos para los labels
      let keys = Object.keys(data);
      let labels = [];
      let _data = [];
      let backgroundColor = [];

      let html = "";

      keys.forEach((element, index) => {
        //console.log(element);
        const R = Math.floor(Math.random() * 256);
        const G = Math.floor(Math.random() * 256);
        const B = Math.floor(Math.random() * 256);

        const COLOR = Math.floor(Math.random() * 5);

        _data.push(data[element]);
        labels.push(element);
        let _back = "rgba("+ R.toString() +","+ G.toString() +","+ B.toString() +",0.5)";
        backgroundColor.push(_back);
        
        html+=`
        <div class='row'>
        <hr>
        <button type='button' class='${this.COLORS[COLOR]}' style='background-color: ${_back}'>${data[element]}</button>
              ${element}
        </div>`;
      })

      console.log(backgroundColor);
      
      let doughnut = {
        labels: labels,
        datasets: [{
            data: _data,
            backgroundColor: backgroundColor
        }]
      }

      document.getElementById("chartContainerPlatform").innerHTML = '&nbsp;';
      document.getElementById("chartContainerPlatform").innerHTML = '<canvas id="doughnutChartPlatform" height="400"></canvas>';

      var ctx = document.getElementById("doughnutChartPlatform"); // .getContext("2d");;

      if (chartPlatform) {
        chartPlatform.clear();
        chartPlatform.destroy();
      }
      var chartPlatform = new Chart(ctx, {
        type: "doughnut",
        data: doughnut,
      });

      document.getElementById("tablePlatform").innerHTML = html

      this.loading_platform= true;
    },2000)
  }

  setDateRange(range) {
  	console.log('setting date ranges: ', range);
  	this.log_filters.from_date = moment(range.from).format('YYYY-MM-DD');
    this.log_filters.to_date = moment(range.to).format('YYYY-MM-DD');
    console.log('initial dates', this.log_filters.from_date, this.log_filters.to_date );
  }

  submitFilters(from, to) {
    //this.loading = true;
    console.log('on submitFilters from: ', from);
    console.log('on submitFilters to: ', to);
    this.downloadReport(from, to);
  }

  downloadReport(from, to){
    this._browser.getDownloadReport(this.log_filters).subscribe(res =>{
      console.log(res);
      //let filename = `${from.toISOString().format('MMMM DD YYYY, h:mm:ss a')} - ${to.toISOString().format('MMMM Do YYYY, h:mm:ss a')}  Credit Card Payments.xlsx`
      let filename = 'Browser_Report.xlsx';
      saveAs(res, filename);
    })
  }

}
