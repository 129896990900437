import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { UserService, CustomerService } from "../../_services";
import { UserSettings } from "../../_models";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import states from "../states.json";
declare var $: any;
import * as globals from "../../globals.json";

@Component({
  selector: "app-payment-method",
  templateUrl: "./update-payment-method.component.html",
  styleUrls: ["./update-payment-method.css"],
})
export class UpdateProfilePaymetMethod implements OnInit {
  user: any;
  userInfo: any;
  userSettings: UserSettings;
  testbool = true;
  idURL: any;
  newId: any;
  newIdURL: string;
  loading: boolean = false;
  uploadLoading: boolean = false;
  resetPasswordLoading: boolean = false;
  updateLoading: boolean = false;
  message: string;
  idFile;
  hideID: boolean = false;
  public PaymentMthodFormGroup: FormGroup;
  @Output() updatedProfile = new EventEmitter();
  saveMethod_id: number;
  paymentsMethods: any[] = [];
  errorMessage: string = String();
  autopay_checkbox_2: boolean = true;
  states: any = states;
  isLoading: boolean = false;
  user_id;
  card_id_delete;
  globals: any = globals.default;
  @ViewChild("successUpdate") private successUpdateSwal: SwalComponent;
  @ViewChild("failureUpdate") private failureUpdateSwal: SwalComponent;
  @ViewChild("successReset") private successResetSwal: SwalComponent;
  @ViewChild("failureReset") private failureResetSwal: SwalComponent;
  @ViewChild("signupFailure") private signupFailureSwal: SwalComponent;
  @ViewChild("confirmDeletePayment")
  private confirmDeletePayment: SwalComponent;
  @Input() select: any; // Recibir la cuenta seleccionada

  public successUpdateTitle: string =
    "Your profile settings have been updated!";
  public failureUpdateTitle: string =
    "Something went wrong in the process, we are very sorry";
  public successResetTitle: string =
    "A link to reset your password has been sent to your e-mail";

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    public US: UserService,
    public CS: CustomerService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.loading = true;
    this.user = JSON.parse(window.localStorage.current_user);
  }

  ngOnInit() {
    this.initFormCC();
    this.PaymentMthodFormGroup.get("exp_month").valueChanges.subscribe(() => {
      this.PaymentMthodFormGroup.updateValueAndValidity();
    });

    this.PaymentMthodFormGroup.get("exp_year").valueChanges.subscribe(() => {
      this.PaymentMthodFormGroup.updateValueAndValidity();
    });
    this.states = states;
    this.getProfileUpdateData();
    if (window.location.href.includes("/es/")) {
      this.successUpdateTitle =
        "Tu configuraciones de perfil han sido actualizadas!";
      this.failureUpdateTitle = "Lo sentimos, hubo un error durante el proceso";
      this.successResetTitle =
        "Un enlace,para recuperar su contraseña, ha sido enviado a su correo";
    }
    this.getAutopayCheckbox();
  }

  getCardImage(cardType: string): string {
    switch (cardType.toLowerCase()) {
      case "visa":
        return "assets/images/visa.jpg";
      case "mstr":
        return "assets/images/mastercard.jpg";
      case "amex":
        return "assets/images/amex.jpg";
      default:
        return "assets/images/visa.jpg"; // Una imagen genérica para tipos desconocidos
    }
  }

  initFormCC() {
    this.PaymentMthodFormGroup = this.formBuilder.group(
      {
        card_number: [
          "",
          [Validators.required, Validators.pattern(/^\d{14,17}$/)],
        ],
        exp_month: [
          "",
          [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])$/)],
        ],
        exp_year: ["", [Validators.required, Validators.pattern(/^\d{2}$/)]],
        cv_code: ["", [Validators.required, Validators.pattern(/^\d{3,4}$/)]],
        nickname: ["", Validators.required],
        card_name: ["", Validators.required],
        card_lastName: ["", Validators.required],
        address1: ["", Validators.required],
        city: ["", Validators.required],
        state: ["TX", Validators.required],
        zip: ["", [Validators.required, Validators.pattern(/^\d{5}$/)]],
        email: ["", [Validators.required, Validators.email]],
        phone: [""],
        autopay_checkbox_2: [true],
      },
      { validator: this.expirationDateValidator() }
    );
  }

  expirationDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const month = control.get("exp_month");
      const year = control.get("exp_year");

      if (month.value && year.value) {
        const expirationDate = new Date(
          2000 + parseInt(year.value),
          parseInt(month.value) - 1
        );

        const currentDate = new Date();

        if (expirationDate <= currentDate) {
          return { expirationDateInvalid: true };
        }
      }

      return null;
    };
  }

  getAutopayCheckbox() {
    this.CS.getAutopayCheckbox(this.user.id).subscribe((response) => {
      this.autopay_checkbox_2 = response[0].autopay;
      this.PaymentMthodFormGroup.patchValue({
        autopay_checkbox_2: response[0].autopay,
      });
    });
  }

  toggleCheck(checkbox) {
    switch (checkbox) {
      case "autoPaymentCheck":
        this.CS.putAutopayCheckbox(
          this.user.id,
          !this.PaymentMthodFormGroup.value.autopay_checkbox_2
        ).subscribe((response) => {});
        break;
    }
  }

  getProfileUpdateData() {
    this.US.getSettingsById(this.user.id).subscribe((data) => {
      this.userSettings = data;
      var userDataObj = {
        ...this.userSettings,
        ...this.user,
        ...this.userInfo,
      };

      this.showProfileStatus(userDataObj);
    });
  }

  showProfileStatus(userData) {
    this.user_id = userData.user_id;
    this.PaymentMthodFormGroup.patchValue({ email: userData.email });

    this.CS.getPaymentMethods(
      userData.user_id ? userData.user_id : userData.email
    ).subscribe(
      (response) => {
        if (response.length > 0) {
          this.paymentsMethods = response;
        }
      },
      (error) => {
        console.log("Error getting Payment Methods", error);
      }
    );

    this.loading = false;
  }

  validateInput(event: any): void {
    const input = event.target.value;

    // Reemplaza cualquier caracter no numérico con una cadena vacía
    const numericInput = input.replace(/\D/g, "");

    // Actualiza el valor del campo con la versión numérica
    event.target.value = numericInput;
  }

  formatExpMonth(event: any): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    if (value.length === 1 && value !== "0") {
      value = "0" + value;
    }

    if (value.length === 2) {
      const month = parseInt(value, 10);
      if (month < 1 || month > 12) {
        value = "";
      }
    }

    input.value = value;
    this.PaymentMthodFormGroup.get("exp_month").setValue(value, {
      emitEvent: false,
    });
  }

  hideModal() {
    this.updatedProfile.emit(true);
    this.bsModalRef.hide();
  }

  savePaymentMethod() {
    this.isLoading = true;
    const formData = this.PaymentMthodFormGroup.value;

    formData.exp_year = parseInt(formData.exp_year, 10) + 2000;

    const paymentMethodData = {
      ccNum: this.PaymentMthodFormGroup.value.card_number.toString(),
      exp_date:
        this.PaymentMthodFormGroup.value.exp_month.toString() +
        this.PaymentMthodFormGroup.value.exp_year.toString(),
      security_code: this.PaymentMthodFormGroup.value.cv_code.toString(),
      nickname: this.PaymentMthodFormGroup.value.nickname,
      user_first_name: this.PaymentMthodFormGroup.value.card_name,
      user_last_name: this.PaymentMthodFormGroup.value.card_lastName,
      service_address: this.PaymentMthodFormGroup.value.address1,
      city: this.PaymentMthodFormGroup.value.city,
      state: this.PaymentMthodFormGroup.value.state,
      zip_code: this.PaymentMthodFormGroup.value.zip.toString(),
      user_email: this.PaymentMthodFormGroup.value.email,
      phone: this.PaymentMthodFormGroup.value.phone
        ? this.PaymentMthodFormGroup.value.phone.toString()
        : "",
      type_card: this.getCardType(
        this.PaymentMthodFormGroup.value.card_number.toString()
      ),
      payment_method: "cc",
      user_id: this.user_id,
    };

    if (this.PaymentMthodFormGroup.invalid) {
      console.log("Invalid Payment Method Form");
      return;
    }

    this.CS.createSavePaymentMethod(paymentMethodData).subscribe(
      (response) => {
        this.errorMessage = "";

        this.saveMethod_id = response[0].id;
        // this.PaymentMthodFormGroup.clearValidators();
        this.paymentsMethods.push(response[0]);
        this.scrollToTop();
        // Agregar la clase de parpadeo a la nueva tarjeta
        this.paymentsMethods[this.paymentsMethods.length - 1].cardPulse = true;

        // Remover la clase de parpadeo después de 2 segundos
        setTimeout(() => {
          this.paymentsMethods[this.paymentsMethods.length - 1].cardPulse =
            false;
        }, 4000);

        this.CS.getPaymentMethods(this.user.user_id).subscribe(
          (response) => {
            //this.paymentsMethods.push(response[0]);

            this.changeDetector.detectChanges();
          },
          (error) => {
            console.log("Error getting Payment Methods", error);
          }
        );
        this.resetPaymentMethodForm();
        // this.initFormCC()

        this.isLoading = false;
      },
      (error) => {
        this.failureUpdateTitle = error.message.message;
        this.message = "";
        this.errorMessage = error.message.message;
        this.isLoading = false;
        this.scrollToTop();
        this.changeDetector.detectChanges();
        this.signupFailureSwal.show();
      }
    );
  }

  resetPaymentMethodForm() {
    this.PaymentMthodFormGroup.reset({
      // Valores por defecto para los controles del formulario
      autopay_checkbox_2: true,
    });

    // Limpiar los errores de validación manualmente
    Object.keys(this.PaymentMthodFormGroup.controls).forEach((key) => {
      this.PaymentMthodFormGroup.get(key).setErrors(null);
    });

    this.PaymentMthodFormGroup.markAsPristine();
    this.PaymentMthodFormGroup.markAsUntouched();
    this.PaymentMthodFormGroup.updateValueAndValidity();
  }

  restForm() {
    this.PaymentMthodFormGroup.reset({
      autopay_checkbox_2: true,
      card_number: null,
      exp_month: null,
      exp_year: null,
      cv_code: null,
      nickname: null,
      card_name: null,
      card_lastName: null,
      address1: null,
      city: "TX",
      zip: null,
      phone: null,
    });

    // Limpiar errores de validación manualmente
    Object.keys(this.PaymentMthodFormGroup.controls).forEach((key) => {
      this.PaymentMthodFormGroup.get(key).setErrors({
        emitEvent: false,
        invalid: false,
      });
    });

    this.PaymentMthodFormGroup.markAsPristine();
    this.PaymentMthodFormGroup.markAsUntouched();
    this.PaymentMthodFormGroup.updateValueAndValidity();
  }

  scrollToTop() {
    const element = document.querySelector(".manage");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  getCardType = (numberCard: any) => {
    var numberClean = numberCard.replace(/[^\d]/g, "");
    if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(numberClean)) {
      return "VISA";
    } else if (/^5[1-5][0-9]{14}$/.test(numberClean)) {
      return "MSTR";
    } else if (/^3[47][0-9]{13}$/.test(numberClean)) {
      return "AMEX";
    } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(numberClean)) {
      return "DISC";
    } else {
      return "VISA";
    }
  };

  deletePaymenMethods(id) {
    this.card_id_delete = id;
    this.confirmDeletePayment.show();
  }

  deletePaymentMethodsConfirm() {
    this.isLoading = true;
    this.CS.deletePaymenMethods(this.card_id_delete).subscribe(
      (response) => {
        this.CS.getPaymentMethods(this.user.id).subscribe(
          (response) => {
            this.paymentsMethods = response;
            this.isLoading = false;
            this.changeDetector.detectChanges();
          },
          (error) => {
            console.log("Error getting Payment Methods", error);
          }
        );
      },
      (error) => {
        this.errorMessage = error.message;
      }
    );
  }

  futureYearValidator(): ValidatorFn {
    return (control: any) => {
      if (!control.value) {
        return null;
      }

      const currentYear = new Date().getFullYear();
      const inputYear = parseInt(control.value, 10) + 2000;

      return inputYear >= currentYear ? null : { invalidYear: true };
    };
  }
}
