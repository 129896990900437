import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  baseUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) {}

  getAllPayments(q: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/api/payments/get_all?from_date=${q.from_date}&to_date=${
        q.to_date
      }&status_id=${q.status_id}&search=${q.search || "undefined"}&limit=${
        q.limit ? q.limit : "10"
      }&page=${q.page ? q.page : "1"}`,
      { observe: "response" }
    );
  }

  getPaymentById(paymentId) {
    return this.http.get<any>(`${this.baseUrl}/api/payments/${paymentId}`);
  }

  getNumberOfPaymentsByTransactionId(transactionId) {
    return this.http.get<any>(
      `${this.baseUrl}/api/multiple_payments/${transactionId}/count`
    );
  }

  getPaymentsByTransactionId(transactionId) {
    return this.http.get<any>(
      `${this.baseUrl}/api/multiple_payments/${transactionId}`
    );
  }

  getSyncPaymentStatus() {
    return this.http.get<any>(`${this.baseUrl}/api/payments/sync_status`);
  }

  syncPayments() {
    return this.http.post<any>(`${this.baseUrl}/api/payments/sync`, {});
  }

  generatePaymentsReport(startDate, endDate) {
    return this.http.get(
      `${this.baseUrl}/api/payments/report?start_date=${startDate}&end_date=${endDate}`,
      { responseType: "blob" }
    );
  }

  generateIncodeReport(startDate, endDate, optionextension) {
    return this.http.get(
      `${this.baseUrl}/api/payments/report/incode?start_date=${startDate}&end_date=${endDate}&type_extension=${optionextension}`,
      { responseType: "blob" }
    );
  }

  generateIncodeReportKiosk(startDate, endDate, optionextension) {
    return this.http.get(
      `${this.baseUrl}/api/payments/report/incode-kiosk?start_date=${startDate}&end_date=${endDate}&type_extension=${optionextension}`,
      { responseType: "blob" }
    );
  }
  //&casher_user_id=${casher_user_id}
  generateIncodeAdminFeeReport(
    startDate,
    endDate,
    optionextension,
    cashier_user_id
  ) {
    return this.http.get(
      `${this.baseUrl}/api/payments/report/admin_fee?start_date=${startDate}&end_date=${endDate}&type_extension=${optionextension}&cashier_user_id=${cashier_user_id}`,
      { responseType: "blob" }
    );
  }
  generateTerminalReport(startDate, endDate, optionextension) {
    return this.http.get(
      `${this.baseUrl}/api/payments/report/terminal?start_date=${startDate}&end_date=${endDate}&type_extension=${optionextension}`,
      { responseType: "blob" }
    );
  }
  generateAdminReport(startDate, optionextension, type_list, cashier_user_id) {
    return this.http.get(
      `${this.baseUrl}/api/payments/report/admin_report?start_date=${startDate}&type_extension=${optionextension}&filter_op=${type_list}&cashier_user_id=${cashier_user_id}`,
      { responseType: "blob" }
    );
  }
  insertPDSInfo(pdsInfo) {
    return this.http.post<any>(`${this.baseUrl}/api/payments/insert`, pdsInfo);
  }

  logCashOrCheckPayment(logInfo) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/log/cash_or_check`,
      logInfo
    );
  }

  getTotalDownloadsLogs() {
    return this.http.get<any>(`${this.baseUrl}/api/metrics/download-logs`);
  }

  getAllDownloadsLogs(q: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/api/metrics/getAlldownload-logs?from_date=${
        q.from_date || "01/01/01"
      }&to_date=${q.to_date || "01/01/01"}&search=${
        q.search || "undefined"
      }&limit=${q.limit ? q.limit : "10"}&page=${q.page ? q.page : "1"}`
    );
  }
  getgraphicLogs() {
    return this.http.get<any>(`${this.baseUrl}/api/metrics/getgraphic`);
  }

  sendReceiptSMS(data) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/send-receipt-sms`,
      data
    );
  }

  generatePaymentsIVR(startDate, endDate) {
    return this.http.get(
      `${this.baseUrl}/api/payments/ivr/payment-report?start_date=${startDate}&end_date=${endDate}`,
      { responseType: "blob" }
    );
  }

  generatePaymentsKiosk(startDate, endDate) {
    return this.http.get(
      `${this.baseUrl}/api/payments/kiosk/payment-report?start_date=${startDate}&end_date=${endDate}`,
      { responseType: "blob" }
    );
  }

  reportAutopay() {
    return this.http.get(`${this.baseUrl}/api/payments/report/autopay`, {
      responseType: "blob",
    });
  }
}
