import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { FormBuilder, Validators, FormArray, FormGroup } from "@angular/forms";

import {
  TypeService,
  CustomerService,
  TicketService,
  StatusService,
  UserService,
  CryptoService,
} from "../../_services";
import { TicketTypes, Ticket, Account } from "src/app/_models";
import { switchMap } from "rxjs/operators";

declare var $: any;

@Component({
  selector: "app-edit-ticket",
  templateUrl: "./edit-ticket.component.html",
  styleUrls: ["./edit-ticket.component.css"],
})
export class EditTicketComponent implements OnInit {
  @ViewChild("editSuccess") private editSuccessSwal: SwalComponent;
  @Output() editTicketModalClosed = new EventEmitter();
  public editTicketFormGroup: FormGroup;
  public errorMessage: string;

  public ticket;

  public accounts: Account[];
  public ticketTypes: TicketTypes[];
  public ticketStatuses: any;
  public user: any;
  public loading: boolean = false;

  public inputFiles: File[];
  public filesToUpload: File[] = [];
  public viewFiles: any[] = [];
  public editSuccessAlertTitle = "Ticket Updated";
  public enterTitleText = "Enter Title";
  public enterDescriptionText = "Enter Description";

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private customerService: CustomerService,
    private typeService: TypeService,
    private ticketService: TicketService,
    private statusService: StatusService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private cryptoService: CryptoService
  ) {
    this.editTicketFormGroup = this.fb.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
      ticket_type: ["", Validators.required],
      ticket_status: ["", Validators.required],
      account_id: ["", Validators.required],
      user_id: ["", Validators.required],
      files: ["", null],
    });
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    if (window.location.href.includes("/es/")) {
      this.editSuccessAlertTitle = "Ticket Actualizado";
      this.enterTitleText = "Ingrese un Título";
      this.enterDescriptionText = "Ingrese una Descripción";
    }
  }

  setEditTicketForm() {
    console.log(this.ticket);
    this.editTicketFormGroup.setValue({
      title: this.ticket.details.title,
      description: this.ticket.details.description,
      ticket_type: this.ticket.ticket_type_id,
      ticket_status: this.ticket.ticket_status_id,
      account_id: this.ticket.account_id,
      user_id: this.ticket.user_id,
      files: this.ticket.details.images,
    });

    this.user = JSON.parse(window.localStorage.current_user);
    console.log("FORM GROUP: ", this.editTicketFormGroup);

    if (this.user.user_type_id == 3) {
      this.customerService
        .getAllAccounts(this.user.id)
        .subscribe((data) => (this.accounts = data));
    } else {
      this.userService
        .getAccountsById(this.ticket.user_id)
        .subscribe((data) => {
          this.accounts = data;
        });
    }

    this.typeService
      .getTicketTypes()
      .subscribe((data) => (this.ticketTypes = data));

    this.statusService
      .getTicketStatus()
      .subscribe((data) => (this.ticketStatuses = data));
  }

  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files.length) {
      this.inputFiles = <Array<File>>fileInput.target.files;
      //this.viewFiles = [];

      Array.from(this.inputFiles).forEach((file) => {
        this.filesToUpload.push(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.viewFiles.push({ data: reader.result, name: file.name });
          console.log("files array:", this.viewFiles);
          console.log("files to upload array:", this.filesToUpload);
        };
      });
    }
  }

  submitEditedTicket() {
    const formData: any = new FormData();
    const files: Array<File> = this.filesToUpload;
    this.loading = true;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i]["name"]);
      }
    }

    const details = {
      title: this.editTicketFormGroup.value.title,
      description: this.editTicketFormGroup.value.description,
      images: this.editTicketFormGroup.value.files,
    };

    formData.append(
      "ticket_type_id",
      this.editTicketFormGroup.value.ticket_type
    );
    formData.append("account_id", this.editTicketFormGroup.value.account_id);
    formData.append("details", JSON.stringify(details));

    if (this.user.user_type_id == 3) {
      this.customerService
        .updateTicket(this.user.id, this.ticket.id, formData)
        .subscribe((data) => {
          this.loading = false;
          this.editSuccessSwal.show();
        });
    } else {
      formData.append("user_id", this.editTicketFormGroup.value.user_id);
      formData.append(
        "ticket_status_id",
        this.editTicketFormGroup.value.ticket_status
      );

      if (!this.editTicketFormGroup.controls["ticket_status"].pristine) {
        console.log("STATUS CHANGED EMAIL USER: ");
        this.ticketService
          .updateTicketStatus(this.ticket.id, {
            id: this.editTicketFormGroup.value.ticket_status,
          })
          .subscribe(
            (response) => {
              console.log("email: ", response);
            },
            (error) => console.log("Error : ", error)
          );
      }

      this.ticketService.updateTicket(this.ticket.id, formData).subscribe(
        (data) => {
          this.loading = false;
          this.editSuccessSwal.show();
        },
        (err) => console.log("error: ", err)
      );
    }
  }

  statusChanged() {
    if (
      this.ticket.ticket_status_id ==
      this.editTicketFormGroup.value.ticket_status
    ) {
      this.editTicketFormGroup.controls["ticket_status"].markAsPristine({
        onlySelf: true,
      });
    }
  }

  confirmUpdate() {
    this.bsModalRef.hide();
    this.editTicketModalClosed.emit(true);
    // /window.location.reload();
  }
}
