import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {
  transform(phone: any, clean: boolean): any {
    if(phone == null || phone == undefined || phone == ''){
      return 'N/A'
    }

    let cleanNumber = phone.match(/\d/g).join('');
    let formattedNumber = cleanNumber;

    if(cleanNumber.length == 10){
      formattedNumber = '(' + cleanNumber.substring(0, 3) + ') ' + cleanNumber.substring(3, 6) + '-' + cleanNumber.substring(6, 10);
    }

    if (cleanNumber.length == 7) {
      formattedNumber = cleanNumber.substring(0, 3) + '-' + cleanNumber.substring(3, 7);
    }
    
    return clean? cleanNumber : formattedNumber
  }
}
