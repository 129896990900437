import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
declare var $: any;

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { PaymentsService } from 'src/app/_services';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

@Component({
  selector: 'app-payment-report-ivr',
  templateUrl: './payment-report-ivr.component.html',
  styleUrls: ['./payment-report-ivr.component.css']
})
export class PaymentReportIvrComponent implements OnInit {

  @ViewChild('report') private reportSwal: SwalComponent;
  @ViewChild('failure') private failureSwal: SwalComponent;
  @ViewChild('success') private successSwal: SwalComponent;
  
  
  private month = moment().format('MM');
  private year = moment().format('YYYY');
  public titleAlert : string = "IVR Payment Report";
  public yearsAlert : string = `<option value='01'>January</option>
  <option value='02'>February</option>              
  <option value='03'>March</option>              
  <option value='04'>April</option>
  <option value='05'>May</option>
  <option value='06'>June</option>
  <option value='07'>July</option>
  <option value='08'>August</option>
  <option value='09'>September</option>
  <option value='10'>October</option>
  <option value='11'>November</option>
  <option value='12'>December</option>`;
  public yearAlert : string = "Year";
  public dayAlert : string = "Day";
  public monthAlert : string = "Month";
  public confirmButtonTextAlert : string ="Download";
  public cancelButtonTextAlert : string ="Cancel";
  public failTextAlert : string = "Something went wrong, we are very sorry";
  public successTextAlert : string = "Downloading...";
  public yearText : string = "Year";
  public notFound : string = "No payments found for selected date range.";

  public bsConfig: Partial<BsDatepickerConfig>;
  public modalRef: BsModalRef;
  public reportDateRange;

  constructor(private paymentService: PaymentsService, private modalService: BsModalService) { 
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', rangeInputFormat : 'MMMM Do YYYY, h:mm:ss a', dateInputFormat: 'MMMM Do YYYY, h:mm:ss a', showWeekNumbers: false });
    this.reportDateRange = [moment().startOf('month').toDate(), moment().endOf('month').toDate()];
  }

  ngOnInit() {

    if(window.location.href.includes("/es/")){
      this.titleAlert = "Reporte de Pagos";
      this.yearsAlert = `<option value='01'>Enero</option>
      <option value='02'>Febrero</option>              
      <option value='03'>Marzo</option>              
      <option value='04'>Abril</option>
      <option value='05'>Mayo</option>
      <option value='06'>Junio</option>
      <option value='07'>Julio</option>
      <option value='08'>Agosto</option>
      <option value='09'>Septiembre</option>
      <option value='10'>Octubre</option>
      <option value='11'>Noviembre</option>
      <option value='12'>Diciembre</option>`;
      this.yearAlert= "Año";
      this.dayAlert= "Día";
      this.confirmButtonTextAlert="Descargar";
      this.cancelButtonTextAlert="Cancelar";
      this.monthAlert= "Mes";
      this.failTextAlert = "Lo sentimos, ha ocurrido un error";
      this.successTextAlert = "Descargando...";
      this.yearText = "Año"; 
      this.notFound = "No hay pagos encontrados para el rango de fechas seleccionada.";

    }

  }

  generatePaymentsReport() {
    let startDate = moment(this.reportDateRange[0]);
    let endDate = moment(this.reportDateRange[1]);
    this.paymentService.generatePaymentsIVR(startDate.toISOString(), endDate.toISOString())
      .subscribe(
        response => {
          let filename = `${startDate.format('MMMM DD YYYY, h:mm:ss a')} - ${endDate.format('MMMM Do YYYY, h:mm:ss a')}  IVR Payments.xlsx`
          saveAs(response, filename);
          this.reportDateRange = [moment().startOf('month').toDate(), moment().endOf('month').toDate()];
          this.modalRef.hide()
          this.successSwal.show()
        },
        error => {
          console.log("error", error);
          if(error.status == 404){
            this.failureSwal.title = this.notFound;
            this.failureSwal.show()
          } else {
            this.failureSwal.show()            
          }
        }
      )
  }

  openAlert() {
    this.reportSwal.show()
    $('#paymentMonth').val(this.month);
    $('#paymentYear').val(this.year);    
  }

  onOpen(event) {
    $('body').removeClass("swal2-height-auto");
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg gray inmodal report-modal ', backdrop: true, ignoreBackdropClick: false}));
  }
}



