export class WorkOrder {
    id: number;
    ticket_id: number;
    work_order_type_id: number;
    work_order_status_id: number;
    bill_id: number;
    tyler_tech_id: number;
    details: any;
    notes: string;
    date_created: string;
    date_updated: string;
}
