import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CountPerDaysService {
  baseUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) { }

  getCountLoginPerDays(from, to): Observable<HttpResponse<any>> {

    let res = this.http.get<any[]>(
      `${this.baseUrl}/api/metrics/user_getLogsPaymentPerDay/${from}/${to}`,
      { observe: "response" });
    return res;

  }

  getCountPaymentsPerDays(from, to): Observable<HttpResponse<any>> {

    let res = this.http.get<any[]>(
      `${this.baseUrl}/api/metrics/user_getStoreTerminalperday/${from}/${to}`,
      { observe: "response" });
    return res;
  }
  
}

