import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders}	from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import * as moment from 'moment'
@Injectable({
  providedIn: 'root'
})
export class TrafficService {
  baseUrl: string = environment.serverUrl;

  constructor(private http : HttpClient) { }
  
  getAllStandaloneterminal(info: any) : Observable<HttpResponse<any>> {
  	return this.http.get<any>(`${this.baseUrl}/api/standalone/get_allstandalone?from_date=${info.from_date}&to_date=${info.to_date}&status_id=${info.status_id}&search=${info.search || 'undefined'}&limit=${info.limit? info.limit:"10"}&page=${info.page? info.page: "1"}`, {observe : 'response'});
  }
  generateVirtualPaymentReport(startDate, endDate){
    return this.http.get(`${this.baseUrl}/api/standalone/reportVirtualterminal?start_date=${startDate}&end_date=${endDate}`, { responseType: 'blob' });    
  }
}
