import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { CommLogs, Notification, allUserMessage } from "../_models";
import { Observable } from "rxjs";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { CryptoService } from "./crypto.service";
@Injectable({
  providedIn: "root",
})
export class CommunicationService {
  baseUrl: string = environment.serverUrl;

  allUserMessage: allUserMessage[];
  public LoggedUser;
  public today;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private cryptoService: CryptoService
  ) {}

  getAll(): Observable<HttpResponse<any>> {
    return this.http.get<CommLogs[]>(
      `${this.baseUrl}/api/communication/communication_logs`,
      { observe: "response" }
    );
  }

  getCommLogsByDateRange(
    from,
    to,
    page,
    limit,
    search
  ): Observable<HttpResponse<any>> {
    console.log("search getCommLogsByDateRange: ", search);
    return this.http.get<CommLogs[]>(
      `${this.baseUrl}/api/communication/communication_logs/${from}/${to}?page=${page}&limit=${limit}&search=${search}`,
      { observe: "response" }
    );
  }

  getLogById(id: number): Observable<HttpResponse<any>> {
    return this.http.get<CommLogs>(
      `${this.baseUrl}/api/communication/communication_logs/${id}`,
      { observe: "response" }
    );
  }

  sendEmail(data: any): Observable<HttpResponse<any>> {
    console.log("send email service? ", data.get("type"));
    return this.http.post<any>(
      `${this.baseUrl}/api/communication/send_email`,
      data,
      { observe: "response" }
    );
  }

  uploadImage(type, data) {
    console.log("TYPE: ", type);
    return this.http.post<any>(
      `${this.baseUrl}/api/upload/emails/${type}`,
      data
    );
  }

  notificationSuccess(msg, title, position) {
    this.toastr.success(msg, title, {
      tapToDismiss: true,
      closeButton: true,
      positionClass: position,
      enableHtml: true,
      timeOut: 9000,
    });
  }

  notificationInfo(msg, title, position) {
    this.toastr.info(msg, title, {
      tapToDismiss: true,
      closeButton: true,
      positionClass: position,
      enableHtml: true,
    });
  }
  notificationWarning(msg, title, position) {
    this.toastr.warning(msg, title, {
      tapToDismiss: true,
      closeButton: true,
      positionClass: position,
      enableHtml: true,
    });
  }

  notificationError(msg, title, position) {
    this.toastr.error(msg, title, {
      tapToDismiss: true,
      closeButton: true,
      positionClass: position,
      enableHtml: true,
    });
  }

  addNotification(info: any): Observable<HttpResponse<any>> {
    // console.log('CommServices -> add notif de comm services: ');
    // console.log(info);
    return this.http.post<any>(`${this.baseUrl}/api/notifications/add`, info, {
      observe: "response",
    });
  }

  getAllNotifications(): Observable<HttpResponse<any>> {
    console.log("CommServices -> get all notif de comm services: ");
    return this.http.get<Notification[]>(
      `${this.baseUrl}/api/notifications/getallnotif`,
      { observe: "response" }
    );
  }

  deleteNotification(id: number): Observable<HttpResponse<any>> {
    console.log("CommServices -> delete notif de comm services: ");
    return this.http.delete<any>(
      `${this.baseUrl}/api/notifications/delete/${id}`,
      { observe: "response" }
    );
  }

  updateNotification(id: number, info: any): Observable<HttpResponse<any>> {
    console.log("CommServices -> update notif de comm services: ");
    return this.http.put<any>(
      `${this.baseUrl}/api/notifications/update/${id}`,
      info,
      { observe: "response" }
    );
  }
  /* CONSULTAR LAS NOTIFICACIONES PARA UN USUARIO */
  getUserMessage(id: number, date: string): Observable<HttpResponse<any>> {
    console.log("DATOS DATE Y ID ENVIADOS");
    return this.http.get<any>(
      `${this.baseUrl}/api/notifications/getUserMessage/${id}/${date}`,
      { observe: "response" }
    );
  }
  /* FIN getUserMessage */

  /* OBTENER LAS NOTIFICACIONES DE UN USUARIO LOGEADO */
  getUserLoggedMessage() {
    this.LoggedUser = JSON.parse(window.localStorage.current_user);
    this.today = moment().format("YYYY-MM-DD");

    const id = this.LoggedUser.id;
    const date = this.today;
    /* console.log("servicio notificaciones -->", id, " ---- ", date); */
    /* 	const id = 3565;
			const date = '2020-10-22'; */

    this.getUserMessage(id, date).subscribe((response) => {
      this.allUserMessage = response.body;
      if (this.allUserMessage) {
        for (let mensaje of this.allUserMessage) {
          /* VISUALIZAR NOTIFICACIONES */
          this.vewNotification(
            mensaje.type,
            mensaje.title,
            mensaje.message,
            mensaje.position
          );
        }
      }
      console.log(" ALL USER MESSAGE : ", this.allUserMessage);
    }),
      (err) => {
        console.log("Erorr getting Notifications: ", err);
      },
      () => {
        console.log("finished gettings all Notifications");
      };
  }
  /*  FIN getUserMessage  */

  /* VISUALIZAR LAS NOTIFICACIONES DE UN USUARIO LOGEADO */
  vewNotification(tipo, titulo, mensaje, posicion) {
    var toastType = tipo;
    var title = titulo;
    var msg = mensaje;
    var position = posicion;
    console.log(toastType, title, msg, position);
    if (toastType === "success") {
      this.notificationSuccess(msg, title, position);
    } else if (toastType === "info") {
      this.notificationInfo(msg, title, position);
    } else if (toastType === "warning") {
      this.notificationWarning(msg, title, position);
    } else {
      this.notificationError(msg, title, position);
    }
  }
  /* FIN vewNotification */
}
