import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SwalComponent } from "@toverux/ngx-sweetalert2";

import { AuthService, BillsService, CryptoService } from "../_services";
import { TermsComponent } from "../_common/terms/terms.component";

import * as globals from "../globals.json";
import { environment } from "src/environments/environment";
import * as Bowser from "bowser";
import { BrowserService } from "../_services/browser.service";
import { User } from "../_models";

declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  @ViewChild("emailSent") private emailSentSwal: SwalComponent;
  @ViewChild("emailSentValidate") private emailSentValidateSwal: SwalComponent;
  @ViewChild("errorSwal") private errorSwal: SwalComponent;
  public email: string;
  public password: string;
  public error: string;
  public rememberMe = false;
  public globals: any = globals.default;
  public language: string;
  public emailPlaceholder = "Email";
  public passwordPlaceholder = "Password";
  public signupAlerthtml: string =
    `
  <div class='row'>
      <h2>Are you sure want to open a <b>NEW</b> ` +
    this.globals.onlyCityName +
    ` Water Department account?</h2>
      <br>
      <br>
  </div>
  <div class='row' style='padding: 30px'>
      <div class='col-md-6'>
          <h3 style='font-weight: lighter'>No, I only want to activate my online account.</h3>
          <button type='button' id='goToActivate' class='btn btn-primary'>Activate</button>
      </div>
      <div class='col-md-6'>
          <h3 style='font-weight: lighter'>Yes, I want to open a <b>NEW</b> ` +
    this.globals.onlyCityName +
    ` account.</h3>
          <button type='button' id='goToSignup' class='btn btn-success'>Continue</button>
      </div>
  </div>`;
  public rememberMeText = "Remember Me";

  bsModalRef: BsModalRef;
  loginLoading = false;
  @ViewChild("signupAlert") private signupAlertSwal: SwalComponent;
  currentUser: any;

  constructor(
    private auth: AuthService,
    private router: Router,
    private bsModalService: BsModalService,
    private browser: BrowserService,
    private BS: BillsService,
    private cryptoService: CryptoService
  ) {}

  getURL(lang) {
    console.log("url ", window.location.href);
    let url = window.location.href;

    //for localhost development

    if (environment.local) {
      if (url.indexOf("4300") > -1 && lang === "en") {
        url = url.replace("4300/es/", "4200/");
      } else if (url.indexOf("4200") > -1 && lang === "es") {
        url = url.replace("4200/", "4300/es/");
      }
    } else {
      if (lang === "en") {
        lang = "";
      } else if (lang === "es") {
        lang = "/es";
      }

      const path = url.replace(environment.serverUrl, "").replace("/es/", "/");
      url = `${environment.serverUrl}${lang}${path}`;
    }

    console.log("url replaced", url);
    return url;
  }

  changeLanguage(lang) {
    window.location.href = this.getURL(lang);
  }

  ngOnInit() {
    if (localStorage.getItem("access_token")) {
      this.auth.logout();
    }

    if (window.location.href.includes("/es/")) {
      this.emailPlaceholder = "Correo";
      this.passwordPlaceholder = "Contraseña";
      this.signupAlerthtml =
        `
  <div class='row'>
      <h2>Esta seguro que desea crear una cuenta de <b>NUEVA</b> ` +
        this.globals.onlyCityName +
        ` Departamento de Agua?</h2>
      <br>
      <br>
  </div>
  <div class='row' style='padding: 30px'>
      <div class='col-md-6'>
          <h3 style='font-weight: lighter'>No, Solo quiero activar mi cuenta en linea.</h3>
          <button type='button' id='goToActivate' class='btn btn-primary'>Activar</button>
      </div>
      <div class='col-md-6'>
          <h3 style='font-weight: lighter'>Si, Quiero abrir una cuenta <b>NUEVA</b> ` +
        this.globals.onlyCityName +
        `.</h3>
          <button type='button' id='goToSignup' class='btn btn-success'>Continuar</button>
      </div>
  </div>`;
      this.rememberMeText = "Recuerdame";
    }
  }

  public submit() {
    this.loginLoading = true;
    this.auth.login(this.email, this.password, this.rememberMe).subscribe(
      (result) => {
        this.loginLoading = false;
        if (result.isStaffUser) {
          this.emailSentSwal.title = "Two Factor Authentication";
          this.emailSentSwal.html =
            "<p> An authentication code has been sent to your email or sms </p>" +
            '<div class="form-group"> ' +
            '<input id="code" class="form-control text-center" type="text" placeholder="Code" (input)="transformToLowercase($event)"> ' +
            "</div>";
          this.emailSentSwal.show();
        } else {
          if (result.paymentMethods) {
            this.emailSentValidateSwal.title = "Two Factor Authentication";
            this.emailSentValidateSwal.html =
              "<p> An authentication code has been sent to your email or sms </p>" +
              '<div class="form-group"> ' +
              '<input id="code_customer" class="form-control text-center" type="text" placeholder="Code" (input)="transformToLowercase($event)"> ' +
              "</div>";
            this.emailSentValidateSwal.show();
            return;
          }

          if (
            JSON.parse(localStorage.getItem("current_user")).user_type_id == 3
          ) {
            this.router.navigate(["dashboard"]);
          } else if (
            JSON.parse(localStorage.getItem("current_user")).user_type_id == 2
          ) {
            this.router.navigate(["work_orders"]);
          } else if (
            JSON.parse(localStorage.getItem("current_user")).user_type_id == 6
          ) {
            this.router.navigate(["bills"]);
          } else {
            this.router.navigate(["users"]);
          }

          //aqui se obtendra la info del equipo y del navegador

          let infoNav = Bowser.parse(window.navigator.userAgent);
          this.currentUser = JSON.parse(window.localStorage.current_user);
          const id = this.currentUser.id;
          this.browser.saveBrowser(id, infoNav).subscribe(
            (result) => {
              console.log(result);
            },
            (error) => {}
          );
        }
      },
      (err) => {
        this.loginLoading = false;
        this.error = err.message;
      }
    );
  }

  transformToLowercase(event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toLowerCase();
    this.email = input.value;
  }

  submitValidate() {
    this.loginLoading = true;
    let token = $.trim($("#code_customer").val());
    token = token ? token.toLowerCase() : "";
    return this.BS.valide2fa({
      email: this.email,
      token,
      paylink: false,
    }).subscribe(
      (res: any) => {
        this.loginLoading = false;
        localStorage.setItem("access_token", res.body.token);
        localStorage.setItem("current_user", JSON.stringify(res.body.user));

        if (
          JSON.parse(localStorage.getItem("current_user")).user_type_id == 3
        ) {
          this.router.navigate(["dashboard"]);
        } else if (
          JSON.parse(localStorage.getItem("current_user")).user_type_id == 2
        ) {
          this.router.navigate(["work_orders"]);
        } else {
          this.router.navigate(["users"]);
        }

        //aqui se obtendra la info del equipo y del navegador
        let infoNav = Bowser.parse(window.navigator.userAgent);
        this.currentUser = JSON.parse(localStorage.getItem("current_user"));
        const id = this.currentUser.id;
        this.browser.saveBrowser(id, infoNav).subscribe(
          (result) => {
            console.log(result);
          },
          (error) => {}
        );
      },
      (error) => {
        this.errorSwal.title = "Error";
        this.errorSwal.text =
          error.message || "Something went wrong, please try again later";
        this.errorSwal.show();
        this.loginLoading = false;
      }
    );
  }

  triggerTermsModal() {
    const tModalRef = (this.bsModalRef = this.bsModalService.show(
      TermsComponent,
      Object.assign(
        {},
        {
          class: "gray modal-md inmodal",
          backdrop: true,
          ignoreBackdropClick: true,
        }
      )
    ));
  }

  openAlert() {
    this.signupAlertSwal.show();
    $("#goToActivate").click(() => {
      this.router.navigate(["signup", "existing"]);
    });
    $("#goToSignup").click(() => {
      this.router.navigate(["signup"]);
    });
  }

  onOpen(event) {
    $("body").removeClass("swal2-height-auto");
  }

  submitStaffAuthentication() {
    this.loginLoading = true;
    let token = $.trim($("#code").val());
    token = token ? token.toLowerCase() : "";
    return this.auth
      .staffLoginAuthentication({ email: this.email, token })
      .subscribe(
        (res) => {
          this.loginLoading = false;
          localStorage.setItem("access_token", res.token);
          localStorage.setItem("current_user", JSON.stringify(res.user));
          const user = JSON.parse(localStorage.getItem("current_user"));

          if (user.user_type_id == 1) {
            this.router.navigate(["users"]);
          } else if (user.user_type_id == 2) {
            this.router.navigate(["work_orders"]);
          }
        },
        (error) => {
          this.errorSwal.title = "Error";
          this.errorSwal.text =
            error.message || "Something went wrong, please try again later";
          this.errorSwal.show();
          this.loginLoading = false;
        }
      );
  }
}
