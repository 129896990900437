import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  // Set our map properties
  // Base map types
  //  "streets",
  //  "satellite",
  //  "hybrid",
  //  "terrain",
  //  "topo",
  //  "gray",
  //  "dark-gray",
  //  "oceans",
  //  "national-geographic",
  //  "osm",
  //  "dark-gray-vector",
  //  "gray-vector",
  //  "streets-vector",
  //  "topo-vector",
  //  "streets-night-vector",
  //  "streets-relief-vector",
  //  "streets-navigation-vector"
  mapCenter = [-98.340721, 26.215781];
  basemapType = 'streets-navigation-vector';
  mapZoomLevel = 11;

  
  constructor() { }

  ngOnInit() {
  }

  // See app.component.html
  mapLoadedEvent(status: boolean) {
    console.log('The map loaded: ' + status);
  }

}
