import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ReversalsService {

  baseUrl: string = environment.serverUrl;
  @Output() propagar: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient) { }

  getReversalsByDate(startDate, endDate): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.baseUrl}/api/reversals/get-all-reversals-by-date?startDate=${startDate}&endDate=${endDate}`);
  }

  updateReversalById(id, user_id){
  	return this.http.put(`${this.baseUrl}/api/reversals/update/${id}`, {state:true, user:user_id});
  }

  getCounterReversals(){
    return this.http.get<any>(`${this.baseUrl}/api/reversals/get-all-count-reversals`)
  }

  sendCount(){
    this.propagar.emit()
  }

  downloadReport(start, end){
    return this.http.get(`${this.baseUrl}/api/reversals/report?start=${start}&end=${end}`, { responseType: 'blob' });
  }
}
