import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Address } from "../_models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuditTrailService {
  baseUrl: string = environment.serverUrl;
  addressURL: string = this.baseUrl + "/api/audit-trail";

  constructor(private http: HttpClient) {}

  getAuditTrail(filters): Observable<HttpResponse<any>> {
    return this.http.get<Address[]>(
      `${this.addressURL}/?search=${filters.search}&page=${filters.page}&limit=${filters.limit}&date_start=${filters.date_start}&date_end=${filters.date_end}`,
      {
        observe: "response",
      }
    );
  }

  getAuditTrailOneUser(user_id): Observable<HttpResponse<any>> {
    return this.http.get<Address[]>(`${this.addressURL}/${user_id}`, {
      observe: "response",
    });
  }
}
