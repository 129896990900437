import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { UserActivationModel } from "../_models";
import { Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class UserActivationService {

  baseUrl: string = environment.serverUrl;
  constructor(private http: HttpClient) { }





  getUserActivation(from, to): Observable<HttpResponse<any>> {
    // console.log('en getUserActivation of service');
    // /user_logs_id/
    let resUserLogs = this.http.get<UserActivationModel[]>(
      `${this.baseUrl}/api/metrics/user_activation/${from}/${to}`,
      { observe: "response" });
    // console.log('resUserLogs de Services: ' + resUserLogs);
    return resUserLogs;
  }

  getUserCountByUserType(): Observable<HttpResponse<any>> {
    let resUserCountByUserType = this.http.get<any>(
      `${this.baseUrl}/api/metrics/user_CountbyUserType`,
      {observe: "response"});
    return resUserCountByUserType;
  }

}
