import {Component, Input, OnInit} from '@angular/core';
import {SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  // @Input() videos: SafeResourceUrl[];

  constructor() { }

  ngOnInit() {
    jQuery(function($){
      $(".rvs-container").rvslider();
    });
  }

}
