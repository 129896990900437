import { Component, OnInit, ViewChild, Output, HostListener } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomerService, TypeService, StatusService } from '../_services';
import { EventEmitter } from 'events';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Router } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TermsComponent } from '../_common/terms/terms.component';
import { AccountTypes } from '../_models';
import * as moment from 'moment';
import * as globals from '../globals.json';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-signup',
  templateUrl: './customer-signup.component.html',
  styleUrls: ['./customer-signup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerSignupComponent implements OnInit {

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    console.log('before unload event', event)
  }

  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  @ViewChild('signupSuccess') private signupSuccessSwal: SwalComponent;
  @ViewChild('signupFailure') private signupFailureSwal: SwalComponent;
  @ViewChild('serverError') private serverErrorSwal: SwalComponent;
  @ViewChild(TermsComponent) terms;
  @ViewChild('pop') private passwordpop: any
  @Output() reload = new EventEmitter();

  public globals: any = globals.default;

  selectedTab = 0
  currentTab: number
  bsModalRef: BsModalRef
  aTypes: any
  public initialBirthDate = moment().subtract(20, 'years').format("MM/DD/YYYY");
  public datePickerInitialDate: any
  public bsConfig: Partial<BsDatepickerConfig>;
  idUploadLoading: boolean = false;
  raUploadLoading: boolean = false;
  idPreviewFile: any;
  RAPreviewFile: any;
  public signupCustomerFormGroup: FormGroup;
  public accountFormGroup: FormGroup;
  public emailFormGroup: FormGroup;
  public passwordFormGroup: FormGroup;
  public profileFormGroup: FormGroup;
  public accountTypeControl: FormGroup;
  public finishFormGroup: FormGroup;
  public disableMailingAddress: boolean = false;
  termsAreRead: boolean = false;
  finishMessage: string = String();
  errorMessage: string = String();
  //validations
  validZips = ['78537']

  public signupSuccessTitle = "Your signup request has been sent";
  public signupFailureTitle = "Something went wrong with the request, we are very sorry";
  public serverErrorTitle = "There was an error contacting the server, we are very sorry";
  public tab1: string = "1. Account";
  public tab2: string = "2. Profile";
  public tab3: string = "3. Finish";

  public inputFiles: File[] = [];
  public filesToUpload: File[] = [];
  public viewFiles: any[] = [];

  public inputFilesId: File[] = [];
  public filesToUploadId: File[] = [];
  public viewFilesId: any[] = [];

  constructor(private fb: FormBuilder, public CS: CustomerService, public TS: TypeService, private SS: StatusService, private router: Router, private bsModalService: BsModalService) {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });
    this.passwordFormGroup = this.fb.group({
      password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{10,}$')]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validator: this.matchValidator.bind(this)
    });

    this.emailFormGroup = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email])
    });

    this.accountFormGroup = this.fb.group({
      email: this.emailFormGroup,
      password: this.passwordFormGroup
    })

    this.accountTypeControl = this.fb.group({
      typeOfAccount: new FormControl('', [Validators.required])
    })

    this.profileFormGroup = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mailingAddress: ['', Validators.required],
      mailingCity: ['', Validators.required],
      mailingState: ['', Validators.required],
      mailingZipcode: new FormControl('', [Validators.required]),
      copyOfID: [''],
      rentalAgreement: [''],
      dateOfBirth: ['', Validators.required],
      driverLicense: ['', Validators.required],
      mobile_phone: ['', Validators.required],
      serviceAddress: ['', Validators.required],
      serviceCity: [this.globals.onlyCityName, Validators.required],
      serviceState: ['TX', Validators.required],
      serviceZipcode: new FormControl('78537', [Validators.required]),
      typeOfAccount: this.accountTypeControl,
      renting: false,
      requireTrashBin: false,
      useServiceAddrForMailing: false,
    })


    this.finishFormGroup = this.fb.group({
      acceptTerms: new FormControl({
        value: false, disabled: true
      })
    })

    this.signupCustomerFormGroup = this.fb.group({
      accountFormGroup: this.accountFormGroup,
      profileFormGroup: this.profileFormGroup,
      finishFormGroup: this.finishFormGroup
    });
  }
  get mobile_number() {
    //  return this.profileFormGroup.value.mobile_phone.length == 10 ? false : true; 
    return this.profileFormGroup.value.mobile_phone;
  }
  get email() { return this.emailFormGroup.get('email'); }
  get password() { return this.passwordFormGroup.get('password'); }
  get confirmPassword() { return this.passwordFormGroup.get('confirmPassword'); }
  get zipcode() { return this.profileFormGroup.get('zipcode'); }

  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files.length) {
      this.inputFiles = <Array<File>>fileInput.target.files;
      //this.viewFiles = [];

      Array.from(this.inputFiles).forEach((file) => {
        this.filesToUpload.push(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.viewFiles.push({ data: reader.result, name: file.name });
        }
      })
    }
  }

  onFileChangeId(fileInput: any) {
    if (fileInput.target.files.length === 1) {
      this.inputFilesId = <Array<File>>fileInput.target.files;
      //this.viewFiles = [];
      Array.from(this.inputFilesId).forEach((file) => {
        this.filesToUploadId.push(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.viewFilesId.push({ data: reader.result, name: file.name });
        }
      })
    }
  }

  onClearImages() {
    this.inputFiles = [];
    this.filesToUpload = [];
    this.viewFiles = [];
  }

  onClearImagesId() {
    this.inputFilesId = [];
    this.filesToUploadId = [];
    this.viewFilesId = [];
  }


  getURL(lang) {
    console.log("url ", window.location.href);
    let url = window.location.href;

    //for localhost development

    if (environment.local) {
      if (url.indexOf("4300") > -1 && lang === "en") {
        url = url.replace("4300/es/", "4200/");
      } else if (url.indexOf("4200") > -1 && lang === "es") {
        url = url.replace("4200/", "4300/es/");
      }
    } else {
      if (lang === "en") {
        lang = "";
      } else if (lang === "es") {
        lang = "/es";
      }

      let path = url.replace(environment.serverUrl, '').replace('/es/', '/');
      url = `${environment.serverUrl}${lang}${path}`
    }

    console.log("url replaced", url);
    return url;
  }

  changeLanguage(lang) {
    window.location.href = this.getURL(lang);
  }


  ngOnInit() {
    this.getAccountTypes()
    //this.getAccountTypes()
    // this.disableTabs(this.selectedTab)
    //this.signup()
    this.SS.termsCurrentValue.subscribe(termsValue => this.finishFormGroup.setValue({ acceptTerms: termsValue }));
    this.staticTabs.tabs[1].disabled = true;
    this.staticTabs.tabs[2].disabled = true;
    this.staticTabs.tabs[0].active = true;
    this.getCurrentTab();

    if (window.location.href.includes("/es/")) {
      this.signupSuccessTitle = "Su solicitud de suscripción ha sido enviada";
      this.signupFailureTitle = "Lo sentimos, Hubo un error con la solicitud";
      this.serverErrorTitle = "Lo sentimos, hubo un error de conexión al servidor.";

      this.tab1 = "1. Cuenta";
      this.tab2 = "2. Perfil";
      this.tab3 = "3. Finalizar";

    }
  }

  ngAfterViewInit() {

  }

  receiveTermsValue(value) {
    this.termsAreRead = value;
  }

  next(index) {
    switch (index) {
      case 0:
        this.staticTabs.tabs[0].disabled = true
        this.staticTabs.tabs[1].disabled = false
        this.staticTabs.tabs[2].disabled = true
        break;
      case 1:
        this.staticTabs.tabs[0].disabled = true
        this.staticTabs.tabs[1].disabled = true
        this.staticTabs.tabs[2].disabled = false
        break;
      default:
        break;
    }
    //let nextIndex = index + 1
    this.staticTabs.tabs[index].active = !this.staticTabs.tabs[index].active;
    this.staticTabs.tabs[index + 1].active = !this.staticTabs.tabs[index + 1].active;
    //this.selectedTab = (index + 1);
    this.getCurrentTab()
    //this.disableTabs(nextIndex)

  }

  previous(index) {
    switch (index) {
      case 1:
        this.staticTabs.tabs[0].disabled = false
        this.staticTabs.tabs[1].disabled = true
        this.staticTabs.tabs[2].disabled = true
        break;
      case 2:
        this.staticTabs.tabs[0].disabled = true
        this.staticTabs.tabs[1].disabled = false
        this.staticTabs.tabs[2].disabled = true
        break;
      default:
        break;
    }
    //let previousIndex = index - 1
    //this.disableTabs(index)
    this.staticTabs.tabs[index].active = !this.staticTabs.tabs[index].active;
    this.staticTabs.tabs[index - 1].active = !this.staticTabs.tabs[index - 1].active;
    this.getCurrentTab()
    //this.selectedTab = index - 1;
  }

  getCurrentTab() {
    console.log(this.staticTabs.tabs)
    for (let i = 0; i < this.staticTabs.tabs.length; i++) {
      if (this.staticTabs.tabs[i].active === true)
        this.currentTab = i
    }
  }

  toggleReqPop() {
    this.passwordpop.toggle()
  }

  matchValidator(accountFormGroup: FormGroup) {
    let values = []
    for (let prop in accountFormGroup.controls) {
      values.push(accountFormGroup.controls[prop].value)
    }

    if (values[1].length <= 0) {
      return null;
    }

    if (values[1] !== values[0]) {
      return {
        doesMatchValue: true
      };
    }

    return null;
  }

  zipcodeValidator(zipcode: FormControl) {
    this.validZips.forEach(element => {
      if (zipcode.value == element) {
        return {
          doesMatchValue: true
        }
      }
    });
    return null
  }

  setInitialBirthDate() {
    this.datePickerInitialDate = this.initialBirthDate
  }

  triggerTermsModal() {
    this.finishFormGroup.enable()
    const tModalRef = this.bsModalRef = this.bsModalService.show(TermsComponent, Object.assign({}, { class: 'gray modal-md inmodal', backdrop: true, ignoreBackdropClick: true }))
  }

  getAccountTypes() {
    /**
     * Por experiencia de usuario se dejara los tipos de cuenta que 
     * tienen en incode, no genericamente como residential o commercial.
     * Cualquier nuevo tipo de cuenta, se agrega aqui
     * 
     * El objeto se compone de id y name, para no perder estructura en el front
     * id: 1 = TYPE RESIDENTIAL
     * id: 2 = TYPE COMMERCIAL
     */
    /*
    this.TS.getAccountTypes().subscribe(response =>{
      console.log(response.body)
      this.aTypes = response.body
    },err =>{
      console.log('error retreiving account types', err)
    })
    */

    this.aTypes = [
      { id: 2, name: "Non Residential Inside" },
      { id: 2, name: "Church" },
      { id: 1, name: "Residential" },
      { id: 2, name: "School" },
      { id: 2, name: "Commercial" },
      { id: 1, name: "Multi Family" },
      { id: 2, name: "Non Residential Outside" },
      { id: 2, name: "GOV" },
      { id: 2, name: "Irrigation" },
    ]
  }

  copyOfIDOnFileChange(event) {
    if (event.target.files) {
      try {
        this.idUploadLoading = true;
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          let preview = { data: reader.result, name: files[0].name }
          // console.log('copy of ID file: ', processed)
          this.getIDURL(files[0], files[0].name, preview)
        }
      } catch (error) {
        console.log("Error: ", error);
        this.idUploadLoading = false;
      }
    }
  }

  getIDURL(file, fileName, newFile) {
    const formData: any = new FormData();


    formData.append("files", file, fileName);

    //WE LEFT OFF HERE
    this.CS.uploadID(this.accountFormGroup.value.email.email, formData)
      .subscribe(
        data => {
          this.idUploadLoading = false
          this.idPreviewFile = newFile;
          // console.log('upload result: ', data)
          this.profileFormGroup.patchValue({
            copyOfID: data.location
          });
        }, error => {
          this.idUploadLoading = false
          // console.log('upload error: ', error)
        }
      )
  }


  RAOnFileChange(event) {
    if (event.target.files) {
      this.raUploadLoading = true
      let file = event.target.files[0]
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let preview = { data: reader.result, name: file.name }
        // console.log('rental agreement file: ', this.RAPreviewFile)
        this.getRAURL(file, file.name, preview)
      }
    }
  }

  getRAURL(file, fileName, newFile) {
    const formData: any = new FormData();


    formData.append("files", file, fileName);

    //WE LEFT OFF HERE
    this.CS.uploadRA(this.accountFormGroup.value.email.email, formData)
      .subscribe(
        data => {
          this.raUploadLoading = false
          this.RAPreviewFile = newFile
          this.profileFormGroup.patchValue({
            rentalAgreement: data.location
          });
        }, error => {
          this.raUploadLoading = false
        }
      )


  }

  signup() {
    this.updateMailingAddress()
    var signupData = {
      email: this.accountFormGroup.value.email.email,
      password: this.accountFormGroup.value.password.password,
      confirm_password: this.accountFormGroup.value.password.confirmPassword,
      first_name: this.profileFormGroup.value.firstName,
      last_name: this.profileFormGroup.value.lastName,
      driver_license_number: this.profileFormGroup.value.driverLicense,
      mailing_address: {
        street_address: this.profileFormGroup.getRawValue().mailingAddress,
        city: this.profileFormGroup.getRawValue().mailingCity,
        state: this.profileFormGroup.getRawValue().mailingState,
        zip_code: this.profileFormGroup.getRawValue().mailingZipcode
      },
      service_address: {
        street_address: this.profileFormGroup.value.serviceAddress,
        city: this.profileFormGroup.value.serviceCity,
        state: this.profileFormGroup.value.serviceState,
        zip_code: this.profileFormGroup.value.serviceZipcode
      },
      mobile_phone: $('#mobile_phone').val().replace(/[()\-\s]/g, ""),
      home_phone: $('#home_phone').val().replace(/[()\-\s]/g, ""),
      ssn: this.profileFormGroup.value.SSN,
      zipcode: this.profileFormGroup.value.zipcode,
      renting: this.profileFormGroup.value.renting,
      require_trashbin: this.profileFormGroup.value.requireTrashBin,
      account_type: this.accountTypeControl.value.typeOfAccount,
      birthday: this.profileFormGroup.value.dateOfBirth,
      driver_license_url: this.profileFormGroup.value.fileId,
      deed_rental_url: this.profileFormGroup.value.file
    }

    const formData: any = new FormData();
    const files: Array<File> = this.filesToUpload;
    const filesId: Array<File> = this.filesToUploadId;

    formData.append("files", filesId[0], filesId[0]['name']);

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i]['name']);
      }
    }

    formData.append("signupData", JSON.stringify(signupData));

    console.log(formData.getAll('files'))
    console.log(formData.getAll('signupData'))

    this.CS.signup(formData).subscribe(
      response => {
        // console.log('response signup:', response)
        this.errorMessage = ""
        this.signupSuccessSwal.text = "Congratulations! You have successfully signed up. ";
        if (window.location.href.includes('/es/')) {
          this.signupSuccessSwal.text = "¡Felicidades! Te has registrado correctamente.";
        }
        this.signupSuccessSwal.show()
      },
      error => {
        console.log('response error', error)
        console.log("error message: ", error.message);
        if (error.message.trim().toLowerCase().includes('driver license') && error.message.trim().toLowerCase().includes('pattern')) {
          error.message = '"Driver license" number must be all numbers';
          if (window.location.href.includes('/es/')) {
            error.message = '"Licencia de Conducción" deben ser todo números';
          }
        }
        this.errorMessage = error.message;
        // this.signupFailureSwal.show();
      }
    )
  }

  disableTabs(step) {
    switch (step) {
      case 0:
        this.staticTabs.tabs[1].disabled = !this.staticTabs.tabs[1].disabled;
        this.staticTabs.tabs[2].disabled = !this.staticTabs.tabs[2].disabled;
        break;
      case 1:
        this.staticTabs.tabs[0].disabled = !this.staticTabs.tabs[1].disabled;
        this.staticTabs.tabs[1].disabled = !this.staticTabs.tabs[1].disabled;
        break;
      case 2:
        this.staticTabs.tabs[0].disabled = !this.staticTabs.tabs[1].disabled;
        this.staticTabs.tabs[1].disabled = !this.staticTabs.tabs[1].disabled;

    }

  }

  setMobileNumber(number: string) {
    let mobileNumber = number.trim().replace(/[()\-_\s]/g, "");
    this.profileFormGroup.patchValue({ mobile_phone: mobileNumber.length == 10 ? mobileNumber : null })
    // this.profileFormGroup.value.mobile_phone = mobileNumber.length == 10 ? mobileNumber : null;
  }

  toggleMailingAddress() {
    if (this.profileFormGroup.value.useServiceAddrForMailing) {
      this.disableMailingAddress = true
      this.profileFormGroup.controls['mailingAddress'].disable()
      this.profileFormGroup.controls['mailingCity'].disable()
      this.profileFormGroup.controls['mailingState'].disable()
      this.profileFormGroup.controls['mailingZipcode'].disable()

    } else {
      this.disableMailingAddress = false
      this.profileFormGroup.controls['mailingAddress'].enable()
      this.profileFormGroup.controls['mailingCity'].enable()
      this.profileFormGroup.controls['mailingState'].enable()
      this.profileFormGroup.controls['mailingZipcode'].enable()
    }
    this.updateMailingAddress()

  }

  updateMailingAddress() {
    if (this.profileFormGroup.value.useServiceAddrForMailing) {
      this.profileFormGroup.patchValue({
        mailingAddress: this.profileFormGroup.value.serviceAddress,
        mailingCity: this.profileFormGroup.value.serviceCity,
        mailingState: this.profileFormGroup.value.serviceState,
        mailingZipcode: this.profileFormGroup.value.serviceZipcode
      });
    }
  }

  returnToLogin() {
    this.router.navigate(['/login']);
  }

}
