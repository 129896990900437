import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/_services/auth.service";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { CryptoService } from "../_services";

@Component({
  selector: "app-staff-login-authentication",
  templateUrl: "./staff-login-authentication.component.html",
  styleUrls: ["./staff-login-authentication.component.css"],
})
export class StaffLoginAuthenticationComponent implements OnInit {
  @ViewChild("error") private errorAlertSwal: SwalComponent;

  public loading: boolean;
  public errorMessage: string;

  constructor(
    private ActiveRoute: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private cryptoService: CryptoService
  ) {
    this.loading = true;
    this.errorMessage = "";
  }

  ngOnInit() {
    this.ActiveRoute.queryParams
      .switchMap((results) => {
        const data = {
          token: results.token,
          email: results.email,
        };
        return this.auth.staffLoginAuthentication(data);
      })
      .subscribe(
        (res) => {
          setTimeout(() => {
            this.loading = false;
            localStorage.setItem("access_token", res.token);
            localStorage.setItem("current_user", JSON.stringify(res.user));
            const user = JSON.parse(window.localStorage.current_user);

            if (user.user_type_id == 3) {
              this.router.navigate(["dashboard"]);
            } else if (user.user_type_id == 2) {
              this.router.navigate(["work_orders"]);
            } else {
              this.router.navigate(["users"]);
            }
          }, 3000);
        },
        (error) => {
          setTimeout(() => {
            this.loading = false;
            if (error.message) {
              this.errorMessage = error.message;
            } else {
              this.errorMessage =
                "Something went wrong, please try again later";
            }
          }, 3000);
        }
      );
  }
}
