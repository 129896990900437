export class Ticket {
    id: number;
    user_id: number;
    account_id: number;
    ticket_type_id: number;
    ticket_status_id: number;
    details: any;
    notes: string;
    date_created: string;
    date_updated: string;
}
