import { Component, Output, Input, EventEmitter, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as $ from 'jquery';
import { FeeCalculator } from '../../_helpers';
import { UsioConstants } from '../../usio-constants';

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.css']
})
export class FeesComponent implements OnInit {
  @Output() FeesModalClosed = new EventEmitter();
  @Input() amount: any;
  public bsConfig: Partial<BsDatepickerConfig>;
  public calculatedTier = 0;
  public iPayFeeObjectACH = {};
  public iPayFeeObjectCC = {};
  public payMethod = 'cc';
  public iPayFee = this.calculator.getIPayFee["feeString"];
  public UsioFeeObjectACH = {};
  public UsioFeeObjectCC = {};
  public UsioThreshold = UsioConstants.DELTA_THRESHOLD;
  constructor(
    public bsModalRef: BsModalRef,
    public calculator: FeeCalculator
  ) {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $('body').bind('cut copy', function (e) {
        e.preventDefault();
      });
    });
    console.log('AMOUNT', this.amount);
    if (this.amount) { // removed switch method, calculator now has switch method
      this.UsioFeeObjectACH = this.calculator.getUSIOFee(this.amount, 'ach');
      this.iPayFeeObjectACH = this.calculator.getIPayFeeDelta(this.amount, 'ach');
      this.UsioFeeObjectCC = this.calculator.getUSIOFee(this.amount, 'cc');
      this.iPayFeeObjectCC = this.calculator.getIPayFeeDelta(this.amount, 'cc');
      this.calculatedTier = this.iPayFeeObjectCC["calculatedTier"];
    }
  }
}
