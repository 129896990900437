import { Component, OnInit, Inject, Renderer2, ViewChild } from "@angular/core";
import {
  CryptoService,
  CustomerService,
  PaymentsService,
  StatusService,
} from "../_services";
import { User, Payments, Status } from "../_models";
import * as moment from "moment";
import { Router, ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import * as globals from "../globals.json";
import { DateRangePickerComponent } from "ng-pick-daterange";
import { saveAs } from "file-saver";
declare var $: any;

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.css"],
})
export class PaymentsComponent implements OnInit {
  public globals: any = globals.default;

  @ViewChild("datePicker") private datePicker: DateRangePickerComponent;
  @ViewChild("datePicker") dateRangeDOM;

  @Inject(DOCUMENT) private document: Document;
  user: User;
  payments: Payments[] = [];
  status: Status[] = [];
  totalPages: Number;
  filters: any = {
    status_id: Number,
    from_date: String,
    to_date: String,
    search: "",
    limit: 10,
    page: 1,
  };
  loading: boolean = false;
  public searchBoxPlaceHolder = "Search";
  public receiptButton = "Receipt";

  constructor(
    private CS: CustomerService,
    private SS: StatusService,
    private PS: PaymentsService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private cryptoService: CryptoService
  ) {}

  ngOnInit() {
    this.loading = true;
    if (this.route.snapshot.params.userSearch) {
      this.filters.search = this.route.snapshot.params.userSearch;
    }
    this.filters.status_id = Number(0);
    this.user = JSON.parse(window.localStorage.current_user);
    console.log(this.user);
    this.getBillStatus();
    let domRange = this.dateRangeDOM.elementRef.nativeElement;

    if (window.location.href.includes("/es/")) {
      this.searchBoxPlaceHolder = "Buscar";
      this.receiptButton = "Recibo";
      domRange.getElementsByClassName("yk-label")[0].innerText = "Inicio";
      domRange.getElementsByClassName("yk-label")[1].innerText = "Fin";
      domRange.getElementsByClassName("yk-btn")[0].innerText = "Este Mes";
      domRange.getElementsByClassName("yk-btn")[1].innerText = "Ultimo Mes";
      domRange.getElementsByClassName("yk-btn")[2].innerText = "Esta Semana";
      domRange.getElementsByClassName("yk-btn")[3].innerText = "Ultima Semana";
      domRange.getElementsByClassName("yk-btn")[4].innerText = "Este Año";
      domRange.getElementsByClassName("yk-btn")[5].innerText = "Ultimo Año";
    }
  }

  ngAfterViewInit() {
    // this.filters.from_date = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD');
    // this.filters.to_date = moment().endOf('month').format('YYYY-MM-DD');
    this.datePicker.toggleCalendar("from");
    this.datePicker.selectDate(
      moment().subtract(3, "month").startOf("month").toDate()
    );
    this.datePicker.toggleCalendar("from");

    let domRange = this.dateRangeDOM.elementRef.nativeElement;
    if (window.location.href.includes("/es/")) {
      domRange.getElementsByClassName("yk-weekday")[0].innerText = "Dom";
      domRange.getElementsByClassName("yk-weekday")[1].innerText = "Lun";
      domRange.getElementsByClassName("yk-weekday")[2].innerText = "Mar";
      domRange.getElementsByClassName("yk-weekday")[3].innerText = "Mie";
      domRange.getElementsByClassName("yk-weekday")[4].innerText = "Jue";
      domRange.getElementsByClassName("yk-weekday")[5].innerText = "Vie";
      domRange.getElementsByClassName("yk-weekday")[6].innerText = "Sab";
    }
  }

  ngAfterContentInit() {
    this.filters.from_date = moment()
      .subtract(3, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    this.filters.to_date = moment().endOf("month").format("YYYY-MM-DD");
    if (
      this.user.user_type_id === 1 ||
      this.user.user_type_id === 4 ||
      this.user.user_type_id === 5 ||
      this.user.user_type_id === 7
    ) {
      this.getAllPayments(this.filters);
    } else {
      this.getCustomerPayments(this.user.id, this.filters);
    }
  }

  submitFilters(filters) {
    this.loading = true;
    this.filters.from_date = moment(filters.from_date).format("YYYY-MM-DD");
    this.filters.to_date = moment(filters.to_date).format("YYYY-MM-DD");

    if (
      this.user.user_type_id == 1 ||
      this.user.user_type_id === 4 ||
      this.user.user_type_id === 5 ||
      this.user.user_type_id === 7
    ) {
      this.getAllPayments(filters);
    } else {
      this.getCustomerPayments(this.user.id, filters);
    }
  }

  setSearchFilter(value) {
    this.filters.search = value.length ? value : "";
  }

  search() {
    this.submitFilters(this.filters);
  }

  setDateRange(range) {
    this.filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.filters.to_date = moment(range.to).format("YYYY-MM-DD");
  }

  getAllPayments(query: any) {
    this.payments = [];
    this.PS.getAllPayments(query).subscribe(
      (response) => {
        this.loading = false;
        if (response.body[0]) {
          console.log("PAYEMNTS: ", response.body);
          this.payments = response.body;
          this.totalPages = response.body[0].count;
        }
      },
      (error) => {
        console.log("Error in all payments admin: ", error);
      }
    );
  }

  getCustomerPayments(id: number, query: any) {
    this.CS.getCustomerPayments(id, query).subscribe(
      (response) => {
        this.loading = false;
        if (response.body[0]) {
          this.payments = response.body;
          this.totalPages = response.body[0].count;
        }
      },
      (error) => {
        console.log("Get customer paymetns error: ", error);
      }
    );
  }

  getBillStatus() {
    this.SS.getBillStatus().subscribe(
      (response) => {
        this.status = response.body;
      },
      (err) => {
        console.log("Error gettings bills: ", err);
      }
    );
  }

  sendBillDetails(id: number) {
    if (this.user.user_type_id == 1) {
      window.open(`/bills/${id}`, "_blank");
    } else {
      window.open(`/bills/${id}`, "_blank");
    }
  }

  goToReceipt(id) {
    this.router.navigate(["yo"]);
  }

  pageChanged(event: any) {
    this.filters.page = event.page;
    this.search();
  }

  closeSidebar() {
    window.scroll(0, 60);
    this.renderer.removeClass(document.body, "mini-navbar");
  }

  reportAutopay() {
    this.PS.reportAutopay().subscribe(
      (response) => {
        console.log("Autopay report: ", response);
        let filename = `${"autopay-report.xlsx"}`;
        saveAs(response, filename);
      },
      (error) => {
        console.log("Error in autopay report: ", error);
      }
    );
  }
}
