import { Component, OnInit } from "@angular/core";
import {
  CustomerService,
  TypeService,
  StatusService,
  TicketService,
  CryptoService,
} from "src/app/_services";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";

import {
  Ticket,
  TicketTypes,
  TicketStatus,
  TicketComment,
} from "src/app/_models";
import { switchMap } from "rxjs/operators";
import { ParamMap, Router } from "@angular/router";

import { EditTicketComponent } from "src/app/_common/edit-ticket/edit-ticket.component";
import { CreateWorkOrderComponent } from "src/app/_common/create-work-order/create-work-order.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";

import { DomSanitizer } from "@angular/platform-browser";

declare var $: any;

@Component({
  selector: "app-ticket-details",
  templateUrl: "./ticket-details.component.html",
  styleUrls: ["./ticket-details.component.css"],
})
export class TicketDetailsComponent implements OnInit {
  public ticketTypes: TicketTypes[];
  public ticketStatuses: TicketStatus[];
  public ticketComments: any;

  public ticket: Ticket;
  public user: any;
  public ticket_id: string;

  public loading: boolean = true;

  public newComment: string;
  public newCommentLoading: boolean = false;
  public newCommentError: string;
  public interval;
  public bsModalRef: BsModalRef;
  public writeText: string = "Write comment...";

  constructor(
    private customerService: CustomerService,
    private typeService: TypeService,
    private statusService: StatusService,
    private ticketService: TicketService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private cryptoService: CryptoService
  ) {}

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  changeTicketType(type) {
    switch (type) {
      case "Garbage Extra":
        return "Basura Extra";
      case "NAWSC Garbage":
        return "Basura NAWSC";
      case "Leaks":
        return "Fugas";
      case "Sewer Back Up":
        return "Respaldo con Alcantarilla";
      case "No Brush Pick Up":
        return "Sin Recoleccion por Escoba";
      case "No Garbage Pick Up":
        return "Sin Recoleccion de Basura";
      case "Low Pressure":
        return "Baja Presion";
      case "No Water":
        return "Sin agua";
      case "Payments":
        return "Pagos";
      case "General Questions":
        return "Preguntas Frecuentes";
      case "New Account":
        return "Nueva Cuenta";
      case "Disconnect Account":
        return "Desconectar Cuenta";
      case "Pause Account":
        return "Pausar Cuenta";
      case "Bad Sewer Smell":
        return "Mal olor de Alcantarilla";
      case "Manhole works":
        return "Trabajos de Alcantarilla";
      case "Mark locate water/sewer":
        return "Marcar Alcatarilla/Agua";
      case "Install Meter":
        return "Instalar Medidor";
      default:
        return "N/A";
    }
  }

  changeTicketStatus(status) {
    switch (status) {
      case "Pending":
        return "Pendiente";
      case "In Progress":
        return "En progreso";
      case "Resolved":
        return "Completado";
      case "Reject":
        return "Rechazado";
      case "Forwarded":
        return "Reenviado";
      default:
        return "N/A";
    }
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    this.ticket_id = this.route.snapshot.params.id;

    if (this.user.user_type_id == 3) {
      this.customerService
        .getTicketById(this.user.id, this.ticket_id)
        .subscribe(
          (data) => {
            console.log(data);
            // data.details.images = data.details.images ? data.details.images.length > 0 ? data.details.images.map((image)=>{
            //   return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + image);
            // }) : null : null;

            console.log("data: ", data);
            this.ticket = data;
            this.loading = true;
            $(".product-images").slick("unslick");

            setTimeout(() => {
              this.loading = false;
              $(".product-images").not(".slick-initialized").slick({
                dots: true,
              });
            }, 200);
          },
          (err) => {
            console.log(err);
            if (err.status == 404) {
              this.router.navigate(["404"]);
            } else if (err.status == 403) {
              this.router.navigate(["403"]);
            }
          }
        );

      this.customerService
        .getTicketComments(this.user.id, this.ticket_id)
        .subscribe((data) => (this.ticketComments = data));
    } else {
      this.ticketService.getTicketById(this.ticket_id).subscribe(
        (data) => {
          // data.details.images = data.details.images ? data.details.images.length > 0 ? data.details.images.map((image)=>{
          //   return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + image);
          // }) : null : null;

          this.ticket = data;
          console.log("tiCKET : ", data.details);
          this.loading = true;

          $(".product-images").slick("unslick");

          setTimeout(() => {
            this.loading = false;
            $(".product-images").not(".slick-initialized").slick({
              dots: true,
            });
          }, 200);
        },
        (err) => {
          this.router.navigate(["404"]);
        }
      );

      this.ticketService
        .getTicketComments(this.ticket_id)
        .subscribe((data) => (this.ticketComments = data));
    }

    this.typeService
      .getTicketTypes()
      .subscribe((data) => (this.ticketTypes = data));

    this.statusService
      .getTicketStatus()
      .subscribe((data) => (this.ticketStatuses = data));

    if (window.location.href.includes("/es/")) {
      this.writeText = "Escriba un comentario...";
    }
  }

  ngAfterViewInit() {
    // Add slimscroll to element
    this.interval = setInterval(() => {
      // this.ngOnInit()
      if (this.user.user_type_id == 3) {
        this.customerService
          .getTicketComments(this.user.id, this.ticket_id)
          .subscribe((data) => (this.ticketComments = data));
      } else {
        this.ticketService
          .getTicketComments(this.ticket_id)
          .subscribe((data) => (this.ticketComments = data));
      }
    }, 15000);
    $(".comment-height-scroll").slimscroll({ height: "500px" });
  }

  submitNewComment() {
    if (this.newComment == null || this.newComment.trim().length == 0) {
      return;
    }
    this.newCommentLoading = true;
    const newComment = {
      ticket_id: this.ticket_id,
      user_id: this.user.id,
      comment: this.newComment,
    };
    console.log("newComment: ", newComment);
    if (this.user.user_type_id == 3) {
      this.customerService
        .createTicketComment(this.user.id, this.ticket_id, newComment)
        .subscribe(
          (data: any) => {
            setTimeout(() => {
              this.newComment = "";
              this.newCommentLoading = false;
              data.first_name = this.user.first_name;
              data.last_name = this.user.last_name;
              data.profile_image_url = this.user.profile_image_url;
              this.ticketComments.comments.unshift(data);
            }, 1000);
          },
          (err) => {
            this.newCommentError = err;
          }
        );
    } else {
      this.ticketService
        .createTicketComment(this.ticket_id, newComment)
        .subscribe(
          (data: any) => {
            setTimeout(() => {
              this.newComment = "";
              this.newCommentLoading = false;
              data.first_name = this.user.first_name;
              data.last_name = this.user.last_name;
              data.profile_image_url = this.user.profile_image_url;
              this.ticketComments.comments.unshift(data);
            }, 1000);
          },
          (err) => {
            this.newCommentError = err;
          }
        );
    }
  }

  openEditTicketModal() {
    this.bsModalRef = this.modalService.show(
      EditTicketComponent,
      Object.assign({}, { class: "gray inmodal animated slideDown" })
    );
    this.bsModalRef.content.ticket = this.ticket;
    this.bsModalRef.content.setEditTicketForm();
    this.bsModalRef.content.editTicketModalClosed.subscribe((result) => {
      if (result) this.ngOnInit();
    });
  }

  openCreateWorkOrderModal() {
    this.bsModalRef = this.modalService.show(
      CreateWorkOrderComponent,
      Object.assign({}, { class: "gray inmodal animated slideDown" })
    );
    this.bsModalRef.content.ticket = this.ticket;
    this.bsModalRef.content.setCreateWorkOrderForm();
  }

  printView() {
    window.print();
  }

  setClass(statusId) {
    return {
      "label-warning": statusId == 1,
      "label-primary": statusId == 2,
      "label-success": statusId == 3,
      "label-danger": statusId == 4,
    };
  }

  getTicketTypeName(typeId) {
    if (typeId) {
      let type = [];
      if (this.ticketTypes) {
        type = this.ticketTypes.filter(
          (ticket_type) => ticket_type.id === typeId
        );
      }

      let typeName = type.length > 0 ? type[0].name : "N/A";
      if (window.location.href.includes("/es/")) {
        typeName = this.changeTicketType(typeName);
      }

      return type.length > 0 ? typeName : "N/A";
    }
  }

  getTicketStatusName(statusId) {
    if (!this.ticketStatuses) {
      return "N/A";
    }

    let status = [];
    let statusName;

    status = this.ticketStatuses.filter(
      (ticketStatuses) => ticketStatuses.id === statusId
    );

    statusName = status.length ? status[0].name : "N/A";

    if (window.location.href.includes("/es/")) {
      statusName = this.changeTicketStatus(statusName);
    }

    return statusName;
  }
}
