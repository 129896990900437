import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import "jquery-slimscroll";
import { User } from "../../_models";
import { TicketService } from "../../_services/ticket.service";
import { ReversalsService } from "../../_services/reversals.service";
import * as globals from "../../globals.json";
import * as moment from "moment";
import { CryptoService } from "src/app/_services";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public currentUser;
  public routerPermissions;
  public startNewMapLabel;
  public showNewMapLabel: boolean = false;
  public newSignupTickets: number = 0;
  public hideSignupTickets: boolean = true;
  public newSupportTickets: number = 0;
  public hideSupportTickets: boolean = true;
  public reversalsCount: number = 0;
  public hideReversalsCount: boolean = true;

  public globals: any = globals.default;

  constructor(
    private router: Router,
    private ticketService: TicketService,
    private reversalService: ReversalsService,
    private cryptoService: CryptoService
  ) {}

  ngOnInit() {
    this.currentUser = JSON.parse(window.localStorage.current_user);
    this.routerPermissions = this.router.config;

    this.startNewMapLabel = moment("2020-02-12", "YYYY-MM-DD");
    let today = moment();

    if (!today.isAfter(this.startNewMapLabel.add(1, "months"))) {
      this.showNewMapLabel = true;
    }

    this.ticketService.getCountSignup().subscribe((data) => {
      this.newSignupTickets = parseInt(data.count || data || 0);
      if (this.newSignupTickets >= 1) this.hideSignupTickets = false;
    });
    this.ticketService.getCountSupport().subscribe((data) => {
      this.newSupportTickets = parseInt(data.count || data || 0);
      if (this.newSupportTickets >= 1) this.hideSupportTickets = false;
    });

    this.getCounterReversals();
  }

  getCounterReversals() {
    this.reversalService.getCounterReversals().subscribe((data) => {
      this.reversalsCount = parseInt(data.count || data || 0);
      if (this.reversalsCount >= 1) this.hideReversalsCount = false;
    });
  }

  ngAfterViewInit() {
    $("#side-menu").metisMenu();

    if ($("body").hasClass("fixed-sidebar")) {
      $(".sidebar-collapse").slimscroll({
        height: "100%",
      });
    }

    this.reversalService.propagar.subscribe((res) => {
      this.getCounterReversals();
    });
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  showMenuOption(route: string) {
    let user_type_id: number = this.currentUser.user_type_id;
    let show: boolean = false;

    this.routerPermissions.forEach(function (router, key) {
      if (
        router.path == route &&
        router.data &&
        router.data.expectedRole.indexOf(user_type_id) != -1
      ) {
        show = true;
      }
    });

    return show;
  }
}
