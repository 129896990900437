import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  constructor(public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const expectedRole = next.data.expectedRole;
    const token = JSON.parse(localStorage.getItem("current_user"));
    // const tokenPayload = decode(token);

    let validRole = false;

    expectedRole.forEach(function (role) {
      if (token.user_type_id == role) {
        validRole = true;
      }
    });

    if (!validRole) {
      this.router.navigate(["login"]);
    }

    return validRole;
  }
}
