import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse }	from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

import { TicketStatus, AccountStatus, WorkOrderStatus } from '../_models';

@Injectable({
  providedIn: 'root'
})

export class StatusService {
  baseUrl: string = environment.serverUrl
  
  private termsSource = new BehaviorSubject<boolean>(false);
  termsCurrentValue = this.termsSource.asObservable();

  constructor(private http: HttpClient) { }

  getBillStatus(): Observable<HttpResponse<any>>{
  	return this.http.get<any>(`${this.baseUrl}/api/statuses/bills`,{observe : 'response'})
  }

  getTicketStatus() {
    return this.http.get<TicketStatus[]>(`${this.baseUrl}/api/statuses/tickets`);
  }

  getWorkOrderStatus() {
    return this.http.get<WorkOrderStatus[]>(`${this.baseUrl}/api/statuses/work_orders`);
  }

  getAccountStatus() {
    return this.http.get<AccountStatus[]>(`${this.baseUrl}/api/statuses/accounts`);    
  }

  setTermsAndConditionsValue(value: boolean){
    console.log("STATUS SERVICE SET TO VALUE: ", value);
    this.termsSource.next(value);
  }
}