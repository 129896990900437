import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventReceiptService {

  drop = false;  
  @Output() change: EventEmitter<boolean> = new EventEmitter();  
  
  clickDrop() {
    this.drop = true;
    this.change.emit(this.drop);
  }
  
}
