import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TypeService, GuestTicketService, CustomerService } from '../../_services';
import { TicketTypes, Ticket, Account, AccountTypes } from 'src/app/_models';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-create-guest-ticket',
  templateUrl: './create-guest-ticket.component.html',
  styleUrls: ['./create-guest-ticket.component.css']
})
export class CreateGuestTicketComponent implements OnInit {
  @ViewChild('createSuccess') private createSuccessSwal: SwalComponent;
  public newTicketFormGroup: FormGroup;
  public errorMessage: string;

  public accounts: Account[];
  public accountTypes: AccountTypes[];
  public ticketTypes: TicketTypes[];
  public user: any;
  public textAlert:string = "Ticket Submitted";
  public enterTitlePlaceholder:string = "Enter Title";
  public firstNamePlaceholder:string = "First Name";
  public lastNamePlaceholder:string = "Last Name";
  public enterEmailPlaceholder:string= "Enter Email";
  public serviceAddressPlaceholder:string= "Service Address";
  public birthdayPlaceholder:string="Birthday";
  public mobilePhonePlaceholder:string="Mobile Phone";
  public enterDescriptionPlaceholder:string="Enter Description";
  public selectAccountTypeText:string="Select Account Type";
  //public inputFiles: File[] = [];
  //public filesToUpload: File[] = [];
  //public viewFiles: any[] = [];
  // public filteredTypes: any[] = [11]; //Remove new account from list

  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private typeService: TypeService,
              private cd: ChangeDetectorRef,
              private TS : GuestTicketService,
              private router: Router) {
    this.newTicketFormGroup = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      ticket_type: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      account_type: ['', Validators.required],
      ssn: ['', Validators.required],  /*Pending these */
      service_address: ['', Validators.required],
      mobile_phone: ['', Validators.required],
      birthday: ['', Validators.required],
    });
  }

  ngOnInit() {

    this.typeService.getTicketTypes()
      .subscribe(
        data => this.ticketTypes = data
      );

    this.typeService.getAccountTypes_support()
      .subscribe(
        data => this.accountTypes = data
      )

      if(window.location.href.includes("/es/")){
        this.textAlert = "Ticket Enviado";
        this.enterTitlePlaceholder = "Título";
        this.firstNamePlaceholder = "Nombre";
        this.lastNamePlaceholder = "Apellido";
        this.enterEmailPlaceholder= "Ingrese un Correo";
        this.serviceAddressPlaceholder= "Dirección de Servicio";
        this.birthdayPlaceholder="Fecha de Nacimiento";
        this.mobilePhonePlaceholder="Número Movil";
        this.enterDescriptionPlaceholder="Ingrese una Descripción";
        this.selectAccountTypeText="Seleccionar Tipo de Cuenta";

      }

     
  }


  submitNewTicket() {
    console.log(this.ticketTypes);

    const details = {
      title: this.newTicketFormGroup.value.title,
      description: this.newTicketFormGroup.value.description,
      images: this.newTicketFormGroup.value.file,
      first_name: this.newTicketFormGroup.value.first_name,
      last_name: this.newTicketFormGroup.value.last_name,
      email: this.newTicketFormGroup.value.email,
      account_type: this.newTicketFormGroup.value.account_type,
      ssn: this.newTicketFormGroup.value.ssn,
      service_address: this.newTicketFormGroup.value.service_address,
      mobile_phone: this.newTicketFormGroup.value.mobile_phone,
      birthday: this.newTicketFormGroup.value.birthday,
    }

    var gSupTicket = {
    "ticket_type_id"    : this.newTicketFormGroup.value.ticket_type,
    "details"           : details          
    }
    this.TS.createGuestTicket(gSupTicket)
      .subscribe(
        data => this.createSuccessSwal.show()
      )
  }

  confirmNewTicket() {
    this.bsModalRef.hide()
    if(this.router.url == "/tickets"){
      this.TS.newTicketCreated();
    }
  }
}
