import { Component, OnInit, ViewChild } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Ticket, TicketStatus, AccountTypes } from "src/app/_models";
import {
  CryptoService,
  StatusService,
  TicketService,
  TypeService,
} from "src/app/_services";
import { debounceTime, switchMap, tap, share } from "rxjs/operators";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import {
  BsModalService,
  BsModalRef,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { RequestRAComponent } from "../_common/request-ra/request-ra.component";
import { RequestIDComponent } from "../_common/request-id/request-id.component";
import { ObservableMedia } from "@angular/flex-layout"; //import obersavable media
import { IncodeReviewComponent } from "src/app/_common/incode-review/incode-review.component";
import * as moment from "moment";
import * as globals from "../globals.json";

declare var $: any;

@Component({
  selector: "app-signup-requests",
  templateUrl: "./signup-requests.component.html",
  styleUrls: ["./signup-requests.component.css"],
})
export class SignupRequestsComponent implements OnInit {
  @ViewChild("success") private successSwal: SwalComponent;
  @ViewChild("error") private errorSwal: SwalComponent;
  @ViewChild("approveCustomerAlert") private approveCustomerSwal: SwalComponent;
  @ViewChild(ModalDirective) modal: ModalDirective;
  public globals: any = globals.default;
  public signupRequests$: Observable<Ticket[]>;
  private searchTerms = new Subject<string>();
  public bsModalRef: BsModalRef;
  public user: any;
  public ticketStatuses: TicketStatus[] = [];
  public billStatuses: any[] = [];

  public accountTypes: AccountTypes[] = [];

  public viewingTicket = null;
  public ticketToUpdate = null;

  public initialMobile: boolean = false;
  public loading: boolean = false;
  public paginationMaxSize = 5;
  public paginationPageLimit = 10;
  public paginationTotalSignupRequests = 0;
  public paginationCurrentPage = 1;
  public searchText = "";
  public searchCategory = "";
  public searchTicketStatus = "";
  public searchTicketType = "new";

  public messages: string[];

  public sendDepositAlertTitle = "User Signup: Approval";
  public sendDepositAlertText = "Send deposit fee to customer?";
  public approveCustomerAlertHtml =
    "Enter incode account number with <b>dashes</b>";
  public rejectCustomerAlertTitle = "User Signup: Reject";
  public rejectCustomerAlertText = "Feedback?";
  public rejectCustomerAlertConfirmButtonText = "Reject";
  public searchBoxPlaceHolder = "Search";

  public textErrorFromAccount = "";
  public S3 = null;

  constructor(
    private statusService: StatusService,
    private ticketService: TicketService,
    private typeService: TypeService,
    private bsModalService: BsModalService,
    public media: ObservableMedia,
    private cryptoService: CryptoService
  ) {
    if (this.media.isActive("xs")) {
      this.initialMobile = true;
    }
  }

  changeRequestStatus(status) {
    console.log("status ", status);
    switch (status) {
      case "Pending":
        return "Pendiente";
      case "In Progress":
        return "En Progreso";
      case "Resolved":
        return "Completada";
      case "Reject":
        return "Rechazada";
      case "Forwarded":
        return "Reenviado";
      case "Closed":
        return "Cerrada";
    }
  }

  capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    this.statusService.getTicketStatus().subscribe((data) => {
      if (window.location.href.includes("/es/")) {
        data.map((item) => {
          item.name = this.changeRequestStatus(item.name);
          return item;
        });
      }
      this.ticketStatuses = data;
    });

    this.typeService.getAccountTypes().subscribe((data) => {
      this.accountTypes = data.body;
    });

    this.statusService.getBillStatus().subscribe((data) => {
      // console.log("bill status: ", data);
      this.billStatuses = data.body;
    });

    this.signupRequests$ = this.searchTerms.pipe(
      debounceTime(200),
      switchMap((term: string) =>
        this.ticketService
          .getFilteredTickets(
            this.paginationCurrentPage,
            this.paginationPageLimit,
            this.searchTicketStatus,
            this.searchTicketType,
            term
          )
          .pipe(
            tap((data) => {
              console.log("tickets: ", data);
              this.loading = false;

              if (window.location.href.includes("/es/")) {
                data.tickets.map((item) => {
                  //item.ticket_type_name = this.changeRequestStatus(item.ticket_type_name);
                  item.ticket_status_name = this.changeRequestStatus(
                    item.ticket_status_name
                  );
                  moment.locale("es");
                  item["diff"] = this.capitalize(
                    moment(item.date_created).fromNow()
                  );
                  return item;
                });
              } else {
                data.tickets.map((item) => {
                  item["diff"] = this.capitalize(
                    moment(item.date_created).fromNow()
                  );
                  return item;
                });
              }

              this.paginationTotalSignupRequests = data.total;
            })
          )
      ),
      share()
    );

    if (window.location.href.includes("/es/")) {
      this.sendDepositAlertTitle = "Suscripcion de Usuario:Aprobado";
      this.sendDepositAlertText = "Enviar tasa de deposito al cliente?";
      this.approveCustomerAlertHtml =
        "Ingresar codigo de numero de cuenta con <b>guiones</b>";
      this.rejectCustomerAlertTitle = "Suscripcion de Usuario: Rechazado";
      this.rejectCustomerAlertText = "Feedback?";
      this.rejectCustomerAlertConfirmButtonText = "Rechazar";
      this.searchBoxPlaceHolder = "Buscar";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.search();
    }, 200);

    // Add slimscroll to element
    $(".user-height-scroll").slimscroll({ height: "500px" });
  }

  search() {
    this.loading = true;
    this.searchTerms.next(this.searchText);
  }

  clearSearchText() {
    this.searchText = "";
    this.search();
  }

  pageChanged(event: any) {
    this.paginationCurrentPage = event.page;
    this.search();
  }

  sendDepositFee() {
    this.ticketToUpdate.details.step += 1;
    this.updateSignupTicket();
  }

  approveCustomer(inputText) {
    if (this.ticketToUpdate.details.step == 2) {
      let account = inputText.split("");
      if (account.length > 7 && account.length < 11) {
        if (account.slice(2, 3)[0] === "-" && account.slice(7, 8)[0] === "-") {
        } else {
          this.errorSwal.title =
            "Please check the dashes in the account number.";
          this.errorSwal.show();
          return;
        }
      } else {
        this.errorSwal.title = "Please check account number.";
        this.errorSwal.show();
        return;
      }
    }
    this.ticketToUpdate.details.incode_account_no = inputText;
    this.ticketToUpdate.details.step += 1;
    this.updateSignupTicket();
  }

  rejectCustomer(inputText) {
    this.ticketToUpdate.details.reject = true;
    this.ticketToUpdate.details.feedback = inputText;
    this.updateSignupTicket();
  }

  reviewIncode(incodeAccountNo) {
    this.bsModalRef = this.bsModalService.show(
      IncodeReviewComponent,
      Object.assign({}, { class: "gray inmodal animated slideDown modal-lg" })
    );
    this.bsModalRef.content.incodeAccountNo = incodeAccountNo;
    this.bsModalRef.content.ticket = this.ticketToUpdate;
    this.bsModalRef.content.setReview();

    this.bsModalRef.content.reviewDone.subscribe((result) => {
      this.approveCustomer(incodeAccountNo);
    });
  }

  updateSignupTicket() {
    const formData: any = new FormData();

    console.log(this.ticketToUpdate);

    formData.append("ticket_type_id", this.ticketToUpdate.ticket_type_id);
    formData.append("ticket_status_id", this.ticketToUpdate.ticket_status_id);

    this.ticketToUpdate.details["deed_rental_url"] =
      this.ticketToUpdate.details.before_deed_rental_url;
    this.ticketToUpdate.details["driver_license_url"] =
      this.ticketToUpdate.details.before_driver_license_url;

    delete this.ticketToUpdate.details.before_driver_license_url;
    delete this.ticketToUpdate.details.before_deed_rental_url;

    formData.append("details", JSON.stringify(this.ticketToUpdate.details));

    console.log(formData.get("details"));

    this.ticketService.updateTicket(this.ticketToUpdate.id, formData).subscribe(
      (data) => {
        this.search();
        if (parseInt(data[0].details.step) == 2 && !data[0].details.reject) {
          this.successSwal.title = `Deposit fee sent to ${data[0].details.first_name} ${data[0].details.last_name}`;
          this.successSwal.text = data[0].details.email;
        } else if (
          parseInt(data[0].details.step) == 3 &&
          !data[0].details.reject
        ) {
          this.successSwal.title = `${data[0].details.first_name} ${data[0].details.last_name}'s new account created successfully`;
          // this.successSwal.text = data[0].details.email;
        } else {
          this.successSwal.title = `Success`;
        }

        this.successSwal.show();
      },
      (err) => {
        console.log("error: ", err);
      }
    );
  }

  getAccountTypeName(type_id) {
    let type = [];
    if (this.accountTypes) {
      type = this.accountTypes.filter(
        (account_type) => account_type.id === parseInt(type_id)
      );
    }

    return type.length > 0 ? type[0].name : "N/A";
  }

  showRequestID(request) {
    const initialState = { message: "hi", title: "ID" };
    const raModalRef = (this.bsModalRef = this.bsModalService.show(
      RequestIDComponent,
      Object.assign({}, { class: "gray modal-md inmodal", initialState })
    ));

    raModalRef.content.setRequestInfo(request.details.driver_license_url);
  }

  showRequestRA(request) {
    const initialState = { message: "hi", title: "Rental Agreement" };
    const raModalRef = (this.bsModalRef = this.bsModalService.show(
      RequestRAComponent,
      Object.assign({}, { class: "gray modal-md inmodal", initialState })
    ));

    raModalRef.content.setRequestInfo(request.details.deed_rental_url);
  }

  viewDetails(ticket) {
    if (ticket.id == this.viewingTicket) {
      this.viewingTicket = null;
    } else {
      this.viewingTicket = ticket.id;
    }
  }

  setTicketStatusClass(statusId) {
    return {
      "label-warning": statusId == 1,
      "label-primary": statusId == 2,
      "label-success": statusId == 3,
      "label-danger": statusId == 4,
    };
  }

  setBillStatusClass(statusId) {
    return {
      "label-warning": statusId == 1, //Pending
      "label-primary": statusId == 2, //Paid
      "label-info": statusId == 3, //Partially Paid
      "label-danger": statusId == 4, //Overdue
      // 'label'  : statusId == 4
    };
  }

  getBillStatusName(statusId) {
    let status = [];
    if (this.billStatuses) {
      status = this.billStatuses.filter(
        (billStatuses) => billStatuses.id === statusId
      );
    }

    return status.length > 0 ? status[0].name : "N/A";
  }
}
