import { Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormBuilder, Validators, FormArray, FormGroup, FormControl} from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

import { UserService, TypeService } from '../../_services';
import { User, UserSettings, UserInfo, UserTypes } from '../../_models';

declare var $:any;

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  @ViewChild('createSuccess') private createSuccessSwal: SwalComponent;
  @ViewChild('createFailure') private createFailureSwal: SwalComponent;
  @ViewChild('createDataFailure') private createDataFailureSwal: SwalComponent;
  @ViewChild('pop') private passwordpop: any
  @Output() reload = new EventEmitter();
  public newUserFormGroup: FormGroup;
  public passwordFormGroup: FormGroup;
  public emailFormGroup: FormGroup;
  public types: UserTypes;
  public errorMessage: string;
  loading:boolean = false
  public createSuccessAlertTitle ="Created New User "
  public createFailureAlertTitle ="Something went wrong, we are very sorry "
  public createDataFailureAlertTitle ="Something went wrong, please check the data entered "
  public userTypePlaceholder="User Type";
  constructor(public userService: UserService,public typeService: TypeService, public bsModalRef: BsModalRef, private fb: FormBuilder) {
    this.passwordFormGroup = this.fb.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{10,}$')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{10,}$')]]
    }, {
        validator: this.matchValidator.bind(this)
      });

    this.emailFormGroup = this.fb.group({
      email: ['', Validators.required],
      confirmEmail: ['', Validators.required]
    }, {
        validator: this.matchValidator.bind(this)
      });

    this.newUserFormGroup = this.fb.group({
      firstName: ['', Validators.required],
      // lastName: ['', Validators.required],
      userType: ['', Validators.required],
      // department: new FormControl({disabled: true},[Validators.nullValidator]),
      emailFormGroup: this.emailFormGroup,
      passwordFormGroup: this.passwordFormGroup
    });
  }

  ngOnInit() {
    this.typeService.getUserTypes()
      .subscribe(
        (data) => this.types = data
      )
      if(window.location.href.includes("/es/")){
        this.createSuccessAlertTitle ="Nuevo Usuario Creado ";
        this.createFailureAlertTitle ="Lo sentimos, hubo un error ";
        this.createDataFailureAlertTitle ="Lo sentimos, por favor verifique los datos ingresados "
        this.userTypePlaceholder="Tipo de Usuario";

      }
  }

  ngAfterViewInit() {
    // Add chosen to select elements
    $('.chosen-select').chosen({ width: "100%" });
  }

  returnToUsers() {
    console.log("reload");
    this.reload.emit('reload')
    this.bsModalRef.hide()
  }

  get password() { return this.passwordFormGroup.get('password'); }

  submitNewUser() {
    this.loading = true;
    const user = {
      first_name: this.newUserFormGroup.value.firstName,
      last_name: this.newUserFormGroup.value.lastName || '',
      email: this.emailFormGroup.value.email,
      password: this.passwordFormGroup.value.password,
      user_type_id: this.newUserFormGroup.value.userType,
      active: true
    }

    this.userService.createUser(user)
      .subscribe(
        data => {
          this.loading = false;
          delete this.errorMessage
          this.createSuccessSwal.show()
        },
        err => {
          console.log("error: ", err);
          this.loading = false;
          if (err.status == 422){
            this.errorMessage = err.message;
            this.createFailureSwal.title = err.message;
            this.createFailureSwal.show()
          }
          if (err.status == 400){
            this.errorMessage = err.message
            this.createDataFailureSwal.show()
          }
        }
      )
  }

  matchValidator(registrationFormGroup: FormGroup) {
    let values = []
    for (let prop in registrationFormGroup.controls){
      values.push(registrationFormGroup.controls[prop].value)
    }

    if (values[1].length <= 0) {
      return null;
    }

    if (values[1] !== values[0]) {
      return {
        doesMatchValue: true
      };
    }

    return null;
  }
}
