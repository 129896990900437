import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  baseUrl: string = environment.serverUrl;

  constructor(private http: HttpClient) { }

  getPackagesById(id): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.baseUrl}/api/package/get-all-packages?id=${id}`);
  }

  getPackagesByIdToday(id, today): Observable<HttpResponse<any>>{
    return this.http.get<any>(`${this.baseUrl}/api/package/get-all-packages-by-today?id=${id}&today=${today}`);
  }


  updatePackageById(id, data){
  	return this.http.put(`${this.baseUrl}/api/package/update/${id}`, data);
  }

  insertPackage(data){
    return this.http.post<any>(`${this.baseUrl}/api/package/add`, data);
  }

}
