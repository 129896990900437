/*export class UserInfo {
  id: string;
  first_name: string;
  last_name: string;
}*/

export class UserInfo {
  id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  date_created : string;
  date_of_birth: string;
  date_updated : string;
  driver_license_number : string;
  driver_license_url : string;
  home_phone : string;
  mobile_phone: string;
  profile_image_url : string;
  ssn : string;
  user_type_id : number;
  email : string;

  constructor(email?: string) { 
    this.email = email || '';
  } 
}
