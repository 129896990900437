import { Component, OnInit,ViewChild } from "@angular/core";
import { LoginCountService } from "../../_services";
import * as moment from "moment";
import { Chart } from "chart.js";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-login-count",
  templateUrl: "./login-count.component.html",
  styleUrls: ["./login-count.component.css"],
})
export class LoginCountComponent implements OnInit {
  @ViewChild('dateRange') dateRangeDOM;
  log_filters: any = {
    from_date: String,
    to_date: String,
  };
  total_users_range = 0;
  res_user_range = false;
  users_count = [];
  month_day_datas = [];
  users_count_types = [];
  month_day_datas_types = [];
  count_all_types = [];
  month_day_all_types = [];
  default_count = [];
  count_admin = [];
  count_staff = [];
  count_customer = [];

  loading = false;

  public domRange: any;
  public toRangeDate=moment().subtract('month').startOf('month').format('YYYY-MM-DD');

  constructor(private LCS: LoginCountService) {}

  dateRange: any = {
    from: String,
    to: String
  };
  
  ngOnInit() {
    this.domRange = this.dateRangeDOM.elementRef.nativeElement;
    this.log_filters.from_date = moment().startOf("week").format("YYYY-MM-DD");
    this.log_filters.to_date = moment().endOf("week").format("YYYY-MM-DD");
    this.getUserRange();
    

    // console.log("DOM RANGE : ", this.domRange);
    if (window.location.href.includes('/es/')) { 
      this.domRange.getElementsByClassName('yk-label')[0].innerText = 'Inicio';
      this.domRange.getElementsByClassName('yk-label')[1].innerText = 'Fin';
      this.domRange.getElementsByClassName('yk-btn')[0].innerText = 'Este Mes';
      this.domRange.getElementsByClassName('yk-btn')[1].innerText = 'Ultimo Mes';
      this.domRange.getElementsByClassName('yk-btn')[2].innerText = 'Esta Semana';
      this.domRange.getElementsByClassName('yk-btn')[3].innerText = 'Ultima Semana';
      this.domRange.getElementsByClassName('yk-btn')[4].innerText = 'Este Año';
      this.domRange.getElementsByClassName('yk-btn')[5].innerText = 'Ultimo Año';
    }


  }

  ngAfterViewInit() {

    this.setDateRange({
      from: moment().startOf("week").format("YYYY-MM-DD"),
      to: moment().endOf("week").format("YYYY-MM-DD")
    });

        // let domRange = this.dateRangeDOM.elementRef.nativeElement;
        if (window.location.href.includes('/es/')) {
          this.domRange.getElementsByClassName('yk-weekday')[0].innerText = 'Dom';
          this.domRange.getElementsByClassName('yk-weekday')[1].innerText = 'Lun';
          this.domRange.getElementsByClassName('yk-weekday')[2].innerText = 'Mar';
          this.domRange.getElementsByClassName('yk-weekday')[3].innerText = 'Mie';
          this.domRange.getElementsByClassName('yk-weekday')[4].innerText = 'Jue';
          this.domRange.getElementsByClassName('yk-weekday')[5].innerText = 'Vie';
          this.domRange.getElementsByClassName('yk-weekday')[6].innerText = 'Sab';
        }
  }


  setDateRange(range) {
    // console.log('setting date ranges: ', range);
    this.log_filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.log_filters.to_date =moment(range.to).format("YYYY-MM-DD");
    //console.log(this.domRange.getElementsByClassName('yk-content'));
   
    //console.log(moment(this.domRange.getElementsByClassName('yk-content')[0].innerText, 'MMM D, YYYY').format('YYYY-MM-DD'));
    //console.log(moment(this.domRange.getElementsByClassName('yk-content')[1].innerText, 'MMM D, YYYY').format('YYYY-MM-DD'));
    // console.log('initial dates', this.log_filters.from_date, this.log_filters.to_date );
  }
// Quantity of Logins throughout a time period
  getUserRange() {
    // this.setDateRange(range);
    console.log('en getUser Range');
    this.setDateRange({
      from: this.log_filters.from_date,
      to: this.log_filters.to_date,
    });
    this.total_users_range = 0;
    this.LCS.getUserRange(
      this.log_filters.from_date,
      this.log_filters.to_date
    ).subscribe((resUserRange) => {
      if (resUserRange.body[0]) {
        // let all_datas = JSON.stringify(resUserRange.body); // se convierte en cadena
        // console.log("Res User Range= " + all_datas);
        // console.log('Total de usuarios en Rango de tiempo:' + resUserRange.body[0].total_counts);
        let i = 0;
        while (resUserRange.body[i]) {
          console.log('valor de  i =' + i);
          i++;
        }
        this.total_users_range = i; // resUserRange.body[0].total_counts;
        console.log('total users range = '+ this.total_users_range);
        this.res_user_range = true;
      }
    });
  }
  // Login Time Frequency: Metric that pays notice to the most common time range in which users tend to Login.
 getLoginCountPerTime() {
  this.log_filters.from_date = moment(this.domRange.getElementsByClassName('yk-content')[0].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
  this.log_filters.to_date = moment(this.domRange.getElementsByClassName('yk-content')[1].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
 
  this.loading = true;
  var startTime = (document.getElementById("time_from") as HTMLInputElement)
    .value;
  var endTime = (document.getElementById("time_to") as HTMLInputElement)
    .value;
  var from_date = this.log_filters.from_date; // + " " + startTime + ":00";
  var to_date = this.log_filters.to_date; // + " " + endTime + ":00";
  this.month_day_datas = [];
  this.users_count = [];
  this.total_users_range = 0;

  this.LCS.getUserLogs(from_date, to_date).subscribe((resLoginCount) => { 
    if (resLoginCount.body[0]) {
      let datas = JSON.stringify(resLoginCount.body); // se convierte en cadena
      var i = 0;
      while (resLoginCount.body[i]) {
        let date = new Date(resLoginCount.body[i].date_log);
        let month_date = date.getMonth() + 1;
        let day_date = date.getDate();
        let month_day = month_date + "/" + day_date;
        var j;
        var exist = 0;
        for (j = 0; j < this.month_day_datas.length; j++) {
          console.log(this.month_day_datas[j] + " = " + month_day);
          if (this.month_day_datas[j] === month_day) {
            this.users_count[j]++;
            exist = 1;
            console.log("Existe!");
          }
        }
        if (exist === 0) {
          var aux = this.month_day_datas.length;
          console.log("Adicionado");
          this.month_day_datas.push(month_day);
          this.users_count[aux] = 1;
        }
        i++;
      }
      this.total_users_range = i;
      console.log("month day datas: " + this.month_day_datas);
      console.log("users count: " + this.users_count);
    }
  });
  // this.graphicsLoginCount();

  setTimeout(() => {
    this.graphicsLoginCount();
  }, 2000);
}
  // Login Time Frequency: Metric that pays notice to the most common time range in which users tend to Login.
  getLoginCount() {
    this.log_filters.from_date = moment(this.domRange.getElementsByClassName('yk-content')[0].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
    this.log_filters.to_date = moment(this.domRange.getElementsByClassName('yk-content')[1].innerText, 'MMM D, YYYY').format('YYYY-MM-DD');
   
    this.loading = true;
    var startTime = (document.getElementById("time_from") as HTMLInputElement)
      .value;
    var endTime = (document.getElementById("time_to") as HTMLInputElement)
      .value;
    var from_date = this.log_filters.from_date + " " + startTime + ":00";
    var to_date = this.log_filters.to_date + " " + endTime + ":00";
    this.month_day_datas = [];
    this.users_count = [];
    this.total_users_range = 0;

    this.LCS.getUserLogsdate(from_date, to_date).subscribe((resLoginCount) => {
      if (resLoginCount.body[0]) {
        let datas = JSON.stringify(resLoginCount.body); // se convierte en cadena
        var i = 0;
        while (resLoginCount.body[i]) {
          let date = new Date(resLoginCount.body[i].date_log);
          let month_date = date.getMonth() + 1;
          let day_date = date.getDate();
          let month_day = month_date + "/" + day_date;
          var j;
          var exist = 0;
          for (j = 0; j < this.month_day_datas.length; j++) {
            console.log(this.month_day_datas[j] + " = " + month_day);
            if (this.month_day_datas[j] === month_day) {
              this.users_count[j]++;
              exist = 1;
              console.log("Existe!");
            }
          }
          if (exist === 0) {
            var aux = this.month_day_datas.length;
            console.log("Adicionado");
            this.month_day_datas.push(month_day);
            this.users_count[aux] = 1;
          }
          i++;
        }
        this.total_users_range = i;
        console.log("month day datas: " + this.month_day_datas);
        console.log("users count: " + this.users_count);
      }
    });
    // this.graphicsLoginCount();

    setTimeout(() => {
      this.graphicsLoginCount();
    }, 2000);
  }
// Graphic login time frecuence
  graphicsLoginCount() {
    this.loading = false;
    // LINE
    var lineData = {
      labels: this.month_day_datas,
      datasets: [ 
        {
          label: "USER LOGINS / DAY",
          backgroundColor: "rgba(26,179,148,0.5)",
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff",
          data: this.users_count,
        },
      ],
    };
    var lineOptions = {
      responsive: true,
      title: {
        display: true,
        text: 'Login Count All Users'
      }
    };

    document.getElementById("chartContainer").innerHTML = '&nbsp;';
    document.getElementById("chartContainer").innerHTML = '<canvas id="lineChartLoginCount"></canvas>';


    var ctx = document.getElementById("lineChartLoginCount"); // .getContext("2d");;

    var ChartLoginCount = new Chart(ctx, {
      type: "line",
      data: lineData,
      options: lineOptions,
    });
  }

// Obtiene cantidad de logueos de c/u de los tipos de usuarios
 datas_user_types() {
   this.loading = true;
    this.month_day_datas_types = [];
    this.count_all_types = [];
    this.default_count = [];
    // dias entre dos fechas    
    var dif_dates = moment(this.log_filters.to_date).diff(
      moment(this.log_filters.from_date),
      "days"
    );
    console.log("days between dates: " + dif_dates);
    var day = 0;
    const aux_date = new Date(this.log_filters.from_date) ;
    const ant= 86399.9 //dia en segundos
    // llenar las variables
    for (day = 0; day <= dif_dates; day++) {
      let finalDate = new Date(aux_date.setSeconds(ant));
      // console.log('finalDate = ' + finalDate);
      let month_date_all = finalDate.getMonth() + 1;
      let day_date_all = finalDate.getDate();
      let month_day_all = month_date_all + "/" + day_date_all;
      //  console.log('var month day all=' + month_day_all );
      this.month_day_datas_types[day] = month_day_all;
      this.default_count[day] = 0;
    }
    var count = 1;
    for (count = 1; count <= 3; count ++) {
      this.getLoginCountByType(count); 
    }      
    console.log("En datas_user_types ejecuto graphics");
    setTimeout(() => {
      this.graphicsLoginCountByType();      
    }, 2000);
  }

//Obtiene los datos de logueo de un tipo de usuario
  getLoginCountByType(type: number) {
    this.loading = false;
    this.setDateRange({
      from: this.log_filters.from_date,
      to: this.log_filters.to_date,
    });
    var startTime = (document.getElementById("time_from") as HTMLInputElement)
      .value;
    var endTime = (document.getElementById("time_to") as HTMLInputElement)
      .value;
    var from_date = this.log_filters.from_date + " " + startTime + ":00";
    var to_date = this.log_filters.to_date + " " + endTime + ":00";       
         
    
   
    console.log("tipo= " + type);
    this.LCS.getUserLogsByType(from_date, to_date, type).subscribe(
      (resLoginCountByType) => {
        if (resLoginCountByType.body[0]) {                
          var i = 0;
          
          this.defaultCount();
          console.log('valores por defecto de inicio de conteo:' + this.default_count);
          this.users_count_types = this.default_count;
          console.log('valores del conteo = ' + this.users_count_types);
          while (resLoginCountByType.body[i] ) {                              
            let date = new Date(resLoginCountByType.body[i].date_log);
            let month_date = date.getMonth() + 1;
            let day_date = date.getDate();
            let month_day = month_date + "/" + day_date;
            var j;
            for (j = 0; j < this.month_day_datas_types.length; j++) {              
              if (this.month_day_datas_types[j] === month_day) {
                this.users_count_types[j]++;
                // console.log('Existe! ' + month_day + '. Suma = ' +this.users_count_types[j]);
                break;
              } 
            }           
            i++;
          }
          // console.log("users count type " + type + " =" + this.users_count_types);
          // console.log( 'Antes de asignar this.count_all_types[type] en tipo' + type + '=' + this.count_all_types[type] );
          this.count_all_types[type] = this.users_count_types;
          console.log( 'Despues this.count_all_types[type]' + this.count_all_types[type] );
          if(type === 1) {
            this.count_admin = this.users_count_types;
            console.log('Tipo 1 ADMIN '+ type + '=' + this.count_admin);
          } else if ( type === 2 ) {
            this.count_staff = this.users_count_types;
            console.log('Tipo 2 STAFF = ' + type + '='+ this.count_staff);
          } else if (type === 3){
            this.count_customer = this.users_count_types;
            console.log('Tipo 3 CUSTOMER = '+ type + '=' + this.count_customer);
          }
          // 
          console.log("En countByType count_admin = " +  this.count_admin);
          console.log("En countByType count_staff = " +  this.count_staff);
          console.log("En countByType connt_customer = " +  this.count_customer);
          console.log('Al final default_count: ' + this.default_count);          
        }
      }
    );
  }

  defaultCount(){
       // dias entre dos fechas    
       var dif_dates = moment(this.log_filters.to_date).diff(
        moment(this.log_filters.from_date), "days"  );
      var day = 0;      
      // llenar las variables
      for (day = 0; day <= dif_dates; day++) {     
        this.default_count[day] = 0;
      }  
  }


  // grafica de logueos de cada tipo de usuario
  graphicsLoginCountByType() {
    this.loading = false;
    // LINE
    console.log("En graphic months = " + this.month_day_datas_types);
    console.log("En graphic count 1 = " + this.count_all_types[1]); // this.count_admin);
    console.log("En graphic count 2 = " + this.count_all_types[2]); // this.count_staff);
    console.log("En graphic count 3 = " + this.count_all_types[3]); // this.count_customer);
    console.log("En graphic count 1 de count_admin = " +  this.count_admin);
    console.log("En graphic count 2 de count_staff = " +  this.count_staff);
    console.log("En graphic count 3 de connt_customer = " +  this.count_customer);
    
    
    var lineData = {
      labels: this.month_day_datas_types,
      datasets: [
        {
          label: "ADMIN",
          backgroundColor: 'rgba(148,159,177,0.2)',
          borderColor: 'rgba(148,159,177,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          data: this.count_admin,
        },
        {
          label: "STAFF",
          backgroundColor: "rgba(26,179,148,0.5)",
          borderColor: "rgba(26,179,148,0.7)",
          pointBackgroundColor: "rgba(26,179,148,1)",
          pointBorderColor: "#fff",
          data: this.count_staff,
        },
        {
          label: "CUSTOMER",
          backgroundColor: 'rgba(255,0,0,0.3)',
          borderColor: 'red',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          data: this.count_customer,
        },
      ],
    };
    var lineOptions = {
      responsive: true,      
      title: {
        display: true,
        text: 'Login Count by Type'
      }
    };

    
    document.getElementById("chartContainer2").innerHTML = '&nbsp;';
    document.getElementById("chartContainer2").innerHTML = '<canvas id="lineChartLoginCountType"></canvas>';

    var ctx = document.getElementById("lineChartLoginCountType"); // .getContext("2d");;
    if (ChartLoginCountTypes) {
      ChartLoginCountTypes.clear();
      ChartLoginCountTypes.destroy();
    }

    
    
    var ChartLoginCountTypes = new Chart(ctx, {
      type: "line",
      data: lineData,
      options: lineOptions,
    });
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}

